import { ManagementGroupShort } from '@coin/shared/util-enums';
import { CurrencyIsoCode } from '../constants/default-currencies.config';
import { BenchmarkData } from './bechmark-data.model';
import { MercerData } from './mercer-data.model';
import { OrganisationSnapshot } from './organisation-snapshot.model';
import { PositionAssignment } from './position-assignment.model';

export interface EmployeeDto {
  id?: string;
  createDate?: string;
  correlationId?: string;
  incentiveSeasonRecordId?: string;
  assignmentManager?: string;
  assignmentCurrency?: string;
  academicTitle?: string;
  actionReasonText?: string;
  actionType?: string;
  actionTypeText?: string;
  are?: string;
  companyEntryDate?: string;
  companyExitDate?: string;
  companyExitReason?: string;
  contractStatus?: ContractStatus;
  costCenter?: string;
  dateOfBirth?: string;
  emailAddress?: string;
  firstname?: string;
  fullName?: string;
  gender?: Gender;
  geoClusterName?: string;
  geoClusterNodeName?: string;
  gid?: string;
  gpmStatus?: string;
  employeeId?: string;
  incentivePaymentType?: string;
  companyManagerId?: string;
  lastname?: string;
  lastNamePrefix?: string;
  lineManager?: Employee;
  companyManager?: Employee;
  head?: Employee;
  lineManagerId?: string;
  locationOffice?: string;
  managementGroup?: ManagementGroupShort;
  middleName?: string;
  nationalities?: string[];
  organisationValidFrom?: string;
  orgClusterName?: string;
  orgClusterNodeName?: string;
  orgCode?: string;
  personnelNumber?: string;
  spsEligibilityEmploymentPercentage?: number;
  placeOfAction?: string;
  serviceDate?: string;
  supplierId?: string;
  surnamePrefix?: string;
  surnameSuffix?: string;
  title?: string;
  geoCluster?: string;
  orgCluster?: string;
  countryOfAre?: string;
  version?: number;
  paOrgCode?: string;
  bankId?: string;
  lastPAValidFrom?: string;
  companyEntryReason?: string;
  dateContractStatus?: string;
  dateManagementType?: string;
  localCurrencyIsoCode?: CurrencyIsoCode;
  headGid?: string;
  headId?: string;
  headName?: string;
  numberOfEmployees?: number;
  organisation?: OrganisationSnapshot;
  orgName?: string;
  orgType?: string;
  parentOrgCode?: string;
  positionName?: string;
  transactionId?: string;
  shortAre?: string;
  areCode?: string;
  areName?: string;
  consolidatedAre?: string;
  countryCodeIso2OfAre?: string;
  mercerData?: MercerData;
  name?: {
    id: number;
    firstname: string;
    lastname: string;
  };
  mercerJobCode?: string;
  mercerDataValidFrom?: string;
  positionClass?: number;
  partialJobCode?: string;
  mercerPositionClass?: number;
  gripPosition?: string;
  jobValidFrom?: string;
  city?: string;
  lineManagers?: ManagerDto[];
  companyManagers?: ManagerDto[];
  lineManagerFirstname?: string;
  lineManagerGid?: string;
  lineManagerLastname?: string;
  companyManagerFirstname?: string;
  companyManagerGid?: string;
  companyManagerLastname?: string;
  currentMercerJobCodeId?: string;
  currentMercerJobCode?: string;
  hrBusinessPartnerId?: string;
  otherSeasons?: string[];
  snapShotId?: string;
  localLevel?: string;
  workforceType?: string;
  image?: string;
  assignmentGid?: string;

  baseValueInternal?: number;
  ltiValueInternal?: number;
  stiValueInternal?: number;
  baseValueExternal?: number;
  ltiValueExternal?: number;
  stiValueExternal?: number;

  // colorado
  coloradoId?: string;
}

export interface EmployeeWithCompensation extends EmployeeDto {
  compensations: Compensation[];
}

export interface EmployeeSnapshotWithCompensation extends EmployeeDto, Compensation {}

export interface EmployeeSnapshotWithCompensationAndBenchmarking extends EmployeeDto, Compensation, BenchmarkData {}

export interface Compensation {
  localCurrency?: string;
  totalDirectCompensation?: number;
  basePay?: number;
  fixedAllowances?: number;
  targetIncentiveAmount?: number;
  flatAllowances?: number;
  allowancesPercentageInTotal?: number;
  annualPayment?: number;
  equity?: number;
  oneTimePayment?: number;
  baseSalary?: number;
  totalTargetCash?: number;
  baseSalaryInEuro?: number;
  baseSalaryValidFrom?: string;
  baseSalaryCurrency?: string;
  baseSalaryConversionDate?: string;
  targetIncentiveAmountValidFrom?: string;
  updatedAt?: string;
}

export interface Employee extends EmployeeDto {
  // local
  id?: string;
  image?: string;
  selected?: boolean;
  childNodes?: string[];
  isInScope?: boolean;
  page?: number;
  countryCode?: string;
  countryRegionHomeAddress?: string;
  baseSalaryCurrency?: string;
  baseSalary?: number;
  baseSalaryValidFrom?: string;
  baseSalaryInEuro?: number;
  baseSalaryConversionDate?: string;
  contractStatusValidFrom?: string;
  isHead?: boolean;
  headFirstname?: string;
  headLastname?: string;
  rowLevelSecurityPass?: boolean;
  allocationMemberId?: string;
  isAssigned?: boolean;
  assignmentCount?: number;
  assignments?: PositionAssignment[];
}

export interface ManagerDto {
  id: string;
  managerId: string;
  employeeId: string;
  isPrimary: boolean;
  isImported: boolean;
  isOverrideActive: boolean;
  overrideValidUntil: string;
  validFrom: string;
  createdById: string;
  createdAt: string;
  updatedById: string;
  updatedAt: string;
}

export interface EmployeeWithMaritalStatus extends Employee {
  maritalStatus?: number;
}

export enum Gender {
  Undefined = 'Undefined',
  Male = 'Male',
  Female = 'Female'
}

export enum ContractStatus {
  Terminated = 'Terminated',
  Dormant = 'Dormant',
  Active = 'Active',
  Other = 'Other'
}
