<div *ngIf="item">
  <h3 style="margin-bottom: 20px" *ngIf="item.headline && !contentEdit">{{ item.headline }}</h3>
  <mat-form-field *ngIf="contentEdit" style="margin: auto; width: 100%">
    <mat-label>{{ 'cms.content-pages.headline' | translate }}</mat-label>
    <input matInput aria-label="headline" [(ngModel)]="item.headline" [placeholder]="'Headline'" />
  </mat-form-field>
  <!-- <h3 style="margin-bottom: 20px;" *ngIf="item.headline && contentEdit" [contentEditable]="contentEdit" [textContent]="item.headline" (input)="item.headline = $event.target.textContent ? $event.target.textContent : 'headline'"></h3> -->
  <app-html-editor *ngIf="contentEdit" [item]="item" [contentEdit]="true" [isNews]="true"></app-html-editor>
  <p [ngStyle]="{ 'column-count': item.columns ? item.columns : 1 }" *ngIf="!contentEdit" style="column-gap: 20px" [innerHTML]="item.text"></p>
  <!-- <p [ngStyle]="{'column-count': item.columns ? item.columns : 1}" *ngIf="contentEdit" [contentEditable]="contentEdit" [textContent]="item.text" (input)="item.text = $event.target.textContent ? $event.target.textContent : 'text'" style="column-gap: 20px;"></p> -->
</div>
<mat-form-field *ngIf="contentEdit && item" style="margin-top: 20px">
  <mat-label>{{ 'cms.cloumns' | translate }}</mat-label>
  <input matInput aria-label="amount columns" [(ngModel)]="item.columns" [placeholder]="'cms.amount-columns' | translate" [disabled]="!contentEdit" type="number" />
</mat-form-field>
