import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PaginatedResult } from '@coin/shared/util-models';
import { IncentiveAllocationsService } from '../../merit-budget-allocation/services/incentive-allocations.service';
import { IncentiveAllocationEmployee } from '../models/incentive-allocation-employee.model';
import { EmployeeWithCompensationInfo } from '../models/merit-budget-direct-with-compensation.model';
import { MeritProcessTypeState } from '../store/merit-process-type.state';
import { MeritAllocationGeneralService } from './merit-allocation-general.service';

export interface AllocationOrgDetailsService {
  getTotalAllocations: (
    page: number,
    size: number,
    seasonId: string,
    filterText: string
  ) => Observable<PaginatedResult<EmployeeWithCompensationInfo | IncentiveAllocationEmployee>>;
  getAllocationMember: (seasonId: string, allocationMemberId: string, orgCode?: string) => Observable<EmployeeWithCompensationInfo | IncentiveAllocationEmployee>;
}

// we need this service to implement 1:1 behaviour of the merit total ui logic for the incentive total view
@Injectable()
export class AllocationOrgDetailsMappingService {
  constructor(
    private meritAllocationGeneralService: MeritAllocationGeneralService,
    private incentiveAllocationsService: IncentiveAllocationsService,
    private store: Store
  ) {}

  public get service(): AllocationOrgDetailsService {
    return this.store.selectSnapshot(MeritProcessTypeState.isMerit) ? this.meritAllocationGeneralService : this.incentiveAllocationsService;
  }
}
