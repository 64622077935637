export const LANGUAGE_COUNTRY_MAPPING: { [language: string]: string } = {
  aa: 'dj',
  ab: 'ge',
  ae: 'ir',
  af: 'za',
  ak: 'gh',
  am: 'et',
  an: 'es',
  ar: 'sa',
  as: 'in',
  av: 'az',
  ay: 'bo',
  az: 'az',
  ba: 'ru',
  be: 'by',
  bg: 'bg',
  bh: 'in',
  bi: 'vu',
  bm: 'ml',
  bn: 'bd',
  bo: 'cn',
  br: 'fr',
  bs: 'ba',
  ca: 'es',
  ce: 'ru',
  ch: 'us',
  co: 'fr',
  cr: 'us',
  cs: 'cz',
  cu: 'bg',
  cv: 'ru',
  cy: 'gb',
  da: 'dk',
  de: 'de',
  dv: 'mv',
  dz: 'bt',
  ee: 'tg',
  el: 'gr',
  en: 'gb',
  eo: 'un',
  es: 'es',
  et: 'ee',
  eu: 'es',
  fa: 'ir',
  ff: 'sn',
  fi: 'fi',
  fj: 'fj',
  fo: 'fo',
  fr: 'fr',
  fy: 'nl',
  ga: 'ie',
  gd: 'gb',
  gl: 'gb',
  gn: 'py',
  gu: 'in',
  gv: 'gb',
  ha: 'ng',
  he: 'il',
  hi: 'in',
  ho: 'pg',
  hr: 'hr',
  ht: 'ht',
  hu: 'hu',
  hy: 'am',
  hz: 'na',
  ia: 'un',
  id: 'id',
  ie: 'un',
  ig: 'ng',
  ii: 'cn',
  ik: 'us',
  io: 'un',
  is: 'is',
  it: 'it',
  iu: 'ca',
  ja: 'jp',
  jv: 'id',
  ka: 'ge',
  kg: 'cd',
  ki: 'ke',
  kj: 'ao',
  kk: 'kz',
  kl: 'gl',
  km: 'kh',
  kn: 'in',
  ko: 'kr',
  kr: 'ng',
  ks: 'in',
  ku: 'un',
  kv: 'ru',
  kw: 'gb',
  ky: 'kg',
  la: 'va',
  lb: 'lu',
  lg: 'ao',
  li: 'nl',
  ln: 'cd',
  lo: 'la',
  lt: 'lt',
  lu: 'cd',
  lv: 'lv',
  mg: 'mg',
  mh: 'mh',
  mi: 'nz',
  mk: 'mk',
  ml: 'in',
  mn: 'mn',
  mr: 'in',
  ms: 'id',
  mt: 'mt',
  my: 'mm',
  na: 'nr',
  nb: 'no',
  nd: 'za',
  ne: 'np',
  ng: 'na',
  nl: 'nl',
  nn: 'no',
  no: 'no',
  nr: 'za',
  nv: 'mx',
  ny: 'mw',
  oc: 'es',
  oj: 'ca',
  om: 'et',
  or: 'in',
  os: 'ru',
  pa: 'in',
  pi: 'in',
  pl: 'pl',
  ps: 'af',
  pt: 'pt',
  qu: 'pe',
  rm: 'ch',
  rn: 'bi',
  ro: 'ro',
  ru: 'ru',
  rw: 'rw',
  sa: 'un',
  sc: 'it',
  sd: 'pk',
  se: 'no',
  sg: 'cf',
  si: 'lk',
  sk: 'sk',
  sl: 'si',
  sm: 'ws',
  sn: 'za',
  so: 'so',
  sq: 'al',
  sr: 'rs',
  ss: 'za',
  st: 'ls',
  su: 'id',
  sv: 'se',
  sw: 'ke',
  ta: 'in',
  te: 'in',
  tg: 'tj',
  th: 'th',
  ti: 'er',
  tk: 'tm',
  tl: 'ph',
  tn: 'bw',
  to: 'to',
  tr: 'tr',
  ts: 'za',
  tt: 'ru',
  tw: 'gh',
  ty: 'pf',
  ug: 'cn',
  uk: 'ua',
  ur: 'pk',
  uz: 'uz',
  ve: 'za',
  vi: 'vn',
  vo: 'un',
  wa: 'be',
  wo: 'sn',
  xh: 'za',
  yi: 'un',
  yo: 'ng',
  za: 'cn',
  zh: 'cn',
  zu: 'za'
};
