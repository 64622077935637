import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CmsItem } from '@coin/admin/cms/util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector

  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'gmm-numbers',
  templateUrl: './numbers.component.html',
  styleUrls: ['./numbers.component.scss']
})
export class NumbersComponent implements OnInit, OnChanges {
  @Input() startanimation = false;
  @Input() item: CmsItem<'numbers'>;
  @Input() noCounting = false;
  @Input() contentEdit = false;

  // actual object type when logged: [[[currentCount: number, x: undefined], [countTo: number], [increment: number]]]
  public countUpHolder: number[][][] = [];
  public numberLengths: number[] = [];
  public amountOptions = [1, 2] as const;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.startanimation) {
      if (changes.startanimation && changes.startanimation.currentValue) {
        this.startCounting();
      }
    }
  }

  ngOnInit(): void {
    for (const itm of this.item?.numbers || []) {
      this.numberLengths.push(itm.numbers.length);
    }
  }

  public addNumber(): void {
    const newItem = {
      topText: 'New Number Top Text',
      numbers: [310000],
      bottomText: 'New Number Bottom Text'
    };
    this.numberLengths.push(1);
    this.item.numbers.push(newItem);
  }
  public removeNumber(num: number): void {
    this.countUpHolder = [];
    this.item.numbers.splice(num, 1);
    this.numberLengths.splice(num, 1);
    this.startCounting();
  }

  public startCounting(): void {
    let num = 0;
    for (const item of this.item.numbers) {
      if (item.numbers.length === 2) {
        const iterator1 = item.numbers[0] / 200;
        const iterator2 = item.numbers[1] / 200;
        this.countUpHolder[num] = [[0, 0], item.numbers, [iterator1, iterator2]];
      } else if (item.numbers.length === 1) {
        const iterator = item.numbers[0] / 200;
        this.countUpHolder[num] = [[0], item.numbers, [iterator]];
      }
      this.countUpNumber(num);
      num += 1;
    }
  }

  public amountChange(e: number, i: number, item: NumbersComponent['item']['numbers'][number]): void {
    if (e === 2) {
      item.numbers.push(1000);
    } else {
      item.numbers.splice(1, 1);
    }
  }

  public updateItemNumber(e: number, item: NumbersComponent['item']['numbers'][number], i: number): void {
    item.numbers[i] = e;
  }

  public countUpNumber(num: number): void {
    const [currentCount, countTo, increment] = this.countUpHolder[num];

    let oneMore = false;
    setTimeout(() => {
      if (currentCount[0] < countTo[0]) {
        currentCount[0] += increment[0];
        oneMore = true;
      } else {
        [currentCount[0]] = countTo;
      }

      if (currentCount.length === 2 && currentCount[1] < countTo[1]) {
        currentCount[1] += increment[1];
        oneMore = true;
      } else {
        [, currentCount[1]] = countTo;
      }
      if (oneMore) {
        this.countUpNumber(num);
      }
    }, 30);
  }
}
