import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { AuthService } from '@coin/modules/auth/data-access';

@Injectable({
  providedIn: 'root'
})
export class DeputyGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  async canActivate(): Promise<boolean> {
    return !(await this.authService.getEmulationDecoded())?.IsDeputy;
  }
}
