import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@coin/shared/util-environments';
import { FeatureFlagName, featureFlags } from '@coin/shared/util-models';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {
  private flagChange$ = new EventEmitter();

  constructor() {
    this.createRuntimeManager();
  }
  private getFlagStatus(flagName: FeatureFlagName): boolean {
    if (!featureFlags[flagName]) {
      console.error(`tried to access featureFlag ${flagName} which is not present/configured`);
      return false;
    }
    return featureFlags[flagName][environment.type] === '✅';
  }

  public get flagHasChanged$(): Observable<void> {
    return this.flagChange$.asObservable();
  }

  public isActive(flagName: FeatureFlagName): boolean {
    return this.getFlagStatus(flagName);
  }

  public isInactive(flagName: FeatureFlagName): boolean {
    return !this.getFlagStatus(flagName);
  }

  private createRuntimeManager(): void {
    if (environment.type !== 'Prod') {
      const { COIN } = this.createEmptyCoinFeatureFlagObject();
      COIN.featureFlags = {
        status: this.createRuntimeStatus.bind(this),
        toggleFlag: this.createRuntimeToggleFlag.bind(this)
      };
    }
  }

  private createEmptyCoinFeatureFlagObject(): CoinWindowObject {
    (window as unknown as CoinWindowObject).COIN = Object.create(null);
    (window as unknown as CoinWindowObject).COIN.featureFlags = Object.create(null);
    return window as unknown as CoinWindowObject;
  }

  private createRuntimeStatus(): void {
    console.table(featureFlags, [environment.type]);
  }

  private createRuntimeToggleFlag(flagName: FeatureFlagName): string {
    featureFlags[flagName][environment.type] = featureFlags[flagName][environment.type] === '✅' ? '⛔' : '✅';
    this.createRuntimeStatus();
    this.flagChange$.emit();
    return `Feature-Flag ${flagName} turned [${featureFlags[flagName][environment.type]}]`;
  }
}

interface CoinWindowObject extends Window {
  COIN: {
    featureFlags: {
      status: () => void;
      toggleFlag: (flagName: string) => string;
    };
  };
}
