import { Component, Input } from '@angular/core';
import { CmsItem } from '@coin/admin/cms/util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-news-headline',
  templateUrl: './news-headline.component.html',
  styleUrls: ['./news-headline.component.scss']
})
export class NewsHeadlineComponent {
  @Input() item: CmsItem<'headline-with-subheadline'>;
  @Input() contentEdit = false;
}
