import { TreeNode } from '../route-builder';

export const CommunicationsModule = {
  segment: 'communication',
  title: 'home.communication',
  children: {
    CommunicationComponent: {
      segment: '',
      title: 'home.communication',
      navigationRoot: true,
      children: {
        CommunicationTableComponent: {
          segment: 'overview',
          title: 'season-mgmt.communication.overview',
          icon: 'list',
          params: ['type'],
          preserveParams: ['type']
        },
        EditCommunicationComponent: {
          segment: 'edit',
          title: 'side-menu.communicationHeadline',
          hiddenNavigation: true,
          paramTitle: 'id',
          params: ['id', 'type', 'isWorkflow']
        },
        MailMonitoringComponent: {
          segment: 'mail-monitoring',
          title: 'season-mgmt.communication.mail-monitoring.title',
          icon: 'email',
          children: {
            MailMonitoringBatchesComponent: {
              segment: '',
              title: 'season-mgmt.communication.mail-monitoring.batches.title'
            },
            MailMonitoringMailsComponent: {
              segment: 'mails',
              title: 'season-mgmt.communication.mail-monitoring.mails.title',
              hiddenNavigation: true,
              paramTitle: 'batchId',
              params: ['batchId']
            }
          }
        }
      }
    }
  }
} as const satisfies TreeNode;
