export enum EquityElementTypeEnum {
  HEADLINE = 'headline',
  SUB_TITLE = 'sub-title',
  TEXT = 'text',
  MEDIA = 'media',
  TILES = 'tiles',
  CARDS = 'cards',
  SPACER = 'spacer',
  COUNTRY_INFO = 'country-info',
  DOWNLOAD = 'download'
}
