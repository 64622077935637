import { Injectable } from '@angular/core';
import { DisplayedCurrency } from '@coin/shared/util-enums';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetEmployeeProfileCurrency } from './employee-profile-currency.actions';

interface EmployeeProfileCurrencyStateModel {
  selectedCurrency: DisplayedCurrency;
}

@State<EmployeeProfileCurrencyStateModel>({
  name: 'EmployeeProfileCurrencyState',
  defaults: {
    selectedCurrency: DisplayedCurrency.Local
  }
})
@Injectable()
export class EmployeeProfileCurrencyState {
  @Selector()
  static selectedCurrency(state: EmployeeProfileCurrencyStateModel): DisplayedCurrency {
    return state.selectedCurrency;
  }

  @Action(SetEmployeeProfileCurrency)
  setEmployeeProfileCurrency(ctx: StateContext<EmployeeProfileCurrencyStateModel>, { payload }: SetEmployeeProfileCurrency): void {
    ctx.patchState({ selectedCurrency: payload });
  }
}
