import { SeasonTypeEnum } from '@coin/shared/util-enums';
import { TreeNode } from '../route-builder';

export const SeasonMgmtModule = {
  segment: 'season-mgmt',
  children: {
    SeasonMgmtComponent: {
      segment: '',
      title: 'season-mgmt.title',
      navigationRoot: true,
      children: {
        SeasonOverviewComponent: { segment: 'season-overview', icon: 'list', params: ['type'], title: 'season-mgmt.season-overview.title' },
        SeasonSettingsComponent: {
          segment: 'season-settings',
          icon: 'settings',
          params: ['type', 'seasonId'],
          paramTitle: 'seasonId',
          isVisible: { type: [SeasonTypeEnum.IncentiveV2, SeasonTypeEnum.SpecialPayment, SeasonTypeEnum.Merit, SeasonTypeEnum.PositionEvaluation] },
          title: 'season-mgmt.season-settings.title',
          disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip',
          children: {
            OrganisationInformationWrapperComponent: {
              segment: 'org-sizing',
              isVisible: { type: [SeasonTypeEnum.PositionEvaluation] },
              title: 'season-mgmt.season-settings.org-information'
            }
          }
        },
        SeasonPopulationComponent: {
          segment: 'population',
          icon: 'group',
          params: ['type', 'seasonId'],
          paramTitle: 'seasonId',
          title: 'season-mgmt.season-population.title',
          disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip'
        },
        SeasonPlanManagementComponent: {
          segment: 'plans',
          icon: 'format_list_numbered',
          params: ['type', 'seasonId'],
          paramTitle: 'seasonId',
          isVisible: { type: [SeasonTypeEnum.IncentiveV2, SeasonTypeEnum.SOG, SeasonTypeEnum.SpecialPayment] },
          title: 'season-mgmt.plan-management.title',
          disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip',
          children: {
            SeasonPlanAssignmentComponent: {
              segment: 'assignment',
              icon: 'article',
              params: ['type', 'seasonId', 'planId'],
              paramTitle: 'planId',
              isVisible: { type: [SeasonTypeEnum.IncentiveV2, SeasonTypeEnum.SOG] },
              title: 'season-mgmt.plan-assignment.title',
              disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip'
            },
            SeasonPlanDesignComponent: {
              segment: 'design',
              params: ['type', 'seasonId', 'planId'],
              paramTitle: 'planId',
              isVisible: { type: [SeasonTypeEnum.IncentiveV2, SeasonTypeEnum.SOG] },
              title: 'season-mgmt.plan-design.title',
              disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip'
            }
          }
        },
        SeasonSchemeManagementComponent: {
          segment: 'schemes',
          icon: 'tune',
          params: ['type', 'seasonId'],
          paramTitle: 'seasonId',
          isVisible: { type: [SeasonTypeEnum.Merit] },
          title: 'season-mgmt.scheme-management.title',
          disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip',
          children: {
            SeasonSchemeAssignmentComponent: {
              segment: 'assignment',
              icon: 'article',
              params: ['type', 'seasonId', 'planId'],
              paramTitle: 'planId',
              title: 'season-mgmt.scheme-management.scheme-assignment.title',
              disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip'
            },
            BudgetSchemeDesignComponent: {
              segment: 'budget-design',
              params: ['type', 'seasonId', 'planId'],
              paramTitle: 'planId',
              title: 'season-mgmt.scheme-management.budget-scheme-design.title',
              disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip'
            }
          }
        },
        SimulationManagementComponent: {
          segment: 'simulations',
          icon: 'poll',
          params: ['type', 'seasonId'],
          paramTitle: 'seasonId',
          isVisible: { type: [SeasonTypeEnum.Merit, SeasonTypeEnum.PositionEvaluation] },
          title: 'season-mgmt.simulation-mgmt.title',
          disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip',
          children: {
            MeritPlansComponent: {
              segment: 'merit-plans',
              params: ['type', 'seasonId', 'simId'],
              paramTitle: 'simId',
              isVisible: { type: [SeasonTypeEnum.Merit] },
              title: 'season-mgmt.simulation-mgmt.merit-plans.title',
              disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip'
            },
            EquityPlansComponent: {
              segment: 'equity-plans',
              params: ['type', 'seasonId', 'simId'],
              paramTitle: 'simId',
              isVisible: { type: [SeasonTypeEnum.Merit] },
              title: 'season-mgmt.simulation-mgmt.equity-plans.title',
              disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip'
            },
            IndividualMultiplierSettingsComponent: {
              segment: 'individual-multiplier-settings',
              params: ['type', 'seasonId', 'simId'],
              paramTitle: 'simId',
              isVisible: { type: [SeasonTypeEnum.Merit] },
              title: 'season-mgmt.simulation-mgmt.individual-multiplier-settings.title',
              disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip'
            },
            PositionInformationSettingsComponent: {
              segment: 'position-information-settings',
              params: ['type', 'seasonId', 'simId'],
              paramTitle: 'simId',
              isVisible: { type: [SeasonTypeEnum.Merit] },
              title: 'season-mgmt.simulation-mgmt.position-information-settings.title',
              disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip'
            },
            ValidationSettingsComponent: {
              segment: 'validation-settings',
              params: ['type', 'seasonId', 'simId'],
              paramTitle: 'simId',
              isVisible: { type: [SeasonTypeEnum.PositionEvaluation] },
              title: 'season-mgmt.simulation-mgmt.validation-settings.title',
              disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip'
            },
            PlacementSettingsComponent: {
              segment: 'placement-settings',
              params: ['type', 'seasonId', 'simId'],
              paramTitle: 'simId',
              isVisible: { type: [SeasonTypeEnum.PositionEvaluation] },
              title: 'season-mgmt.simulation-mgmt.placement-settings.title',
              disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip'
            }
          }
        },
        SeasonPublicationSettingsComponent: {
          segment: 'publication-settings',
          icon: 'manage_accounts',
          params: ['type', 'seasonId'],
          paramTitle: 'seasonId',
          title: {
            type: {
              [SeasonTypeEnum.Merit]: 'season-mgmt.budget-allocation.title',
              [SeasonTypeEnum.PositionEvaluation]: 'season-mgmt.publication-settings.title'
            }
          },
          disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip',
          isVisible: { type: [SeasonTypeEnum.Merit, SeasonTypeEnum.PositionEvaluation] }
        },
        GbrSeasonSettingsComponent: {
          segment: 'gbr/season-settings',
          icon: 'settings',
          params: ['type', 'seasonId'],
          paramTitle: 'seasonId',
          title: 'season-mgmt.season-settings.title',
          disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip',
          isVisible: { type: [SeasonTypeEnum.GBR] }
        },
        GbrRequestsOverviewComponent: {
          segment: 'gbr/requests-overview',
          icon: 'tune',
          params: ['type', 'seasonId'],
          paramTitle: 'seasonId',
          title: 'season-mgmt.monitoring.title',
          disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip',
          isVisible: { type: [SeasonTypeEnum.GBR] }
        },
        SeasonMonitoringComponent: {
          segment: 'monitoring',
          icon: 'tune',
          params: ['type', 'seasonId'],
          paramTitle: 'seasonId',
          title: 'season-mgmt.monitoring.title',
          disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip',
          isVisible: { type: [SeasonTypeEnum.IncentiveV2, SeasonTypeEnum.PositionEvaluation, SeasonTypeEnum.SpecialPayment, SeasonTypeEnum.SOG] },
          emptyPage: true,
          children: {
            RecordsComponent: {
              segment: 'records',
              params: ['type', 'seasonId'],
              title: 'season-mgmt.monitoring.records.title',
              children: {
                RecordsDetailsComponent: {
                  segment: 'details',
                  params: ['type', 'seasonId', 'recordId'],
                  title: 'season-mgmt.monitoring.record-details.title',
                  isVisible: { type: [SeasonTypeEnum.IncentiveV2, SeasonTypeEnum.PositionEvaluation, SeasonTypeEnum.SOG] }
                }
              }
            },
            ReassignmentsComponent: {
              segment: 'reassignments',
              params: ['type', 'seasonId'],
              title: 'season-mgmt.monitoring.reassignments.title',
              isVisible: { type: [SeasonTypeEnum.IncentiveV2] },
              children: {
                EditReassignmentComponent: {
                  segment: 'details',
                  params: ['type', 'seasonId'],
                  title: 'season-mgmt.monitoring.reassignment-details.title'
                }
              }
            },
            ReassignmentsMissingValuesComponent: {
              segment: 'missing-values',
              params: ['type', 'seasonId'],
              title: 'season-mgmt.monitoring.missing-values.title',
              isVisible: { type: [SeasonTypeEnum.IncentiveV2] }
            }
          }
        },
        IncentiveAchievementsComponent: {
          segment: 'achievements',
          icon: 'stacked_bar_chart',
          params: ['type', 'seasonId'],
          paramTitle: 'seasonId',
          title: 'season-mgmt.incentive-achievements.title',
          disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip',
          isVisible: { type: [SeasonTypeEnum.IncentiveV2] },
          children: {
            IncentiveAchievementOverviewComponent: {
              segment: '',
              title: 'season-mgmt.incentive-achievements.title',
              params: ['type', 'seasonId'],
              paramTitle: 'seasonId',
              children: {
                IncentiveAchievementRecordsDetailsComponent: {
                  segment: 'details',
                  params: ['type', 'seasonId', 'recordId'],
                  title: 'season-mgmt.incentive-achievements.details.title',
                  isVisible: { type: [SeasonTypeEnum.IncentiveV2] }
                }
              }
            },
            IncentiveExportsComponent: {
              segment: 'exports',
              params: ['type', 'seasonId'],
              title: 'season-mgmt.incentive-achievements.exports.title',
              isVisible: { type: [SeasonTypeEnum.IncentiveV2] }
            }
          }
        },
        PayrollComponent: {
          segment: 'letter-payroll',
          icon: 'stacked_bar_chart',
          params: ['type', 'seasonId'],
          paramTitle: 'seasonId',
          title: 'season-mgmt.letter-payroll.title',
          disabledTooltip: 'season-mgmt.menu-item-disabled-tooltip',
          isVisible: { type: [SeasonTypeEnum.Merit] },
          emptyPage: true,
          children: {
            PayrollRecordsComponent: {
              segment: 'records',
              params: ['type', 'seasonId'],
              title: 'season-mgmt.letter-payroll.records.title',
              children: {
                PayrollDetailsComponent: {
                  segment: 'details',
                  params: ['type', 'seasonId', 'employeeId'],
                  title: 'season-mgmt.letter-payroll.record-details.title'
                }
              }
            },
            PayrollExportsComponent: {
              segment: 'exports',
              params: ['type', 'seasonId'],
              title: 'season-mgmt.letter-payroll.exports.title'
            }
          }
        }
      }
    }
  }
} as const satisfies TreeNode;
