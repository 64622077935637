<div class="container"
  (click)="goToDocumentCenter()">
  <div class="image-part empty-image"
    [ngStyle]="{'background-image': direct?.image ? 'url(' + direct.image + ')' : 'unset'}">
    <span [hidden]="direct?.image" class="material-icons">person</span>
  </div>
  <div class="text-part">
    <p class="bold">{{direct?.firstname}}</p>
    <p>{{direct?.lastname}}</p>
  </div>
</div>