import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@coin/shared/util-environments';
import { DownloadFileHelper } from '@coin/shared/util-helpers';
import { ToastrService } from 'ngx-toastr';
import { Observable, filter, map, of, switchMap, tap } from 'rxjs';
import { LoadingService } from './loading/loading.service';
import { RequestHandlerService } from './request-handler.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private requestHandler: RequestHandlerService,
    private toastrService: ToastrService,
    private loadingService: LoadingService
  ) {}

  public upload(handler: string, file: File, key: string, withId = true): Observable<{ signedUrl: string }> {
    const params = new HttpParams().append('key', key).append('withId', withId);
    return this.http
      .get<{
        signedUrl: string;
      }>(`${environment.api.documentsUrl}/${handler}/upload`, { params })
      .pipe(
        switchMap(({ signedUrl }) => this.http.put(signedUrl, file).pipe(map(() => ({ signedUrl })))),
        this.requestHandler.handleError(`Error while saving the file: ${key}`, true)
      );
  }

  // TODO: remove dialog opening from service
  public download(filename: string, handler: string, confirmationDialogComponent: Type<unknown>): Observable<void> {
    return this.http
      .post(
        `${environment.api.documentsUrl}/${handler}/download`,
        {
          key: filename,
          useBase64: false
        },
        { responseType: 'text' }
      )
      .pipe(
        switchMap(response => {
          return this.dialog
            .open(confirmationDialogComponent, {
              data: {
                headline: 'general.download-ready',
                msg: 'general.download-ready-info-message',
                cancelMsg: 'general.btnCancel',
                confirmMsg: 'general.btnDownload',
                translate: true
              }
            })
            .afterClosed()
            .pipe(
              filter(confirmation => {
                if (confirmation) {
                  return true;
                }
                this.toastrService.warning('Cancelled Download');
                return false;
              }),
              switchMap(() => of(response))
            );
        }),
        map(response => (response.startsWith('data') ? DownloadFileHelper.download(response as string, filename.split('/').pop()) : this.loadSignedUrl(response))),
        tap(() => this.toastrService.success('Downloaded File')),
        this.requestHandler.handleError(`Error while loading the file: ${filename}`, true)
      )
      .pipe(this.loadingService.withLoadingScreen);
  }

  private loadSignedUrl(json: string): void {
    const data = JSON.parse(json);
    window.open(data?.signedUrl, '_blank');
  }
}
