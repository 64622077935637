import { ActivityStreamAllocationPropertyType } from '../models/activity-stream-allocation-property-type.enum';
import { ActivityStreamEvaluationMemberPropertyType } from '../models/activity-stream-evaluation-member-property-type.enum';
import { ActivityStreamItemType } from '../models/activity-stream-item-type.enum';
import { ActivityStreamSpecialPaymentProposalPropertyType } from '../models/activity-stream-special-payment-proposal-property-type.enum';
import { ActivityStreamEclPropertyType } from '../models/activity-stream-ecl-property-type.enum';

export const ActivityStreamPropertyItemTypeMapping = {
  [ActivityStreamEvaluationMemberPropertyType.PlacementState]: ActivityStreamItemType.StatusChanged,
  [ActivityStreamEvaluationMemberPropertyType.ValidationState]: ActivityStreamItemType.StatusChanged,
  [ActivityStreamEvaluationMemberPropertyType.ValidationManagerId]: ActivityStreamItemType.ManagerChanged,
  [ActivityStreamAllocationPropertyType.AllocationManagerId]: ActivityStreamItemType.ManagerChanged,
  [ActivityStreamAllocationPropertyType.IndividualMultiplierManagerId]: ActivityStreamItemType.ManagerChanged,
  [ActivityStreamSpecialPaymentProposalPropertyType.CurrentApprover]: ActivityStreamItemType.OtherEvent,
  [ActivityStreamEclPropertyType.ReviewedById]: ActivityStreamItemType.ReviewerChanged,
  [ActivityStreamEclPropertyType.ApprovedById]: ActivityStreamItemType.ApproverChanged
};
