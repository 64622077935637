import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CmsItem } from '@coin/admin/cms/util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-equity-cms-chapter',
  templateUrl: './equity-cms-chapter.component.html',
  styleUrls: ['./equity-cms-chapter.component.scss']
})
export class EquityCmsChapterComponent {
  @Input() contentEdit = false;
  @Input() item: CmsItem<'chapter'>;

  @Input() germanyRowSlideToggle = false;
  @Output() slideChange = new EventEmitter<MatSlideToggleChange>();

  public printPage(): void {
    window.print();
  }
}
