import { Employee } from '@coin/shared/util-models';
import { OrgTreeSortType } from '../../../shared/enums/org-tree-sorting.enum';
import { IncentiveAllocationEmployee } from '../../merit-shared/models/incentive-allocation-employee.model';
import { MeritAllocationOrganisationSnapshot } from '../../merit-shared/models/merit-allocation-organisation-snapshot.model';
import { EmployeeWithCompensationInfo } from '../../merit-shared/models/merit-budget-direct-with-compensation.model';
import { EmployeeAllocation } from './merit-budget-allocation-total.state';

export class LoadAllocationMembers {
  static readonly type = '[MeritBudgetAllocationTotal] Load Allocation Members';
  constructor(public readonly payload: { loadRoot: boolean; reload: boolean }) {}
}
export class LazyLoadAllocationMembers {
  static readonly type = '[MeritBudgetAllocationTotal] Lazy Load Allocation Members';
}

export class LoadAllocationMemberChildren {
  static readonly type = '[MeritBudgetAllocationTotal] Load Allocation Member Children';
  constructor(
    public readonly payload: {
      node: EmployeeAllocation;
      isFirstPageReload: boolean;
      reload?: boolean;
      resetSearchNode?: boolean;
      disableNavigation?: boolean;
      isBudgetView?: boolean;
    }
  ) {}
}

export class LoadAllocationMemberChildrenSuccess {
  static readonly type = '[MeritBudgetAllocationTotal] Load Allocation Member Children Success';
  constructor(
    public readonly payload: {
      children: (EmployeeWithCompensationInfo | IncentiveAllocationEmployee)[];
      id: string;
      reload: boolean;
      disableNavigation: boolean;
      isBudgetView: boolean;
    }
  ) {}
}
export class ReloadAllocationMemberChild {
  static readonly type = '[MeritBudgetAllocationTotal] Reload Allocation Member Child';
  constructor(public readonly id: string) {}
}

export class ResetChildrenVisibility {
  static readonly type = '[MeritBudgetAllocationTotal] Reset Children Visibility';
}

export class UpdateBreadcrumbItems {
  static readonly type = '[MeritBudgetAllocationTotal] Update Breadcrumb Items';
  constructor(public readonly payload?: { id?: string }) {}
}

export class UpdateNode {
  static readonly type = '[MeritBudgetAllocationTotal] Update Node';
  constructor(public readonly payload?: unknown) {}
}

export class UpdateSortingState {
  static readonly type = '[MeritBudgetAllocationTotal] Update Sorting';
  constructor(public readonly payload: OrgTreeSortType) {}
}

export class UpdateSearchedNode {
  static readonly type = '[MeritBudgetAllocationTotal] Update Searched node';
  constructor(public readonly payload: { node: Employee; isActive: boolean }) {}
}

export class UpdateCurrentLevel {
  static readonly type = '[MeritBudgetAllocationTotal] Update Current Level';
  constructor(public readonly payload: { levelDelta: number }) {}
}

export class SetCurrentLevel {
  static readonly type = '[MeritBudgetAllocationTotal] Set Current Level';
  constructor(public readonly payload: { level: number }) {}
}

export class LoadUserHeadOrganisations {
  static readonly type = '[Allocation] Load Organisations';
}

export class MeritAllocationOpenEmployeeInView {
  static readonly type = '[MeritBudgetAllocationTotal] Open Employee In View';
  constructor(public readonly payload: { node: Employee; fullTree: boolean }) {}
}

export class SetSelectedOrganisation {
  static readonly type = '[MeritBudgetAllocationTotal] Set Selected Organisation';
  constructor(public readonly payload: MeritAllocationOrganisationSnapshot) {}
}

export class ResetMeritBudgetAllocationTotalState {
  static readonly type = '[MeritBudgetAllocationTotal] Reset Merit Budget Allocation Total State';
}

export class UpdateNodeCompensationComponents {
  static readonly type = '[MeritBudgetAllocationTotal] Update Node Compensation Components';
  constructor(public readonly payload: EmployeeWithCompensationInfo) {}
}
