import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CmsUsages } from '@coin/admin/cms/util';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private httpHelpersService: HttpHelpersService
  ) {}

  getAllImages(): Observable<{ key: string }[]> {
    return this.http.get<{ key: string }[]>(`${environment.api.imagesService}images`).pipe(this.httpHelpersService.handleError('Cannot get images'));
  }

  uploadImage(imageEncoded: string): Observable<{ fileName: string }> {
    return this.http.post<{ fileName: string }>(`${environment.api.imagesService}images`, { imageBase64: imageEncoded });
  }

  deleteImage(key: string): Observable<unknown> {
    return this.loadingService.withLoadingScreen(this.http.delete(`${environment.api.imagesService}images/${key}`));
  }

  public getImageCmsUsage(path: string): Observable<CmsUsages> {
    return this.http
      .get<CmsUsages>(`${environment.api.imagesService}images/cms-usages?path=${encodeURIComponent(path)}`)
      .pipe(this.httpHelpersService.handleError('Cannot get image usages'));
  }
}
