import { Pipe, PipeTransform } from '@angular/core';
import { GeneralUserService } from '@coin/modules/auth/data-access';
import { Observable, of } from 'rxjs';
import { Employee, EmptyGuid } from '@coin/shared/util-models';

@Pipe({
  name: 'employeeId$',
  standalone: true
})
export class EmployeeIdPipe implements PipeTransform {
  constructor(private employeeService: GeneralUserService) {}

  transform(id: string): Observable<Employee> {
    if (id && id !== EmptyGuid) {
      return this.employeeService.getEmployeeDetails(id);
    }
    return of(null);
  }
}
