<div
  class="list-header mat-elevation-z8"
  [ngStyle]="{ 'grid-template-columns': columnWidths?.join(' '), 'padding-right': 'calc(2rem + ' + scrollbarWidth() + 'px)', background: background }">
  <div *ngFor="let column of columns; let i = index" [class.section]="column.name" [class.section--first]="i === firstNamedIndex" [class.section--last]="i === lastNamedIndex">
    <ng-container *ngIf="i === 0 && leftTemplate" [ngTemplateOutlet]="leftTemplate"></ng-container>
    <div *ngIf="column.text" class="text-wrapper">
      <p *ngIf="column.text" class="text">{{ column.text }}</p>
    </div>

    <p *ngIf="column.name" class="bold">{{ translationPrefix + '.' + pascalcaseToKebabCase(column.name) | translate }}</p>

    <mat-icon *ngIf="column.info" class="column-info-icons" [matTooltip]="column.info">info_outlined</mat-icon>

    <ng-template [ngIf]="i === columns.length - 1 && rightTemplate" [ngIfElse]="actionsTemplate">
      <ng-template [ngTemplateOutlet]="rightTemplate"></ng-template>
    </ng-template>

    <ng-template #actionsTemplate>
      <ng-container *ngIf="column?.actions?.length > 0">
        <coin-action-button class="text" [noicon]="true" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
          {{ translationPrefix + '.' + 'more-actions' | translate }}
        </coin-action-button>
        <mat-menu #menu="matMenu">
          <button *ngFor="let action of column?.actions" class="text" (click)="onActionClick(action)" mat-menu-item>
            {{ translationPrefix + '.' + pascalcaseToKebabCase(action) + '-action' | translate }}
          </button>
        </mat-menu>
      </ng-container>
    </ng-template>
  </div>
</div>
