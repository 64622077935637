export type ActivityStreamLoadConfig =
  | ActivityStreamEvaluationMemberLoadConfig
  | ActivityStreamSpecialPaymentProposalLoadConfig
  | ActivityStreamIndividualMultiplierLoadConfig
  | ActivityStreamOrgPlanningOrganisationLoadConfig
  | ActivityStreamOrgPlanningPositionLoadConfig
  | ActivityStreamOrgPlanningEmployeeLoadConfig
  | ActivityStreamSeasonLoadConfig
  | ActivityStreamRecordLoadConfig
  | ActivityStreamSuccessionManagementTalentPoolLoadConfig
  | ActivityStreamClauseVersionLoadConfig
  | ActivityStreamTemplateVersionLoadConfig
  | ActivityStreamHeadcountApprovalPositionRequestLoadConfig
  | ActivityStreamMercerMasterDataLoadConfig
  | ActivityStreamStandingPositionEvaluationLoadConfig; // add new ones here

export class ActivityStreamEvaluationMemberLoadConfig {
  seasonId: string;
  memberId: string;

  constructor(initial?: Partial<ActivityStreamEvaluationMemberLoadConfig>) {
    Object.assign(this, initial);
  }
}

export class ActivityStreamIndividualMultiplierLoadConfig {
  seasonId: string;
  allocationMemberId: string;

  constructor(initial?: Partial<ActivityStreamIndividualMultiplierLoadConfig>) {
    Object.assign(this, initial);
  }
}

export class ActivityStreamMeritLoadConfig {
  seasonId: string;
  allocationMemberId: string;

  constructor(initial?: Partial<ActivityStreamMeritLoadConfig>) {
    Object.assign(this, initial);
  }
}

export class ActivityStreamSpecialPaymentProposalLoadConfig {
  seasonId: string;
  recordId: string;

  constructor(initial?: Partial<ActivityStreamSpecialPaymentProposalLoadConfig>) {
    Object.assign(this, initial);
  }
}

export class ActivityStreamOrgPlanningOrganisationLoadConfig {
  organisationId: string;
  organisationChartId: string;

  constructor(initial?: Partial<ActivityStreamOrgPlanningOrganisationLoadConfig>) {
    Object.assign(this, initial);
  }
}

export class ActivityStreamOrgPlanningPositionLoadConfig {
  organisationChartId: string;
  positionId: string;

  constructor(initial?: Partial<ActivityStreamOrgPlanningPositionLoadConfig>) {
    Object.assign(this, initial);
  }
}

export class ActivityStreamOrgPlanningEmployeeLoadConfig {
  organisationChartId: string;
  employeeId: string;

  constructor(initial?: Partial<ActivityStreamOrgPlanningEmployeeLoadConfig>) {
    Object.assign(this, initial);
  }
}

export class ActivityStreamSeasonLoadConfig {
  seasonId: string;
  constructor(initial?: Partial<ActivityStreamSeasonLoadConfig>) {
    Object.assign(this, initial);
  }
}

export class ActivityStreamRecordLoadConfig {
  recordId: string;
  constructor(initial?: Partial<ActivityStreamRecordLoadConfig>) {
    Object.assign(this, initial);
  }
}

export class ActivityStreamSuccessionManagementTalentPoolLoadConfig {
  talentPoolId: string;

  constructor(initial?: Partial<ActivityStreamRecordLoadConfig>) {
    Object.assign(this, initial);
  }
}

export class ActivityStreamHeadcountApprovalPositionRequestLoadConfig {
  positionRequestId: string;
  constructor(initial?: Partial<ActivityStreamHeadcountApprovalPositionRequestLoadConfig>) {
    Object.assign(this, initial);
  }
}

export class ActivityStreamClauseVersionLoadConfig {
  clauseId: string;
  versionId: string;

  constructor(initial?: Partial<ActivityStreamClauseVersionLoadConfig>) {
    Object.assign(this, initial);
  }
}

export class ActivityStreamTemplateVersionLoadConfig {
  templateId: string;
  versionId: string;
  constructor(initial?: Partial<ActivityStreamTemplateVersionLoadConfig>) {
    Object.assign(this, initial);
  }
}

export class ActivityStreamMercerMasterDataLoadConfig {
  employeeId: string;
  constructor(initial?: Partial<ActivityStreamMercerMasterDataLoadConfig>) {
    Object.assign(this, initial);
  }
}

export class ActivityStreamStandingPositionEvaluationLoadConfig {
  employeeId: string;
  constructor(initial?: Partial<ActivityStreamStandingPositionEvaluationLoadConfig>) {
    Object.assign(this, initial);
  }
}
