import { TreeNode } from '../route-builder';

export const InterfacesModule = {
  segment: 'interfaces',
  title: 'home.interfaces',
  navigationRoot: true,
  children: {
    ColoradoComponent: { segment: 'colorado', title: 'interfaces.colorado.title', icon: 'list' },
    EmployeeDataComponent: { segment: 'employee-md', title: 'interfaces.employee-md.title', icon: 'paid' },
    EquityValuesComponent: { segment: 'equity-values', title: 'interfaces.equity-values.title', icon: 'balance' },
    BenchmarkDataComponent: { segment: 'benchmark-data', title: 'interfaces.benchmark-data.title', icon: 'leaderboard' }
  }
} as const satisfies TreeNode;
