import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomerSurveyAnswer, CustomerSurveyQuestion } from '@coin/modules/customer-survey/util';

@Component({
  selector: 'coin-customer-survey-question',
  templateUrl: './customer-survey-question.component.html',
  styleUrls: ['./customer-survey-question.component.scss']
})
export class CustomerSurveyQuestionComponent {
  @Input() question: CustomerSurveyQuestion;
  @Input() index: number;
  @Input() expandedPanelIndex: number;
  @Input() isEditable: boolean;
  @Input() isLocked: boolean;

  @Output() togglePanel = new EventEmitter<void>();
  @Output() answerToggle = new EventEmitter<CustomerSurveyAnswer>();
  @Output() expandNextPanel = new EventEmitter<void>();

  public get isExpanded(): boolean {
    return this.expandedPanelIndex === this.index;
  }

  public get selectedAnswer(): CustomerSurveyAnswer {
    return this.question?.answers?.find(answer => answer.id === this.question.selectedAnswerId);
  }

  public get isLastElement(): boolean {
    return !!this.selectedAnswer?.linkedSurveyResultId;
  }

  public onAnswerToggle(isSelected: boolean, toggledAnswer: CustomerSurveyAnswer): void {
    if (!isSelected) return;

    this.question.answers = this.question.answers.map(answer => ({
      ...answer,
      isSelected: answer.id === toggledAnswer.id
    }));
    this.question.selectedAnswerId = toggledAnswer.id;
    this.answerToggle.emit(toggledAnswer);
  }

  public trackByItemFn(index: number, answer: CustomerSurveyAnswer): string | number {
    return answer.id ?? index;
  }

  public togglePanelExpansion(): void {
    if (this.isExpanded) {
      return;
    }
    this.togglePanel.emit();
  }

  public openNextQuestion(): void {
    this.expandNextPanel.emit();
  }
}
