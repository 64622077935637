import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MeritEquityPlan, MeritPlan, MeritPromotionState, MeritSimulationMember } from '@coin/customer/merit-incentive/util';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { Employee, MassExportTransactionStatus, Organisation, PaginatedResult, SearchItemService, TransactionStatus } from '@coin/shared/util-models';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { IncentiveUpdateAllocationValues } from '../../merit-budget-allocation/models/incentive-update-allocation-values.model';
import { IncentiveAllocationEmployee } from '../../merit-shared/models/incentive-allocation-employee.model';
import { MeritAllocationSnapshot } from '../../merit-shared/models/merit-allocation-snapshot.model';
import { MeritBudgetCompensationInfo } from '../../merit-shared/models/merit-budget-compensation-info.model';
import { EmployeeWithCompensationInfo } from '../../merit-shared/models/merit-budget-direct-with-compensation.model';
import { UpdateAllocationValuesDto } from '../../merit-shared/models/update-allocation-values-dto.model';
import { UpdateEmployeeRelevantAllocationData } from '../../merit-shared/models/update-employee-relevant-allocation-data.model';
import { MeritAllocationSnapshotMapOperations } from '../../merit-shared/operations/merit-allocation-snapshot-map.operations';
import { ActivateRequestDto } from '../models/activate-request-dto.model';
import { DeactivateRequestDto } from '../models/deactivate-request-dto.model';
import { AllocationEmployeeExportDto } from '../models/merit-support-export-dto.model';

interface GeneralRequestDto {
  employeeId: string;
  seasonId: string;
  revert?: boolean;
}

interface UpdateSimulationMemberDto extends GeneralRequestDto {
  targetIncentiveAmountPoB: number;
}

interface ApproveNewHireDto extends GeneralRequestDto {
  mercerPositionClass?: number;
  equitySettingsId?: string;
  meritSettingsId?: string;
  equitySubSettingsId?: string;
}

interface ChangeAllocationManagerDto {
  newAllocationManagerId?: string;
  newLineManagerId?: string;
  newInCompanyManagerId?: string;
  orgCode?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MeritSupportService implements SearchItemService {
  constructor(
    private httpClient: HttpClient,
    private httpHelpersService: HttpHelpersService,
    private loadingService: LoadingService
  ) {}

  getEmployees(seasonId: string, page: number, size: number, filterText = ''): Observable<PaginatedResult<EmployeeWithCompensationInfo>> {
    return this.loadingService.withLoadingScreen(
      this.httpClient
        .get<
          PaginatedResult<EmployeeWithCompensationInfo>
        >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/employees?Paging.page=${page + 1}&Paging.size=${size}${filterText}`)
        .pipe(this.httpHelpersService.handleError('Cannot get employees'))
    );
  }

  getEmployeeByGid(seasonId: string, gid: string): Observable<PaginatedResult<EmployeeWithCompensationInfo>> {
    return this.loadingService.withLoadingScreen(
      this.httpClient
        .get<
          PaginatedResult<EmployeeWithCompensationInfo>
        >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/employees?Query.Gid=${gid}`)
        .pipe(this.httpHelpersService.handleError('Cannot get employee'))
    );
  }

  updateEmployeeRelevantAllocationData(seasonId: string, employeeId: string, data: UpdateEmployeeRelevantAllocationData): Observable<void> {
    this.loadingService.present();
    return this.httpClient.patch<void>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/employee-data/${employeeId}`, data).pipe(
      this.httpHelpersService.handleError('Cannot update employee data'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  public updateIncentiveAllocationValues(body: IncentiveUpdateAllocationValues, allocationMemberId: string): Observable<IncentiveAllocationEmployee> {
    this.loadingService.present();
    return this.httpClient
      .patch<IncentiveAllocationEmployee>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/incentive/partner/allocations/${allocationMemberId}/values`, body)
      .pipe(
        map(employee => ({ ...employee, id: employee.allocationMemberId ?? employee.id })), // hack because backend cannot deliver right id
        this.httpHelpersService.handleError('Could not update allocation.'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  updateLocalCurrency(seasonId: string, employeeId: string, simulationMemberId: string, newLocalCurrency: string): Observable<void> {
    this.loadingService.present();
    return this.httpClient
      .patch<void>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/employee-data/${employeeId}`, {
        seasonId,
        employeeId,
        simulationMemberId,
        newLocalCurrency
      })
      .pipe(
        this.httpHelpersService.handleError('Cannot update local currency'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getEmployeesToExport(seasonId: string, filterText = '', page: number, size: number): Observable<PaginatedResult<AllocationEmployeeExportDto>> {
    this.loadingService.present();
    return this.httpClient
      .get<
        PaginatedResult<AllocationEmployeeExportDto>
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocation/export?Paging.page=${page + 1}&Paging.size=${size}${filterText}`)
      .pipe(
        this.httpHelpersService.handleError('Cannot get employees for export'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getSearchItemsByText(text: string, page: number, seasonId: string): Observable<Employee[]> {
    let params = new HttpParams();

    params = params.set('page', `${page + 1}`);
    params = params.set('size', '50');
    params = params.set('seasonId', seasonId);
    params = params.set('search', text);

    return this.httpClient.get<PaginatedResult<Employee>>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/employees`, { params }).pipe(
      map(res => res.content),
      this.httpHelpersService.handleError('Cannot get employees by search text')
    );
  }

  getAllocationMember(seasonId: string, employeeId: string): Observable<MeritBudgetCompensationInfo> {
    this.loadingService.present();
    return this.httpClient.get<MeritAllocationSnapshot>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/total/${employeeId}`).pipe(
      map(info => ({ ...info, compensationComponents: MeritAllocationSnapshotMapOperations.mapCompensationComponents(info.compensationComponents) })),
      catchError(() => of(null)),
      finalize(() => this.loadingService.dismiss())
    );
  }

  // employeeId is really employeeId and not allocation member id
  updateAllocationMember(seasonId: string, employeeId: string, body: UpdateAllocationValuesDto): Observable<UpdateAllocationValuesDto> {
    return this.loadingService.withLoadingScreen(
      this.httpClient
        .put<UpdateAllocationValuesDto>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/values/${employeeId}`, body)
        .pipe(this.httpHelpersService.handleError('Cannot update values'))
    );
  }

  // employeeId is really employeeId and not allocation member id
  getSimulationMember(seasonId: string, employeeId: string): Observable<MeritSimulationMember> {
    return this.loadingService.withLoadingScreen(
      this.httpClient.get<MeritSimulationMember>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/${employeeId}`).pipe(
        map(member => ({ ...member, proratedTiaDelta: member.proratedTiaEuro ? member.proratedTiaEuro - member.individualMultiplierEstimatedPayoutAmountInEuro : undefined })),
        catchError(() => {
          console.error('Cannot get simulation member');
          return of(null); // ignore errors
        })
      )
    );
  }

  // employeeId is really employeeId and not allocation member id
  updateSimulationMember(seasonId: string, employeeId: string, body: UpdateSimulationMemberDto): Observable<MeritSimulationMember> {
    this.loadingService.present();
    return this.httpClient.patch<MeritSimulationMember>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/${employeeId}`, body).pipe(
      this.httpHelpersService.handleError('Cannot update simulation member'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  getEquitySettings(seasonId: string, employeeId: string, page: number, size: number, positionClass?: number): Observable<MeritEquityPlan[]> {
    const positionClassQuery = positionClass !== undefined ? `&positionClass=${positionClass}` : '';

    return this.httpClient
      .get<
        PaginatedResult<MeritEquityPlan>
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/${employeeId}/equity-settings?page=${page + 1}&size=${size}${positionClassQuery}`)
      .pipe(
        map(result => result.content),
        this.httpHelpersService.handleError('Cannot get equity settings')
      );
  }

  getMeritSettings(seasonId: string, employeeId: string, page: number, size: number): Observable<MeritPlan[]> {
    return this.httpClient
      .get<
        PaginatedResult<MeritPlan>
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/${employeeId}/merit-settings?page=${page + 1}&size=${size}`)
      .pipe(
        map(result => result.content),
        this.httpHelpersService.handleError('Cannot get merit settings')
      );
  }

  activate(body: ActivateRequestDto): Observable<MeritSimulationMember> {
    this.loadingService.present();
    return this.httpClient
      .patch<MeritSimulationMember>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${body.seasonId}/allocations/${body.employeeId}/activate`, body)
      .pipe(
        this.httpHelpersService.handleError('Cannot activate employee'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  deactivate(seasonId: string, employeeId: string, body: DeactivateRequestDto): Observable<MeritSimulationMember> {
    this.loadingService.present();
    return this.httpClient
      .patch<MeritSimulationMember>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/${employeeId}/deactivate`, body)
      .pipe(
        this.httpHelpersService.handleError('Cannot deactivate employee'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  reviewPromotion(seasonId: string, employeeId: string, state: MeritPromotionState): Observable<void> {
    this.loadingService.present();
    return this.httpClient
      .patch<void>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/${employeeId}/review-promotion`, {
        state
      })
      .pipe(
        this.httpHelpersService.handleError('Cannot review promotion'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public promoteEmployee(seasonId: string, employeeId: string, comment: string): Observable<void> {
    this.loadingService.present();
    return this.httpClient
      .patch<void>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/${employeeId}/promote`, {
        state: MeritPromotionState.Requested,
        comment
      })
      .pipe(
        this.httpHelpersService.handleError('Cannot promote employee'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  rejectNewHire(seasonId: string, employeeId: string, body: GeneralRequestDto): Observable<void> {
    this.loadingService.present();
    return this.httpClient.patch<void>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/${employeeId}/reject-new-hire`, body).pipe(
      this.httpHelpersService.handleError('Cannot reject new hire'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  rejectTermination(seasonId: string, employeeId: string, body: GeneralRequestDto): Observable<void> {
    this.loadingService.present();
    return this.httpClient.patch<void>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/${employeeId}/reject-termination`, body).pipe(
      this.httpHelpersService.handleError('Cannot reject termination'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  approveNewHire(seasonId: string, employeeId: string, body: ApproveNewHireDto): Observable<void> {
    this.loadingService.present();
    return this.httpClient.patch<void>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/${employeeId}/approve-new-hire`, body).pipe(
      this.httpHelpersService.handleError('Cannot approve new hire'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  public changeAllocationManager(
    seasonId: string,
    employeeId: string,
    body: ChangeAllocationManagerDto,
    errorMessage = 'allocation manager'
  ): Observable<EmployeeWithCompensationInfo> {
    this.loadingService.present();
    return this.httpClient
      .put<EmployeeWithCompensationInfo>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/${employeeId}/change-allocation-manager`, body)
      .pipe(
        this.httpHelpersService.handleError(`Cannot change ${errorMessage}`),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getFieldValues(page: number, size: number, key: string, searchText: string, seasonId: string): Observable<PaginatedResult<string>> {
    const searchQuery = searchText?.trim() ? `&${key.replace('Inverted', '')}=${encodeURIComponent(searchText)}` : '';

    return this.httpClient
      .get<
        PaginatedResult<string>
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/field-values?page=${page + 1}&size=${size}&targetProperty=${key.replace('Inverted', '')}${searchQuery}`)
      .pipe(catchError(() => of(null)));
  }

  public updateInternalLevel(seasonId: string, employeeId: string, simulationMemberId: string, internalLevel: string): Observable<EmployeeWithCompensationInfo> {
    this.loadingService.present();
    return this.httpClient
      .patch<EmployeeWithCompensationInfo>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/employee-data/${employeeId}`, {
        seasonId,
        employeeId,
        simulationMemberId,
        internalLevel
      })
      .pipe(
        this.httpHelpersService.handleError('Cannot change internal level'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  downloadMeritExcelExport(seasonId: string, fileName: string, filterQuery = ''): Observable<TransactionStatus<MassExportTransactionStatus>> {
    return this.httpClient.post<TransactionStatus<MassExportTransactionStatus>>(
      `${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocation/export${filterQuery}`,
      {
        fileName
      }
    );
  }

  downloadSimplifiedMeritExcelExport(seasonId: string, fileName: string, filterQuery = ''): Observable<TransactionStatus<MassExportTransactionStatus>> {
    return this.httpClient.post<TransactionStatus<MassExportTransactionStatus>>(
      `${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocation/simplified/export${filterQuery}`,
      {
        fileName
      }
    );
  }

  downloadIndividualMultiplierExcelExport(seasonId: string, fileName: string, filterQuery = ''): Observable<TransactionStatus<MassExportTransactionStatus>> {
    return this.httpClient.post<TransactionStatus<MassExportTransactionStatus>>(
      `${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/individual-multiplier/export${filterQuery}`,
      {
        fileName
      }
    );
  }

  getMeritSupportCommunicationSetting(seasonId: string): Observable<{ isPromotionCommunicationActive: boolean } | null> {
    return this.httpClient
      .get<{ isPromotionCommunicationActive: boolean } | null>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/${seasonId}/communication-settings`)
      .pipe(this.httpHelpersService.handleError('Cannot get merit communication settings'));
  }

  updateMeritSupportCommunicationSetting(seasonId: string, isPromotionCommunicationActive: boolean): Observable<void> {
    return this.httpClient
      .patch<void>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/${seasonId}/communication-settings`, { isPromotionCommunicationActive })
      .pipe(this.httpHelpersService.handleError('Cannot update merit communication settings'));
  }

  public getOrganizations(page: number, search: string): Observable<PaginatedResult<Organisation>> {
    return this.httpClient
      .get<
        PaginatedResult<Organisation>
      >(`${environment.api.baseUrl}/admin/api/v3/master/organisations?Size=50&Page=${page}&Query.OrgCode=${encodeURIComponent(search)}&Sortable.OrderBy=Asc&Sortable.Property=OrgCode`)
      .pipe(
        catchError((res: HttpErrorResponse) => {
          this.httpHelpersService.handleError('Error retrieving org codes.');
          return throwError(() => `Error occured. ${res?.error?.reason || 'Unknown'}`);
        })
      );
  }
}
