import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MeritProcessType } from '../enums/merit-process-type.enum';
import { SetMeritProcessType, SetMeritProcessTypeWithRouterChange } from './merit-process-type.actions';

interface MeritProcessTypeStateModel {
  selectedProcessType: MeritProcessType;
}

@State<MeritProcessTypeStateModel>({
  name: 'MeritProcessTypeState',
  defaults: {
    selectedProcessType: MeritProcessType.Merit
  }
})
@Injectable()
export class MeritProcessTypeState {
  constructor(
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  @Selector()
  static selectedProcessType(state: MeritProcessTypeStateModel): MeritProcessType {
    return state.selectedProcessType;
  }

  @Selector()
  static isMerit(state: MeritProcessTypeStateModel): boolean {
    return state.selectedProcessType === MeritProcessType.Merit;
  }

  @Selector()
  static isIncentive(state: MeritProcessTypeStateModel): boolean {
    return state.selectedProcessType === MeritProcessType.Incentive;
  }

  @Action(SetMeritProcessType)
  setMeritProcessType(ctx: StateContext<MeritProcessTypeStateModel>, { payload }: SetMeritProcessType): void {
    ctx.patchState({ selectedProcessType: payload });
  }

  @Action(SetMeritProcessTypeWithRouterChange)
  setMeritProcessTypeWithRouterChange(ctx: StateContext<MeritProcessTypeStateModel>, { payload }: SetMeritProcessTypeWithRouterChange): void {
    ctx.dispatch(new SetMeritProcessType(payload));
    this.ngZone.run(
      function (this: Router, navigationExtras): Promise<boolean> {
        return this.navigate([], navigationExtras);
      },
      this.router,
      [{ relativeTo: this.activatedRoute, queryParams: { processType: payload }, queryParamsHandling: 'merge' }]
    );
  }
}
