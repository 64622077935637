import { Injectable } from '@angular/core';
import { EmployeeService } from '@coin/modules/auth/data-access';
import { UserState } from '@coin/modules/auth/data-management';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MeritAllocationImpersonatedEmployeeService {
  public employeeId: string;

  constructor(
    private store: Store,
    private employeeService: EmployeeService
  ) {}

  public getEmployeeIdQuery(prefix = '&'): string {
    return this.employeeId ? `${prefix}impersonatedEmployee=${encodeURIComponent(this.employeeId)}` : '';
  }

  public getGid(): Observable<string> {
    if (!this.employeeId) {
      return this.store.select(UserState.user).pipe(
        take(1),
        map(user => user?.gid)
      );
    }

    return this.employeeService.getEmployeeDetails(this.employeeId).pipe(map(employee => employee?.gid));
  }
}
