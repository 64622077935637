import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Employee } from '@coin/shared/util-models';
import { DirectsOverviewTabViewComponent } from './directs-overview-tab-view/directs-overview-tab-view.component';

export interface DirectsInterface {
  allDirects: Employee[];
  lineManager: Employee[];
  inCompanyManager: Employee[];
}

@Component({
  selector: 'app-directs-overview',
  templateUrl: './directs-overview.component.html',
  styleUrls: ['./directs-overview.component.scss']
})
export class DirectsOverviewComponent implements OnInit {
  @ViewChild('matTabGroup') matTabGroup: MatTabGroup;
  @ViewChild('tabAllDirects') tabAllDirects: DirectsOverviewTabViewComponent;
  @ViewChild('tabLineManager') tabLineManager: DirectsOverviewTabViewComponent;
  @ViewChild('tabInCompanyManager') tabInCompanyManager: DirectsOverviewTabViewComponent;

  searchEmployee = '';

  constructor(
    public dialogRef: MatDialogRef<DirectsOverviewComponent>,
    @Inject(MAT_DIALOG_DATA) public directs: DirectsInterface
  ) {}

  ngOnInit(): void {
    if (this.dialogRef.addPanelClass) {
      // Function is not defined in Unit-Tests
      this.dialogRef.addPanelClass('d1r-dialog-container-bo');
    }

    this.directs.allDirects = [...(this.directs.lineManager || []), ...(this.directs.inCompanyManager || [])];

    this.directs.allDirects = this.directs.allDirects.filter((direct, index) => this.directs.allDirects.findIndex(item => item?.id === direct?.id) === index);
  }

  close(): void {
    this.dialogRef.close();
  }

  onTabSelectedChange(event: MatTabChangeEvent): void {
    switch (event.index) {
      case 0:
        this.tabAllDirects.onTabSelectedChange(event);
        break;
      case 1:
        this.tabLineManager.onTabSelectedChange(event);
        break;
      case 2:
        this.tabInCompanyManager.onTabSelectedChange(event);
        break;
    }
  }
}
