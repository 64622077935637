import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@coin/shared/util-environments';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { PaginatedResult } from '@coin/shared/util-models';
import { OrganisationService } from '../../../shared/models/organisation-service.model';
import { MeritAllocationOrganisationSnapshot } from '../models/merit-allocation-organisation-snapshot.model';

const DEFAULT_PAGE_SIZE = 100_000;

@Injectable({
  providedIn: 'root'
})
export class MeritAllocationOrganisationsService implements OrganisationService {
  constructor(
    private httpClient: HttpClient,
    private httpHelpersService: HttpHelpersService,
    private loadingService: LoadingService
  ) {}

  getOrganisations(seasonId: string, filterText = ''): Observable<MeritAllocationOrganisationSnapshot[]> {
    this.loadingService.present();
    return this.httpClient
      .get<
        PaginatedResult<MeritAllocationOrganisationSnapshot>
      >(`${environment.api.baseUrl}/admin/api/v3/master/seasons/${seasonId}/snapshots/organisations?page=1&size=${DEFAULT_PAGE_SIZE}${filterText}`)
      .pipe(
        map(data => data.content),
        this.httpHelpersService.handleError('Cannot get organisations'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getParentOrganisation(seasonId: string, orgCode: string): Observable<MeritAllocationOrganisationSnapshot> {
    this.loadingService.present();
    return this.httpClient
      .get<MeritAllocationOrganisationSnapshot>(
        `${environment.api.baseUrl}/admin/api/v3/master/seasons/${seasonId}/snapshots/organisations/parents?query.orgCode=${encodeURIComponent(orgCode)}`
      )
      .pipe(
        this.httpHelpersService.handleError('Cannot get parent organisation'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getChildOrganisations(orgCode: string, seasonId?: string): Observable<MeritAllocationOrganisationSnapshot[]> {
    this.loadingService.present();
    return this.httpClient
      .get<
        PaginatedResult<MeritAllocationOrganisationSnapshot>
      >(`${environment.api.baseUrl}/admin/api/v3/master/seasons/${seasonId}/snapshots/organisations?query.parentOrgCode=${encodeURIComponent(orgCode)}`)
      .pipe(
        map(result => result.content),
        this.httpHelpersService.handleError('Cannot get children organisations'),
        finalize(() => this.loadingService.dismiss())
      );
  }
}
