import { Pipe, PipeTransform } from '@angular/core';

type DynamicType = 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function';

@Pipe({
  name: 'typeof',
  standalone: true
})
export class TypeofPipe implements PipeTransform {
  transform<T extends DynamicType>(value: unknown, type: T): boolean {
    return typeof value === type;
  }
}
