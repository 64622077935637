<div class="container">
  <div *ngIf="pageContent && !contentHidden" class="content">
    <div class="header">
      <div *ngIf="parent.settings.overviewPage" class="cp-menu" (click)="openOverview()" [ngClass]="{ 'cp-menu--active': currentRoute === '/' + this.parent.settings.routing }">
        <div class="material-icons-outlined">other_houses</div>
        <p style="color: white">{{ 'content-pages.overview' | translate }}</p>
      </div>
      <div
        *ngIf="parent.pages.length > 0 && parent.settings.showPagesInDropdown"
        #cpmenu
        class="cp-menu cp-menu--programs"
        (click)="toggleProgramsMenu()"
        [ngClass]="{ 'cp-menu--active cp-menu--programs-active': programsMenuActive }">
        <div class="material-icons-outlined">view_carousel</div>
        <p style="color: white">{{ 'content-pages.programs' | translate }}</p>
        <div *ngIf="programsMenuActive" class="programs-menu">
          <div
            *ngFor="let menu of parent.pages"
            (click)="menuItemClicked($event, menu)"
            [ngClass]="{ 'programs-menu-active': getMenuActive(menu) }"
            (mouseenter)="menu.hover = true"
            (mouseleave)="menu.hover = false"
            class="programs-menu-item"
            @animateMenu>
            <div class="material-icons-outlined">{{ menu.settings.icon }}</div>
            <p>{{ menu.settings.title }}</p>
            <div style="flex: 1"></div>
            <div *ngIf="menu.pages" class="material-icons">chevron_right</div>
            <div *ngIf="menu.pages && menu.hover" class="programs-submenu" @animateMenu>
              <div
                *ngFor="let submenu of menu.pages"
                [ngClass]="{ 'programs-menu-active': getMenuActive(menu, submenu) }"
                (click)="menuItemClicked($event, submenu, menu)"
                class="programs-menu-item">
                <div class="material-icons-outlined">{{ submenu.settings.icon }}</div>
                <p>{{ submenu.settings.title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="parent.settings.documentsPage && isAuthenticated"
        class="cp-menu"
        (click)="openDocuments()"
        [ngClass]="{ 'cp-menu--active': currentRoute.endsWith('/documents') }">
        <div class="material-icons-outlined">folder</div>
        <p style="color: white">{{ 'content-pages.documents' | translate }}</p>
      </div>
    </div>
    <div class="main-part">
      <app-equity-cms-chapter-overview
        *ngIf="!pageContent[usedLang]?.hideChapterOverview && (responsive.isMobile$ | async) === false"
        [item]="pageContent[usedLang]?.content"
        [collapsed]="collapsedHeader$ | async"></app-equity-cms-chapter-overview>
      <div class="simplebar">
        <ngx-simplebar class="primary-scrollbar-vertical equity cp-scrollthing" id="equity-simplebar">
          <div class="dynamic-content">
            <div class="content-pages-main-part news" style="padding-bottom: 100px">
              <app-all-cms-news-items
                [isResponsive]="responsive.isMobile$ | async"
                [hideChapters]="true"
                [item]="pageContent[usedLang]"
                [settings]="pageSettings"
                (slideChange)="slideChange($event)"
                [germanyRowType]="germanyRowType"
                [germanyRowSlideToggle]="germanyRowSlideToggle"
                [authService]="authService"
                [isEquityPage]="true"
                style="padding-right: 20px"></app-all-cms-news-items>
            </div>
          </div>
        </ngx-simplebar>
      </div>
    </div>
  </div>
</div>
