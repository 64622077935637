<div class="comment">
  <div class="triangle"></div>

  <div class="user">
    <p class="username">
      <span><b>{{comment?.author?.lastname}}, {{comment?.author?.firstname}} </b></span>
      <span class="gid" *ngIf="myComment || comment?.author?.gid">(GID: {{comment?.author?.gid || user?.gid}})</span>
    </p>
    <p *ngIf="comment?.reason" class="reason"><i>Reason: {{comment?.reason}}</i></p>
  </div>
  <p class="text-area">{{comment?.text}}</p>
  <div class="date">
    <span>{{comment?.['timestamp'] || comment?.['createdAt'] | date:'MM/dd/yyyy HH:mm'}} UTC</span>
  </div>
</div>