export enum MercerJobCodeSearchablePropertyEnum {
  // Do not change order, append to end
  FamilyCode = 'familyCode',
  SubFamilyCode = 'subFamilyCode',
  SpecializationCode = 'specializationCode',
  CareerStream = 'careerStream',
  CareerLevel = 'careerLevel',
  CareerLevelShort = 'careerLevelShort',
  JobCode = 'jobCode'
}
