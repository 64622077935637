import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TopicOpenType } from '@coin/shared/util-enums';
import { Customer } from '@coin/shared/util-helpers';
import { CommunicationContent, Topic } from '@coin/shared/util-models';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TafDialogComponent } from '../../home/dialogs/taf-dialog/taf-dialog.component';
import { NewsPopupComponent } from '../dialogs/news-popup/news-popup.component';

@Injectable({
  providedIn: 'root'
})
export class TopicItemOpenHandlerService {
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private toast: ToastrService
  ) {}

  public handle(topic: Topic, type: TopicOpenType): void {
    switch (type) {
      case TopicOpenType.Content:
        this.openContent(topic);
        break;
      case TopicOpenType.TafDialg:
        this.openTafDialog(topic);
        break;
      case TopicOpenType.Allocation:
        this.openMeritAllocation(topic);
        break;
      case TopicOpenType.FinalIncumbent:
        this.openManagerApp(topic);
        break;
      case TopicOpenType.HeadcountActions:
        this.openHeadcountApproval();
        break;
    }
  }

  private openContent(topic: Topic): void {
    const languageContent = this.getLanguageContent(topic);
    languageContent
      ? this.dialog.open(NewsPopupComponent, {
          width: '100vw',
          maxWidth: '1200px',
          height: '95vh',
          data: {
            dto: topic,
            title: languageContent.title,
            description: languageContent.description,
            type: topic.type
          },
          disableClose: true
        })
      : this.toast.error(this.translateService.instant('general.loading-error'));
  }

  private getLanguageContent(topic: Topic): CommunicationContent {
    if (topic?.contents?.length) {
      const foundContent: CommunicationContent = topic.contents.find(content => content.language === this.translateService?.currentLang);
      return foundContent || topic.contents[0];
    }
    return;
  }

  private openHeadcountApproval(): void {
    Customer.navigate(this.router, 'HeadcountApprovalIntroductionComponent')
      .then((successful: boolean) => {
        if (!successful) {
          this.toast.error(this.translateService.instant('general.loading-error'));
        }
      })
      .catch(error => {
        this.toast.error(this.translateService.instant('general.loading-error'));
      });
  }

  private openTafDialog(topic: Topic): void {
    this.dialog.open(TafDialogComponent, {
      disableClose: true,
      panelClass: 'close-icon-and-transition',
      data: {
        todos: topic.todos
      }
    });
  }

  private openMeritAllocation(topic: Topic): void {
    this.router.navigate(['budget-allocation/list-view'], { queryParams: { seasonId: topic.season.id } });
  }

  private openManagerApp(topic: Topic): void {
    Customer.navigate(this.router, 'MercerPositionListViewComponent', { queryParams: { seasonId: topic.season.id } });
  }
}
