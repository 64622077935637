import { AngularEditorConfig } from '@kolkov/angular-editor';

export const AngularEditorHTMLConfig: AngularEditorConfig = {
  editable: true,
  spellcheck: false,
  height: 'auto',
  minHeight: '0',
  maxHeight: 'auto',
  width: 'auto',
  minWidth: '0',
  enableToolbar: true,
  showToolbar: true,
  toolbarPosition: 'top',
  defaultParagraphSeparator: '',
  defaultFontName: '',
  defaultFontSize: '',
  sanitize: false,
  toolbarHiddenButtons: [
    [
      //   'undo',
      //   'redo',
      //   'bold',
      //   'italic',
      //   'underline',
      //   'strikeThrough',
      //   'subscript',
      //   'superscript',
      'justifyLeft',
      'justifyCenter',
      'justifyRight',
      'justifyFull',
      'indent',
      'outdent',
      'insertUnorderedList',
      'insertOrderedList',
      'heading',
      'fontName'
    ],
    ['fontSize', 'textColor', 'backgroundColor', 'customClasses', 'link', 'unlink', 'insertImage', 'insertVideo', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode']
  ]
};
