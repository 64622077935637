import { Pipe, PipeTransform } from '@angular/core';
import { ManagementGroup, ManagementGroupShort } from '@coin/shared/util-enums';

@Pipe({ name: 'mgTranslation', standalone: true })
export class MgmtGroupTranslationPipe implements PipeTransform {
  private static managementGroupShortMap: Record<ManagementGroupShort, ManagementGroup> = {
    [ManagementGroupShort.ManagingBoard]: ManagementGroup.ManagingBoard,
    [ManagementGroupShort.TopManagement]: ManagementGroup.TopManagement,
    [ManagementGroupShort.SeniorManagement]: ManagementGroup.SeniorManagement,
    [ManagementGroupShort.NonSeniorManagement]: ManagementGroup.NonSeniorManagement,
    [ManagementGroupShort.Default]: ManagementGroup.Default
  };

  public static translate(managementGroupShort: ManagementGroupShort): ManagementGroup {
    return this.managementGroupShortMap[managementGroupShort];
  }

  transform(managementGroupShort: ManagementGroupShort): ManagementGroup | string {
    if (!managementGroupShort) return '';

    return MgmtGroupTranslationPipe.translate(managementGroupShort) || '';
  }
}
