import { Directive, inject, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: 'ng-template[coinValue]',
  standalone: true
})
/**
 * Directive that allows for passing data along templates.
 * */
export class TemplateWithValueDirective<T> {
  @Input({ alias: 'coinValue', required: true }) value: T;
  @Input() disabled?: boolean;

  public template = inject(TemplateRef);
}
