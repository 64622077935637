import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { V2DialogFooterComponent } from './footer/v2-dialog-footer.component';
import { V2DialogFrameComponent } from './v2-dialog-frame.component';

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule, CdkPortalOutlet],
  declarations: [V2DialogFrameComponent, V2DialogFooterComponent],
  exports: [V2DialogFrameComponent, V2DialogFooterComponent]
})
export class V2DialogModule {}
