import { TreeNode } from '../route-builder';

export const HeadCountApprovalModule = {
  segment: 'headcount-approval',
  children: {
    HeadCountApprovalComponent: { segment: '' },
    HeadcountApprovalIntroductionComponent: { segment: 'introduction' },
    HeadcountApprovalTasksComponent: { segment: 'my-tasks' },
    HeadcountApprovalAllRequestsComponent: { segment: 'all-requests' }
  }
} as const satisfies TreeNode;
