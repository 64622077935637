import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@coin/shared/util-environments';
import { ExportFile, MassExportMetadata, MassExportTransactionStatus, S3File, TransactionStatus } from '@coin/shared/util-models';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { TransactionState } from '@coin/shared/util-enums';
import { LoadingService } from './loading/loading.service';
import { RequestHandlerService } from './request-handler.service';

@Injectable({
  providedIn: 'root'
})
export class MassExportService {
  constructor(
    private http: HttpClient,
    private requestHandler: RequestHandlerService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private loadingService: LoadingService
  ) {}

  public getUploadList(seasonId: string): Observable<ExportFile[]> {
    const params = new HttpParams().append('prefix', `${seasonId}/Done/`);
    return this.http
      .get<S3File<MassExportMetadata>[]>(`${environment.api.documentsUrl}/mass-export/list/info`, {
        params
      })
      .pipe(
        map(result =>
          result.map(file => ({
            name: this.getName(file.key),
            originalName: file.key,
            type: this.getType(file.key),
            size: file.size,
            created: file.metadata.exportedat,
            createdBy: file.metadata.exportedby_gid, // `${file.metadata.userfirstname} ${file.metadata.userlastname}` right now the BE is only setting GID in the metadata. If name is added please change here
            gid: file.metadata.exportedby_gid,
            entityId: file.metadata.entityid,
            queryFilter: file.metadata.queryfilter,
            state: file.metadata.state
          }))
        )
      );
  }

  private getName(key: string): string {
    return key.split('/').pop();
  }

  private getType(key: string): string {
    return key.split('.').pop();
  }

  public downloadMassExport(massExportTransactionStatus: TransactionStatus<MassExportTransactionStatus>, confirmationDialogComponent: Type<unknown>): Observable<void> {
    switch (massExportTransactionStatus.state) {
      case TransactionState.Cancelled:
        return EMPTY;
      case TransactionState.Failed:
        throw Error('Export failed.');
      case TransactionState.Finished:
        return this.callMassExportDownload(massExportTransactionStatus.metadata.filename, confirmationDialogComponent);
    }
  }

  public downloadMassExportFile(filename: string, confirmationDialogComponent: Type<unknown>): Observable<void> {
    return this.callMassExportDownload(filename, confirmationDialogComponent);
  }

  // TODO: remove dialog opening from service
  private callMassExportDownload(filename: string, confirmationDialogComponent: Type<unknown>): Observable<void> {
    return this.http
      .get<{
        'signed-url': string;
      }>(`${environment.api.exportDownloadServiceUrl}/download?name=${encodeURIComponent(filename)}`)
      .pipe(
        switchMap(response => {
          return this.dialog
            .open(confirmationDialogComponent, {
              data: {
                headline: 'general.download-ready',
                msg: 'general.download-ready-info-message',
                cancelMsg: 'general.btnCancel',
                confirmMsg: 'general.btnDownload',
                translate: true
              }
            })
            .afterClosed()
            .pipe(
              filter(confirmation => {
                if (confirmation) {
                  return true;
                }
                this.toastrService.warning('Cancelled Download');
                return false;
              }),
              switchMap(() => of(response))
            );
        }),
        map(response => this.loadSignedUrl(response['signed-url'])),
        tap(() => this.toastrService.success('Downloaded File')),
        this.requestHandler.handleError(`Error while loading the file: ${filename}`, true)
      )
      .pipe(this.loadingService.withLoadingScreen);
  }

  private loadSignedUrl(url: string): void {
    window.open(url, '_blank');
  }
}
