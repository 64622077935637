import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImageService } from '@coin/admin/cms/data-access';
import { CmsUsages } from '@coin/admin/cms/util';
import { ConfirmationDialogComponent } from '@coin/shared/feature-legacy-components';
import { environment } from '@coin/shared/util-environments';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter, finalize, Observable, of, switchMap, tap } from 'rxjs';

export interface DialogData {
  imageUrl: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'gmm-select-image',
  templateUrl: './select-image.component.html',
  styleUrls: ['./select-image.component.scss']
})
export class SelectImageComponent implements OnInit {
  public isLoading = false;
  public isUsageLoading = false;
  public selectedImageUrl: string;
  public cmsUsages: CmsUsages;
  public thumbnailUrls: string[] = [];
  public saveCalculatedCmsUsages: Record<string, CmsUsages> = {};
  public isUsageAlreadyCalculated: boolean;

  public get isImageUsed(): boolean {
    return this.cmsUsageCounter > 0;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { img: string },
    private dialogRef: MatDialogRef<SelectImageComponent, DialogData>,
    private imageService: ImageService,
    private dialog: MatDialog,
    private toast: ToastrService,
    private translate: TranslateService,
    private destroyRef: DestroyRef
  ) {}

  public ngOnInit(): void {
    this.loadAllImages();
    this.selectedImageUrl = this.data?.img;
  }

  /**
   * Select the original image for a selected thumbnail.
   * @param path The thumbnail image path
   */

  public selectImage(path: string): void {
    this.selectedImageUrl = path.replace('thumbnails', 'originals');
    this.isUsageAlreadyCalculated = Object.keys(this.saveCalculatedCmsUsages).includes(this.selectedImageUrl);
    this.cmsUsages = this.isUsageAlreadyCalculated ? this.saveCalculatedCmsUsages[this.selectedImageUrl] : undefined;
  }

  /**
   * Confirm the current image selection.
   */
  public confirm(): void {
    this.dialogRef.close({ imageUrl: this.selectedImageUrl });
  }

  /**
   * Discard the current image selection.
   */
  public discard(): void {
    this.dialogRef.close();
  }

  public loadAllImages(): void {
    this.isLoading = true;
    this.imageService
      .getAllImages()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(thumbnails => {
        this.thumbnailUrls = thumbnails.map(imagePath => environment.coinImagePath + imagePath.key);
        this.isLoading = false;
      });
  }
  /**
   * Upload a file to the cloud. Creates a thumbnail as well and updates the current selection.
   * @param event The file input event.
   */
  public async uploadImage(event: Event): Promise<void> {
    this.isLoading = true;
    const file = (event.target as HTMLInputElement).files[0];
    // element should be the same as event.target
    (document.getElementById('imageInput') as HTMLInputElement).value = null; // Reset input field to trigger event again on same image
    if (file) {
      const base64Image = await this.readFile(file);
      this.imageService
        .uploadImage(base64Image)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
          response => {
            // Get image
            this.selectedImageUrl = `${environment.coinImagePath}coin/images/originals/${response.fileName}`;
            this.thumbnailUrls.push(`${environment.coinImagePath}coin/images/thumbnails/${response.fileName}`);
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          }
        );
    }
  }

  public deleteImage(url: string): void {
    const deleteImgUrl = this.selectedImageUrl.split('/').at(-1);
    this.isUsageAlreadyCalculated = Object.keys(this.saveCalculatedCmsUsages).includes(deleteImgUrl);
    this.getImageCmsUsage(deleteImgUrl)
      .pipe(
        switchMap(() => {
          return this.dialog
            .open(ConfirmationDialogComponent, {
              disableClose: true,
              data: {
                headline: this.translate.instant('general.delete-confirm'),
                noteText: this.isImageUsed
                  ? `${this.translate.instant('cms.image-is-used-in')} ${this.cmsUsageCounter}${this.translate.instant('cms.cms-items')}\n${this.translate.instant(
                      'cms.for-details-go-back'
                    )}`
                  : null,
                translate: true,
                msg: this.translate.instant('cms.delete-image-confirm-question'),
                confirmMsg: this.translate.instant('general.btnDelete'),
                cancelMsg: this.translate.instant('general.btnCancel')
              }
            })
            .afterClosed();
        }),
        filter(result => !!result),
        switchMap(() => this.imageService.deleteImage(deleteImgUrl))
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.toast.success(this.translate.instant('cms.image-delete-success'));
        this.selectedImageUrl = '';
        this.loadAllImages();
        this.isUsageLoading = false;
      });
  }

  public get cmsUsageCounter(): number {
    if (this.cmsUsages) {
      const contentpages = Object.values(this.cmsUsages?.contentPages)
        .map(array => array.length)
        .reduce((a, b) => a + b, 0);
      return this.cmsUsages?.emails?.length + this.cmsUsages?.news?.length + contentpages;
    }
    return 0;
  }

  public calculateUsageClickHandler(url: string): void {
    this.getImageCmsUsage(url).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public getImageCmsUsage(url: string): Observable<CmsUsages> {
    this.isUsageAlreadyCalculated = Object.keys(this.saveCalculatedCmsUsages).includes(this.selectedImageUrl);
    if (this.isUsageAlreadyCalculated) {
      this.isUsageLoading = false;
      return of(this.saveCalculatedCmsUsages[url]);
    }
    this.isUsageLoading = true;
    return this.imageService.getImageCmsUsage(url).pipe(
      tap(response => {
        this.saveCalculatedCmsUsages[url] = response;
        this.cmsUsages = response;
        this.isUsageAlreadyCalculated = Object.keys(this.saveCalculatedCmsUsages).includes(url);
        if (response) {
          this.isUsageLoading = false;
        } else if (!response) {
          this.toast.error(this.translate.instant('general.please-try-again'));
          this.isUsageLoading = false;
        }
      }),
      finalize(() => {
        this.isUsageLoading = false;
      })
    );
  }
  /**
   * Read a given file to a base64 string.
   * @param file The file to read.
   */
  private readFile(file: File): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = event => resolve(btoa(event.target.result.toString()));
    });
  }
}
