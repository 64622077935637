<ng-container *ngIf="showBackgroundPicker && item && contentEdit">
  <div class="background-color-picker">
    <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
  </div>
</ng-container>
<div
  *ngIf="item && !item?.['options']"
  style="display: block; width: 100%; transition: height 300ms ease-in-out"
  [ngStyle]="{
    height: item.height ? item.height + 'px' : '50px',
    border: contentEdit ? '1px solid var(--purple700)' : ''
  }"></div>
<div
  *ngIf="item?.['options'] as options"
  style="display: block; width: 100%; transition: height 300ms ease-in-out"
  [ngStyle]="{
    height: options.height ? options.height + 'px' : '10px',
    border: contentEdit ? '1px solid var(--purple700)' : ''
  }"></div>

<mat-slider class="cmsslider" *ngIf="contentEdit" [max]="100" [min]="5" discrete style="width: 200px">
  <input [(ngModel)]="item.height" matSliderThumb />
</mat-slider>
<p *ngIf="contentEdit" style="display: inline-flex">{{ item.height }}</p>
