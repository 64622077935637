<div class="turbine" [class.contrast]="contrast" [class.no-shadow]="!shadow">
  <div class="pilot">
    <div class="prop-container prop-container--{{speed}}">
      <div class="prop"></div>
      <div class="prop"></div>
      <div class="prop"></div>
    </div>
  </div>
  <div class="pole" *ngIf="withPole"></div>
  <p @shrinkExpand *ngIf="!reload && text && currLoadingNum" class="turbine-text">
    Loading...</p>
</div>