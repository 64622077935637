import { Topic } from '@coin/shared/util-models';

// Load Topics from Service
export class LoadTopics {
  static readonly type = '[Topics] Load Topics';
}
export class LoadTopicsSuccess {
  static readonly type = '[Topics] Load Topics Success';
  constructor(public readonly payload: Topic[]) {}
}
export class LoadTopicsFail {
  static readonly type = '[Topics] Load Topics Fail';
  constructor(public readonly payload?: unknown) {}
}

// Filter Topics
export class UpdateCategories {
  static readonly type = '[Categories] Update Categories';
  constructor(public readonly payload: string) {}
}

export class UpdateBannerVisibility {
  static readonly type = '[Topics] Update Banner Visibility';
  constructor(public readonly payload: Topic) {}
}

export class ResetCategories {
  static readonly type = '[Categories] Reset Categories';
}

// Extract Categories
export class ExtractCategories {
  static readonly type = '[Categories] Extract Categories';
}

export type TopicsActions = LoadTopics | LoadTopicsSuccess | LoadTopicsFail | UpdateCategories | ExtractCategories;
