import { ListViewTagFilterParameter } from '@coin/shared/util-models';
import { DisplayedCurrency, SeasonTypeEnum } from '@coin/shared/util-enums';
import { Season } from '@coin/admin/season-mgmt/util';
import { MercerSupportAddPosition } from '../../mercer-shared/models/mercer-support-add-position.model';
import { MercerEvaluationMember, PositionSearchSlim } from '../../shared/models/mercer-support-evaluation-member.model';

export class LoadMercerSupportEvaluationMembers {
  static readonly type = '[Mercer Support] Load Evaluation Members';
}

export class LazyLoadMercerSupportEvaluationMembers {
  static readonly type = '[Mercer Support] Lazy Load Evaluation Members';
}

export class MercerSupportAddNewPosition {
  static readonly type = '[Mercer Support] Add Mew Position';
  constructor(public readonly payload: MercerSupportAddPosition) {}
}

export class SetMercerSupportEvaluationMember {
  static readonly type = '[Mercer Support] Set Mercer Support Evaluation Member';
  constructor(public readonly payload: MercerEvaluationMember) {}
}

export class ChangeValidationManager {
  static readonly type = '[Mercer Support] Change Validation Manager';
  constructor(public readonly payload: { evaluationMemberId: string; newValidationManagerId: string; organisationSnapshotId?: string }) {}
}

export class PatchMercerSupportEvaluationMember {
  static readonly type = '[Mercer Support] Patch Mercer Support Evaluation Member';
  constructor(
    public readonly id: string,
    public readonly payload: Partial<MercerEvaluationMember>
  ) {}
}

export class LoadMercerSupportSeasons {
  static readonly type = '[Mercer Support] Load Seasons';
  constructor(public readonly partnerEndDateAt?: string) {}
}

export class SetMercerSupportSeason {
  static readonly type = '[Mercer Support] Set Season';

  constructor(public readonly season: Season) {}
}

export class SetMercerSupportPositionLock {
  static readonly type = '[Mercer Support] Set Position Lock';
  constructor(public readonly payload: { evaluationMemberId: string; lock: boolean }) {}
}

export class ResetMercerSupportState {
  static readonly type = '[Mercer Support] Reset Mercer Support State';
}

export class AddMercerSupportFilterParameter {
  static readonly type = '[Mercer Support] Add Filter Parameter';
  constructor(public readonly payload: ListViewTagFilterParameter) {}
}

export class SetMercerSupportFilterParameters {
  static readonly type = '[Mercer Support] Set Filter Parameters';
  constructor(public readonly payload: ListViewTagFilterParameter[]) {}
}

export class ClearMercerSupportFilterParameterKey {
  static readonly type = '[Mercer Support] Clear Filter Parameter Key';
  constructor(public readonly category: string) {}
}

export class SetEvaluationMemberBySearch {
  static readonly type = '[Mercer Support] Set Evaluation Member By Search';

  constructor(public readonly payload: PositionSearchSlim) {}
}

export class SetMercerSupportCurrency {
  static readonly type = '[Mercer Support] Set Currency';

  constructor(public readonly currency: DisplayedCurrency) {}
}

export class LoadMercerSupportSeasonSettings {
  static readonly type = '[Mercer Support] Load Season Settings';
  constructor(public readonly payload: { id: string; type: SeasonTypeEnum }) {}
}
