<div class="color-container">
  <p class="bold">{{ isTextColorPicker ? 'Text Color:' : 'Background color:' }}</p>
  <div *ngIf="!isTextColorPicker" class="selection-container">
    <mat-radio-group [class.disabled]="disabled" [(ngModel)]="value" (change)="onChangeSelection($event)">
      <mat-radio-button *ngFor="let value of this.colors.bg" [value]="value.hexCode" [class]="value.class">{{ value.displayName }} </mat-radio-button>
    </mat-radio-group>
  </div>
  <div *ngIf="isTextColorPicker" class="selection-container">
    <mat-radio-group [class.disabled]="disabled" [(ngModel)]="value" (change)="onChangeSelection($event)">
      <mat-radio-button *ngFor="let value of this.colors.fg" [value]="value.hexCode" [class]="value.class">{{ value.displayName }} </mat-radio-button>
    </mat-radio-group>
  </div>
</div>
