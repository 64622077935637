<div *ngIf="item" style="padding: 20px 0px; margin-top: 30px">
  <h3 *ngIf="!contentEdit">{{ item.headline }}</h3>
  <h3 *ngIf="contentEdit" [contentEditable]="contentEdit" [textContent]="item.headline" (input)="item.headline = $event.target.textContent"></h3>
  <div
    style="
      display: grid;
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 50px;
      padding-bottom: 0px;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      column-gap: 15px;
      row-gap: 15px;
    "
    *ngIf="startanimation">
    <div style="flex: 1" *ngFor="let item of item.numbers; let i = index" class="item-container">
      <div *ngIf="contentEdit" class="action-button-container">
        <coin-action-button (click)="removeNumber(i)" class="secondary" icon="close"></coin-action-button>
      </div>
      <p *ngIf="!contentEdit">{{ item.topText }}</p>
      <p *ngIf="contentEdit" [contentEditable]="contentEdit" [textContent]="item.topText" (input)="item.topText = $event.target.textContent"></p>
      <h1 *ngIf="item.numbers.length === 1 && !contentEdit">{{ countUpHolder[i][0][0] | numberFormatter }}</h1>
      <h1
        *ngIf="item.numbers.length === 1 && contentEdit"
        [contentEditable]="contentEdit"
        [textContent]="item.numbers[0]"
        (input)="updateItemNumber($event.target.textContent, item, 0)"></h1>
      <div class="double-number-input" *ngIf="item.numbers.length === 2" style="display: flex; flex-wrap: wrap">
        <h1 *ngIf="!contentEdit">{{ countUpHolder[i][0][0] | numberFormatter }}</h1>
        <h1 *ngIf="contentEdit" [contentEditable]="contentEdit" [textContent]="item.numbers[0]" (input)="updateItemNumber($event.target.textContent, item, 0)"></h1>
        <h1 class="middle">to</h1>
        <h1 *ngIf="!contentEdit">{{ countUpHolder[i][0][1] | numberFormatter }}</h1>
        <h1 *ngIf="contentEdit" [contentEditable]="contentEdit" [textContent]="item.numbers[1]" (input)="updateItemNumber($event.target.textContent, item, 1)"></h1>
      </div>
      <p *ngIf="!contentEdit">{{ item.bottomText }}</p>
      <p *ngIf="contentEdit" [contentEditable]="contentEdit" [textContent]="item.bottomText" (input)="item.bottomText = $event.target.textContent"></p>
      <div *ngIf="contentEdit">
        <mat-form-field appearance="fill" *ngIf="contentEdit">
          <mat-label>{{ 'cms.amount-numbers' | translate }}</mat-label>
          <mat-select (selectionChange)="amountChange($event.value, i, item)" [(ngModel)]="numberLengths[i]">
            <mat-option *ngFor="let option of amountOptions" [value]="option">{{ option }}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <gmm-select
        [model]="numberLengthes[i]"
        [label]="'Amount numbers'"
        [optionList]="amountOptions"
        [disabled]="!contentEdit"
        [required]="true"
        (modelChange)="amountChange($event, i, item)"
        [name]="'amountNumbers'"></gmm-select> -->
      </div>
    </div>

    <div class="action-button-container add" *ngIf="contentEdit">
      <coin-action-button (click)="addNumber()" icon="add" class="primary"></coin-action-button>
    </div>
  </div>
</div>
