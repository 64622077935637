<div class="headline">
  <span>{{ 'general.rule-engine-headline-prefix' | translate }}</span>
  <coin-rule-engine-condition-picker [disabled]="disabled" [currentCondition]="value.condition" (changed)="setCondition($event)"></coin-rule-engine-condition-picker>
  <span>{{ 'general.rule-engine-headline-postfix' | translate }}</span>
</div>

<ng-container *ngFor="let ruleOrRuleSet of value.rules">
  <coin-lightweight-rule-set
    *ngIf="isRuleSet(ruleOrRuleSet)"
    [ruleSet]="ruleOrRuleSet"
    [config]="config"
    [disabled]="disabled"
    [translateKeys]="translateKeys"
    (delete)="deleteItem(ruleOrRuleSet)"
    (changes)="registerChanges()"></coin-lightweight-rule-set>
  <coin-lightweight-rule
    *ngIf="!isRuleSet(ruleOrRuleSet)"
    [rule]="ruleOrRuleSet"
    [config]="config"
    [disabled]="disabled"
    [translateKeys]="translateKeys"
    (delete)="deleteItem(ruleOrRuleSet)"
    (changes)="registerChanges()"></coin-lightweight-rule>
</ng-container>

<div class="buttons">
  <coin-v2-button *ngIf="!disabled" (click)="addRule()" icon="add" type="secondary" [text]="'general.rule-engine-add-rule' | translate"></coin-v2-button>
  <coin-v2-button *ngIf="!disabled" (click)="addRuleSet()" icon="add" type="secondary" [text]="'general.rule-engine-add-rule-set' | translate"></coin-v2-button>
  <ng-container *ngTemplateOutlet="additionalFooterButtons"></ng-container>
</div>
