import { MercerSupportExperienceLevel } from '@coin/shared/util-enums';
import { CurrencyIsoCode, EmployeeSlim } from '@coin/shared/util-models';
import { Observable } from 'rxjs';
import { MercerSupportInterview } from '../../mercer-shared/enums/mercer-support-interview-state.enum';

export interface ProposalShortlistItem {
  positionTitle: string;
  orgCode: string;
  listedAs: JobAssignmentType;
  isShortlistedOnClosedPosition: boolean;
}

export interface MercerSupportIncumbentMember {
  id: string;
  priority: number;

  firstname: string;
  lastname: string;
  age: number;
  gender: string;
  companyEntryDate: string;
  employeeId: string;
  placeOfAction: string;
  nationality: string;
  mercerJobCode: string;
  managementGroup: string;
  mercerPositionClass?: number;
  showSensitiveData: boolean;
  showCompensationData: boolean;

  interview: MercerSupportInterview;
  experienceLevel: MercerSupportExperienceLevel;
  lineManager: EmployeeSlim;

  contractStatus?: string;

  totalTargetCashValue?: number;
  totalTargetCashLocalCurrencyIsoCode: CurrencyIsoCode;

  localCurrencyIsoCode: CurrencyIsoCode;
  equityValue?: number;

  type: JobAssignmentType;
  proposalState: ProposalState;

  benchmarkCurrency: string;
  benchmarkLowValue: number;
  benchmarkMidValue: number;
  benchmarkHighValue: number;

  shortlistedOn: ProposalShortlistItem[];

  // UI only
  fxRate$?: Observable<number>;
  positionFlyoutVisible: boolean;
  finalIncumbentPositions: ProposalShortlistItem[];
  shortlistedPositions: ProposalShortlistItem[];
  shortlistedOnOpenPositions: ProposalShortlistItem[];
  shortlistedOnClosedPositions: ProposalShortlistItem[];
}

export enum JobAssignmentType {
  Candidate = 'Candidate',
  Proposal = 'Proposal',
  Incumbent = 'Incumbent',
  FinalIncumbent = 'FinalIncumbent'
}

export enum ProposalState {
  Open = 'Open',
  Requested = 'Requested',
  Accepted = 'Accepted',
  Communicated = 'Communicated',
  Rejected = 'Rejected'
}

export interface UpdateJobAssignmentProposal {
  priority?: number;
  type?: JobAssignmentType;
  proposalState?: ProposalState;
  interview?: MercerSupportInterview;
  experienceLevel?: MercerSupportExperienceLevel;
  undoPlacement?: boolean;
}

export interface UpdateJobAssignmentProposalResponse {
  id: string;
  priority: number;
  type: JobAssignmentType;
  proposalState: ProposalState;
  interview: MercerSupportInterview;
  experienceLevel: MercerSupportExperienceLevel;
}
