<ng-container *ngIf="item">
  <coin-action-button class="{{ item.style }}" [icon]="item.icon" (click)="openPage(item['link'])">{{ item.text }}</coin-action-button>

  <div style="display: flex; flex-direction: column; width: 100%">
    <mat-form-field *ngIf="contentEdit" style="margin-top: 20px">
      <mat-label>{{ 'cms.button-text' | translate }}</mat-label>
      <input matInput aria-label="button text" [(ngModel)]="item['text']" [placeholder]="'cms.button-text-placeholder' | translate" [disabled]="!contentEdit" type="text" />
    </mat-form-field>
    <mat-form-field *ngIf="contentEdit" style="margin-top: 20px">
      <mat-label>{{ 'cms.button-link' | translate }}</mat-label>
      <input matInput aria-label="button text" [(ngModel)]="item['link']" [placeholder]="'cms.button-text-placeholder' | translate" [disabled]="!contentEdit" type="test" />
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="contentEdit">
      <mat-label>{{ 'cms.button-style' | translate }}</mat-label>
      <mat-select [(ngModel)]="item.style">
        <mat-option *ngFor="let option of buttonStyles" [value]="option">{{ option }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="contentEdit">
      <mat-label>{{ 'cms.button-style' | translate }}</mat-label>
      <mat-select [(ngModel)]="item.icon">
        <mat-option *ngFor="let option of buttonIcons" [value]="option">{{ option }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>
