<h1 *ngIf="item?.headline && !contentEdit" style="text-transform: uppercase">{{ item?.headline }}</h1>
<h1
  *ngIf="contentEdit"
  style="text-transform: uppercase"
  [class.placeholder]="!item?.headline"
  [contentEditable]="contentEdit"
  [textContent]="item?.headline"
  (input)="item.headline = $event.target.textContent"></h1>
<h3 style="margin-bottom: 20px; font-family: Siemens Slab bold; margin-top: 20px" *ngIf="item?.text && !contentEdit" [innerHTML]="item?.text | stripParagraphs"></h3>
<app-html-editor *ngIf="contentEdit" [item]="item" [contentEdit]="true" [isNews]="true"></app-html-editor>
