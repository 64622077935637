<div class="container">
  <h4 class="top-header">{{ 'cms.emails.select-index-item' | translate }}</h4>
  <div class="selection-list">
    <div
      *ngFor="let item of selectionItems"
      class="selection-item"
      [class.selected]="selectedItem?.id === item.id"
      [class.active]="item.id | pure : isSelected : data.selectedItemReferenceIds"
      (click)="selectedItem = item">
      <div class="view-only" [innerHTML]="item.contentHtml"></div>
    </div>
  </div>
  <div class="buttons">
    <coin-action-button class="secondary" (click)="close()">{{ 'general.btnClose' | translate }}</coin-action-button>
    <coin-action-button class="primary" (click)="proceed()" [disabled]="!selectedItem">{{ 'general.proceed' | translate }}</coin-action-button>
  </div>
</div>
