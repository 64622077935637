import { trigger, transition, style, animate } from '@angular/animations';

export const shrinkExpandAllAnimation = trigger('shrinkExpandAll', [
  transition(':enter', [
    style({ height: '0px', minHeight: '0px', maxHeight: '0px', opacity: 0, overflow: 'hidden', padding: '0px', margin: '0px' }),
    animate('400ms ease-in', style({ height: '*', minHeight: '*', maxHeight: '*', opacity: 1, overflow: 'hidden', padding: '*', margin: '*' }))
  ]),
  transition(':leave', [
    style({ height: '*', minHeight: '*', maxHeight: '*', opacity: 1, overflow: 'hidden' }),
    animate('300ms ease-out', style({ height: '0px', minHeight: '0px', maxHeight: '0px', opacity: 0, overflow: 'hidden', padding: '0px', margin: '0px' }))
  ])
]);
