<div *ngIf="item" style="width: 100%; position: relative" class="main-slider-body">
  <div class="slider-text-underlay" [ngClass]="{ 'underlay-full-width': item.fullWidth }"></div>
  <div class="action-button-container" *ngIf="contentEdit">
    <coin-action-button (click)="deleteSliderItem()" class="secondary" [icon]="'close'"></coin-action-button>
  </div>
  <drag-scroll
    style="min-width: 100%"
    [ngClass]="{ 'slider-full-width': item.fullWidth }"
    [snap-duration]="1000"
    [drag-scroll-y-disabled]="true"
    (indexChanged)="indexChanged()"
    #nav>
    <img aria-label="slider image" drag-scroll-item [src]="image.img" *ngFor="let image of item.sliderImages" />
  </drag-scroll>
  <div class="action-button-container add" *ngIf="contentEdit">
    <coin-action-button (click)="addSliderItem()" class="primary" [icon]="'add'">Add new slider</coin-action-button>
  </div>
  <div class="slider-indicator" *ngIf="item.sliderImages.length > 1">
    <div
      (click)="moveTo(i)"
      *ngFor="let image of item.sliderImages; let i = index"
      class="slider-dot"
      [ngStyle]="{ 'background-color': i == sliderIndex ? 'white' : 'hsla(0,0%,100%,.35)' }"></div>
    <!-- <i class="material-icons" (click)="moveTo(i)" *ngFor="let image of item.sliderImages; let i = index">{{i == sliderIndex ? 'panorama_fish_eye' : 'fiber_manual_record'}}</i> -->
  </div>
  <div class="slider-text">
    <div style="background: linear-gradient(180deg, var(--purple-contrast600), var(--purple700)); width: 6px; position: absolute; left: 0; top: 0.3rem; bottom: 0.6rem"></div>
    <h2 *ngIf="!contentEdit">{{ item.sliderImages[sliderIndex].text }}</h2>
    <h2
      *ngIf="contentEdit"
      [contentEditable]="contentEdit"
      [textContent]="item.sliderImages[sliderIndex].text"
      (input)="item.sliderImages[sliderIndex].text = $event.target.textContent"></h2>
    <p *ngIf="!contentEdit" style="position: absolute; color: white; font-size: 1em">{{ item.sliderImages[sliderIndex].subtext }}</p>
    <p
      *ngIf="contentEdit"
      [contentEditable]="contentEdit"
      [textContent]="item.sliderImages[sliderIndex].subtext"
      (input)="item.sliderImages[sliderIndex].subtext = $event.target.textContent"
      style="position: absolute; color: white; font-size: 1em"></p>
  </div>
  <i class="material-icons direction direction--left" *ngIf="sliderIndex != 0" (click)="moveLeft()">keyboard_arrow_left</i>
  <i class="material-icons direction direction--right" *ngIf="sliderIndex != item.sliderImages.length - 1" (click)="moveRight()">keyboard_arrow_right</i>
</div>

<div class="inputs" style="margin-top: -45px; margin-left: 10px" *ngIf="contentEdit">
  <coin-action-button (click)="editPicture()" class="secondary" svgIcon="edit" [noicon]="true" *ngIf="contentEdit">
    {{ 'cms.content-pages.change-image-button' | translate }}</coin-action-button
  >
</div>
