export const COUNTRIES_ASIA = [
  'AF',
  'AM',
  'AZ',
  'BH',
  'BD',
  'BT',
  'BN',
  'KH',
  'CN',
  'CY',
  'GE',
  'HK',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IL',
  'JP',
  'JO',
  'KZ',
  'KP',
  'KR',
  'KW',
  'KG',
  'LA',
  'LB',
  'MO',
  'MY',
  'MV',
  'MN',
  'MM',
  'NP',
  'OM',
  'PK',
  'PS',
  'PH',
  'QA',
  'SA',
  'SG',
  'LK',
  'SY',
  'TW',
  'TJ',
  'TH',
  'TL',
  'TR',
  'TM',
  'AE',
  'UZ',
  'VN',
  'YE'
];
