<button *ngFor="let tab of tabs" #tabButton class="tab" [class.selected]="isTabSelected(tab)" (click)="selectTab(tab)">
  <mat-icon *ngIf="tab.icon">{{ tab.icon }}</mat-icon>
  <ng-container *ngIf="tab.content | typeof: 'string'; else displayTemplate">
    {{ tab.content }}
  </ng-container>
  <ng-template #displayTemplate>
    <ng-container *ngTemplateOutlet="tab.content"></ng-container>
  </ng-template>
</button>

<ng-template #content>
  <ng-container *ngFor="let tab of tabs">
    <!-- Preload all tabs that are not lazy -->
    <div *ngIf="!tab.lazy || cachedTabs.has(tab) || isTabSelected(tab)" [hidden]="!isTabSelected(tab)" class="tab-projection">
      <ng-container *ngTemplateOutlet="tab.template"></ng-container>
    </div>
  </ng-container>
</ng-template>
