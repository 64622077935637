import { RuleEngineConditionTypeEnum } from '@coin/shared/util-enums';
import { Rule, RuleV2 } from '@coin/shared/util-models';
import { v4 as uuid } from 'uuid';

export class EmptyRuleSet implements RuleSet {
  name = `ruleset-${uuid()}`;
  scope = 'EmployeeData';
  level = 'A';
  condition = RuleEngineConditionTypeEnum.Or;
  field = '';
  rules: Rule[] = [];
  // ruleSets: RuleSet[] = [];
}

export class EmptyRule {
  id = uuid();
  value = '';
}

export class EmptyGroup {
  condition = RuleEngineConditionTypeEnum.Or;
  rules: RuleV2[] = [];
  level: string;
  id = uuid();
  field = '';

  constructor(_level: string) {
    this.level = _level;
  }
}

export interface RuleSetQuery {
  ruleSet?: RuleSet;
  createdBy?: { id: string; firstname: string; lastname: string };
  creationDate?: string;
  updateDate?: string;
  updatedBy?: { id: string; firstname: string; lastname: string };
  version?: number;
}

export interface RuleSet {
  id?: string;
  version?: number;
  name: string;
  scope: string;
  condition: RuleEngineConditionTypeEnum;
  rules: Rule[];
  tags?: Tag[];
  createdBy?: string | object;
  updatedBy?: string | object;
  creationDate?: string;
  updateDate?: string;
  level?: string;
  // ruleSets?: RuleSet[];
  active?: boolean;
}

export interface Tag {
  id: string;
  name: string;
}

export interface RuleSetCreate {
  ruleSet: RuleSet;
}

export interface RuleSetUpdate {
  id: string;
  ruleSet: RuleSet;
  version: number;
}
