import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AuthService } from '@coin/modules/auth/data-access';
import { CmsContentItemContent, CmsContentPageSettings, CmsItem } from '@coin/admin/cms/util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-all-cms-news-items',
  templateUrl: './all-cms-news-items.component.html',
  styleUrls: ['./all-cms-news-items.component.scss']
})
export class AllCmsNewsItemsComponent implements OnInit, OnChanges {
  @Input() item: CmsContentItemContent;
  @Input() isEquityPage = false;
  @Input() heightModified = false;
  @Input() modifiedWidth = false;
  @Input() overviewUpdate = true;
  @Input() germanyRowType: string;
  @Input() settings: CmsContentPageSettings;
  @Input() hasMargin = true;
  @Input() highlightItem: CmsItem;
  @Input() hideChapters = false;
  @Input() authService: AuthService;
  @Input() isResponsive: boolean;
  @Input() germanyRowSlideToggle = false;

  @Output() slideChange = new EventEmitter<MatSlideToggleChange>();
  @Output() chapterOverviewClicked = new EventEmitter<CmsItem>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onItemClicked = new EventEmitter<CmsItem>();

  public isAuthenticated = false;
  public highlightId: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.highlightItem) {
      if (changes.highlightItem.currentValue) {
        this.highlightId = changes.highlightItem.currentValue.id;
        document.getElementById(this.highlightId)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      if (!changes.highlightItem.currentValue) {
        this.highlightId = undefined;
      }
    }
  }

  public itemClicked(item: CmsItem, event: MouseEvent): void {
    if (this.onItemClicked.observed) {
      event.preventDefault();
      this.onItemClicked.next(item);
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.checkAuthentication();
    }, 200);
  }

  private checkAuthentication(): void {
    if (this.authService) {
      this.isAuthenticated = this.authService.isAuthenticated$.value;
    }
  }
}
