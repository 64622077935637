import { MeritCompensationVisibilityLevel } from '@coin/shared/util-enums';
import { MercerEditableByType, MercerPermissionType, MercerSurveyDecision } from '@coin/admin/master-data/util';

interface CandidatePoolSeasonSetting {
  candidatePoolId: string;
  seasonSettingsId: string;
}

export class SeasonSetting {
  id: string;
  seasonId: string;

  constructor(initial?: Partial<SeasonSetting>) {
    Object.assign(this, initial);
  }
}

export class MeritSeasonSetting extends SeasonSetting {
  calculationFreezeDateAt: string;
  equityFreezeDateAt: string;
  allocationFreezeDateAt: string;
  equityEffectiveDateAt: string;
  incentiveSeasonId: string;
  orientationValueExcessFactor: number;
  promotionOrientationValueExcessFactor: number;

  constructor(initial?: Partial<MeritSeasonSetting>) {
    super();
    Object.assign(this, initial);
  }
}

export class SpecialPaymentSetting extends SeasonSetting {
  proposalRequestDueDateAt: string;
  fxDateAt: string;
  hoHMailTemplateId: string;
  hrMailTemplateId: string;
  nMinus2MailTemplateId: string;
  rewardDate?: string;
  maxCashBudget?: string;

  constructor(initial?: Partial<SpecialPaymentSetting>) {
    super();
    Object.assign(this, initial);
  }
}

export class PositionEvaluationSetting extends SeasonSetting {
  usePopulationUpload: boolean;
  freezeDateAt: string;
  compensationVisibilityLevel: MeritCompensationVisibilityLevel;
  seasonSettingsCandidatePools: CandidatePoolSeasonSetting[];
  isPlacementSettingsEnabled: boolean;
  permissionType: MercerPermissionType;
  positionCountryEditableBy: MercerEditableByType;
  questionnaireDecision: MercerSurveyDecision;
  isAllowedToAddPositions: boolean;
  isConsistencyCheckingEnabled: boolean;
  isNewHireProcessEnabled: boolean;
  isQuestionnaireDisabledForGermanNonSeniors: boolean;
  isManagerAppReadOnly: boolean;
  season: { latestSeasonSetting: PositionEvaluationSetting };

  constructor(initial?: Partial<PositionEvaluationSetting>) {
    super();
    Object.assign(this, initial);
  }
}

export type GenericSeasonSettings = MeritSeasonSetting | SpecialPaymentSetting | PositionEvaluationSetting;
