import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@coin/shared/util-environments';
import { forkJoin, from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInjector implements HttpInterceptor {
  private readonly tokenBlacklist = [
    'https://randomuser.me/api/?inc=picture',
    'https://restcountries.eu',
    'https://coin-generic-documents-dev.s3.eu-central-1.amazonaws.com',
    'https://coin-generic-documents-pre-prod.s3.eu-central-1.amazonaws.com',
    'https://coin-generic-documents-prod.s3.eu-central-1.amazonaws.com',
    'https://d30qz857dkw45t.cloudfront.net',
    'https://coin-help.s3.eu-central-1.amazonaws.com',
    'https://de0w05fuukbfv.cloudfront.net',
    'https://coin-images.s3.eu-central-1.amazonaws.com',
    'https://experts-dev-dataimports.s3.eu-central-1.amazonaws.com',
    'https://experts-preprod-dataimports.s3.eu-central-1.amazonaws.com',
    'https://experts-prod-dataimports.s3.eu-central-1.amazonaws.com',
    'https://coin-download-documents.s3.eu-central-1.amazonaws.com',
    'https://coin-documents.s3.eu-central-1.amazonaws.com',
    'https://coin-documents-zip-uploads.s3.eu-central-1.amazonaws.com',
    'https://coin-cms-',
    'https://coin-download-documents-dev.s3.eu-central-1.amazonaws.com',
    'https://coin-documents-dev.s3.eu-central-1.amazonaws.com',
    'https://coin-download-documents-pre-prod.s3.eu-central-1.amazonaws.com',
    'https://coin-documents-pre-prod.s3.eu-central-1.amazonaws.com',
    environment.cmsContentProdBucketCloudfront,
    environment.cmsContentStageBucketCloudfront,
    environment.documentDownloadCloudfront
  ];

  private readonly emulationBlacklist = [];
  private readonly emulationWhitelist = ['document-service/private-documents', 'document-service/direct-documents'];

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let request = req;

    return forkJoin([from(this.authService.getAccessToken()), from(this.authService.getEmulation())]).pipe(
      switchMap(([accessToken, emulationToken]) => {
        if (accessToken) {
          // decode access token and check if url matches one of the audiences
          // as this is not implemented yet.. we validate.. it is not send to an amazonaws service aside from apigateway
          if (accessToken && !this.urlInTokenBlacklist(request.url)) {
            request = this.addBearerToken(request, accessToken);

            if (this.shouldSetEmulationToken(request.url, emulationToken)) {
              request = this.setEmulationToken(request, emulationToken);

              if (request.url.includes(environment.api.baseUrl) && !request.url.includes(environment.api.letterServiceUrl)) {
                request = this.removeBearerToken(request);
              }
            }
          }
        }
        return next.handle(request);
      })
    );
  }

  private shouldSetEmulationToken(url: string, emulationToken: string): boolean {
    return emulationToken && this.emulationListsAllow(url) && !this.isEmulationTokenRefresh(url);
  }

  private isEmulationTokenRefresh(url: string): boolean {
    return url.endsWith('/oauth/token');
  }

  private addBearerToken(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private removeBearerToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: ''
      }
    });
  }

  private setEmulationToken(request: HttpRequest<unknown>, emulation: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        EmulationToken: emulation.toString()
      }
    });
  }

  private urlInTokenBlacklist(url: string): boolean {
    return this.tokenBlacklist.some(whiteUrl => url.includes(whiteUrl));
  }

  private emulationListsAllow(url: string): boolean {
    return this.emulationWhitelist.some(whiteUrl => url.includes(whiteUrl)) || !this.emulationBlacklist.some(blackUrl => url.includes(blackUrl));
  }
}
