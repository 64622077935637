import * as moment from 'moment';
import { LetterCommentReasonEnum } from '@coin/shared/util-enums';
import { LetterSignature } from './letter-signature.model';
import { Author, Comment } from './comment.model';

export class LetterComment implements Comment {
  id: string;
  letterId: string;
  text = '';
  linkedTodoId: string;
  createdAt?: string;
  timestamp?: string = moment.utc().toISOString();
  author?: Author;
  rejectedSignature: LetterSignature;
  reason?: LetterCommentReasonEnum | string;

  constructor(initial?: Partial<LetterComment>) {
    Object.assign(this, initial);
  }
}
