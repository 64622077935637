import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@coin/shared/util-environments';
import { Employee, PaginatedResult, SearchItemService } from '@coin/shared/util-models';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { LoadingService } from '@coin/shared/data-access';
import { OrgReviewEmployeeMoveDto } from '../../../../org-review/models/org-review-employee-move-dto.model';
import { OrganisationService } from '../../../models/organisation-service.model';

@Injectable({
  providedIn: 'root'
})
export class OrgTreeService implements OrganisationService, SearchItemService {
  private maxSize = 1_000;

  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private toast: ToastrService
  ) {}

  getParentOrganisation(orgCode: string): Observable<Employee> {
    return this.httpClient
      .get<Employee>(`${environment.api.baseUrl}/admin/api/v3/master/organisations/row-level-security/parent-organisation?orgCode=${encodeURIComponent(orgCode)}`)
      .pipe(
        map(result => {
          result.headId = result?.head?.id;
          delete result?.head?.id;
          delete result?.head?.gid;
          return { ...result, ...result?.head, isInScope: true, page: 1 };
        }),
        catchError((res: HttpErrorResponse) => {
          return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
        })
      );
  }

  getOrganisation(orgCode: string): Observable<Employee> {
    return this.httpClient.get<Employee>(`${environment.api.baseUrl}/admin/api/v3/master/organisations/row-level-security/byorgcode?orgCode=${encodeURIComponent(orgCode)}`).pipe(
      map(result => {
        result.headId = result?.head?.id;
        delete result?.head?.id;
        delete result?.head?.gid;
        return { ...result, ...result?.head, isInScope: true, page: 1 };
      }),
      catchError((res: HttpErrorResponse) => {
        return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }

  getOrganisationsOfEmployee(gid: string): Observable<Employee[]> {
    return this.httpClient
      .get<
        PaginatedResult<Employee>
      >(`${environment.api.baseUrl}/admin/api/v3/master/organisations/row-level-security?page=1&size=${this.maxSize}&query.headGid=${encodeURIComponent(gid)}`)
      .pipe(
        map(data => {
          return data.content.map(item => {
            item.headId = item?.head?.id;
            delete item?.head?.id;
            delete item?.head?.gid;
            return { ...item, ...item?.head };
          });
        }),
        catchError((res: HttpErrorResponse) => {
          return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
        })
      );
  }

  getChildOrganisations(orgCode: string): Observable<Employee[]> {
    return this.httpClient
      .get<
        PaginatedResult<Employee>
      >(`${environment.api.baseUrl}/admin/api/v3/master/organisations/row-level-security/child-organisations?page=1&size=${this.maxSize}&orgCode=${encodeURIComponent(orgCode)}`)
      .pipe(
        map(data => {
          return data.content.map(item => {
            item.headId = item?.head?.id;
            delete item?.head?.id;
            delete item?.head?.gid;
            return { ...item, ...item?.head, isInScope: true, page: 0 };
          });
        }),
        catchError((res: HttpErrorResponse) => {
          return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
        })
      );
  }

  getEmployees(orgCode: string, page: number): Observable<Employee[]> {
    return this.httpClient
      .get<
        PaginatedResult<Employee>
      >(`${environment.api.baseUrl}/admin/api/v3/master/employees/row-level-security?page=${page + 1}&size=${this.maxSize}&orgCode=${encodeURIComponent(orgCode)}`)
      .pipe(
        map(data => {
          return data.content.map(item => {
            return { ...item, isInScope: true, page: 0 };
          });
        }),
        catchError((res: HttpErrorResponse) => {
          return throwError(`Error occured.${res?.error?.reason || 'Unknown'} `);
        })
      );
  }

  move(body: OrgReviewEmployeeMoveDto): Observable<unknown[]> {
    this.loadingService.present();

    const requests$ = [];

    if (body.lineManagerId) {
      requests$.push(this.httpClient.put(`${environment.api.baseUrl}/admin/api/v3/master/employees/${body.employeeId}/line-manager`, { ...body, inCompanyGuid: undefined }));
    }

    if (body.companyManagerId) {
      requests$.push(
        this.httpClient.put(`${environment.api.baseUrl}/admin/api/v3/master/employees/${body.employeeId}/in-company-manager`, { ...body, lineManagerGuid: undefined })
      );
    }

    return forkJoin(requests$).pipe(
      tap(() => {
        this.toast.success('Moved employee.');
        this.loadingService.dismiss();
      }),
      catchError((res: HttpErrorResponse) => {
        return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
      }),
      finalize(() => this.loadingService.dismiss())
    );
  }

  getEmployeeDetails(id: string): Observable<Employee> {
    return this.httpClient.get<Employee>(`${environment.api.baseUrl}/admin/api/v3/master/employees/row-level-security/${id}`).pipe(
      map(result => {
        return { ...result, isInScope: true, page: 0 };
      }),
      catchError((res: HttpErrorResponse) => {
        return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }

  getSearchItemsByText(text: string, page: number): Observable<Employee[]> {
    return this.httpClient
      .get<
        PaginatedResult<Employee>
      >(`${environment.api.baseUrl}/admin/api/v3/master/employees/row-level-security?page=${page + 1}&size=50&gid=${text}&name=${text}&orgCode=${encodeURIComponent(text)}`)
      .pipe(
        map(res => res.content),
        catchError((res: HttpErrorResponse) => {
          return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
        })
      );
  }
}
