import { Override } from '@coin/shared/util-models';
import { ALL_SELECTABLE_CMS_ITEMS } from '../news/all-selectable-cms-items.config';

type CmsItemType = CmsItems[number]['type'] | null;
type CmsItems = typeof ALL_SELECTABLE_CMS_ITEMS;

export const DefaultColorPickerValues: HtmlMetaData = {
  backgroundColor: 'white',
  textColor: 'black'
};

export interface HtmlMetaData {
  id?: string;
  fullWidth?: boolean;
  backgroundColor?: string;
  paddingTop?: number;
  paddingBottom?: number;
  textColor?: string;
  requiresAuthentication?: boolean;
}

export type CmsItem<T extends CmsItemType = null> = T extends null ? CmsItems[number] & HtmlMetaData : Override<Extract<CmsItems[number], { type: T }>, HtmlMetaData>;
