import { Component, DestroyRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { CmsItem } from '@coin/admin/cms/util';
import { EnlargeImageComponent } from '../../shared/enlarge-image/enlarge-image.component';
import { SelectImageComponent } from '../../shared/select-image/select-image.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-equity-cms-full-widthpart-with-image',
  templateUrl: './equity-cms-full-widthpart-with-image.component.html',
  styleUrls: ['./equity-cms-full-widthpart-with-image.component.scss']
})
export class EquityCmsFullWidthpartWithImageComponent {
  @Input() item: CmsItem<'full-width-part-with-image'>;
  @Input() contentEdit = false;
  @Input() enableEnlarge = true;

  constructor(
    private dialog: MatDialog,
    private destroyRef: DestroyRef
  ) {}

  /**
   * Open popup to select a different image or upload a new one
   * @param item current image item
   */
  public editPicture(item: CmsItem<'full-width-part-with-image'>['content']): void {
    this.dialog
      .open(SelectImageComponent, { data: { img: item.imageUrl } })
      .afterClosed()
      .pipe(
        filter(newImage => !!newImage),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(newImage => {
        item.imageUrl = newImage.imageUrl;
      });
  }

  /**
   * Open popup with current image but bigger in size
   */
  public enlargeImage(): void {
    this.dialog.open(EnlargeImageComponent, { data: { imageUrl: this.item.content.imageUrl } });
  }
}
