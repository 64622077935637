import { ValidationErrors } from '@angular/forms';

/**
 * @param errors Angular form errors object, i.e. FormControl.errors
 * @returns An array containing an error text for each error. */
export function errorsToText(errors: ValidationErrors): string[] {
  if (!errors) return [];

  return Object.entries(errors).map(([key, value]) => {
    if (typeof value === 'string') return value;

    switch (key) {
      case 'required':
        return 'A value is required';
      case 'maxlength':
        return `Maximum length: ${value['requiredLength']}\nActual length: ${value['actualLength']}`;

      default:
        return `${key}: ${JSON.stringify(value)}`;
    }
  });
}
