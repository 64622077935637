<coin-v2-card (blur)="onTouch(value)" padding="none" rounding="medium">
  <div [class.selected]="value" (click)="toggleCard()" class="outline-container">
    <div class="checkbox-container">
      <coin-v2-checkbox [contrast]="value" [ngModel]="value"></coin-v2-checkbox>
    </div>
    <div class="content padding-{{ padding }}">
      <ng-content></ng-content>
    </div>
  </div>
</coin-v2-card>
