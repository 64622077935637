import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@coin/shared/util-environments';
import { LifecycleHelper, ObservableCachingHelper } from '@coin/shared/util-helpers';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GetImageWithGidService extends LifecycleHelper {
  private cachingHelper = new ObservableCachingHelper<string>();

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    super();
    this.addToLifecycle(this.cachingHelper);
  }

  private getImage(gid: string): Observable<string> {
    return from(this.authService.getUser()).pipe(
      switchMap(user => {
        const params = new HttpParams({ fromObject: { gid, requestergid: user.gid } });
        return this.http.get<{
          img: string;
        }>(`${environment.api.imagesService}images/employee-picture`, { params });
      }),
      map(data => data?.img),
      catchError(() => of(null))
    );
  }

  public getImageWithStore(gid: string): Observable<string> {
    return this.cachingHelper.cacheSetOnMiss$(this.getImage(gid), [gid]);
  }
}
