import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { GlobalEventsService } from '@coin/shared/util-helpers';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appClickedOutside]',
  standalone: true
})
export class ClickedOutsideDirective {
  @Output() clickedOutside = new EventEmitter<void>();

  constructor(
    private elementRef: ElementRef,
    private events: GlobalEventsService
  ) {
    this.events
      .listen('click')
      .pipe(takeUntilDestroyed())
      .subscribe(event => {
        const targetElement = event.target as HTMLElement;
        if (targetElement?.parentElement && !targetElement.parentElement.isEqualNode(this.elementRef.nativeElement) && !this.elementRef.nativeElement.contains(targetElement)) {
          this.clickedOutside.next();
        }
      });
  }
}
