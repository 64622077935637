/**
 * SuccessionManagementAPI
 * API for succession management module.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: andre.weinkoetz@siemens-energy.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Acknowledgement dto for accepting/rejecting a nomination of an employee
 */
export interface AcknowledgeTalentRecommendation { 
    /**
     * only required when ACK type = Reject
     */
    reasonForReject?: string;
    type: AcknowledgeTalentRecommendation.TypeEnum;
}
export namespace AcknowledgeTalentRecommendation {
    export type TypeEnum = 'Approve' | 'Reject';
    export const TypeEnum = {
        Approve: 'Approve' as TypeEnum,
        Reject: 'Reject' as TypeEnum
    };
}


