import { Component, Input, OnInit } from '@angular/core';
import { CmsItem, DefaultColorPickerValues } from '@coin/admin/cms/util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'gmm-spacer',
  templateUrl: './spacer.component.html',
  styleUrls: ['./spacer.component.scss']
})
export class SpacerComponent implements OnInit {
  @Input() contentEdit = false;
  @Input() item: CmsItem<'spacer'>;
  @Input() showBackgroundPicker = false;

  ngOnInit(): void {
    this.item ??= {} as CmsItem<'spacer'>;
    this.setDefaultBackgroundColor();
  }

  private setDefaultBackgroundColor(): void {
    this.item.backgroundColor = this.item.backgroundColor || DefaultColorPickerValues.backgroundColor;
  }
}
