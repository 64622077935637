import { TreeNode } from '../route-builder';

export const OrgPlanningModule = {
  segment: 'org-planning',
  children: {
    OrgPlanningComponent: {
      segment: '',
      children: {
        OrgPlanningOrgTreeSplitViewComponent: {
          segment: 'tree-view',
          params: ['orgCode', 'secondOrgCode', 'simulationId', 'leftDriven', 'chartId']
        }
      }
    }
  }
} as const satisfies TreeNode;
