import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TextDialogData } from '@coin/shared/util-models';

@Component({
  selector: 'coin-text-dialog',
  templateUrl: './text-dialog.component.html',
  styleUrls: ['./text-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TextDialogData,
    private dialogRef: MatDialogRef<TextDialogComponent>
  ) {}

  public onCloseButtonClick(): void {
    this.dialogRef.close();
  }
}
