import { Injectable } from '@angular/core';
import { FeatureFlagsService } from '@coin/shared/data-management-state';
import { PermissionResource } from '@coin/shared/util-enums';
import { NavigationLink, RouteBuilder, RouteTree, TreeNode } from '@coin/shared/util-helpers';
import { CoinTab } from '@coin/shared/util-models';
import { Store } from '@ngxs/store';
import { PermissionHelper } from './permission-helper';
import { UserState } from './user.state';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  constructor(
    private store: Store,
    private featureFlagsService: FeatureFlagsService
  ) {}

  public isAdmin(): boolean {
    return PermissionHelper.isAdmin(this.store);
  }

  public isOrgPlanningAdmin(): boolean {
    return this.hasPermissions(PermissionResource.OrganisationPlanningAdmin);
  }

  public isHeadcountApprovalAdmin(): boolean {
    return this.hasPermissions(PermissionResource.HeadcountApprovalAdmin);
  }

  public isStandingPeHr(): boolean {
    return this.hasPermissions(PermissionResource.StandingPositionEvaluationHR) && !this.isAdmin() && !this.isCAndB();
  }

  public hasPermissions(...permissions: PermissionResource[]): boolean {
    return this.isAdmin() || this.store.selectSnapshot(UserState.allPermissions)?.some(userPermission => permissions.some(permission => permission === userPermission.resource));
  }

  public isCAndB(): boolean {
    return this.hasPermissions(PermissionResource.CAndB);
  }

  public isHeadcountApprovalHr(): boolean {
    return this.hasPermissions(PermissionResource.HeadcountApprovalHr);
  }

  // region Navigation

  getNavigationLinks(url: URL, routerBuilder: RouteBuilder<RouteTree>): NavigationLink[] {
    const { navigationRoot, firstSegment } = routerBuilder.findNavigationRoot(url);
    if (!navigationRoot) {
      console.error(`No navigation-root found for ${firstSegment} module!`);
      return [];
    }

    return Object.keys(navigationRoot.children)
      .map(selector => navigationRoot.children[selector] as TreeNode)
      .filter(node => this.isRoutePermitted(node) && !node.hiddenNavigation)
      .filter(node => !node?.isVisible || Object.entries(node.isVisible).every(([param, validValues]) => validValues.includes(url.searchParams.get(param))))
      .map(node => {
        return {
          title:
            this.resolveTitle(url, node) ??
            this.resolveTitle(
              url,
              Object.values(node.children || {}).find(child => child.segment === '')
            ),
          hasChilds: Object.values(node.children || {}).some(child => child.segment !== ''),
          tooltip: node.tooltip,
          disabledTooltip: node.disabledTooltip,
          url: `/${firstSegment}/${node.segment}`,
          preserveParams: node.params,
          icon: node.icon,
          permissions: node.permissions,
          controlledByFeatureFlag: node.controlledByFeatureFlag
        };
      });
  }

  private isRoutePermitted(node: TreeNode): boolean {
    if (node.controlledByFeatureFlag && this.featureFlagsService.isInactive(node.controlledByFeatureFlag)) {
      return false;
    }

    if (node.permissions?.length > 0 && !this.hasPermissions(...node.permissions)) {
      return false;
    }

    return true;
  }

  public getSubPageTabs(url: URL, routerBuilder: RouteBuilder<RouteTree>): CoinTab[] {
    const { navigationRoot, firstSegment } = routerBuilder.findNavigationRoot(url);
    if (!navigationRoot) {
      console.error(`No navigation-root found for ${firstSegment} module!`);
      return [];
    }

    const parentRoute = `/${firstSegment}${navigationRoot.segment ? `/${navigationRoot.segment}` : ''}`;
    const disabledTabTooltip = 'general.disabled-nav-tab';
    return Object.keys(navigationRoot.children)
      .map(selector => navigationRoot.children[selector] as TreeNode)
      .filter(node => url.pathname.startsWith(`${parentRoute}${node.segment ? `/${node.segment}` : ''}`))
      .filter(node => this.isRoutePermitted(node))
      .flatMap(node => {
        const childs = Object.values(node.children || {});
        const hasEmptyRoute = childs.some(child => child.segment === '');
        if (!childs.length) {
          return [];
        }

        const baseRoute = `${parentRoute}${node.segment ? `/${node.segment}` : ''}`;
        let tabs = childs
          .filter(child => this.isRoutePermitted(child))
          .filter(child => !child?.isVisible || Object.entries(child.isVisible).every(([param, validValues]) => validValues.includes(url.searchParams.get(param))))
          .map(
            child =>
              ({
                name: child.title,
                isDisabled: !!child.paramTitle && !url.searchParams.has(child.paramTitle),
                isSelected: url.pathname === `${baseRoute}${child.segment ? `/${child.segment}` : ''}`,
                route: `${baseRoute}${child.segment ? `/${child.segment}` : ''}`,
                tooltip: disabledTabTooltip
              }) as CoinTab
          );

        if (!hasEmptyRoute && !node.emptyPage) {
          tabs = [
            { name: this.resolveTitle(url, node), isDisabled: false, isSelected: url.pathname.endsWith(node.segment), route: baseRoute, tooltip: disabledTabTooltip },
            ...tabs
          ];
        }

        return tabs.length === 1 ? [] : tabs;
      });
  }

  private resolveTitle(url: URL, node: TreeNode): string {
    if (!node?.title || typeof node.title === 'string') {
      return node.title as string;
    }

    const key = Object.keys(node.title)[0];
    const value = url.searchParams.get(key);
    return node.title[key][value];
  }

  // endregion
}
