<app-equity-cms-chapter-overview
  *ngIf="isEquityPage && !item?.hideChapterOverview && !hideChapters"
  class="cms-item hover-effect chapter-overview"
  [update]="overviewUpdate"
  [item]="item?.content"
  (click)="chapterOverviewClicked.emit()"></app-equity-cms-chapter-overview>
<div
  *ngFor="let subItem of item?.content"
  class="cms-item"
  [id]="subItem.id"
  (click)="itemClicked(subItem, $event)"
  [style.background-color]="subItem.backgroundColor"
  [ngClass]="{ 'highlight-item': highlightId === subItem.id, 'fadeout-item': highlightId && highlightId !== subItem.id, 'hover-effect': isEquityPage }"
  [hidden]="
    (settings?.germanyRowSplit && germanyRowType !== 'both' && subItem.germanyRowType !== germanyRowType && subItem.germanyRowType !== 'both' && subItem.germanyRowType) ||
    (subItem.requiresAuthentication && !isAuthenticated)
  "
  [ngClass]="{ 'equity-width-limited': isEquityPage && !subItem.fullWidth }">
  <gmm-text-block *ngIf="subItem.type === 'text-block'" [id]="subItem.id" [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></gmm-text-block>
  <gmm-image-slider *ngIf="subItem.type === 'image-slider'" [id]="subItem.id" [item]="subItem" class="showOnScroll has-margin"></gmm-image-slider>
  <gmm-quote *ngIf="subItem.type === 'quote'" [id]="subItem.id" [item]="subItem" class="showOnScroll has-margin"></gmm-quote>
  <gmm-spacer *ngIf="subItem.type === 'spacer'" [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></gmm-spacer>
  <gmm-picture-container *ngIf="subItem.type === 'picture-container'" [heightModified]="heightModified" [item]="subItem" class="showOnScroll has-margin"></gmm-picture-container>
  <gmm-compilation *ngIf="subItem.type === 'compilation'" [item]="subItem" [startanimation]="true" class="showOnScroll has-margin"></gmm-compilation>
  <gmm-numbers *ngIf="subItem.type === 'numbers'" [noCounting]="true" [item]="subItem" [startanimation]="true" class="showOnScroll has-margin"></gmm-numbers>
  <gmm-text-image-link *ngIf="subItem.type === 'text-image-link'" [item]="subItem" class="showOnScroll has-margin"></gmm-text-image-link>
  <gmm-line *ngIf="subItem.type === 'line'" [item]="subItem"></gmm-line>
  <app-html-editor
    *ngIf="subItem.type === 'editor' || subItem.type === 'email-headline' || subItem.type === 'bullet-list'"
    [item]="subItem"
    [ngClass]="{ hasMargin: hasMargin }"></app-html-editor>
  <app-news-headline *ngIf="subItem.type === 'headline-with-subheadline'" [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></app-news-headline>
  <app-equity-expand-box *ngIf="subItem.type === 'expand-box'" [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></app-equity-expand-box>
  <app-equity-expand-linksbox *ngIf="subItem.type === 'expand-linksbox'" [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></app-equity-expand-linksbox>
  <app-cms-button *ngIf="subItem.type === 'button'" [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></app-cms-button>
  <app-download-file *ngIf="subItem.type === 'download-file'" [isContentPage]="isEquityPage" [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></app-download-file>
  <app-email-button *ngIf="subItem.type === 'email-button'" [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></app-email-button>
  <app-email-single-double-image
    [isResponsive]="isResponsive"
    *ngIf="subItem.type === 'single-or-multi-image'"
    [item]="subItem"
    [ngClass]="{ hasMargin: hasMargin }"></app-email-single-double-image>
  <app-equity-cms-chapter
    *ngIf="subItem.type === 'chapter'"
    [germanyRowSlideToggle]="germanyRowSlideToggle"
    (slideChange)="slideChange.emit($event)"
    [item]="subItem"></app-equity-cms-chapter>
  <app-equity-cms-cards *ngIf="subItem.type === 'cards'" [modifiedWidth]="modifiedWidth" [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></app-equity-cms-cards>
  <app-equity-cms-full-widthpart-with-image *ngIf="subItem.type === 'full-width-part-with-image'" [item]="subItem"></app-equity-cms-full-widthpart-with-image>
  <coin-a-email-main-body-image-text *ngIf="subItem.type === 'main-body-image-text'" [item]="subItem"></coin-a-email-main-body-image-text>
  <coin-a-email-index-list *ngIf="subItem.type === 'index-list'" [item]="subItem" [content]="item.content"></coin-a-email-index-list>
</div>
