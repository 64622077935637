/**
 * SuccessionManagementAPI
 * API for succession management module.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: andre.weinkoetz@siemens-energy.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ExportTransactionStatus } from '../model/export-transaction-status.model';
// @ts-ignore
import { TalentEmployeePage } from '../model/talent-employee-page.model';
// @ts-ignore
import { TalentReviewDashboardValues } from '../model/talent-review-dashboard-values.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface GetSuccessionManagementCustomerV1ReviewTalentsRequestParams {
    /** filters for talent pool title (va contains) */
    queryTalentPoolTitle?: Array<string>;
    /** multi-select filters for talent pool types */
    queryTalentPoolReferenceMetaTypes?: Array<string>;
    /** contains */
    queryFirstname?: string;
    /** contains */
    queryLastname?: string;
    /** contains */
    queryGid?: string;
    /** OR filter clause for gid, firstname, lastname */
    querySearch?: string;
    /** filters for id of talent pool */
    queryTalentPoolId?: string;
    /** contains */
    queryOrgCode?: string;
    /** contains */
    queryOrgName?: string;
    /** equals */
    queryPlaceOfAction?: Array<string>;
    /** in list */
    queryOrgCodes?: Array<string>;
    /** in list */
    queryOrgNames?: Array<string>;
    pagingPage?: number;
    pagingSize?: number;
    sortingProperty?: string;
    /** enum: ASC/DESC */
    sortingOrderBy?: string;
    /** enum - defaults for N-3 &amp; HR BPs */
    queryRecommendationState?: Array<string>;
    /** in list */
    queryReferenceTracks?: Array<string>;
    /** in list */
    queryAreCodes?: Array<string>;
}

export interface GetSuccessionManagementCustomerV1TalentsRequestParams {
    /** filters for talent pool title (va contains) */
    queryTalentPoolTitle?: Array<string>;
    /** contains */
    queryFirstname?: string;
    /** contains */
    queryLastname?: string;
    /** contains */
    queryGid?: string;
    /** OR filter clause for gid, firstname, lastname */
    querySearch?: string;
    /** filters for id of talent pool */
    queryTalentPoolId?: string;
    /** contains */
    queryOrgCode?: string;
    /** contains */
    queryOrgName?: string;
    /** equals */
    queryPlaceOfAction?: Array<string>;
    /** in list */
    queryOrgCodes?: Array<string>;
    /** in list */
    queryOrgNames?: Array<string>;
    pagingPage?: number;
    pagingSize?: number;
    sortingProperty?: string;
    /** enum: ASC/DESC */
    sortingOrderBy?: string;
    queryRecommendationState?: Array<string>;
    /** contains */
    queryAreCodes?: Array<string>;
}

export interface GetSuccessionManagementPartnerV1ExportTalentsRequestParams {
    /** filters for talent pool title (va contains) */
    queryTalentPoolTitle?: Array<string>;
    /** multi-select filters for talent pool types */
    queryTalentPoolReferenceMetaTypes?: Array<string>;
    /** contains */
    queryFirstname?: string;
    /** contains */
    queryLastname?: string;
    /** contains */
    queryGid?: string;
    /** filters for id of talent pool */
    queryTalentPoolId?: string;
    /** contains */
    queryOrgCode?: string;
    /** contains */
    queryOrgName?: string;
    /** equals */
    queryPlaceOfAction?: string;
    /** in list */
    queryOrgCodes?: Array<string>;
    /** in list */
    queryOrgNames?: Array<string>;
    pagingPage?: number;
    pagingSize?: number;
    sortingProperty?: string;
    /** enum: ASC/DESC */
    sortingOrderBy?: string;
    /** enum - defaults for N-3 &amp; HR BPs */
    queryRecommendationState?: Array<string>;
    /** in list */
    queryReferenceTracks?: Array<string>;
    /** contains */
    queryAreCodes?: Array<string>;
}

export interface GetSuccessionManagementPartnerV1ReviewTalentsRequestParams {
    /** filters for talent pool title (va contains) */
    queryTalentPoolTitle?: Array<string>;
    /** multi-select filters for talent pool types */
    queryTalentPoolReferenceMetaTypes?: Array<string>;
    /** contains */
    queryFirstname?: string;
    /** contains */
    queryLastname?: string;
    /** contains */
    queryGid?: string;
    /** OR filter clause for gid, firstname, lastname */
    querySearch?: string;
    /** filters for id of talent pool */
    queryTalentPoolId?: string;
    /** contains */
    queryOrgCode?: string;
    /** contains */
    queryOrgName?: string;
    /** equals */
    queryPlaceOfAction?: Array<string>;
    /** in list */
    queryOrgCodes?: Array<string>;
    /** in list */
    queryOrgNames?: Array<string>;
    pagingPage?: number;
    pagingSize?: number;
    sortingProperty?: string;
    /** enum: ASC/DESC */
    sortingOrderBy?: string;
    /** enum - defaults for N-3 &amp; HR BPs */
    queryRecommendationState?: Array<string>;
    /** in list */
    queryReferenceTracks?: Array<string>;
    /** in list */
    queryAreCodes?: Array<string>;
    /** gid NOT IN */
    queryReviewExcluded?: Array<string>;
}


@Injectable({
  providedIn: 'root'
})
export class TalentsService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Talent Pool - Get talents to review
     * Provides list of talents that match filter criteria. Callable as a manager. Default filter is \&quot;Recommended\&quot;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuccessionManagementCustomerV1ReviewTalents(requestParameters: GetSuccessionManagementCustomerV1ReviewTalentsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TalentEmployeePage>;
    public getSuccessionManagementCustomerV1ReviewTalents(requestParameters: GetSuccessionManagementCustomerV1ReviewTalentsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TalentEmployeePage>>;
    public getSuccessionManagementCustomerV1ReviewTalents(requestParameters: GetSuccessionManagementCustomerV1ReviewTalentsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TalentEmployeePage>>;
    public getSuccessionManagementCustomerV1ReviewTalents(requestParameters: GetSuccessionManagementCustomerV1ReviewTalentsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const queryTalentPoolTitle = requestParameters.queryTalentPoolTitle;
        const queryTalentPoolReferenceMetaTypes = requestParameters.queryTalentPoolReferenceMetaTypes;
        const queryFirstname = requestParameters.queryFirstname;
        const queryLastname = requestParameters.queryLastname;
        const queryGid = requestParameters.queryGid;
        const querySearch = requestParameters.querySearch;
        const queryTalentPoolId = requestParameters.queryTalentPoolId;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgName = requestParameters.queryOrgName;
        const queryPlaceOfAction = requestParameters.queryPlaceOfAction;
        const queryOrgCodes = requestParameters.queryOrgCodes;
        const queryOrgNames = requestParameters.queryOrgNames;
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;
        const sortingProperty = requestParameters.sortingProperty;
        const sortingOrderBy = requestParameters.sortingOrderBy;
        const queryRecommendationState = requestParameters.queryRecommendationState;
        const queryReferenceTracks = requestParameters.queryReferenceTracks;
        const queryAreCodes = requestParameters.queryAreCodes;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryTalentPoolTitle) {
            queryTalentPoolTitle.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.talentPoolTitle');
            })
        }
        if (queryTalentPoolReferenceMetaTypes) {
            queryTalentPoolReferenceMetaTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.talentPoolReferenceMetaTypes');
            })
        }
        if (queryFirstname !== undefined && queryFirstname !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFirstname, 'query.firstname');
        }
        if (queryLastname !== undefined && queryLastname !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryLastname, 'query.lastname');
        }
        if (queryGid !== undefined && queryGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryGid, 'query.gid');
        }
        if (querySearch !== undefined && querySearch !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>querySearch, 'query.search');
        }
        if (queryTalentPoolId !== undefined && queryTalentPoolId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryTalentPoolId, 'query.talentPoolId');
        }
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'query.orgCode');
        }
        if (queryOrgName !== undefined && queryOrgName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgName, 'query.orgName');
        }
        if (queryPlaceOfAction) {
            queryPlaceOfAction.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.placeOfAction');
            })
        }
        if (queryOrgCodes) {
            queryOrgCodes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgCodes');
            })
        }
        if (queryOrgNames) {
            queryOrgNames.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgNames');
            })
        }
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'paging.page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'paging.size');
        }
        if (sortingProperty !== undefined && sortingProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingProperty, 'sorting.property');
        }
        if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingOrderBy, 'sorting.orderBy');
        }
        if (queryRecommendationState) {
            queryRecommendationState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.recommendationState');
            })
        }
        if (queryReferenceTracks) {
            queryReferenceTracks.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.referenceTracks');
            })
        }
        if (queryAreCodes) {
            queryAreCodes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.areCodes');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/customer/v1/review-talents`;
        return this.httpClient.request<TalentEmployeePage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Talent Pool - Get talents
     * Provides list of talents that match filter criteria. Depending on the currently active user, the result set is prefiltered (e.g. only directs of manager)
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuccessionManagementCustomerV1Talents(requestParameters: GetSuccessionManagementCustomerV1TalentsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TalentEmployeePage>;
    public getSuccessionManagementCustomerV1Talents(requestParameters: GetSuccessionManagementCustomerV1TalentsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TalentEmployeePage>>;
    public getSuccessionManagementCustomerV1Talents(requestParameters: GetSuccessionManagementCustomerV1TalentsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TalentEmployeePage>>;
    public getSuccessionManagementCustomerV1Talents(requestParameters: GetSuccessionManagementCustomerV1TalentsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const queryTalentPoolTitle = requestParameters.queryTalentPoolTitle;
        const queryFirstname = requestParameters.queryFirstname;
        const queryLastname = requestParameters.queryLastname;
        const queryGid = requestParameters.queryGid;
        const querySearch = requestParameters.querySearch;
        const queryTalentPoolId = requestParameters.queryTalentPoolId;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgName = requestParameters.queryOrgName;
        const queryPlaceOfAction = requestParameters.queryPlaceOfAction;
        const queryOrgCodes = requestParameters.queryOrgCodes;
        const queryOrgNames = requestParameters.queryOrgNames;
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;
        const sortingProperty = requestParameters.sortingProperty;
        const sortingOrderBy = requestParameters.sortingOrderBy;
        const queryRecommendationState = requestParameters.queryRecommendationState;
        const queryAreCodes = requestParameters.queryAreCodes;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryTalentPoolTitle) {
            queryTalentPoolTitle.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.talentPoolTitle');
            })
        }
        if (queryFirstname !== undefined && queryFirstname !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFirstname, 'query.firstname');
        }
        if (queryLastname !== undefined && queryLastname !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryLastname, 'query.lastname');
        }
        if (queryGid !== undefined && queryGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryGid, 'query.gid');
        }
        if (querySearch !== undefined && querySearch !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>querySearch, 'query.search');
        }
        if (queryTalentPoolId !== undefined && queryTalentPoolId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryTalentPoolId, 'query.talentPoolId');
        }
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'query.orgCode');
        }
        if (queryOrgName !== undefined && queryOrgName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgName, 'query.orgName');
        }
        if (queryPlaceOfAction) {
            queryPlaceOfAction.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.placeOfAction');
            })
        }
        if (queryOrgCodes) {
            queryOrgCodes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgCodes');
            })
        }
        if (queryOrgNames) {
            queryOrgNames.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgNames');
            })
        }
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'paging.page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'paging.size');
        }
        if (sortingProperty !== undefined && sortingProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingProperty, 'sorting.property');
        }
        if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingOrderBy, 'sorting.orderBy');
        }
        if (queryRecommendationState) {
            queryRecommendationState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.recommendationState');
            })
        }
        if (queryAreCodes) {
            queryAreCodes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.areCodes');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/customer/v1/talents`;
        return this.httpClient.request<TalentEmployeePage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Talent Pool - Export talents
     * Provides list of talents that match filter criteria. Callable as HR BP/Admin. Default filter is \&quot;ReviewerAccepted\&quot;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuccessionManagementPartnerV1ExportTalents(requestParameters: GetSuccessionManagementPartnerV1ExportTalentsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ExportTransactionStatus>;
    public getSuccessionManagementPartnerV1ExportTalents(requestParameters: GetSuccessionManagementPartnerV1ExportTalentsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ExportTransactionStatus>>;
    public getSuccessionManagementPartnerV1ExportTalents(requestParameters: GetSuccessionManagementPartnerV1ExportTalentsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ExportTransactionStatus>>;
    public getSuccessionManagementPartnerV1ExportTalents(requestParameters: GetSuccessionManagementPartnerV1ExportTalentsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const queryTalentPoolTitle = requestParameters.queryTalentPoolTitle;
        const queryTalentPoolReferenceMetaTypes = requestParameters.queryTalentPoolReferenceMetaTypes;
        const queryFirstname = requestParameters.queryFirstname;
        const queryLastname = requestParameters.queryLastname;
        const queryGid = requestParameters.queryGid;
        const queryTalentPoolId = requestParameters.queryTalentPoolId;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgName = requestParameters.queryOrgName;
        const queryPlaceOfAction = requestParameters.queryPlaceOfAction;
        const queryOrgCodes = requestParameters.queryOrgCodes;
        const queryOrgNames = requestParameters.queryOrgNames;
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;
        const sortingProperty = requestParameters.sortingProperty;
        const sortingOrderBy = requestParameters.sortingOrderBy;
        const queryRecommendationState = requestParameters.queryRecommendationState;
        const queryReferenceTracks = requestParameters.queryReferenceTracks;
        const queryAreCodes = requestParameters.queryAreCodes;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryTalentPoolTitle) {
            queryTalentPoolTitle.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.talentPoolTitle');
            })
        }
        if (queryTalentPoolReferenceMetaTypes) {
            queryTalentPoolReferenceMetaTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.talentPoolReferenceMetaTypes');
            })
        }
        if (queryFirstname !== undefined && queryFirstname !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFirstname, 'query.firstname');
        }
        if (queryLastname !== undefined && queryLastname !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryLastname, 'query.lastname');
        }
        if (queryGid !== undefined && queryGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryGid, 'query.gid');
        }
        if (queryTalentPoolId !== undefined && queryTalentPoolId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryTalentPoolId, 'query.talentPoolId');
        }
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'query.orgCode');
        }
        if (queryOrgName !== undefined && queryOrgName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgName, 'query.orgName');
        }
        if (queryPlaceOfAction !== undefined && queryPlaceOfAction !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryPlaceOfAction, 'query.placeOfAction');
        }
        if (queryOrgCodes) {
            queryOrgCodes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgCodes');
            })
        }
        if (queryOrgNames) {
            queryOrgNames.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgNames');
            })
        }
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'paging.page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'paging.size');
        }
        if (sortingProperty !== undefined && sortingProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingProperty, 'sorting.property');
        }
        if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingOrderBy, 'sorting.orderBy');
        }
        if (queryRecommendationState) {
            queryRecommendationState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.recommendationState');
            })
        }
        if (queryReferenceTracks) {
            queryReferenceTracks.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.referenceTracks');
            })
        }
        if (queryAreCodes) {
            queryAreCodes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.areCodes');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/partner/v1/review-talents/export`;
        return this.httpClient.request<ExportTransactionStatus>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Talent Pool - Get talents to review
     * Provides list of talents that match filter criteria. Callable as HR BP/Admin. Default filter is \&quot;ReviewerAccepted\&quot;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuccessionManagementPartnerV1ReviewTalents(requestParameters: GetSuccessionManagementPartnerV1ReviewTalentsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TalentEmployeePage>;
    public getSuccessionManagementPartnerV1ReviewTalents(requestParameters: GetSuccessionManagementPartnerV1ReviewTalentsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TalentEmployeePage>>;
    public getSuccessionManagementPartnerV1ReviewTalents(requestParameters: GetSuccessionManagementPartnerV1ReviewTalentsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TalentEmployeePage>>;
    public getSuccessionManagementPartnerV1ReviewTalents(requestParameters: GetSuccessionManagementPartnerV1ReviewTalentsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const queryTalentPoolTitle = requestParameters.queryTalentPoolTitle;
        const queryTalentPoolReferenceMetaTypes = requestParameters.queryTalentPoolReferenceMetaTypes;
        const queryFirstname = requestParameters.queryFirstname;
        const queryLastname = requestParameters.queryLastname;
        const queryGid = requestParameters.queryGid;
        const querySearch = requestParameters.querySearch;
        const queryTalentPoolId = requestParameters.queryTalentPoolId;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgName = requestParameters.queryOrgName;
        const queryPlaceOfAction = requestParameters.queryPlaceOfAction;
        const queryOrgCodes = requestParameters.queryOrgCodes;
        const queryOrgNames = requestParameters.queryOrgNames;
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;
        const sortingProperty = requestParameters.sortingProperty;
        const sortingOrderBy = requestParameters.sortingOrderBy;
        const queryRecommendationState = requestParameters.queryRecommendationState;
        const queryReferenceTracks = requestParameters.queryReferenceTracks;
        const queryAreCodes = requestParameters.queryAreCodes;
        const queryReviewExcluded = requestParameters.queryReviewExcluded;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryTalentPoolTitle) {
            queryTalentPoolTitle.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.talentPoolTitle');
            })
        }
        if (queryTalentPoolReferenceMetaTypes) {
            queryTalentPoolReferenceMetaTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.talentPoolReferenceMetaTypes');
            })
        }
        if (queryFirstname !== undefined && queryFirstname !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFirstname, 'query.firstname');
        }
        if (queryLastname !== undefined && queryLastname !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryLastname, 'query.lastname');
        }
        if (queryGid !== undefined && queryGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryGid, 'query.gid');
        }
        if (querySearch !== undefined && querySearch !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>querySearch, 'query.search');
        }
        if (queryTalentPoolId !== undefined && queryTalentPoolId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryTalentPoolId, 'query.talentPoolId');
        }
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'query.orgCode');
        }
        if (queryOrgName !== undefined && queryOrgName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgName, 'query.orgName');
        }
        if (queryPlaceOfAction) {
            queryPlaceOfAction.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.placeOfAction');
            })
        }
        if (queryOrgCodes) {
            queryOrgCodes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgCodes');
            })
        }
        if (queryOrgNames) {
            queryOrgNames.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgNames');
            })
        }
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'paging.page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'paging.size');
        }
        if (sortingProperty !== undefined && sortingProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingProperty, 'sorting.property');
        }
        if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingOrderBy, 'sorting.orderBy');
        }
        if (queryRecommendationState) {
            queryRecommendationState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.recommendationState');
            })
        }
        if (queryReferenceTracks) {
            queryReferenceTracks.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.referenceTracks');
            })
        }
        if (queryAreCodes) {
            queryAreCodes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.areCodes');
            })
        }
        if (queryReviewExcluded) {
            queryReviewExcluded.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.reviewExcluded');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/partner/v1/review-talents`;
        return this.httpClient.request<TalentEmployeePage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Dashboard Values for Partner App
     * Provides dashboard values for partner app
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuccessionManagementPartnerV1ReviewTalentsDashboard(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TalentReviewDashboardValues>;
    public getSuccessionManagementPartnerV1ReviewTalentsDashboard(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TalentReviewDashboardValues>>;
    public getSuccessionManagementPartnerV1ReviewTalentsDashboard(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TalentReviewDashboardValues>>;
    public getSuccessionManagementPartnerV1ReviewTalentsDashboard(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/partner/v1/review-talents/dashboard`;
        return this.httpClient.request<TalentReviewDashboardValues>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
