import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { PermissionAction, PermissionResource } from '@coin/shared/util-enums';
import { Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { TinyHelpers } from '@coin/shared/util-helpers';
import { takeUntil } from 'rxjs/operators';
import { UserState } from './user.state';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[permissionAccess]',
  standalone: true
})
export class DynamicDomAccessDirective implements OnChanges, OnInit, OnDestroy {
  @Input() allowedResources: PermissionResource[];
  @Input() allowedActions?: PermissionAction[];
  @Input() useLoggedInUser: boolean;

  public initialDisplayStyle: string;

  private newValue$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.initialDisplayStyle = this.elementRef.nativeElement.style.display;
    this.elementRef.nativeElement.style.display = 'none';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.elementRef?.nativeElement && changes.allowedResources && !TinyHelpers.areItemsSimilar(changes.allowedResources.previousValue, changes.allowedResources.currentValue)) {
      this.newValue$.next();
      this.updateVisibilityBasedOnPermissions();
    }
  }

  private updateVisibilityBasedOnPermissions(): void {
    const storeReq$ = !this.useLoggedInUser ? this.store.select(UserState?.allPermissions) : this.store.select(UserState?.allPermissionsLoggedInUser);

    storeReq$.pipe(takeUntil(this.newValue$)).subscribe(userPermissions => {
      if (userPermissions && this.allowedResources?.length) {
        const allowedActions = this.allowedActions || [PermissionAction.All, PermissionAction.Read];

        const hasRights = userPermissions.some(
          permission =>
            permission.resource === PermissionResource.All ||
            (this.allowedResources.some(res => res === permission.resource) &&
              (permission.action === PermissionAction.All || allowedActions.some(action => action === permission.action)))
        );

        if (hasRights) {
          setTimeout(() => {
            this.elementRef.nativeElement.style.display = this.initialDisplayStyle;
          });
        } else {
          this.elementRef.nativeElement.remove();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.newValue$.complete();
  }
}
