import { Pipe, PipeTransform } from '@angular/core';
import { ThousandsSeparatorPipe } from './thousands-separator.pipe';

@Pipe({
  name: 'formatUnknownValue',
  standalone: true
})
export class FormatUnknownValuePipe implements PipeTransform {
  constructor(private thousandsSeparatorPipe: ThousandsSeparatorPipe) {}

  transform(value: number): string | number;
  transform(value: unknown): unknown {
    if (typeof value === 'number' || !isNaN(value as number)) {
      return this.thousandsSeparatorPipe.transform(value as number);
    }

    return value;
  }
}
