import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName'
})
export class FileNamePipe implements PipeTransform {
  private uuidLength = 36;

  transform(name: string): string {
    const nameParts = name?.split('/');
    const lastPart = nameParts?.[nameParts.length - 1];

    // do not show ids in name
    if (lastPart?.length === this.uuidLength) {
      nameParts.splice(-1);
    }

    return nameParts.join('_');
  }
}
