<div class="person" [class.light]="light" [class.big]="big" [class.small]="small" [class.tiny]="tiny">
  <!-- Image -->
  <div *ngIf="employee || showPlaceholder" class="avatar-container" [class.show-flag]="employee && showFlag">
    <div class="avatar" slot="start" [class]="{ transparent: userImage, placeholder: showPlaceholder }">
      <mat-icon *ngIf="!userImage && !imageIcon">person</mat-icon>
      <mat-icon *ngIf="imageIcon">{{ imageIcon }}</mat-icon>
      <div class="image-part" *ngIf="userImage" [ngStyle]="{ background: 'url(' + userImage + ')' }"></div>
    </div>
    <div
      *ngIf="employee?.companyExitDate && showExitDateWarning"
      [matTooltip]="'general.employee-exit-date-msg' | translate: { exitDate: formattedCompanyExitDate }"
      class="exit-date-warning">
      <mat-icon>event_busy</mat-icon>
    </div>
  </div>

  <div class="additional-icons">
    <mat-icon>chevron_right</mat-icon>
    <coin-v2-country-flag
      *ngIf="employee && showFlag && (employee.countryCode || employee.placeOfAction || employee['placeofAction'])"
      [showTooltip]="true"
      class="flag-icon"
      [country]="employee | pure: country"
      size="s"></coin-v2-country-flag>
  </div>

  <div class="infos">
    <!-- Top info -->
    <p *ngFor="let item of customTopInfo" coinEllipsisTooltip matTooltip class="additional-info">{{ item }}</p>

    <!-- Employee name -->
    <div class="info-header" [ngClass]="{ 'single-line': singleLine || showPlaceholder }">
      <ng-container *ngIf="showPlaceholder; else employeeName">
        <p>{{ placeholderText }}</p>
      </ng-container>

      <ng-template #employeeName>
        <ng-container *ngIf="employee">
          <p *ngIf="singleLine; else multiLine" coinEllipsisTooltip matTooltip [class.bold]="boldName" [ngClass]="{ smaller: noWrap }">
            {{ namePrefix }} {{ employee | employeeName }}
          </p>

          <ng-template #multiLine>
            <p *ngIf="namePrefix" [class.bold]="boldName">{{ namePrefix }}</p>
            <p [class.bold]="boldName">{{ employee?.firstname }}</p>
            <p [class.bold]="boldName">{{ employee?.lastname }}</p>
          </ng-template>
        </ng-container>
      </ng-template>

      <coin-action-button (click)="onActionClick()" [small]="true" class="action-button" *ngIf="showActionButton" [matIcon]="actionIcon" [noicon]="true">{{
        actionButtonTitle
      }}</coin-action-button>
    </div>

    <!-- Org code -->
    <ng-container *ngIf="showOrgCode">
      <coin-c-text-input-overlay
        *ngIf="!employee?.headGid && !employee?.isHead && (employee?.paOrgCode || employee?.orgCode)"
        [isEditable]="isOrgCodeEditable"
        class="org-code"
        [ngClass]="{ smaller: noWrap, wrap }"
        [infoHeader]="'general.orgcode-update' | translate"
        [infoText]="'general.change-orgcode' | translate"
        [isSmall]="true"
        [value]="employee?.paOrgCode ?? employee?.orgCode"
        [choicesWithSearch]="true"
        [lazyLoadChoicesFn]="lazyLoadOrgCodesFn"
        (valueChanged)="orgCodeChanged.emit($event)">
      </coin-c-text-input-overlay>
      <coin-c-text-input-overlay
        *ngIf="(employee?.headGid || employee?.isHead) && employee?.orgCode"
        [isEditable]="isOrgCodeEditable"
        class="org-code"
        [ngClass]="{ smaller: noWrap, wrap }"
        [infoHeader]="'general.orgcode-update' | translate"
        [infoText]="'general.change-orgcode' | translate"
        [isSmall]="true"
        [value]="employee?.orgCode"
        [choicesWithSearch]="true"
        [lazyLoadChoicesFn]="lazyLoadOrgCodesFn"
        (valueChanged)="orgCodeChanged.emit($event)">
      </coin-c-text-input-overlay>
    </ng-container>

    <!-- Sub title -->
    <ng-container *ngIf="subTitle">
      <p [ngClass]="{ smaller: noWrap }">
        {{ subTitle }}
      </p>
    </ng-container>

    <!-- Gid -->
    <p *ngIf="showGid && (employee?.headGid || employee?.gid)">{{ idKey }}: {{ employee?.headGid || employee?.gid }}</p>

    <!-- Sub info -->
    <p *ngFor="let item of customSubInfo" coinEllipsisTooltip matTooltip class="additional-info">{{ item }}</p>
  </div>

  <!-- Roles -->
  <div *ngIf="roleNames.length" class="user-roles">
    <p *ngFor="let name of roleNames" class="item-coin" matTooltip coinEllipsisTooltip>
      {{ name }}
    </p>
  </div>

  <!-- Position -->
  <div *ngIf="positionClass" class="position-class" [class.pc-small]="smallerPositionClass">
    <p>PC {{ positionClass }}</p>
  </div>
  <div *ngIf="!positionClass && showHintIfNoPositionClassSet" class="position-class missing" [class.pc-small]="smallerPositionClass">
    <p>{{ 'mercer-support.dialog.no-pc' | translate }}</p>
  </div>
</div>
