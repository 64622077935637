import { Component, Input } from '@angular/core';
import { EquityPageComponentModel } from '../../models/equity-page-component.model';

@Component({
  selector: 'app-equity-sub-title',
  templateUrl: './equity-sub-title.component.html',
  styleUrls: ['./equity-sub-title.component.scss']
})
export class EquitySubTitleComponent implements EquityPageComponentModel {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() data: any;
}
