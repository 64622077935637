<div class="container" (contextmenu)="onRightClick($event)">
  <!-- view only -->
  <div class="angular-editor-textarea p" *ngIf="showAngularTextarea" [innerHTML]="item.content?.['text'] || item.content?.['html'] || item.content"></div>
  <ng-container *ngIf="showAdditionalEditOptions && item">
    <div *ngIf="item.type === 'editor'" class="background-color-picker">
      <mat-checkbox [(ngModel)]="item.isHighlighted">Highlighted</mat-checkbox>
    </div>
    <div class="background-color-picker">
      <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
    </div>
    <div class="background-color-picker">
      <coin-color-picker [disabled]="item.isHighlighted" [(ngModel)]="item.textColor" [isTextColorPicker]="true"></coin-color-picker>
    </div>
    <coin-a-email-padding-slider [(ngModel)]="item.paddingTop" label="Space-Top"></coin-a-email-padding-slider>
    <coin-a-email-padding-slider [(ngModel)]="item.paddingBottom" label="Space-Bottom"></coin-a-email-padding-slider>
  </ng-container>
  <!-- ngx-quill editor v1  -->
  <!-- info: switch to v2 if released in order to remove kolkov editor which supports html tables (see below) -->
  <quill-editor *ngIf="showContentEditor" [modules]="quillSimpleTextModules" [formControl]="quillFormControl"></quill-editor>
  <quill-editor *ngIf="showTextEditor" [modules]="quillSimpleTextModules" [formControl]="quillFormControl"></quill-editor>
  <quill-editor *ngIf="showContentTextEditor" [modules]="quillAdvancedModules" [formControl]="quillFormControl"></quill-editor>
  <quill-editor *ngIf="showContentTextHtml" [modules]="quillAdvancedModules" [formControl]="quillFormControl"></quill-editor>

  <!-- kolkov editor with table support -->
  <angular-editor *ngIf="showAngularHtmlEditor" [config]="angularEditorHTMLConfig" [(ngModel)]="item.content['html']"></angular-editor>
  <angular-editor *ngIf="showFaqTableTextEditor" [config]="angularEditorHTMLConfig" [(ngModel)]="item.text"></angular-editor>

  <!-- opens at right click -->
  <app-contextmenu
    *ngIf="contextMenuVisible"
    #contextMenu
    [x]="contextmenuX"
    [y]="contextmenuY"
    [contextMenuReplacements]="contextMenuReplacements"
    (addItemToContent)="addItemToCursor($event)"
    (close)="contextMenuVisible = false"></app-contextmenu>
</div>
