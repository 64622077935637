import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserImageStoreService {
  private allStoredUserImages: string[] = [];

  public getUser(gid: string): string {
    return this.allStoredUserImages[gid];
  }
}
