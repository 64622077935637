import { RecommendationStateEnum } from '@coin/shared/util-enums';

export class SuccessionManagementHelper {
  public static getColorForRecommendationState(recommendationState: RecommendationStateEnum): string {
    switch (recommendationState) {
      case 'Recommended':
        return 'purple';
      case 'ReviewerAccepted':
        return 'petrol';
      case 'Declined':
      case 'ReviewerDeclined':
        return 'red';
      case 'Approved':
        return 'petrol';
    }
  }
}
