import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TopicOpenType } from '@coin/shared/util-enums';
import { Topic } from '@coin/shared/util-models';
import { Store } from '@ngxs/store';
import { TopicsState } from '../../../home/store';
import { TopicItemOpenHandlerService } from '../../services/topic-item-open-handler.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [
    trigger('topicItemAnimation', [
      transition(':enter', [
        style({ maxHeight: '0', marginBottom: '0', opacity: '0' }),
        animate('400ms linear', style({ maxHeight: '200px', marginBottom: '30px', opacity: '1' }))
      ]),
      transition(':leave', [style({ maxHeight: '200px', marginBottom: '30px', opacity: '1' }), animate('400ms linear', style({ maxHeight: '0', marginBottom: '0', opacity: '0' }))])
    ])
  ]
})
export class NotificationComponent implements OnInit {
  filteredTopics$ = this.store.select<Topic[]>(TopicsState.filteredTopics);

  constructor(
    public dialogRef: MatDialogRef<NotificationComponent>,
    private topicOpenHandler: TopicItemOpenHandlerService,
    private store: Store
  ) {}

  ngOnInit(): void {
    if (this.dialogRef.addPanelClass) {
      // Function is not defined in Unit-Test
      this.dialogRef.addPanelClass('d1r-dialog-container-bo');
    }
  }

  deleteAllNotifications(): void {}
  markAllAsRead(): void {}

  close(): void {
    this.dialogRef.close();
  }

  public onTopicItemOpen(type: TopicOpenType, item: Topic): void {
    this.topicOpenHandler.handle(item, type);
  }
}
