<div class="dialog-header">
  <ng-container *ngTemplateOutlet="isCustomHeader ? $any(header) : textHeader"> </ng-container>
  <div *ngIf="showCloseButton" class="close-dialog-button-wrapper">
    <button (click)="closeButtonClick.emit()"><mat-icon>close</mat-icon> {{ 'general.btnClose' | translate }}</button>
  </div>
</div>
<div class="dialog-body">
  <ng-content></ng-content>
</div>
<ng-content select="coin-v2-dialog-footer"> </ng-content>

<ng-template #textHeader>
  <h4>{{ header }}</h4>
</ng-template>
