<div class="container">
  <h4 class="top-headline">{{ 'general.emulate' | translate }}</h4>
  <div class="select-emulate-popup">
    <div *ngIf="recentlyEmulated.length > 0" class="recently-emulated-container">
      <p class="bold">{{ 'general.recently-emulated' | translate }}</p>
      <div class="recently-emulated-employees">
        <coin-siemens-energy-employee-preview *ngFor="let employee of recentlyEmulated" [employee]="employee" (click)="proceed(employee)"></coin-siemens-energy-employee-preview>
      </div>
    </div>
    <div class="emulate-search">
      <coin-input addClass="shadow" (enterClicked)="search()" type="text" [(ngModel)]="user.gid" placeholder="Gid" label="User GID"></coin-input>
      <coin-input
        addClass="shadow"
        (enterClicked)="search()"
        type="text"
        [(ngModel)]="user.name.lastname"
        placeholder="{{ 'general.lastname' | translate }}"
        label="{{ 'general.lastname' | translate }}"></coin-input>
      <coin-input
        addClass="shadow"
        (enterClicked)="search()"
        type="text"
        [(ngModel)]="user.name.firstname"
        placeholder="{{ 'general.firstname' | translate }}"
        label="{{ 'general.firstname' | translate }}"></coin-input>
      <coin-action-button class="primary" svgIcon="create-order" (click)="search()">{{ 'general.search' | translate }} </coin-action-button>
    </div>

    <div class="emulate-list">
      <div class="loading" @shrinkExpand *ngIf="loading">
        <mat-progress-bar class="select-emu" mode="buffer"></mat-progress-bar>
      </div>

      <p *ngIf="emulateablePersons.content.length === 0 && !loading">{{ 'general.no-emulation-users-found' | translate }}</p>

      <ngx-simplebar class="content-container primary-scrollbar-vertical no-space">
        <div class="user-list" *ngIf="!loading">
          <div *ngFor="let userItem of emulateablePersons.content" @shrinkExpand (click)="proceed(userItem)" class="user-item">
            <div class="user-profile">
              <coin-v2-country-flag [country]="userItem?.placeOfAction" size="s"></coin-v2-country-flag>
              <div class="lang-image" *ngIf="userItem?.image !== 'no-image'" [ngStyle]="{ 'background-image': 'url(' + userItem?.image + ')' }"></div>
              <div class="lang-image no-image" *ngIf="userItem?.image === 'no-image'">
                <span class="material-icons">person</span>
              </div>
            </div>
            <div class="user-titles">
              <p>{{ userItem?.firstname }} {{ userItem?.lastname }}</p>
              <p class="user-org">{{ userItem?.paOrgCode }}, {{ userItem?.placeOfAction }}</p>
            </div>
          </div>
        </div>
      </ngx-simplebar>
    </div>

    <div class="emulate-cancel">
      <coin-action-button (click)="cancel()">{{ 'general.btnCancel' | translate }} </coin-action-button>
    </div>
  </div>
</div>
