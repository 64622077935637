import { TreeNode } from '../route-builder';

export const IncentiveModule = {
  segment: 'incentive',
  title: '',
  children: {
    IncentiveComponent: {
      segment: '',
      title: 'incentive.title',
      navigationRoot: true,
      children: {
        // TODO Icons ?
        IncentiveKpisComponent: {
          segment: 'kpis',
          icon: 'analytics',
          title: 'incentive.interfaces.kpis.title',
          children: {
            IncentiveKpiTargetSettingsComponent: { segment: 'target-settings', params: ['kpiId'], paramTitle: 'kpiId', title: 'incentive.interfaces.kpis.target-settings.title' }
          }
        },
        IncentiveFactorsComponent: {
          segment: 'factors',
          icon: 'bubble_chart',
          title: 'incentive.interfaces.factors.title',
          children: {
            IncentiveFactorTargetSettingsComponent: {
              segment: 'target-settings',
              params: ['factorId'],
              paramTitle: 'factorId',
              title: 'incentive.interfaces.factors.target-settings.title'
            }
          }
        },
        IncentiveIPPsComponent: {
          segment: 'ipps',
          icon: 'insights',
          title: 'incentive.interfaces.ipps.title'
        }
      }
    }
  }
} as const satisfies TreeNode;
