<div class="header">
  <div class="text-container">
    <h3>{{ 'service-provider.main-headline' | translate }}</h3>
    <p>
      <b>{{ 'service-provider.sub-headline' | translate }}</b>
    </p>
  </div>
</div>
<div class="scroll-wrapper">
  <div class="container">
    <ng-container *ngFor="let sp of serviceProvider">
      <h2>{{ 'service-provider.' + sp.title | translate }}</h2>
      <table>
        <tr class="headline">
          <th *ngFor="let header of sp.header; let i = index" [style.width]="columnWidths[i]">
            <p *ngIf="header.attribute && header.attribute !== ''" [innerHtml]="'service-provider.' + header.attribute | translate"></p>
          </th>
        </tr>
        <tr *ngFor="let data of sp.data">
          <td *ngFor="let header of sp.header; let i = index" [style.width]="columnWidths[i]">
            <p
              *ngIf="header.attribute && (header.attribute === 'country' || header.attribute === 'spForSharePlans' || header.attribute === 'spForBudgetAllocation')"
              [innerHtml]="'service-provider.' + data[header.attribute] | translate"></p>
            <p
              *ngIf="header.attribute && !(header.attribute === 'country' || header.attribute === 'spForSharePlans' || header.attribute === 'spForBudgetAllocation')"
              [innerHtml]="data[header.attribute]"></p>
          </td>
        </tr>
      </table>
      <div *ngFor="let footnote of sp.footnote">
        <p *ngIf="footnote.attribute === 'financialSp'">{{ 'service-provider.financial-service-footnote' | translate }}</p>
        <p *ngIf="footnote.attribute === 'otherSp'">{{ 'service-provider.other-service-provider-footnote' | translate }}</p>
      </div>
    </ng-container>

    <p>{{ 'service-provider.list-of-other-services' | translate }}</p>
    <h2>{{ 'service-provider.revocation-consent-shareholder-meetings' | translate }}</h2>
    <p>{{ 'service-provider.revocation-consent-shareholder-meetings-text' | translate }}</p>
    <p style="margin: 0px">Siemens Energy AG</p>
    <p style="margin: 0px">c/o ADEUS Aktienregister-Service-GmbH</p>
    <p style="margin: 0px">20621 Hamburg</p>
    <p style="margin: 0px">{{ 'service-provider.email' | translate }}: <a href="mailto:hv-service.siemens-energy@adeus.de"> hv-service.siemens-energy&#64;adeus.de</a></p>
    <p>{{ 'service-provider.revocation-consent-shareholder-meetings-text-2' | translate }}</p>
    <h2>{{ 'service-provider.right-of-objection' | translate }}</h2>
    <p>{{ 'service-provider.right-of-objection-text' | translate }}</p>
    <p style="margin: 0px"><a href="mailto:hv-service.siemens-energy@adeus.de"> hv-service.siemens-energy&#64;adeus.de</a></p>
  </div>
</div>
