<div>
  <ng-container *ngIf="showAdditionalEditOptions">
    <div class="spacing-elements">
      <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
    </div>
    <coin-a-email-padding-slider [(ngModel)]="item.paddingTop" label="Space-Top"></coin-a-email-padding-slider>
    <coin-a-email-padding-slider [(ngModel)]="item.paddingBottom" label="Space-Bottom"></coin-a-email-padding-slider>
  </ng-container>
  <div *ngFor="let linkItem of item.content" class="download-file-item">
    <img class="download-file-icon" aria-label="download icon" [src]="imagePath + 'coin/images/originals/download_icon_SE.png'" />
    <mat-form-field class="flex-one" *ngIf="contentEdit">
      <mat-label>Link Text</mat-label>
      <input matInput aria-label="link text" [(ngModel)]="linkItem.text" />
    </mat-form-field>
    <mat-form-field class="flex-one" *ngIf="contentEdit">
      <mat-label>Link Href</mat-label>
      <input matInput aria-label="link link" [(ngModel)]="linkItem.link" type="test" placeholder="https://..." />
    </mat-form-field>
    <a [href]="linkItem.link" class="flex-one" *ngIf="!contentEdit" target="_blank">{{ linkItem.text }}</a>
  </div>
</div>

<div class="download-file-edit" *ngIf="contentEdit">
  <mat-form-field appearance="fill">
    <mat-label>{{ 'cms.amount-links' | translate }}</mat-label>
    <mat-select (selectionChange)="amountChange($event.value)" [(ngModel)]="amountLinks">
      <mat-option *ngFor="let option of amountOptions" [value]="option">{{ option }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox *ngIf="isContentPage" [(ngModel)]="item.requiresAuthentication" [checked]="item.requiresAuthentication">
    {{ 'cms.content-pages.requires-authentication' | translate }}
  </mat-checkbox>
</div>
