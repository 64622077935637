/* eslint-disable max-lines */
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, DestroyRef, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@coin/modules/auth/data-access';
import { FirstVisitService } from '@coin/modules/auth/data-management';
import { CmsTranslationService, FaqService, StorageService } from '@coin/shared/data-access';
import { ResponsivenessService } from '@coin/shared/data-management-state';
import { ContentLanguage, FrontendType, StorageKey } from '@coin/shared/util-enums';
import { environment } from '@coin/shared/util-environments';
import { GlobalEventsService } from '@coin/shared/util-helpers';
import { FaqItem, LanguageMapping, Topic } from '@coin/shared/util-models';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { iif, Observable, of, Subject } from 'rxjs';
import { filter, finalize, skip, switchMap, tap } from 'rxjs/operators';
import { TopicsState } from '../../home/store';
import { NotificationComponent } from '../../shared/dialogs/notification/notification.component';
import { OpenFaqService } from '../../shared/services/open-faq.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    [
      trigger('openClose', [
        // ...
        state(
          'open',
          style({
            opacity: 1,
            left: '320px',
            'pointer-events': 'inherit'
          })
        ),
        state(
          'closed',
          style({
            opacity: 0,
            left: '0px',
            'pointer-events': 'none'
          })
        ),
        transition('open => closed', [animate('400ms')]),
        transition('closed => open', [animate('400ms')])
      ]),
      trigger('shrinkExpand', [
        transition(':enter', [
          style({ height: '0px', minHeight: '0px', maxHeight: '0px', opacity: 0 }),
          animate('250ms linear', style({ height: '*', minHeight: '*', maxHeight: '*', opacity: 1 }))
        ]),
        transition(':leave', [animate('250ms linear', style({ height: '0px', minHeight: '0px', maxHeight: '0px', opacity: 0 }))])
      ]),
      trigger('slideFaq', [
        transition(':enter', [style({ right: '-450px' }), animate('400ms ease-in', style({ right: '0px' }))]),
        transition(':leave', [style({ right: '0px' }), animate('400ms ease-out', style({ right: '-450px' }))])
      ])
    ]
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  filteredTopics$ = this.store.select<Topic[]>(TopicsState.filteredTopics);
  @ViewChild('navigationItem', { static: false }) navigationItem: ElementRef;

  public data = 'Testing data';
  public navItems: unknown[] = [];
  public faqOpened = false;
  public route: string;
  public navigationStyle: unknown = null;
  public loginState = false;
  public languageSelected: ContentLanguage = ContentLanguage.ENGLISH;
  private unsubscribe$ = new Subject<void>();
  public isAuthenticated$: Observable<boolean>;
  public isHelpDataLoading = false;

  public totalNewFlags = 0;
  loadingDone = false;
  tabIndex = 0;
  counter = 0;
  showNavigation = false;
  public isApp = false;
  allPlats = '';
  private selectableLangs: LanguageMapping;
  showLangs = false;
  private itemsLength = 0;
  noFindCounter = 0;
  public showFaq = false;

  private faqOverride = false;

  public helpData: { [lang in ContentLanguage]?: FaqItem[] } = {};
  public deactivated = false;

  clickout = (event: MouseEvent): void => {
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (this.showFaq && !this.faqOverride) {
        this.toggleFaq();
      }
    }
  };

  constructor(
    private router: Router,
    public authService: AuthService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private eRef: ElementRef,
    private guidedTour: FirstVisitService,
    private openFaqService: OpenFaqService,
    private storageService: StorageService,
    private cmsTranslationService: CmsTranslationService,
    private faqService: FaqService,
    public responsive: ResponsivenessService,
    private events: GlobalEventsService,
    private store: Store,
    private destroyRef: DestroyRef
  ) {
    this.isApp = environment.device !== 'desktop';
  }

  get currentEnvironment(): typeof environment {
    return environment;
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.openFaqService.openClose.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((faqstate: boolean) => {
      setTimeout(() => {
        this.showFaq = faqstate;
        this.faqOverride = faqstate;
      }, 10);
    });
    this.getTranslations();
    setTimeout(() => {
      this.loadNotifications();
    }, 1000);

    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(route => {
      if (route instanceof NavigationEnd) {
        this.deactivated = route.url === '/fallback';
      }
    });

    this.events.listen('click').pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.clickout);
  }

  private loadNotifications(): void {
    this.filteredTopics$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(items => {
      if (items) {
        this.itemsLength = items.length;
      }
    });
  }

  private getTranslations(): void {
    this.cmsTranslationService
      .getTranslationConfigFile(FrontendType.customer)
      .pipe(
        filter(selectableLangs => !!selectableLangs),
        tap(selectableLangs => {
          this.selectableLangs = selectableLangs;
        }),
        switchMap(() => this.storageService.getAsync(StorageKey.LANGUAGE)),
        filter(lang => !!lang),
        tap(lang => {
          this.languageSelected = lang;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.translate.onLangChange.pipe(skip(1), takeUntilDestroyed(this.destroyRef)).subscribe(lang => {
      this.languageSelected = lang.lang as ContentLanguage;
      setTimeout(() => {
        this.getHelpData();
      }, 600);
    });
  }

  async getHelpData(): Promise<void> {
    if (!this.helpData[this.languageSelected]) {
      this.isHelpDataLoading = true;
      this.faqService
        .getFaq(`help_${this.languageSelected}_publish.json`)
        .pipe(
          switchMap(data => iif(() => !data, this.faqService.getFaq(`help_en_publish.json`), of(data))),
          filter(helpItem => !!helpItem),
          tap(helpItem => {
            this.searchForNewFlagItems(helpItem);
            this.helpData[this.languageSelected] = helpItem;
          }),
          finalize(() => {
            this.isHelpDataLoading = false;
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();
    }
  }

  private searchForNewFlagItems(helpItems: FaqItem[]): void {
    this.totalNewFlags = 0;
    for (const item of helpItems) {
      item.newFlagCount = this.checkItemsForFlag(item);
      this.totalNewFlags += item.newFlagCount;
    }
  }

  private checkItemsForFlag(item: FaqItem): number {
    if (item && item.content && !item.isAnswer) {
      let contentNewFlagCount = 0;
      if (item.newFlagUntil) {
        contentNewFlagCount += 1;
      } else {
        for (const itm of item.content) {
          itm.newFlagCount = this.checkItemsForFlag(itm);
          contentNewFlagCount += itm.newFlagCount;
        }
      }
      return contentNewFlagCount;
    }
    if (item && item.content && item.isAnswer) {
      if (item.newFlagUntil) {
        const timeUntilEndNewFlag = new Date(item.newFlagUntil).getTime() - new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        if (timeUntilEndNewFlag > 0) {
          return 1;
        }
      }
    }
    return 0;
  }

  public gotoStartPage(): void {
    this.router.navigate(['/']);
  }

  public login(): void {
    this.authService.login();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openNotifications(): void {
    this.dialog.open(NotificationComponent, { width: '80vw', panelClass: 'close-icon-and-transition' });
  }

  public toggleFaq(): void {
    this.showFaq = !this.showFaq;
    if (this.showFaq) {
      this.getHelpData();
    }
  }

  public onOpenOtherFaqTopic(title: string): void {
    if (title === 'openGuidedTour') {
      this.storageService.remove(StorageKey.FIRST_VISIT_COMPLETED);
      this.guidedTour.reopenGuidedTour();
    } else if (title === 'helpdesk') {
      window.open(environment.helpdeskUrl, '_blank');
    }
  }
}
