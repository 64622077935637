import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MonoTypeOperatorFunction, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ListViewTagFilterParameter, PaginationState } from '@coin/shared/util-models';

@Injectable({
  providedIn: 'root'
})
export class HttpHelpersService {
  constructor(
    private toast: ToastrService,
    private translateService: TranslateService
  ) {}

  public handleSuccess<T>(message: string): MonoTypeOperatorFunction<T> {
    return tap(() => {
      this.toast.success(this.translateService.instant(message));
    });
  }

  public handleInfo<T>(message: string): MonoTypeOperatorFunction<T> {
    return tap(() => {
      this.toast.info(this.translateService.instant(message));
    });
  }

  public handleError<T>(message: string): MonoTypeOperatorFunction<T> {
    return catchError((res: HttpErrorResponse) => {
      const errorMessage = `${this.translateService.instant(message)}. ${res?.error?.reason || 'Unknown'}`;
      this.toast.error(errorMessage);
      return throwError(() => new Error(errorMessage));
    });
  }

  public withStatusMessages<T>({ error, success, info }: { error?: string; success?: string; info?: string }): MonoTypeOperatorFunction<T> {
    return source$ =>
      source$.pipe(
        error ? this.handleError(error) : tap(), // Add error handling
        success ? this.handleSuccess(success) : tap(), // Add success handling
        info ? this.handleInfo(info) : tap() // Add info handling
      );
  }

  public getHttpParams(paginationState: PaginationState, filters: ListViewTagFilterParameter[], filterPrefix = 'Query.', paginationPrefix = 'Paging.'): HttpParams {
    let params = new HttpParams();
    if (paginationState?.currentPage) {
      params = params.set(`${paginationPrefix}Page`, paginationState.currentPage);
    }
    if (paginationState?.pageSize) {
      params = params.set(`${paginationPrefix}Size`, paginationState.pageSize);
    }
    for (const filter of filters) {
      params = params.set(`${filterPrefix}${filter.category}`, filter.value);
    }
    return params;
  }
}
