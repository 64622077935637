import { Component, DestroyRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { CmsItem } from '@coin/admin/cms/util';
import { SelectImageComponent } from '../../shared/select-image/select-image.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'gmm-text-image-link',
  templateUrl: './text-image-link.component.html',
  styleUrls: ['./text-image-link.component.scss']
})
export class TextImageLinkComponent {
  @Input() item: CmsItem<'text-image-link'>;
  @Input() contentEdit = false;

  constructor(
    private dialog: MatDialog,
    private destroyRef: DestroyRef
  ) {}

  public editPicture(item: TextImageLinkComponent['item']): void {
    this.dialog
      .open(SelectImageComponent, { data: item })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(newImage => {
        if (newImage) {
          item.img = newImage.imageUrl;
        }
      });
  }

  public gotoPage(link: string): void {
    window.open(link, '_blank');
  }
}
