const DEFAULT_QUILL_TOOLBAR_MODULES = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  ['clean'],
  ['link'],
  [{ header: [1, 2] }]
];

export const QUILL_ADVANCED_MODULES = {
  toolbar: [...DEFAULT_QUILL_TOOLBAR_MODULES, ...[[{ indent: '-1' }, { indent: '+1' }], [{ align: [] }]], ['link', 'image']]
};

export const QUILL_DEFAULT_MODULES = {
  toolbar: DEFAULT_QUILL_TOOLBAR_MODULES
};
