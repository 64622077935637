import { Component, Input, OnInit } from '@angular/core';
import { CmsItem, DefaultColorPickerValues } from '@coin/admin/cms/util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'gmm-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss']
})
export class LineComponent implements OnInit {
  @Input() item: CmsItem<'line'>;
  @Input() contentEdit = false;
  @Input() showBackgroundPicker = false;

  ngOnInit(): void {
    this.item ??= {} as CmsItem<'line'>;
    this.setDefaultColors();
  }

  private setDefaultColors(): void {
    this.item.backgroundColor = this.item.backgroundColor || DefaultColorPickerValues.backgroundColor;
    this.item.textColor = this.item.textColor || DefaultColorPickerValues.textColor;
  }
}
