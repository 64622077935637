import { Pipe, PipeTransform } from '@angular/core';
import { ContentLanguage } from '@coin/shared/util-enums';

@Pipe({
  name: 'numberFormatter',
  standalone: true
})
export class NumberFormatterPipe implements PipeTransform {
  transform(input: number, decimals = 0): string {
    return this._formatBigNumbers(input, decimals);
  }

  private _formatBigNumbers(input: number, decimals: number): string {
    const lang = ContentLanguage.ENGLISH; // TODO: Get current language this.userDataService.getLanguage();
    const decSep = '.';
    const thousandSep = ',';
    // if (lang === 'de') {
    //     decSep = ',';
    //     thousandSep = '.';
    // }
    if (input !== undefined) {
      const inputString = input.toString();
      if (inputString.split('.')[0].length > 6) {
        // eslint-disable-next-line no-bitwise
        const millions = (input / 1000000) >> 0;
        // eslint-disable-next-line no-bitwise
        let remainder = ((input % 1000000) / 1000) >> 0;

        if (remainder > 1000) {
          remainder = Math.round(remainder);
        }

        return `${this._formatNumber(millions, 0, null, decSep, thousandSep)}'${this._pad(remainder)}`;
      }
      return this._formatNumber(input, decimals, null, decSep, thousandSep);
    }
    return this._formatNumber(input, decimals, null, decSep, thousandSep);
  }

  private _formatNumber(input: number, decimals: number, wholeLength: number = null, decimalSep = '.', thousandSep = ','): string {
    const re = `\\d(?=(\\d{${wholeLength || 3}})+${decimals > 0 ? '\\D' : '$'})`;
    let num = '';
    const parsedInput = parseFloat(`${input}`);
    // eslint-disable-next-line no-bitwise
    if (parsedInput !== undefined) {
      num = parsedInput.toFixed(Math.max(0, ~~decimals));
    }

    return (decimalSep ? num.replace('.', decimalSep) : num).replace(new RegExp(re, 'g'), `$&${thousandSep || ','}`);
  }

  private _pad(num: number, size = 3): string {
    if (num !== undefined) {
      let s = `${num}`;
      while (s.length < size) {
        s = `0${s}`;
      }
      return s;
    }
    return `${num}`;
  }
}
