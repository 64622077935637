<coin-v2-dialog-frame [header]="data?.dialogTitle | translate" (closeButtonClick)="onCloseButtonClick()">
  <div class="content">
    <div class="section-container" *ngFor="let section of data?.sections">
      <div class="section">
        <h2 *ngIf="section?.title">{{ section?.title | translate }}</h2>
        <p *ngIf="section?.text" [innerHTML]="section?.text | translate"></p>
      </div>
      <div *ngIf="section !== data?.sections.at(-1)" class="divider"></div>
    </div>
  </div>
</coin-v2-dialog-frame>
