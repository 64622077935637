import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@coin/shared/util-environments';
import { FaqDrill, FaqItem } from '@coin/shared/util-models';
import { map, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  public drillDown$ = new Subject<FaqDrill>();
  public changeOfMoreWidth$ = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  public faqDown(e: FaqDrill): void {
    this.drillDown$.next(e);
  }

  public moreWidthChange(e: boolean): void {
    this.changeOfMoreWidth$.next(e);
  }

  public createInvalidationForHelp(): Observable<unknown> {
    return this.http.post(`${environment.api.cmsService}invalidation`, null);
  }

  public createNewFaq(data: FaqItem[], filename: string): Observable<unknown> {
    return this.http.post(`${environment.api.cmsService}faq/create`, {
      data: JSON.stringify(data),
      filename
    });
  }

  public getFaq(filename: string): Observable<FaqItem[]> {
    return this.http
      .post<{
        item: string;
      }>(`${environment.api.cmsService}faq/get`, { filename })
      .pipe(map(item => JSON.parse(item?.item)));
  }

  public updateFaq(data: FaqItem[], filename: string): Observable<unknown> {
    return this.http.put(`${environment.api.cmsService}faq/update`, {
      data: JSON.stringify(data),
      filename
    });
  }
}
