<div>
  <div *ngIf="!showDropdownFilter" class="container">
    <button *ngIf="!isInEditMode" (click)="toggleEditMode()" class="more" [class.binary]="isBinary" [class.is-binary-filter-active]="isBinaryFilterActive">
      <ng-container *ngTemplateOutlet="informationTag"></ng-container>
    </button>
    <div *ngIf="isInEditMode">
      <ng-container [ngSwitch]="tag?.type">
        <coin-input
          *ngSwitchCase="listViewFilterTagType.Input"
          #textInput
          (onClear)="onClearTextInput()"
          (onBlur)="submitTextInput()"
          (enterClicked)="submitTextInput()"
          [(ngModel)]="inputText"
          [placeholder]="tag?.title | translate"></coin-input>
        <coin-v2-date-input *ngSwitchCase="listViewFilterTagType.Date" [placeholder]="'general.click-to-select' | translate" [formControl]="dateFormControl" />
      </ng-container>
    </div>
  </div>

  <div *ngIf="showDropdownFilter" class="container">
    <button class="more" [matMenuTriggerFor]="filterActions">
      <ng-container *ngTemplateOutlet="informationTag"></ng-container>
    </button>
    <mat-menu #filterActions="matMenu" class="filter-tag-options">
      <ng-container *ngIf="!!tag?.subFilters?.length">
        <button *ngFor="let filter of tag.subFilters" class="text" [class.selected]="isSelected(filter)" (click)="onFilterClicked(filter)" mat-menu-item>
          <div class="support-state-filter-item">
            <p>{{ filter?.title | translate }}</p>
            <p *ngIf="!hideCount">{{ filter?.count }}</p>
          </div>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</div>

<ng-template #informationTag>
  <coin-v2-dual-pill
    [contentLeft]="tag?.title | translate"
    [textRight]="tagValue | translate"
    [icon]="tag?.icon"
    [svgIcon]="tag?.svgIcon"
    [themeColor]="tag?.color"></coin-v2-dual-pill>
</ng-template>
