import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import { DisplayedCurrency } from '@coin/shared/util-enums';
import { SetMeritCurrency } from './merit-currency.actions';

interface MeritCurrencyStateModel {
  selectedCurrency: DisplayedCurrency;
}

@State<MeritCurrencyStateModel>({
  name: 'MeritCurrencyState',
  defaults: {
    selectedCurrency: DisplayedCurrency.Local
  }
})
@Injectable()
export class MeritCurrencyState {
  @Selector()
  static selectedCurrency(state: MeritCurrencyStateModel): DisplayedCurrency {
    return state.selectedCurrency;
  }

  @Action(SetMeritCurrency)
  setMeritCurrency(ctx: StateContext<MeritCurrencyStateModel>, { payload }: SetMeritCurrency): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.selectedCurrency = payload;
      })
    );
  }
}
