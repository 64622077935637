import { Component, Input } from '@angular/core';
import { EquityPageComponentModel } from '../../models/equity-page-component.model';

@Component({
  selector: 'app-equity-cards',
  templateUrl: './equity-cards.component.html',
  styleUrls: ['./equity-cards.component.scss']
})
export class EquityCardsComponent implements EquityPageComponentModel {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() data: any;

  get gridTemplateColumns(): string {
    return `repeat(${this.data?.content?.cards?.length}, minmax(0,1fr))`;
  }
}
