import { DisplayedCurrency, SpecialPaymentProposalState } from '@coin/shared/util-enums';
import { ListViewTagFilterParameter } from '@coin/shared/util-models';
import { SpecialPaymentSetting } from '@coin/admin/season-mgmt/util';
import { SpecialPaymentCompensationType } from '../enums/special-payment-compensation-type.enum';
import { SpecialPaymentApproval } from '../models/special-payment-approval.model';
import { SpecialPaymentProposal } from '../models/special-payment-proposal.model';

export class LoadTaskSpecialPaymentProposals {
  static readonly type = '[Special Payment] Load Task Proposals';
}

export class LoadInvolvedSpecialPaymentProposals {
  static readonly type = '[Special Payment] Load Involved Proposals';
}

export class LoadAllSpecialPaymentProposals {
  static readonly type = '[Special Payment] Load All Proposals';
}

export class LoadSpecialPaymentProposalForEmployee {
  static readonly type = '[Special Payment] Load Special Payment Proposal For Employee';
  constructor(public readonly payload: { employeeId: string }) {}
}

export class LazyLoadInvolvedSpecialPaymentProposals {
  static readonly type = '[Special Payment] Lazy Load Involved Proposals';
}

export class LazyLoadAllSpecialPaymentProposals {
  static readonly type = '[Special Payment] Lazy Load All Proposals';
}

export class ApproveOrRejectSpecialPaymentProposal {
  static readonly type = '[Special Payment] Approve or Reject Proposal';
  constructor(
    public proposalState: SpecialPaymentProposalState,
    public task?: SpecialPaymentProposal
  ) {}
}

export class ResetSpecialPaymentProposalState {
  static readonly type = '[Special Payment] Reset Proposal State';
  constructor(public task: SpecialPaymentProposal) {}
}

export class SetSpecialPaymentCurrency {
  static readonly type = '[Special Payment] Set Currency';
  constructor(public readonly payload: DisplayedCurrency) {}
}

export class CreateSpecialPaymentProposalAction {
  static readonly type = '[Special Payment] Create Proposal';
  constructor(public proposal: SpecialPaymentProposal) {}
}

export class UpdateSpecialPaymentProposalAction {
  static readonly type = '[Special Payment] Update Proposal';
  constructor(public proposal: SpecialPaymentProposal) {}
}

export class GetSpecialPaymentDashboard {
  static readonly type = '[Special Payment] Get Dashboard';
}

export class SetSpecialPaymentProposalsBySearch {
  static readonly type = '[Special Payment] Set Proposals by Search';
  constructor(public employeeId: string) {}
}

export class AddSpecialPaymentFilterParameter {
  static readonly type = '[Special Payment] Add Filter Parameter';
  constructor(public readonly payload: ListViewTagFilterParameter) {}
}

export class ResetSpecialPaymentFilterParameterKey {
  static readonly type = '[Special Payment] Reset Filter Parameter';
}

export class ClearSpecialPaymentFilterParameterKey {
  static readonly type = '[Special Payment] Clear Filter Parameter Key';
  constructor(public readonly category: string) {}
}

export class SetUpdatedSpecialPaymentApproval {
  static readonly type = '[Special Payment] Set Updated Approval';
  constructor(public readonly payload: SpecialPaymentApproval) {}
}

export class SetSeasonForEmployeeInvalid {
  static readonly type = '[Special Payment] Set Season For Employee Invalid';
  constructor(public invalid: boolean) {}
}

export class LoadSpecialPaymentSeasons {
  static readonly type = '[Special Payment] Load Seasons';
  constructor(public readonly payload: { compensationType: SpecialPaymentCompensationType; employeeId: string }) {}
}

export class DeleteSpecialPaymentRecord {
  static readonly type = '[Special Payment] Delete Special Payment Proposal';
  constructor(public readonly payload: { proposalId: string }) {}
}

export class SetSelectedSeasonSettings {
  static readonly type = '[Special Payment] Set selected season setting';
  constructor(public readonly payload: { selectedSeasonSetting: SpecialPaymentSetting | undefined }) {}
}
