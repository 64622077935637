import { HttpClient } from '@angular/common/http';
import { environment } from '@coin/shared/util-environments';
import { TranslateLoader, TranslateModuleConfig, TranslateParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationDebugParser } from '@coin/shared/data-access';

export class CustomTranslationLoader {
  public static readonly config: TranslateModuleConfig = {
    loader: {
      provide: TranslateLoader,
      useFactory: CustomTranslationLoader.createTranslateLoader,
      deps: [HttpClient]
    },
    parser: { provide: TranslateParser, useClass: TranslationDebugParser }
  };

  static createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, `${environment.cmsContentProdBucketCloudfront}translations/customer/`, '.json');
  }
}
