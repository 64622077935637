import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@coin/shared/util-environments';
import { CmsItem, DefaultColorPickerValues } from '@coin/admin/cms/util';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SelectImageComponent } from '../../shared/select-image/select-image.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-email-single-double-image',
  templateUrl: './email-single-double-image.component.html',
  styleUrls: ['./email-single-double-image.component.scss']
})
export class EmailSingleDoubleImageComponent implements OnInit {
  @Input() item: CmsItem<'single-or-multi-image'>;
  @Input() isResponsive: boolean;
  @Input() contentEdit = false;
  @Input() editImageLink: false;
  @Input() showBackgroundPicker = false;

  public amountOptions = [1, 2, 3];
  public amountImages = 1;

  constructor(
    private dialog: MatDialog,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.item ??= {} as never;
    this.amountImages = this.item?.content?.length;
    this.setDefaultBackgroundColor();
  }

  private setDefaultBackgroundColor(): void {
    this.item.backgroundColor = this.item.backgroundColor || DefaultColorPickerValues.backgroundColor;
  }

  public amountChange(value: number): void {
    if (value > this.item.content.length) {
      for (let i = this.item.content.length; i < value; i++) {
        this.item.content.push({ link: `${environment.coinImagePath}coin/images/originals/coin_email_base_image.png` } as never);
      }
    } else if (value < this.item.content.length) {
      this.item.content = this.item.content.slice(0, value);
    }
  }

  public editPicture(item: CmsItem<'single-or-multi-image'>['content'][number]): void {
    this.dialog
      .open(SelectImageComponent, {})
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(newImage => {
        if (newImage) {
          item.link = newImage.imageUrl;
        }
      });
  }
}
