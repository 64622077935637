import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingScreenComponent } from './loading-screen.component';
import { TurbineComponent } from './turbine/turbine.component';

@NgModule({
  declarations: [LoadingScreenComponent, TurbineComponent],
  imports: [CommonModule],
  exports: [LoadingScreenComponent, TurbineComponent]
})
export class LoadingModule {}
