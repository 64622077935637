import { Injectable, NgZone } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@coin/shared/util-environments';

@Injectable()
export class UnauthorizedInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private toast: ToastrService,
    private ngZone: NgZone
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 403 && !err.url.startsWith(environment.documentDownloadCloudfront) && !err.url.includes('partner/dashboard')) {
          if (!this.router.routerState.snapshot.url) {
            this.ngZone.run(() => this.router.navigate(['unauthorized']));
          } else {
            this.ngZone.run(() => this.toast.error('Not authorized.'));
          }
          return from([err]);
        }
        return throwError(err);
      })
    );
  }
}
