import { Injectable } from '@angular/core';
import { BudgetComponentType, DisplayedCurrency } from '@coin/shared/util-enums';
import { RoundOperations } from '@coin/shared/util-helpers';
import { EmployeeWithCompensationInfo } from '../models/merit-budget-direct-with-compensation.model';
import { UpdateAllocationValuesDto } from '../models/update-allocation-values-dto.model';
import { MeritBudgetCalculationOperations } from '../operations/merit-budget-calculation.operations';
import { CurrencyConversionPipe } from '../pipes/currency-conversion.pipe';

@Injectable()
export class UpdateAllocationValuesService {
  constructor(private currencyConversion: CurrencyConversionPipe) {}

  public async getUpdateBody(direct: EmployeeWithCompensationInfo): Promise<UpdateAllocationValuesDto> {
    const baseSalary = this.getAmountToUpdateOfComponent(direct, BudgetComponentType.BaseSalary);
    const targetIncentiveAmount = this.getAmountToUpdateOfComponent(direct, BudgetComponentType.TargetIncentiveAmount);
    const basePay = this.getAmountToUpdateOfComponent(direct, BudgetComponentType.BasePay);
    const fixedAllowances = this.getAmountToUpdateOfComponent(direct, BudgetComponentType.FixedAllowances);
    const flatAllowances = this.getAmountToUpdateOfComponent(direct, BudgetComponentType.FlatAllowances);
    const allowancesPercentageTotal = this.getAmountToUpdateOfComponent(direct, BudgetComponentType.AllowancesPercentageInTotal);
    const annualPayment = this.getAmountToUpdateOfComponent(direct, BudgetComponentType.AnnualPayment);
    const { equityAmount, equityAmountInEuro } = this.getEquityAmountToUpdateOfComponent(direct);
    const totalTargetCash = this.getTTCAmountToUpdateOfComponent(direct);
    const oneTimePayment = MeritBudgetCalculationOperations.getOneTimePaymentComponent(direct)?.totalAmount;

    return {
      isDone: direct.isDone,
      simulationMemberId: direct.simulationMemberId,
      localCurrency: direct.localCurrency,
      employmentPercentage: direct.spsEligibilityEmploymentPercentage,
      equityType: MeritBudgetCalculationOperations.getEquityComponent(direct)?.equityType,
      annualPaymentFactor: MeritBudgetCalculationOperations.getBudgetComponentByType(direct, BudgetComponentType.AnnualPayment)?.referenceTotalValue,
      targetIncentiveAmount: targetIncentiveAmount,
      totalTargetCash,
      oneTimePayment,
      baseSalary,
      basePay,
      fixedAllowances,
      flatAllowances,
      allowancesPercentageTotal: allowancesPercentageTotal,
      annualPayment,
      equityAmount,
      equityAmountInEuro,
      allowancesPoB: MeritBudgetCalculationOperations.getBudgetComponentByType(direct, BudgetComponentType.AllowancesPercentageInTotal)?.percentageOfBase,
      targetIncentiveAmountPoB: MeritBudgetCalculationOperations.getBudgetComponentByType(direct, BudgetComponentType.TargetIncentiveAmount)?.percentageOfBase,
      baseSalaryInEuro: await this.currencyConversion.transform(baseSalary, direct.localCurrency, false, DisplayedCurrency.EUR).toPromise(),
      basePayInEuro: await this.currencyConversion.transform(basePay, direct.localCurrency, false, DisplayedCurrency.EUR).toPromise(),
      targetIncentiveAmountInEuro: await this.currencyConversion.transform(targetIncentiveAmount, direct.localCurrency, false, DisplayedCurrency.EUR).toPromise(),
      fixedAllowancesInEuro: await this.currencyConversion.transform(fixedAllowances, direct.localCurrency, false, DisplayedCurrency.EUR).toPromise(),
      totalTargetCashInEuro: await this.currencyConversion.transform(totalTargetCash, direct.localCurrency, false, DisplayedCurrency.EUR).toPromise(),
      flatAllowancesInEuro: await this.currencyConversion.transform(flatAllowances, direct.localCurrency, false, DisplayedCurrency.EUR).toPromise(),
      allowancesPercentageTotalInEuro: await this.currencyConversion.transform(allowancesPercentageTotal, direct.localCurrency, false, DisplayedCurrency.EUR).toPromise(),
      annualPaymentInEuro: await this.currencyConversion.transform(annualPayment, direct.localCurrency, false, DisplayedCurrency.EUR).toPromise(),
      oneTimePaymentInEuro: await this.currencyConversion.transform(oneTimePayment, direct.localCurrency, false, DisplayedCurrency.EUR).toPromise()
    };
  }

  getAmountToUpdateOfComponent(direct: EmployeeWithCompensationInfo, meritBudgetComponentType: BudgetComponentType): number {
    return RoundOperations.round(
      direct?.isMeritExcluded
        ? MeritBudgetCalculationOperations.getBudgetComponentByType(direct, meritBudgetComponentType)?.currentAmount
        : MeritBudgetCalculationOperations.getBudgetComponentByType(direct, meritBudgetComponentType)?.totalAmount,
      2
    );
  }

  getTTCAmountToUpdateOfComponent(direct: EmployeeWithCompensationInfo): number {
    return RoundOperations.round(
      direct?.isMeritExcluded
        ? MeritBudgetCalculationOperations.getTotalTargetCashComponent(direct)?.currentAmount
        : MeritBudgetCalculationOperations.getTotalTargetCashComponent(direct)?.totalAmount,
      2
    );
  }

  getEquityAmountToUpdateOfComponent(direct: EmployeeWithCompensationInfo): { equityAmount: number; equityAmountInEuro: number } {
    const component = MeritBudgetCalculationOperations.getEquityComponent(direct);
    return { equityAmountInEuro: RoundOperations.round(component?.totalAmount, 2), equityAmount: RoundOperations.round(component?.localTotalAmount, 2) };
  }
}
