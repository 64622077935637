import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IncentiveAllocationEmployee } from '../../merit-shared/models/incentive-allocation-employee.model';

@Injectable({
  providedIn: 'root'
})
export class IncentiveSupportService {
  constructor(
    private httpClient: HttpClient,
    private httpHelpersService: HttpHelpersService,
    private loadingService: LoadingService
  ) {}

  getIncentiveAllocation(seasonId: string, employeeId: string): Observable<IncentiveAllocationEmployee> {
    this.loadingService.present();
    return this.httpClient
      .get<IncentiveAllocationEmployee>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/incentive/partner/allocations/total/${employeeId}?meritSeasonId=${seasonId}`)
      .pipe(
        this.httpHelpersService.handleError('Cannot get incentive allocation'),
        finalize(() => this.loadingService.dismiss())
      );
  }
}
