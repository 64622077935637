import { AfterViewInit, Component, DestroyRef, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedResult } from '@coin/shared/util-models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'coin-c-text-input-overlay',
  templateUrl: './text-input-overlay.component.html',
  styleUrls: ['./text-input-overlay.component.scss']
})
export class TextInputOverlayComponent implements OnChanges, AfterViewInit {
  public editValue = '';
  public isOverlayVisible = false;

  @Input() value: string;
  @Input() isEditable = true;
  @Input() infoHeader: string;
  @Input() infoText: string;
  @Input() placeholder: string;
  @Input() choices?: string[];
  @Input() choicesWithSearch = false;
  @Input() isSmall = false;
  @Input() displayFn: (arg0: unknown) => string;
  @Input() lazyLoadChoicesFn: (page: number, search: string, key: string) => Observable<PaginatedResult<unknown>>;

  @Output() valueChanged = new EventEmitter<string>();

  @ViewChildren(InputComponent) inputComponents: QueryList<InputComponent>;

  constructor(private destroyRef: DestroyRef) {}

  public ngAfterViewInit(): void {
    this.subscribeToInputComponentChanges();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.editValue = changes.value.currentValue;
  }

  private subscribeToInputComponentChanges(): void {
    // The input element needs to set using a query list because it is dynamically shown/hidden and a simple @ViewChild() is not working in this case
    this.inputComponents.changes.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((inputList: QueryList<InputComponent>) => {
      setTimeout(() => {
        if (inputList.first) {
          inputList.first.focus();
          this.editValue = this.value;
        }
      });
    });
  }

  public originClicked(): void {
    this.toggleOverlay();
  }

  public saveClicked(): void {
    this.valueChanged.emit(this.editValue);
    this.resetAndClose();
  }

  public cancelClicked(): void {
    this.resetAndClose();
  }

  private resetAndClose(): void {
    this.editValue = '';
    this.toggleOverlay();
  }

  public toggleOverlay(): void {
    this.isOverlayVisible = !this.isOverlayVisible;
  }
}
