import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fv-right',
  templateUrl: './fv-right.component.html',
  styleUrls: ['./fv-right.component.scss']
})
export class FvRightComponent {
  @Input() image: string;
  @Input() text: { headline: string; text: string };
  @Input() needsColumn = false;
  @Input() maxHeight = 'calc(100vh - 210px)';
  @Input() imageMaxWidth = '350px';
}
