import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-spacer',
  templateUrl: './dynamic-spacer.component.html',
  styleUrls: ['./dynamic-spacer.component.scss']
})
export class DynamicSpacerComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() data: any;
}
