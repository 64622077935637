<div class="faq-answer-items-holder">
  <h3 style="margin-bottom: 20px">{{ items?.title }}</h3>
  <div class="answer-items" *ngFor="let item of items?.content">
    <h3 *ngIf="item.type === 'headline' && item.size === 'big'">{{ item.title }}</h3>
    <h4 *ngIf="item.type === 'headline' && item.size === 'small'" style="margin-bottom: 10px">
      {{ item.title }}
    </h4>
    <p *ngIf="item.type === 'text-block'" [innerHTML]="item.text"></p>
    <coin-action-button *ngIf="item.type === 'button'" class="{{ item.style }}" [icon]="item.icon" (click)="openPage(item['link'])">{{ item.text }}</coin-action-button>
    <div *ngIf="item.type === 'single-or-multi-image'">
      <div class="single-double-main">
        <div class="flex-one" *ngFor="let image of item?.content">
          <div
            class="single-double-image"
            [ngClass]="{ 'multiple-images': item?.content?.length > 1 }"
            [style.background-image]="'url(' + image.link + ')'"
            [style.height.px]="item?.height"></div>
        </div>
      </div>
    </div>
    <div *ngIf="item.type === 'table'" [innerHTML]="item.text"></div>
    <div class="faq-seperator" *ngIf="item.type === 'seperator'"></div>
    <img *ngIf="item.type === 'image'" aria-label="answer image" [src]="item.src && item.src !== '' ? item.src : 'assets/images/mac_skyline.png'" />
    <ul *ngIf="item.type === 'list'">
      <li *ngFor="let listItem of item.items" [innerHTML]="listItem"></li>
    </ul>
  </div>
</div>
