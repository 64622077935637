<div class="container">
  <div class="content">
    <div class="header">
      <h3 class="fv-header-text">{{'equity.disclaimer.title' | translate}}</h3>
      <coin-simple-lang-switch></coin-simple-lang-switch>
    </div>
    <div class="terms-and-conditions-text">
      <h4>{{'equity.disclaimer.warning' | translate}}</h4>
      <p class="padding-bottom">{{'equity.disclaimer.warning-text' | translate}}</p>
      <h4>{{'equity.disclaimer.terms-and-conditions' | translate}}</h4>
      <p class="padding-bottom" [innerHTML]="getTranslation('equity.disclaimer.terms-and-conditions-textq')">
      </p>
      <h4>{{'equity.disclaimer.confirmation' | translate}}</h4>
      <p [innerHTML]="getTranslation('equity.disclaimer.confirmation-text')"></p>
    </div>
    <div class="fv-buttons">
      <coin-action-button class="secondary fv-btn fv-btn--right" (click)="close()">
        {{'general.btnDecline' | translate}}
      </coin-action-button>
      <coin-action-button class="primary fv-btn fv-btn--right" (click)="confirm()">
        {{'general.btnConfirm' | translate}}
      </coin-action-button>
    </div>
  </div>
</div>