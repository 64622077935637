export enum BudgetComponentType {
  TotalDirectCompensation = 'TotalDirectCompensation',
  TotalTargetCash = 'TotalTargetCash',
  BaseSalary = 'BaseSalary',
  BasePay = 'BasePay',
  FixedAllowances = 'FixedAllowances',
  TargetIncentiveAmount = 'TargetIncentiveAmount',
  FlatAllowances = 'FlatAllowances',
  AllowancesPercentageInTotal = 'AllowancesPercentageInTotal',
  AnnualPayment = 'AnnualPayment',
  Equity = 'Equity',
  OneTimePayment = 'OneTimePayment'
}
