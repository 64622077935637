import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { Fn } from '@coin/shared/util-models';

/**
 * @deprecated Use `coin-v2-text-area` instead.
 */
@Component({
  selector: 'coin-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    }
  ]
})
export class TextareaComponent implements ControlValueAccessor, OnChanges {
  @ViewChild(MatTooltip) tooltip: MatTooltip;

  @Input() public placeholder = '';
  @Input() public disabled = false;
  @Input() public invalid = false;
  @Input() addClass: string;
  @Input() errorMessage: string;
  @Input() fullHeight: boolean;
  @Input() readonly: boolean;

  @Output() inputNew = new EventEmitter<string>();
  @Output() blurEvent = new EventEmitter<string>();

  public ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (changes.errorMessage?.currentValue) {
        this.tooltip?.show();
      } else {
        this.tooltip?.hide();
      }
    });
  }

  private _value: string = null;

  set value(val: string) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get value(): string {
    return this._value;
  }

  onChange: Fn = () => {};
  onTouch: Fn = () => {};

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: Fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Fn): void {
    this.onTouch = fn;
  }

  onInput(e: string): void {
    this.inputNew.emit(e);
  }

  onBlur(e: string): void {
    this.blurEvent.emit(e);
  }
}
