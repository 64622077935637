import { Component, Input, OnInit } from '@angular/core';
import { CmsItem } from '@coin/admin/cms/util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-equity-cms-cards',
  templateUrl: './equity-cms-cards.component.html',
  styleUrls: ['./equity-cms-cards.component.scss']
})
export class EquityCmsCardsComponent implements OnInit {
  @Input() item: CmsItem<'cards'>;
  @Input() contentEdit = false;
  @Input() modifiedWidth = false;

  public amountCards = 1;
  public amountOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  ngOnInit(): void {
    if (this.item && !this.item?.requiresAuthentication) {
      this.item.requiresAuthentication = false;
    }
    this.amountCards = this.item?.content.length;
  }

  /**
   * If amount is greater than current amount, add new cards with default content
   * If amount is smaller than current amount, cut of not needed elements
   * @param value new amount of card items
   */
  amountChange(value: number): void {
    if (value > this.item.content.length) {
      for (let i = this.item.content.length; i < value; i++) {
        this.item.content.push({
          backgroundUrl: 'assets/equity/images/SOG/L.jpg',
          text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.',
          headline: 'Some Headline',
          button: {
            name: 'Button Text',
            url: 'https://www.google.de'
          }
        });
      }
    } else if (value < this.item.content.length) {
      this.item.content = this.item.content.slice(0, value);
    }
  }
}
