import { Pipe, PipeTransform } from '@angular/core';
import { ContractStatus } from '@coin/shared/util-models';

@Pipe({ name: 'contractStatusColorPipe', standalone: true })
export class ContractStatusColorPipe implements PipeTransform {
  transform(contractStatus: ContractStatus): string {
    if (!contractStatus) {
      return contractStatus;
    }

    switch (contractStatus) {
      case ContractStatus.Active:
        return 'green';
      case ContractStatus.Terminated:
        return 'red';
      case ContractStatus.Dormant:
        return 'orange';
      default:
        return 'grey';
    }
  }
}
