import { Component, Input } from '@angular/core';
import { EquityPageComponentModel } from '../../models/equity-page-component.model';

@Component({
  selector: 'app-equity-tiles',
  templateUrl: './equity-tiles.component.html',
  styleUrls: ['./equity-tiles.component.scss']
})
export class EquityTilesComponent implements EquityPageComponentModel {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() data: any;
}
