<div class="container">
  <div class="title-bar" (click)="toggle.emit()">
    <h3>{{ 'main-menu.directs' | translate }}</h3>
    <coin-action-button *ngIf="isActive" (click)="openDirectsDialog()" class="details-btn secondary" style="margin-left: 25px">{{
      'main-menu.show-details' | translate
    }}</coin-action-button>
    <div class="icons custom-hide-down"></div>
  </div>
  <div class="content" *ngIf="isActive">
    <mat-tab-group [dynamicHeight]="true" *ngIf="lineManagerDirects?.length || inCompanyManagerDirects?.length">
      <mat-tab [label]="'main-menu.line-manager' | translate" *ngIf="lineManagerDirects?.length">
        <ngx-simplebar class="primary-scrollbar-vertical no-space modal-appearance">
          <div class="directs" intersectionObserverList>
            <app-direct-item-overview *ngFor="let direct of lineManagerDirects" intersectionObserverElement [direct]="direct"> </app-direct-item-overview>
          </div>
        </ngx-simplebar>
      </mat-tab>
      <mat-tab [label]="'main-menu.incompany-manager' | translate" *ngIf="inCompanyManagerDirects?.length">
        <ngx-simplebar class="primary-scrollbar-vertical no-space modal-appearance">
          <div class="directs" intersectionObserverList>
            <app-direct-item-overview *ngFor="let direct of inCompanyManagerDirects" intersectionObserverElement [direct]="direct"> </app-direct-item-overview>
          </div>
        </ngx-simplebar>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
