import { Injectable } from '@angular/core';
import { SeasonSettingsService } from '@coin/admin/season-mgmt/data-access';
import { GenericSeasonSettings, PositionEvaluationSetting, Season } from '@coin/admin/season-mgmt/util';
import { LoadingService } from '@coin/shared/data-access';
import { DisplayedCurrency, SeasonTypeEnum } from '@coin/shared/util-enums';
import { ListViewTagFilterParameter, PaginatedResult } from '@coin/shared/util-models';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { produce } from 'immer';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { MercerSupportPositionService } from '../../mercer-shared/services/mercer-support-position.service';
import { MeritSupportSortingParameter } from '../../merit/merit-support/enums/merit-support-sorting-parameter.enum';
import { FilterSortState } from '../../shared/components/filter-sort-bar/store/filter-sort.state';
import { MercerEvaluationMember, MercerEvaluationMemberDetails } from '../../shared/models/mercer-support-evaluation-member.model';
import { MercerSupportService } from '../../shared/services/mercer-support.service';
import {
  AddMercerSupportFilterParameter,
  ChangeValidationManager,
  ClearMercerSupportFilterParameterKey,
  LazyLoadMercerSupportEvaluationMembers,
  LoadMercerSupportEvaluationMembers,
  LoadMercerSupportSeasonSettings,
  LoadMercerSupportSeasons,
  MercerSupportAddNewPosition,
  PatchMercerSupportEvaluationMember,
  ResetMercerSupportState,
  SetEvaluationMemberBySearch,
  SetMercerSupportCurrency,
  SetMercerSupportEvaluationMember,
  SetMercerSupportFilterParameters,
  SetMercerSupportPositionLock,
  SetMercerSupportSeason
} from './mercer-support.actions';

const STATE_DEFAULTS: MercerSupportStateModel = {
  evaluationMembers: [],
  seasons: [],
  season: undefined,
  filterParameter: [],
  currentPage: 0,
  maxPage: 0,
  sortingParameter: MeritSupportSortingParameter.Default,
  selectedCurrency: DisplayedCurrency.Local,
  seasonSettings: undefined
};

const DEFAULT_PAGE_SIZE = 15;

interface MercerSupportStateModel {
  evaluationMembers: MercerEvaluationMember[];
  seasons: Season[];
  season: Season;
  filterParameter: ListViewTagFilterParameter[];
  currentPage: number;
  maxPage: number;
  sortingParameter: string;
  selectedCurrency: DisplayedCurrency;
  seasonSettings: PositionEvaluationSetting;
}

@State<MercerSupportStateModel>({
  name: 'MercerSupportState',
  defaults: STATE_DEFAULTS
})
@Injectable()
export class MercerSupportState {
  constructor(
    private mercerSupportService: MercerSupportService,
    private store: Store,
    private seasonSettingsService: SeasonSettingsService,
    private mercerSupportPositionService: MercerSupportPositionService,
    private loadingService: LoadingService
  ) {}

  @Selector()
  static evaluationMembers(state: MercerSupportStateModel): MercerEvaluationMember[] {
    return state.evaluationMembers;
  }

  @Selector()
  static seasons(state: MercerSupportStateModel): Season[] {
    return state.seasons;
  }

  @Selector()
  static season(state: MercerSupportStateModel): Season {
    return state.season;
  }

  @Selector()
  static selectedCurrency(state: MercerSupportStateModel): DisplayedCurrency {
    return state.selectedCurrency;
  }

  @Selector()
  static filterParameter(state: MercerSupportStateModel): ListViewTagFilterParameter[] {
    return state.filterParameter;
  }

  @Selector()
  static seasonSettings(state: MercerSupportStateModel): PositionEvaluationSetting {
    return state.seasonSettings;
  }

  @Selector()
  static queryText(state: MercerSupportStateModel): string {
    return MercerSupportState.getFilterParameterText(state.filterParameter, false);
  }

  @Selector()
  static isLastPage(state: MercerSupportStateModel): boolean {
    return state.currentPage >= state.maxPage;
  }

  static getEvaluationMember(id: string): (s: MercerEvaluationMember[]) => MercerEvaluationMember {
    return createSelector([MercerSupportState.evaluationMembers], (evaluationMembers: MercerEvaluationMember[]) => {
      return evaluationMembers.find(evaluationMember => evaluationMember.id === id);
    });
  }

  private getSortingParameterText(ctx: StateContext<MercerSupportStateModel>): string {
    return ctx.getState().sortingParameter !== MeritSupportSortingParameter.Default ? `&Sorting.Property=${ctx.getState().sortingParameter}` : '';
  }

  static getFilterParameterText(filterParameter: ListViewTagFilterParameter[], showQueryPrefix = true): string {
    let filterText = '';

    filterParameter.forEach(param => {
      filterText += `&${showQueryPrefix ? 'Query.' : ''}${encodeURIComponent(param.category)}=${encodeURIComponent(param.value)}`;
    });

    return filterText ?? '';
  }

  @Action(MercerSupportAddNewPosition)
  mercerSupportAddNewPosition(ctx: StateContext<MercerSupportStateModel>, { payload }: MercerSupportAddNewPosition): Observable<unknown> {
    const seasonId = ctx.getState().season.id;

    return this.mercerSupportPositionService.addPosition(seasonId, payload).pipe(
      map(result => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.evaluationMembers.unshift(result);
          })
        );
      })
    );
  }

  @Action(LoadMercerSupportEvaluationMembers)
  loadMercerSupportEvaluationMembers(ctx: StateContext<MercerSupportStateModel>): Observable<PaginatedResult<MercerEvaluationMember>> {
    const seasonId = ctx.getState().season.id;
    let filterText = this.store.selectSnapshot(FilterSortState.getSortAndFilterText);

    filterText += MercerSupportState.getFilterParameterText(ctx.getState().filterParameter) + this.getSortingParameterText(ctx);

    return this.mercerSupportService.getEvaluationMembers(seasonId, 0, DEFAULT_PAGE_SIZE, filterText).pipe(
      this.loadingService.withLoadingScreen,
      tap(result => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.evaluationMembers = result.content;
            state.currentPage = 0;
            state.maxPage = result.pageCount - 1;
          })
        );
      })
    );
  }

  @Action(LazyLoadMercerSupportEvaluationMembers)
  lazyLoadMercerSupportEvaluationMembers(ctx: StateContext<MercerSupportStateModel>): Observable<PaginatedResult<MercerEvaluationMember>> {
    const seasonId = ctx.getState().season.id;
    const { currentPage, maxPage } = ctx.getState();

    if (currentPage >= maxPage) {
      return;
    }
    let filterText = this.store.selectSnapshot(FilterSortState.getSortAndFilterText);
    filterText += MercerSupportState.getFilterParameterText(ctx.getState().filterParameter) + this.getSortingParameterText(ctx);
    return this.mercerSupportService.getEvaluationMembers(seasonId, currentPage + 1, DEFAULT_PAGE_SIZE, filterText).pipe(
      this.loadingService.withLoadingScreen,
      tap(result => {
        ctx.setState(
          produce(ctx.getState(), state => {
            const evaluationMembers = [...state.evaluationMembers, ...result.content];

            state.evaluationMembers = evaluationMembers?.filter((evaluationMember, index) => evaluationMembers.findIndex(e => e.id === evaluationMember.id) === index); // delete all duplicates
            state.currentPage += 1;
          })
        );
      })
    );
  }

  @Action(SetEvaluationMemberBySearch)
  setEvaluationMemberBySearch(ctx: StateContext<MercerSupportStateModel>, { payload }: SetEvaluationMemberBySearch): Observable<MercerEvaluationMemberDetails> {
    const seasonId = ctx.getState().season.id;
    return this.mercerSupportService.getEvaluationMemberDetails(seasonId, payload.evaluationMemberId).pipe(
      tap(newEvaluationMember => {
        ctx.setState(
          produce(ctx.getState(), state => {
            const evaluationMembersWithoutSearchedEmployee = state.evaluationMembers.filter(evaluationMember => evaluationMember.id !== newEvaluationMember.id);
            state.evaluationMembers = [newEvaluationMember, ...evaluationMembersWithoutSearchedEmployee];
          })
        );
      })
    );
  }

  @Action(SetMercerSupportEvaluationMember)
  setMercerSupportEvaluationMember(ctx: StateContext<MercerSupportStateModel>, { payload }: SetMercerSupportEvaluationMember): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const index = state.evaluationMembers?.findIndex(member => payload.id === member.id);
        state.evaluationMembers[index] = payload;
      })
    );
  }

  @Action(PatchMercerSupportEvaluationMember)
  patchMercerSupportEvaluationMember(ctx: StateContext<MercerSupportStateModel>, { id, payload }: PatchMercerSupportEvaluationMember): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const index = state.evaluationMembers?.findIndex(member => id === member.id);
        Object.assign(state.evaluationMembers[index], payload);
      })
    );
  }

  @Action(LoadMercerSupportSeasons)
  loadMercerSupportSeasons(ctx: StateContext<MercerSupportStateModel>, { partnerEndDateAt }: LoadMercerSupportSeasons): Observable<void> {
    return this.mercerSupportService.getSeasons(partnerEndDateAt).pipe(
      tap(seasons => ctx.patchState({ seasons })),
      filter(seasons => seasons?.length === 1),
      switchMap(seasons => ctx.dispatch(new SetMercerSupportSeason(seasons[0])))
    );
  }

  @Action(SetMercerSupportSeason)
  setMercerSupportSeason(ctx: StateContext<MercerSupportStateModel>, { season }: SetMercerSupportSeason): Observable<void> {
    return ctx.dispatch(new LoadMercerSupportSeasonSettings({ id: season.id, type: SeasonTypeEnum.PositionEvaluation })).pipe(tap(() => ctx.patchState({ season })));
  }

  @Action(SetMercerSupportPositionLock)
  setMercerSupportPositionLock(ctx: StateContext<MercerSupportStateModel>, { payload }: SetMercerSupportPositionLock): Observable<unknown> {
    const { season } = ctx.getState();
    return this.mercerSupportService
      .updateLock(season?.id, payload.evaluationMemberId, payload.lock)
      .pipe(map(member => ctx.dispatch(new SetMercerSupportEvaluationMember(member))));
  }

  @Action(ResetMercerSupportState)
  resetMercerSupportState(ctx: StateContext<MercerSupportStateModel>): void {
    ctx.patchState(STATE_DEFAULTS);
  }

  @Action(SetMercerSupportCurrency)
  setMercerSupportCurrency(ctx: StateContext<MercerSupportStateModel>, { currency }: SetMercerSupportCurrency): void {
    ctx.patchState({ selectedCurrency: currency });
  }

  @Action(AddMercerSupportFilterParameter)
  addMercerSupportFilterParameter(ctx: StateContext<MercerSupportStateModel>, { payload }: AddMercerSupportFilterParameter): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.filterParameter.push(payload);
      })
    );
  }

  @Action(SetMercerSupportFilterParameters)
  setMercerSupportFilterParameters(ctx: StateContext<MercerSupportStateModel>, { payload }: SetMercerSupportFilterParameters): void {
    ctx.patchState({ filterParameter: payload });
  }

  @Action(ClearMercerSupportFilterParameterKey)
  clearMercerSupportFilterParameterKey(ctx: StateContext<MercerSupportStateModel>, { category }: ClearMercerSupportFilterParameterKey): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.filterParameter = state.filterParameter.filter(param => param.category !== category);
      })
    );
  }

  @Action(LoadMercerSupportSeasonSettings)
  loadMercerSupportSeasonSettings(ctx: StateContext<MercerSupportStateModel>, { payload }: LoadMercerSupportSeasonSettings): Observable<GenericSeasonSettings> {
    return this.seasonSettingsService.getSeasonSettings(payload.id, payload.type).pipe(tap((seasonSettings: PositionEvaluationSetting) => ctx.patchState({ seasonSettings })));
  }

  @Action(ChangeValidationManager)
  changeValidationManager(ctx: StateContext<MercerSupportStateModel>, { payload }: ChangeValidationManager): Observable<unknown> {
    const { season } = ctx.getState();
    return this.mercerSupportService.changeValidationManager(season.id, payload.evaluationMemberId, payload);
  }
}
