<mat-form-field
  [matTooltip]="errorTooltip$ | async"
  [matTooltipDisabled]="ngControl?.untouched"
  matTooltipClass="error-tooltip"
  class="lib-input coin-form-field-outline coin-form-field-outline--{{ matFormFieldClass }} no-margin"
  appearance="outline"
  floatLabel="always">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <textarea
    matInput
    cdkTextareaAutosize
    [cdkAutosizeMinRows]="rows === 'auto' ? 1 : rows"
    [cdkAutosizeMaxRows]="rows === 'auto' ? 50 : rows"
    (blur)="onTouch(value)"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [disabled]="disabled"
    autocomplete="off"></textarea>
  <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
</mat-form-field>
