import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Employee } from '@coin/shared/util-models';
import { Store } from '@ngxs/store';
import { ActionDef } from '@ngxs/store/src/actions/symbols';
import { SetCurrentLevel } from '../../../../merit/merit-budget-allocation/store/merit-budget-allocation-total.actions';
import { MeritBudgetAllocationState } from '../../../../merit/merit-budget-allocation/store/merit-budget-allocation.state';
import { OrganisationService } from '../../../models/organisation-service.model';

@Component({
  selector: 'coin-c-org-tree-overview-item',
  templateUrl: './org-tree-overview-item.component.html',
  styleUrls: ['./org-tree-overview-item.component.scss']
})
export class OrgTreeOverviewItemComponent implements OnInit {
  @Input() head: Employee;
  @Input() level: number;
  @Input() last: boolean;
  @Input() lastParent: boolean;
  @Input() selectedEmployeeId: string;
  @Input() customOpenEmployeeInViewAction: ActionDef;
  @Input() service: OrganisationService;
  @Input() seasonId?: string;
  @Output() clickTreeItem = new EventEmitter<void>();

  private maxVisibleLevel: number;

  get disableNavigation(): boolean {
    return this.maxVisibleLevel && this.level >= this.maxVisibleLevel;
  }

  get isRoot(): boolean {
    return this.level === 0;
  }

  get isMaxLevel(): boolean {
    return this.level === 2;
  }

  get isSelected(): boolean {
    return this.head?.id === this.selectedEmployeeId;
  }

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.maxVisibleLevel = this.store.selectSnapshot(MeritBudgetAllocationState.allocationSettings)?.visibleLevels;
  }

  public onClickItem(): void {
    if (!this.disableNavigation) {
      let isFullTree = true;
      if (this.maxVisibleLevel) {
        const level = this.head.orgCode === 'SE GP' ? this.level : this.level + 1;
        this.store.dispatch(new SetCurrentLevel({ level: level }));
        isFullTree = false;
      }
      this.store.dispatch(new this.customOpenEmployeeInViewAction({ node: this.head, fullTree: isFullTree }));
      this.clickTreeItem.emit();
    }
  }
}
