<div class="container" [class.small]="isSmall">
  <p>{{ displayFn ? (value | pure : displayFn) : value }}</p>
  <div *ngIf="isEditable">
    <button #overlayOrigin cdkOverlayOrigin #trigger="cdkOverlayOrigin" *ngIf="isEditable" (click)="originClicked()" class="no-style">
      <mat-icon>edit</mat-icon>
      <ng-template
        cdk-connected-overlay
        [cdkConnectedOverlayDisableClose]="false"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="isOverlayVisible"
        [cdkConnectedOverlayHasBackdrop]="true"
        (backdropClick)="toggleOverlay()">
        <div class="overlay-container" #overlay>
          <p *ngIf="infoHeader" class="bold">{{ infoHeader }}</p>
          <p *ngIf="infoText">{{ infoText }}</p>
          <coin-dropdown
            *ngIf="choices?.length || lazyLoadChoicesFn"
            [lazyLoadFn]="lazyLoadChoicesFn"
            [placeholder]="placeholder"
            [(ngModel)]="editValue"
            [items]="choices"
            [displayFn]="displayFn"
            [withSearch]="choicesWithSearch"></coin-dropdown>
          <coin-input *ngIf="!choices?.length && !lazyLoadChoicesFn" [placeholder]="placeholder" [(ngModel)]="editValue"></coin-input>
          <div class="action-row">
            <coin-action-button (click)="cancelClicked()">{{ 'general.cancel' | translate }}</coin-action-button>
            <coin-action-button class="primary" (click)="saveClicked()">{{ 'general.btnSave' | translate }}</coin-action-button>
          </div>
        </div>
      </ng-template>
    </button>
  </div>
</div>
