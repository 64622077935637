<div
  class="textarea-container {{ addClass ? 'coin-form-field-outline coin-form-field-outline--' + addClass : '' }}"
  [class.full-height]="fullHeight"
  [class.disabled]="disabled"
  [class.invalid]="errorMessage || (!disabled && invalid)"
  [matTooltip]="errorMessage">
  <textarea
    matInput
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [readonly]="readonly"
    (input)="onInput($event.target.value)"
    (blur)="blurEvent.emit($event.target.value)"></textarea>
  <mat-icon>edit</mat-icon>
</div>
