import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { ContentLanguage, EquityElementTypeEnum } from '@coin/shared/util-enums';
import { TinyHelpers } from '@coin/shared/util-helpers';
import { AuthService } from '@coin/modules/auth/data-access';
import { DynamicPageDataService } from '../../shared/models/dynamic-page-data-service.model';

interface Item {
  fileName: string;
}

@Injectable({
  providedIn: 'root'
})
export class EquityPageDataService implements DynamicPageDataService {
  @Output() onNewPage$ = new EventEmitter<Item>();

  private chapters: unknown[] = [];
  private isRow = true;
  private currentItem: Item;
  private isAuthenticatedState = false;

  public get canSwitchCountry(): boolean {
    return (
      this.currentItem?.fileName === 'direct-match-program/direct-match-program' ||
      this.currentItem?.fileName === 'employee-spin-off-incentive-program/employee-spin-off-incentive-program' ||
      this.currentItem?.fileName === 'download-center/download-center'
    );
  }

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    this.setIsRow();
    this.checkLoginState();
  }

  public getRowState(): boolean {
    return this.isRow;
  }

  private async setIsRow(): Promise<void> {
    const user = await this.authService.getUser();
    this.isRow = user?.['custom:country'] === undefined || user['custom:country'] !== 'DE';
  }

  private checkLoginState(): void {
    setTimeout(async () => {
      const isAuthenticated = this.authService.isAuthenticated$.value;
      const user = await this.authService.getUser();

      if (this.isAuthenticatedState !== isAuthenticated) {
        this.isAuthenticatedState = isAuthenticated;
        this.isRow = user?.['custom:country'] === undefined || user['custom:country'] !== 'DE';
        this.displayPage(null);
      }
      this.checkLoginState();
    }, 3000);
  }

  private getAssetDataByFileName(name: string, lang: string): Observable<unknown> {
    return name ? this.httpClient.get(`./assets/equity/json/${name}_${lang}.json`) : of(EMPTY);
  }

  public toggleROW(): void {
    this.isRow = !this.isRow;
    this.displayPage(null);
  }

  public async displayPage(item?: Item): Promise<void> {
    if (item) {
      this.currentItem = item;
    }

    const currLang: string = this.translateService?.currentLang === ContentLanguage.GERMAN ? ContentLanguage.GERMAN : ContentLanguage.ENGLISH;
    let data;
    if (this.currentItem?.fileName) {
      data = await this.getAssetDataByFileName(this.currentItem.fileName, currLang).pipe(take(1)).toPromise();
    }
    const newChapters = !this.isRow && this.canSwitchCountry ? data?.dtld?.chapters : data?.row?.chapters;

    if (newChapters && !TinyHelpers.areItemsSimilar(newChapters, this.chapters)) {
      this.chapters = newChapters.filter(async chapter => (chapter?.elements?.[0]?.type === EquityElementTypeEnum.DOWNLOAD ? this.authService.isAuthenticated$.value : true));
      this.onNewPage$.emit(this.currentItem);
    }
  }
}
