import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'individualMultiplierView', standalone: true })
export class IndividualMultiplierPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(originalValue: number, digits = 1): string {
    return this.decimalPipe.transform(originalValue, `1.${digits}-${digits}`);
  }
}
