import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrencyIsoCode } from '@coin/shared/util-models';
import { DisplayedCurrency } from '@coin/shared/util-enums';
import { CurrencyConversionService } from '@coin/shared/data-access';

@Pipe({ name: 'fxRate', standalone: true })
export class FxRatePipe implements PipeTransform {
  constructor(private currencyConversionService: CurrencyConversionService) {}

  transform(fxDate: string | Date, fromCurrency: CurrencyIsoCode, toCurrency: CurrencyIsoCode = DisplayedCurrency.EUR, forceConversion?: boolean): Observable<number> {
    return this.currencyConversionService.getFxRate({ date: fxDate instanceof Date ? fxDate.toISOString() : fxDate, from: fromCurrency, to: toCurrency }, forceConversion);
  }
}
