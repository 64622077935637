<div [ngSwitch]="isIntegrated">
  <ng-template #calculationContainer>
    <div *ngIf="isCalculating" class="recalculation-container" [class.mat-elevation-z4]="!isIntegrated" [class.is-integrated]="isIntegrated">
      <div class="info">
        <div class="top">
          <p *ngIf="!isIntegrated" class="bold">{{ calculationHeadline }}</p>
          <coin-action-button
            *ngIf="transactionCalculationOperations && !isIntegrated"
            class="primary"
            [small]="true"
            permissionAccess
            [allowedResources]="[permissionResource.TransactionStatus]"
            [allowedActions]="[permissionActions.Update]"
            (click)="cancelTransactionProcess()"
            >{{ 'general.btnCancel' | translate }}</coin-action-button
          >
        </div>

        <ng-container *ngIf="transactionCalculationOperations?.displayAsList; then processStepList; else currentProcessStep"> </ng-container>
        <ng-template #processStepList>
          <div *ngFor="let processStep of transactionCalculationOperations?.transactionStatus?.processSteps ?? []; trackBy: stepNr" class="process-step-line">
            <div class="process-step-icon">
              <app-turbine
                *ngIf="processStep.transactionState === 'Running' || processStep.transactionState === 'Started'"
                [withPole]="false"
                [shadow]="false"
                [contrast]="true"></app-turbine>
              <mat-icon *ngIf="processStep.transactionState === 'Finished'">done</mat-icon>
            </div>

            <p>{{ processStep.title }} {{ transactionCalculationOperations?.showProgressCount ? '(' + processStep.progressCount + '/' + processStep.totalCount + ')' : '' }}</p>
          </div>
        </ng-template>
        <ng-template #currentProcessStep>
          <p *ngIf="currentProcessStepTitle">{{ currentProcessStepTitle }}</p>
        </ng-template>
      </div>
      <div class="progress">
        <mat-progress-bar mode="determinate" [value]="calculationProgress"></mat-progress-bar>
        <div class="turbine" [style.left]="leftTurbineStyleValue">
          <app-turbine [withPole]="false" [shadow]="false"></app-turbine>
        </div>
      </div>
      <div class="bottom">
        <coin-action-button
          *ngIf="isHideable"
          class="secondary"
          [small]="true"
          permissionAccess
          [allowedResources]="[permissionResource.TransactionStatus]"
          [allowedActions]="[permissionActions.Update]"
          (click)="hide()"
          >{{ 'general.hide' | translate }}</coin-action-button
        >
      </div>
    </div>
  </ng-template>
  <ng-container *ngSwitchCase="true" [ngTemplateOutlet]="calculationContainer"></ng-container>
  <ng-container *ngSwitchCase="false">
    <div cdkOverlayOrigin #origin="cdkOverlayOrigin"></div>
    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOpen]="isCalculating" [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayOrigin]="origin">
      <ng-container [ngTemplateOutlet]="calculationContainer"></ng-container>
    </ng-template>
  </ng-container>
</div>
