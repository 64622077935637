import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationErrors } from '@angular/forms';
import { errorsToText } from '@coin/shared/util-helpers';
import { PureFunctionPipe } from '@coin/shared/util-pipes';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'coin-form-info-message',
  standalone: true,
  imports: [CommonModule, PureFunctionPipe, MatIcon, TranslateModule],
  templateUrl: './form-info-message.component.html',
  styleUrl: './form-info-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormInfoMessageComponent {
  @Input() errors: ValidationErrors;
  @Input() warnings: string[];

  protected readonly errorsToText = errorsToText;
}
