<div class="chapter-overview-main">
  <div class="top-chapter-bar" [ngClass]="{ 'less-height-cp': collapsed }">
    <ng-container *ngFor="let chapter of usedChapters; let i = index">
      <div class="chapter-icon-main" (click)="chapterClick(chapter)" @animateChapter>
        <div
          *ngIf="!collapsed"
          @flyInFromTopNew
          class="chapter-icon top-chapter"
          [ngClass]="{ 'chapter-icon--last': i === usedChapters.length - 1, 'chapter-icon--inbetween': i !== 0 && i !== usedChapters.length - 1, 'chapter-icon--first': i === 0 }">
          <span class="material-icons-outlined">{{ chapter.content['icon'] ? chapter.content['icon'] : 'view_in_ar' }}</span>
        </div>
        <p [ngClass]="{ 'chapter-text--first': i === 0, 'chapter-text--last': i === usedChapters.length - 1 }" @flyInFromTopNew class="chapter-text">
          {{ chapter.content['text'] }}
        </p>
      </div>
      <div class="chapter-line" *ngIf="i < usedChapters.length - 1">
        <div class="flex-one"></div>
        <div class="chapter-line-item" [ngStyle]="{ background: collapsed ? 'transparent' : 'black' }"></div>
        <div class="flex-one"></div>
      </div>
    </ng-container>
  </div>
</div>
