import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { MercerSupportState } from '../../../mercer-support/state/mercer-support.state';
import { MercerState } from '../../../mercer/state/mercer.state';
import { SpecialPaymentState } from '../../../special-payment/state/special-payment.state';
import { MeritBudgetAllocationState } from '../../merit-budget-allocation/store/merit-budget-allocation.state';
import { MeritSupportState } from '../../merit-support/store/merit-support.state';

@Injectable({
  providedIn: 'root'
})
export class CurrencyHelper {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  public getFreezeDate(freezeDate?: string): string {
    if (this.router.url.includes('merit-support')) {
      return this.store.selectSnapshot(MeritSupportState.seasonSettings).allocationFreezeDateAt;
    }
    if (this.router.url.includes('budget-allocation')) {
      return this.store.selectSnapshot(MeritBudgetAllocationState.seasonSettings).allocationFreezeDateAt;
    }
    if (this.router.url.includes('mercer-support')) {
      return this.store.selectSnapshot(MercerSupportState.seasonSettings).freezeDateAt;
    }
    if (this.router.url.includes('mercer')) {
      return this.store.selectSnapshot(MercerState.seasonSettings).freezeDateAt;
    }
    if (this.router.url.includes('special-payment')) {
      return this.store.selectSnapshot(SpecialPaymentState.selectedSeasonSettings)?.fxDateAt ?? freezeDate;
    }

    return freezeDate;
  }
}
