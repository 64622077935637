import { trigger, transition, style, animate } from '@angular/animations';

export const flyInFromTopNew = trigger('flyInFromTopNew', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)', opacity: 0, padding: 0, margin: 0, overflow: 'hidden' }),
    animate('300ms ease-in', style({ transform: 'translateY(0%)', opacity: 1, padding: '*', margin: '*', overflow: 'hidden' }))
    // animate('800ms cubic-bezier(1,0,1,0)', style({ 'box-shadow': 'none' }))
  ]),
  transition(':leave', [animate('300ms ease-out', style({ transform: 'translateY(-100%)', opacity: 0, padding: 0, margin: 0, overflow: 'hidden' }))])
]);
