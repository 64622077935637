<button
  (mouseenter)="mouseEnterLeave('enter')"
  (mouseleave)="mouseEnterLeave('leave')"
  class="{{ iconClass }} {{ addClass }}"
  [style.background-color]="bgColor"
  [class.circle]="circle"
  [class.text-to-ellipsis]="ellipsis"
  [class.small]="small"
  [disabled]="disabled || loading"
  (click)="onClick($event)"
  #button>
  <div
    *ngIf="arrowPos === 'left' && svgIcon"
    #svgicon
    style="overflow: hidden"
    [ngStyle]="{
      width: iconTextDistance + 'px',
      'margin-right': iconTextMargin + 'px',
      height: height + 'px'
    }"
    (click)="onClick($event)"></div>

  <mat-icon *ngIf="matIcon && iconPos === 'left'" (click)="onClick($event)" [style.margin-right.px]="matIconTextMargin" [class.material-icons-outlined]="matIconOutlined">
    {{ matIcon }}
  </mat-icon>

  <ng-content></ng-content>

  <mat-icon *ngIf="matIcon && iconPos === 'right'" (click)="onClick($event)" [style.margin-left.px]="matIconTextMargin" [class.material-icons-outlined]="matIconOutlined">
    {{ matIcon }}
  </mat-icon>

  <div
    *ngIf="arrowPos === 'right' && svgIcon"
    #svgicon
    style="overflow: hidden"
    [ngStyle]="{
      width: iconTextDistance + 'px',
      'margin-left': iconTextMargin + 'px',
      height: height + 'px'
    }"
    (click)="onClick($event)"></div>

  <div class="loading-container" *ngIf="loading" (click)="onClick($event)">
    <app-turbine [contrast]="true" [withPole]="false"></app-turbine>
  </div>
</button>
