import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EmbeddedViewRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  forwardRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { DefaultControlValueAccessor } from '@coin/shared/util-helpers';
import { TranslateModule } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { timer } from 'rxjs';
import { InputSize, MatFormFieldClass } from '../../input.types';
import { V2ButtonComponent } from '../button/v2-button.component';

export const COIN_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'coin-v2-date-input',
  templateUrl: './v2-date-input.component.html',
  styleUrls: ['./v2-date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatDatepickerModule, MatIconModule, MatInputModule, FormsModule, MatTooltip, V2ButtonComponent, TranslateModule],
  standalone: true,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: COIN_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'en-EN' },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => V2DateInputComponent),
      multi: true
    }
  ]
})
export class V2DateInputComponent extends DefaultControlValueAccessor<Moment> implements OnInit, OnDestroy {
  @ViewChild('todayButton') todayButton: TemplateRef<unknown>;

  @Input() label: string;
  @Input() placeholder: string;
  @Input() matFormFieldClass: MatFormFieldClass = 'customer-grey';
  @Input() icon = 'date_range';
  @Input() boldValue = false;
  @Input() size: InputSize;
  @Input() min: Moment;
  @Input() max: Moment;
  @Input() disableDays?: number[];

  private todayButtonView: EmbeddedViewRef<unknown>;

  @HostBinding('class')
  private get class(): string {
    return `size-${this.size}`;
  }

  constructor(
    private vcr: ViewContainerRef,
    private destroyRef: DestroyRef
  ) {
    super();
  }

  protected insertTodayButton(): void {
    if (!this.todayButtonView) {
      this.todayButtonView = this.vcr.createEmbeddedView(this.todayButton);
      this.todayButtonView.rootNodes.forEach((node: HTMLElement) => node.remove());
    }

    timer(0)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const host = document.querySelector('.mat-calendar-spacer');
        this.todayButtonView.rootNodes.forEach(node => host?.appendChild(node));
      });
  }

  ngOnDestroy(): void {
    this.todayButtonView?.destroy();
  }

  protected selectToday(datePicker: MatDatepicker<unknown>) {
    const today = moment().utc().startOf('day');
    this.value = today;
    datePicker.close();
  }

  dayFilter = (d: Date): boolean => {
    if (this.disableDays && d) {
      const day = new Date(d).getDay();
      return !this.disableDays.includes(day);
    }
    return true;
  };
}
