export enum ManagementGroup {
  ManagingBoard = 'Managing Board',
  TopManagement = 'Top Management',
  SeniorManagement = 'Senior Management',
  NonSeniorManagement = 'Non-Senior-Management',
  Default = 'Default'
}

export enum ManagementGroupShort {
  ManagingBoard = 'A',
  TopManagement = 'B',
  SeniorManagement = 'C',
  NonSeniorManagement = 'O',
  Default = 'Z'
}
