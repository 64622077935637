import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuleEngineOperations, SimulationResponse, UserSimulationResponse } from '@coin/admin/common/util';
import { PopulationScope, RuleEngineOperators } from '@coin/shared/util-enums';
import { environment } from '@coin/shared/util-environments';
import { RuleEngineHelper, checkResult, pollingStrategy } from '@coin/shared/util-helpers';
import { Employee, PaginatedResult, RuleSetV2 } from '@coin/shared/util-models';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retryWhen } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RuleEngineV2Service {
  constructor(
    private httpClient: HttpClient,
    private toast: ToastrService
  ) {}

  public getRuleSet(ruleSetId: string, withPolling = true): Observable<RuleSetV2> {
    return this.httpClient.get<RuleSetV2>(`${environment.api.baseUrl}/admin/api/v2/rule-sets/${ruleSetId}`).pipe(
      map(result => (withPolling ? checkResult()(result) : result)),
      retryWhen(pollingStrategy({ timerDuration: 1000 })),
      map(result => RuleEngineOperations.dtoToRuleSetV2(result)),
      catchError((res: HttpErrorResponse) => {
        return throwError(res.error);
      })
    );
  }

  public createRuleSet(body: RuleSetV2): Observable<RuleSetV2> {
    return this.httpClient.post<RuleSetV2>(`${environment.api.baseUrl}/admin/api/v2/rule-sets`, RuleEngineHelper.ruleSetV2ToDto(body)).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res?.error);
      })
    );
  }

  public updateRuleSet(body: RuleSetV2): Observable<RuleSetV2> {
    return this.httpClient.put<RuleSetV2>(`${environment.api.baseUrl}/admin/api/v2/rule-sets/${body.id}`, RuleEngineHelper.ruleSetV2ToDto(body)).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res?.error);
      })
    );
  }

  public getScopes(populationScope?: PopulationScope): Observable<Record<string, RuleEngineOperators[]>> {
    return this.httpClient.get<{ fieldsAndOperators: Record<string, RuleEngineOperators[]> }>(`${environment.api.baseUrl}${this.getScopeUrl(populationScope)}`).pipe(
      map(result => result?.fieldsAndOperators),
      catchError((res: HttpErrorResponse) => {
        return throwError(res.error);
      })
    );
  }

  /** @deprecated unused */
  getEmployeesByRuleSet(body: RuleSetV2, size: number, skip: number): Observable<SimulationResponse> {
    const page = !skip ? 1 : Math.floor(skip / size) + 1;

    return this.httpClient
      .post<PaginatedResult<Employee>>(`${environment.api.baseUrl}/admin/api/v2/employees/search?page=${page}&size=${size}`, RuleEngineHelper.ruleSetV2ToDto(body))
      .pipe(
        map(result => ({ employees: result?.content, totalCount: result?.total })),
        catchError((res: HttpErrorResponse) => {
          this.toast.error(`Simulation request error: ${res.error?.message || JSON.stringify(res.error)}`);
          return throwError(res.error);
        })
      );
  }

  public getDropDownValues(field: string, populationScope?: PopulationScope): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.api.baseUrl}${this.getFieldValuesUrl(populationScope)}${field}`).pipe(
      catchError((res: HttpErrorResponse) => {
        this.toast.error(`Cannot retrieve rule values: ${res.error?.message || JSON.stringify(res.error)}`);
        return throwError(res.error);
      })
    );
  }

  public getUsersForRuleSet(body: RuleSetV2, size: number, skip: number): Observable<SimulationResponse> {
    const page = !skip ? 1 : Math.floor(skip / size) + 1;

    return this.httpClient
      .post<
        PaginatedResult<UserSimulationResponse>
      >(`${environment.api.baseUrl}/admin/api/v2/master/users/simulate-population?Paging.Page=${page}&Paging.Size=${size}`, RuleEngineHelper.ruleSetV2ToDto(body))
      .pipe(
        map(result => ({ employees: result?.content, totalCount: result?.total })),
        catchError((res: HttpErrorResponse) => {
          this.toast.error(`Simulation request error: ${res.error?.message || JSON.stringify(res.error)}`);
          return throwError(res.error);
        })
      );
  }

  private getScopeUrl(populationScope?: PopulationScope): string {
    switch (populationScope) {
      case PopulationScope.Employee:
        return '/admin/api/v3/master/employees/scope';
      case PopulationScope.Position:
        return '/admin/api/v3/master/positions/scope';
      case PopulationScope.PositionSnapshot:
        return '/admin/api/v3/master/position-snapshots/scope';
      case PopulationScope.User:
        return '/admin/api/v2/master/users/scope';
      case PopulationScope.EclEmployee:
        return '/condition-library/admin/v1/employees/scope';
      case PopulationScope.OmRule:
        return '/organisation-management/admin/v1/rules/scope';
      default:
        return '/admin/api/v3/master/employees/scope';
    }
  }

  private getFieldValuesUrl(populationScope?: PopulationScope): string {
    switch (populationScope) {
      case PopulationScope.Employee:
        return '/admin/api/v3/master/employees/field-values/';
      case PopulationScope.Position:
        return '/admin/api/v3/master/positions/field-values/';
      case PopulationScope.PositionSnapshot:
        return '/admin/api/v3/master/position-snapshots/field-values/';
      case PopulationScope.EclEmployee:
        return '/condition-library/admin/v1/employees/field-values/';
      default:
        return '/admin/api/v3/master/employees/field-values/';
    }
  }
}
