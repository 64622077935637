import { TreeNode } from '../route-builder';

export const MeritBudgetAllocationModule = {
  segment: 'budget-allocation',
  children: {
    MeritBudgetAllocationComponent: {
      segment: '',
      params: ['seasonId', 'impersonatedEmployeeId', 'processType'],
      children: { MeritBudgetListViewComponent: { segment: 'list-view' }, MeritBudgetPromotionsViewComponent: { segment: 'promotions' } }
    }
  }
} as const satisfies TreeNode;
