import { TreeNode } from '../route-builder';

export const SuccessionManagementModule = {
  segment: 'succession-management',
  children: {
    SuccessionManagementComponent: { segment: '' },
    SuccessionManagementIntroductionComponent: { segment: 'introduction' },
    SuccessionManagementEmployeesComponent: { segment: 'my-employees' },
    SuccessionManagementOrganisationViewComponent: { segment: 'organisation-view' }
  }
} as const satisfies TreeNode;
