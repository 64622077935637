import { ModuleWithProviders, NgModule } from '@angular/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CommonModule } from '@angular/common';
import { AuthGmsClientConfigService, AuthService } from '@coin/modules/auth/data-access';
import { AuthGmsClientConfig } from '@coin/modules/auth/util';
import { AuthGmsClientCallbackComponent } from './auth-gms-client-callback.component';

@NgModule({
  imports: [CommonModule, OAuthModule.forRoot()],
  declarations: [AuthGmsClientCallbackComponent],
  exports: [AuthGmsClientCallbackComponent]
})
export class AuthGmsAngularClientModule {
  static forRoot(config: AuthGmsClientConfig): ModuleWithProviders<AuthGmsAngularClientModule> {
    return {
      ngModule: AuthGmsAngularClientModule,
      providers: [
        AuthService,
        {
          provide: AuthGmsClientConfigService,
          useValue: config
        }
      ]
    };
  }

  static forTest(): ModuleWithProviders<AuthGmsAngularClientModule> {
    return this.forRoot({} as AuthGmsClientConfig);
  }
}
