/* eslint-disable max-lines */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Season } from '@coin/admin/season-mgmt/util';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { IsoToCountryService } from '@coin/shared/data-management-state';
import { SeasonSimulationState, SeasonTypeEnum } from '@coin/shared/util-enums';
import { environment } from '@coin/shared/util-environments';
import { CountryCode, MassExportTransactionStatus, PaginatedResult, SearchItemService, TransactionStatus } from '@coin/shared/util-models';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { FieldValuesTargetProperty } from '../enums/field-values-target-property.enum';
import { MercerNewHireState } from '../enums/mercer-support-new-hire-state.enum';
import { MercerSupportTreeViewItem } from '../enums/mercer-support-tree-view-item.model';
import { MercerValidationState } from '../enums/mercer-support-validation-state.enum';
import { ResetFilterService } from '../interfaces/reset-filter-service.interface';
import { PositionEvaluationCandidate } from '../models/mercer-support-candidate.model';
import { EvaluationMemberExpertPositionsDropDown } from '../models/mercer-support-evaluation-member-expert.model';
import { MercerEvaluationMember, MercerEvaluationMemberDetails, PositionSnapshotSearchResult } from '../models/mercer-support-evaluation-member.model';
import { MercerSupportIncumbentMember, UpdateJobAssignmentProposal, UpdateJobAssignmentProposalResponse } from '../models/mercer-support-incumbent-member.model';
import { MercerSupportJobCodeSuggestion } from '../models/mercer-support-job-code-suggestion.model';
import { PositionMetaData } from '../models/position-meta-data.model';
import { MercerPositionClassService } from './mercer-position-class.service';

@Injectable({
  providedIn: 'root'
})
export class MercerSupportService implements SearchItemService, ResetFilterService {
  public readonly resetFilters$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private httpHelpersService: HttpHelpersService,
    private toast: ToastrService,
    private mercerPositionClassService: MercerPositionClassService,
    private translate: TranslateService,
    private isoToCountryService: IsoToCountryService
  ) {}

  getEvaluationMembers(seasonId: string, page: number, size: number, sortQuery = '', filterQuery = ''): Observable<PaginatedResult<MercerEvaluationMember>> {
    return this.httpClient
      .get<
        PaginatedResult<MercerEvaluationMember>
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members?Paging.page=${page + 1}&Paging.size=${size}${sortQuery}${filterQuery}`)
      .pipe(this.httpHelpersService.handleError('Cannot get members'));
  }

  getTreeViewItems(seasonId: string, parentOrgCode: string, filterQuery = ''): Observable<MercerSupportTreeViewItem[]> {
    this.loadingService.present();
    return this.httpClient
      .get<
        MercerSupportTreeViewItem[]
      >(`${environment.api.baseUrl}/admin/api/v3/master/position-snapshots/${seasonId}?parentOrgCode=${encodeURIComponent(parentOrgCode)}${filterQuery}`)
      .pipe(
        this.httpHelpersService.handleError('Cannot get positions'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getEvaluationMemberDetails(seasonId: string, memberId: string): Observable<MercerEvaluationMemberDetails> {
    this.loadingService.present();
    return this.httpClient
      .get<MercerEvaluationMemberDetails>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}`)
      .pipe(
        this.httpHelpersService.handleError('Cannot get member'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  updateEvaluationMemberDetails(seasonId: string, member: MercerEvaluationMemberDetails): Observable<MercerEvaluationMemberDetails> {
    return this.httpClient
      .patch<MercerEvaluationMemberDetails>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${member.id}`, member)
      .pipe(
        tap(() => this.toast.success('Updated successfully')),
        this.loadingService.withLoadingScreen,
        this.httpHelpersService.handleError('Cannot update member')
      );
  }

  updateJobAssignmentProposal(seasonId: string, memberId: string, proposalId: string, update: UpdateJobAssignmentProposal): Observable<UpdateJobAssignmentProposalResponse[]> {
    this.loadingService.present();
    return this.httpClient
      .put<
        UpdateJobAssignmentProposalResponse[]
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/proposals/${proposalId}`, update)
      .pipe(
        tap(() => this.toast.success('Updated successfully')),
        this.httpHelpersService.handleError('Cannot update proposal'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  updateLock(seasonId: string, memberId: string, isLocked: boolean): Observable<MercerEvaluationMember> {
    this.loadingService.present();
    return this.httpClient
      .put<MercerEvaluationMember>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/lock`, {
        isLocked
      })
      .pipe(
        tap(() => this.toast.success('Lock updated successfully')),
        this.httpHelpersService.handleError('Cannot update lock'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  deleteJobAssignmentProposal(seasonId: string, memberId: string, id: string): Observable<void> {
    this.loadingService.present();
    return this.httpClient
      .delete<void>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/proposals`, { body: { id } })
      .pipe(
        tap(() => this.toast.success('Deleted successfully')),
        this.httpHelpersService.handleError('Cannot delete proposal'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getSeasons(partnerEndDateAt = new Date().toISOString()): Observable<Season[]> {
    const simulationStatesFilter = [SeasonSimulationState.Frozen, SeasonSimulationState.InAllocation, SeasonSimulationState.PrePublished, SeasonSimulationState.Published]
      .map(state => `&simulationState=${state}`)
      .join('');

    this.loadingService.present();
    return this.httpClient
      .get<
        PaginatedResult<Season>
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons?seasonType=${SeasonTypeEnum.PositionEvaluation}${simulationStatesFilter}&query.partnerEndDateAt=${partnerEndDateAt}&Paging.page=1&Paging.size=1000`)
      .pipe(
        map(data => data?.content),
        this.httpHelpersService.handleError('Cannot get seasons'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getFieldValues(page: number, size: number, key: string, searchText: string, seasonId: string, parentOrgCode?: string, searchSuffix = ''): Observable<PaginatedResult<string>> {
    const searchQuery = searchText?.trim() ? `&Query.${key}=${encodeURIComponent(searchText)}` : '';

    return this.httpClient
      .get<
        PaginatedResult<string>
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/partner/${seasonId}/field-values?page=${page + 1}&size=${size}&targetProperty=${key}${searchQuery}${searchSuffix}`)
      .pipe(catchError(() => of(null)));
  }

  getSearchItemsByText(text: string, page: number, seasonId: string, searchSuffix?: string): Observable<PositionSnapshotSearchResult[]> {
    const encodedText = encodeURIComponent(text);
    return this.httpClient
      .get<
        PaginatedResult<PositionSnapshotSearchResult>
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/partner/${seasonId}/positions?Paging.page=${page + 1}&Paging.size=50&Query.positionName=${encodedText}&Query.incumbentFirstName=${encodedText}&Query.incumbentLastName=${encodedText}&Query.incumbentGid=${encodedText}&Query.orgCode=${encodedText}${searchSuffix ?? ''}`)
      .pipe(
        map(res => res.content),
        this.httpHelpersService.handleError('Cannot get search item')
      );
  }

  getJobCodeSuggestions(seasonId: string, memberId: string): Observable<MercerSupportJobCodeSuggestion[]> {
    this.loadingService.present();
    return this.httpClient
      .get<
        MercerSupportJobCodeSuggestion[]
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/job-code-suggestions`)
      .pipe(
        this.httpHelpersService.handleError('Cannot get job code suggestions'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  downloadExcelExport(seasonId: string, fileName: string, filterQuery = ''): Observable<TransactionStatus<MassExportTransactionStatus>> {
    return this.httpClient.post<TransactionStatus<MassExportTransactionStatus>>(
      `${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/partner/export${filterQuery}`,
      {
        seasonId: seasonId,
        fileName: fileName
      }
    );
  }

  getIncumbentCandidates(seasonId: string, memberId: string): Observable<MercerSupportIncumbentMember[]> {
    return this.httpClient
      .get<
        MercerSupportIncumbentMember[]
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/proposals/details`)
      .pipe(this.httpHelpersService.handleError('Cannot get incumbent candidates'));
  }

  addNewCandidate(seasonId: string, memberId: string, employeeId: string): Observable<void> {
    this.loadingService.present();
    return this.httpClient
      .post<void>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/proposals`, { employeeId })
      .pipe(
        tap(() => this.toast.success(this.translate.instant('mercer-support.toast.add-new-candidate-success'))),
        this.httpHelpersService.handleError('Cannot add candidate'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  acceptOrDeclinePlacement(seasonId: string, memberId: string, accept: boolean): Observable<UpdateJobAssignmentProposalResponse> {
    this.loadingService.present();
    return this.httpClient
      .put<UpdateJobAssignmentProposalResponse>(
        `${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/proposals/accept?accept=${accept}`,
        null
      )
      .pipe(
        this.httpHelpersService.handleError('Cannot accept or decline placement'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  changeValidationManager(seasonId: string, memberId: string, request: { newValidationManagerId: string; organisationSnapshotId?: string }): Observable<MercerEvaluationMember> {
    this.loadingService.present();
    return this.httpClient
      .put<MercerEvaluationMember>(
        `${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/change-validation-manager`,
        request
      )
      .pipe(
        this.httpHelpersService.handleSuccess('mercer-support.change-validation-manager-success'),
        this.httpHelpersService.handleError('Cannot change validation manager'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getExperts(seasonId: string, evaluationMemberId: string): Observable<EvaluationMemberExpertPositionsDropDown> {
    this.loadingService.present();
    return this.httpClient
      .get<EvaluationMemberExpertPositionsDropDown>(
        `${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${evaluationMemberId}/experts`
      )
      .pipe(finalize(() => this.loadingService.dismiss()));
  }

  public getPositionClassByOrgCodeAndJobCode(seasonId: string, orgCode: string, jobCode: string): Observable<number> {
    return this.httpClient
      .get<number>(`${environment.api.baseUrl}/admin/api/v3/master/position-classes/${seasonId}?orgCode=${encodeURIComponent(orgCode)}&jobCode=${encodeURIComponent(jobCode)}`)
      .pipe(this.loadingService.withLoadingScreen);
  }

  public loadAllParentHeadPositions(seasonId: string, orgCode: string): Observable<MercerSupportTreeViewItem[]> {
    this.loadingService.present();
    return this.httpClient
      .get<MercerSupportTreeViewItem[]>(`${environment.api.baseUrl}/admin/api/v3/master/position-snapshots/${seasonId}?orgCode=${encodeURIComponent(orgCode)}`)
      .pipe(
        map(positions => positions.sort((itemA, itemB) => itemA?.orgLevel - itemB?.orgLevel)),
        this.httpHelpersService.handleError('Cannot get parent head positions'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  undoPositionClassValidation(seasonId: string, memberId: string, derivedPositionClass: number): Observable<void> {
    this.loadingService.present();
    return this.httpClient
      .patch<void>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}`, {
        undoValidation: true,
        originalProposedPositionClass: derivedPositionClass,
        newPositionClass: this.mercerPositionClassService.adjustedPositionClass$.value
      })
      .pipe(
        this.httpHelpersService.handleError('Cannot undo validation'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  finalizePositionClass(
    seasonId: string,
    memberId: string,
    newPositionClass?: number,
    originalProposedPositionClass?: number,
    validationState?: MercerValidationState
  ): Observable<MercerEvaluationMember> {
    this.loadingService.present();
    return this.httpClient
      .patch<MercerEvaluationMember>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}`, {
        finalizeValidation: true,
        newPositionClass,
        originalProposedPositionClass,
        validationState
      })
      .pipe(
        tap(() => this.toast.success(this.translate.instant('mercer-support.toast.finalize-position-class-success'))),
        this.httpHelpersService.handleError('Cannot finalize position'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getCandidates(seasonId: string, filterParameter: string, page?: number, size?: number): Observable<PaginatedResult<PositionEvaluationCandidate>> {
    return this.httpClient
      .get<
        PaginatedResult<PositionEvaluationCandidate>
      >(`${environment.api.baseUrl}/admin/api/v2/master/employee-candidates?seasonId=${seasonId}${filterParameter}${this.getPageableQuery(page, size)}`)
      .pipe(
        this.httpHelpersService.handleError('Cannot get members'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getCandidatesExport(seasonId: string, fileName: string, filterParameter = ''): Observable<TransactionStatus<MassExportTransactionStatus>> {
    return this.httpClient
      .get<
        TransactionStatus<MassExportTransactionStatus>
      >(`${environment.api.baseUrl}/admin/api/v2/master/employee-candidates/export?seasonId=${seasonId}${filterParameter}&fileName=${encodeURIComponent(fileName)}`)
      .pipe(
        this.httpHelpersService.handleError('Cannot start excel export'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getCandidatePoolFieldValues(
    seasonId: string,
    targetProperty: FieldValuesTargetProperty,
    filterQuery = '',
    page?: number,
    size?: number
  ): Observable<PaginatedResult<string>> {
    return this.httpClient
      .get<
        PaginatedResult<string>
      >(`${environment.api.baseUrl}/admin/api/v2/master/employee-candidates/field-values?seasonId=${seasonId}&targetProperty=${targetProperty}${filterQuery}${this.getPageableQuery(page, size)}`)
      .pipe(
        this.httpHelpersService.handleError('Cannot get members'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public reviewNewHire(seasonId: string, evaluationMemberId: string, state: MercerNewHireState): Observable<MercerEvaluationMember> {
    return this.httpClient
      .patch<MercerEvaluationMember>(
        `${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${evaluationMemberId}/review-new-hire`,
        { state }
      )
      .pipe(this.httpHelpersService.handleError('Cannot review new hire'), this.loadingService.withLoadingScreen);
  }

  public loadCountries(): Observable<CountryCode[]> {
    return this.isoToCountryService.loadCountries();
  }

  private getPageableQuery(page?: number, size?: number): string {
    let param = '';
    if (typeof page === 'number') param += `&Paging.Page=${page + 1}`;
    if (typeof size === 'number') param += `&Paging.Size=${size}`;
    return param;
  }

  public massAutoApprove(orgCode: string, seasonId: string, ids?: string[]): Observable<TransactionStatus<PositionMetaData>> {
    return this.httpClient
      .put<TransactionStatus<PositionMetaData>>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/mass-approve`, {
        ids,
        orgCode
      })
      .pipe(this.httpHelpersService.handleError('Cannot review new hire'), this.loadingService.withLoadingScreen);
  }
}
