import { Pipe, PipeTransform } from '@angular/core';
import { CoinUser, Employee, EmployeeLight, EmployeeSlim } from '@coin/shared/util-models';

@Pipe({
  name: 'employeeName',
  standalone: true
})
export class EmployeeNamePipe implements PipeTransform {
  public transform(value: Employee | CoinUser | EmployeeLight | EmployeeSlim): string {
    return EmployeeNamePipe.formatEmployee(value);
  }

  public static formatEmployee(employee: Employee | CoinUser | EmployeeLight | EmployeeSlim): string {
    if (!employee) return '';
    const firstName = 'headFirstname' in employee ? employee.headFirstname : employee.firstname;
    const lastName = 'headLastname' in employee ? employee.headLastname : employee.lastname;
    const title = 'title' in employee ? employee.title : '';
    const lastNamePrefix = 'lastNamePrefix' in employee ? employee.lastNamePrefix : '';

    return [title, firstName, lastNamePrefix, lastName].filter(Boolean).join(' ').trim();
  }
}
