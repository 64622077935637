import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { Metadata, TransactionStatus, TransactionStatusMetadata } from '@coin/shared/util-models';
import { environment } from '@coin/shared/util-environments';
import { catchError, tap } from 'rxjs/operators';
import { HttpHelpersService } from './http-helpers';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelpersService,
    private toastrService: ToastrService
  ) {}

  public createTransaction(): Observable<TransactionStatus<TransactionStatusMetadata>> {
    return this.httpClient.post<TransactionStatus<TransactionStatusMetadata>>(`${environment.api.baseUrl}/admin/api/v1/master/transaction-status/imports`, {});
  }

  public getTransactionStatusByTransactionId(id: string): Observable<TransactionStatus<TransactionStatusMetadata>> {
    return this.httpClient.get<TransactionStatus<TransactionStatusMetadata>>(`${environment.api.baseUrl}/admin/api/v1/master/transaction-status/${id}`);
  }

  public getTransactionStatusByEntityId<T extends Metadata = Metadata>(id: string): Observable<TransactionStatus<T>> {
    return this.httpClient.get<TransactionStatus<T>>(`${environment.api.baseUrl}/admin/api/v1/master/transaction-status/entity/${id}`).pipe(catchError(() => of(null)));
  }

  public cancelTransactionProcess<T extends Metadata = Metadata>(id: string): Observable<TransactionStatus<T>> {
    return this.httpClient.post<TransactionStatus<T>>(`${environment.api.baseUrl}/admin/api/v1/master/transaction-status/${id}/cancel`, { id }).pipe(
      tap(() => this.toastrService.info('Process cancelled.')),
      this.httpHelper.handleError('Cannot cancel process')
    );
  }
}
