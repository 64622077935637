import { DestroyRef, Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, defer, Observable, of, Subject } from 'rxjs';
import { debounceTime, finalize, scan, switchMap, takeUntil, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoadingScreenComponent } from './loading-screen.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingService implements OnDestroy {
  private loadingScreen: MatDialogRef<LoadingScreenComponent>;
  private loadingCounter$ = new BehaviorSubject(0);
  private shouldLoad$ = new BehaviorSubject(false);
  private unsubscribe$ = new Subject<void>();
  private abortDismiss$ = new Subject<void>();
  private isSuppressed$ = new BehaviorSubject(false);

  public isLoading$ = new BehaviorSubject(false);

  constructor(
    private dialog: MatDialog,
    private destroyRef: DestroyRef
  ) {
    this.abortDismiss$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.subscribeToDismiss();
    });
    this.loadingCounter$
      .pipe(
        scan((previous, increment) => {
          const newCount = previous + increment < 0 ? 0 : previous + increment;
          this.shouldLoad$.next(newCount > 0);
          return newCount;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
    this.subscribeToPresent();
  }

  public present(): void {
    this.loadingCounter$.next(1);
    this.abortDismiss$.next();
  }

  public dismiss(): void {
    this.loadingCounter$.next(-1);
  }

  private subscribeToPresent(): void {
    combineLatest([this.shouldLoad$, this.isSuppressed$])
      .pipe(debounceTime(0), takeUntil(this.unsubscribe$))
      .subscribe(([shouldLoad, isSuppressed]) => {
        if (shouldLoad && !this.loadingScreen && !isSuppressed) {
          this.loadingScreen = this.dialog.open(LoadingScreenComponent, {
            minWidth: '100vw',
            minHeight: '100vh',
            disableClose: true,
            panelClass: 'transparent-dialog-panel'
          });
        }
      });
  }

  private subscribeToDismiss(): void {
    combineLatest([this.shouldLoad$, this.isSuppressed$])
      .pipe(debounceTime(100), takeUntil(this.abortDismiss$), takeUntil(this.unsubscribe$))
      .subscribe(([shouldLoad, isSuppressed]) => {
        if ((!shouldLoad || isSuppressed) && this.loadingScreen) {
          this.loadingScreen.close();
          this.loadingScreen = null;
        }
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.abortDismiss$.complete();
  }

  public withLoadingScreen = <T>(observable$: Observable<T>): Observable<T> =>
    of(null).pipe(
      tap(() => this.present()),
      switchMap(() => observable$),
      finalize(() => this.dismiss())
    );

  /** Prevents the loading screen from displaying while the observable is active */
  public suppressLoadingScreen =
    (suppress = true) =>
    <T>(observable$: Observable<T>): Observable<T> => {
      return suppress
        ? defer(() => {
            this.isSuppressed$.next(true);
            return observable$.pipe(finalize(() => this.isSuppressed$.next(false)));
          })
        : observable$;
    };
}
