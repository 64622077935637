import { forkJoin, Observable, ObservableInput, of, OperatorFunction, switchMap, throwError, timer } from 'rxjs';
import { concatAll, finalize, mergeMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TinyHelpers } from './tiny-helpers';

export const pollingStrategy =
  ({
    maxRetryAttempts = 20,
    timerDuration = 300,
    excludedStatusCodes = [400, 403, 404, 500]
  }: {
    maxRetryAttempts?: number;
    timerDuration?: number;
    excludedStatusCodes?: number[];
  } = {}) =>
  (attempts$: Observable<HttpErrorResponse>): Observable<number> => {
    return attempts$.pipe(
      mergeMap((error, i) => {
        const retryAttempt: number = i + 1;

        if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find(e => e === error?.status)) {
          return throwError(error);
        }

        console.log(`Attempt ${retryAttempt}: retrying in ${timerDuration}ms`);
        return timer(timerDuration);
      }),
      finalize(() => console.log('Finished polling request'))
    );
  };

export const checkResult =
  (strictValuesCheck = false) =>
  <T>(result: T): T => {
    if (!result || (strictValuesCheck && !(result as unknown[]).length)) {
      throw new Error('Result is empty or invalid');
    }

    return result;
  };

export const checkIfArrayCountChanges =
  <T extends unknown[]>(initialData: T = null) =>
  (result: T): T => {
    if (!result || (initialData && initialData.length === result.length)) {
      throw new Error('Array count did not change');
    }

    return result;
  };

export const checkIfArrayChanged =
  (initialData: unknown[] = null, depth?: number) =>
  (result: unknown[]) => {
    if (!result || (initialData && checkIfTwoArraysValidAndEqual(initialData, result, depth))) {
      throw new Error('Array did not change');
    }

    return result;
  };

export const checkVersionGreaterThan =
  (version: number) =>
  <T extends { version?: number }>(result: T): T => {
    if (!result || result.version <= version) {
      throw new Error(`Version is not greater than ${version}`);
    }

    return result;
  };

const checkIfTwoArraysValidAndEqual = (array1: unknown[], array2: unknown[], depth?: number) => {
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return false;
  }

  if (!depth) {
    return TinyHelpers.areItemsSimilar(array1.sort(), array2.sort());
  }
  // Following stingifys the arrays only to a specific depth
  // With this you can discard changes made in detail and only consider changes until a specific level
  return stringifyWithDepth(array1.sort(), depth) === stringifyWithDepth(array2.sort(), depth);
};

// Better here would be to constantly run x requests in parallel instead of having x forkJoins
export const batchRequest = <T>(requests$: Observable<T>[], reqSize: number): Observable<T[]> => {
  const batch: Observable<T[]>[] = [];

  for (let i = 0; i < requests$.length; i += reqSize) {
    batch.push(forkJoin(requests$.slice(i, i + reqSize)));
  }

  return of(...batch).pipe(concatAll());
};

const stringifyWithDepth = (obj: unknown, depth = 1) => {
  if (!obj || typeof obj !== 'object') {
    return JSON.stringify(obj);
  }
  const recursiveString =
    depth < 1
      ? '?'
      : Object.entries(obj)
          .map((k, v) => `${k}: ${stringifyWithDepth(v, depth - 1)}`)
          .join(', ');

  return `{ ${recursiveString} }`;
};

export const onResize = <T extends HTMLElement>(elem: T): Observable<ResizeObserverEntry> => {
  return new Observable(subscriber => {
    const resizeObserver = new ResizeObserver(([entry]) => subscriber.next(entry));
    resizeObserver.observe(elem);
    return () => resizeObserver.unobserve(elem);
  });
};

export type TrackedResult<T> = { latestValue: T | null; loading: boolean };
/**
 * Like `switchMap`, but keeps track of the current loading status if
 * @example
 *     timer(1000)
 *       .pipe(switchMapTracked(() => timer(2000)))
 *       .subscribe(console.log);
 *     // log { latestValue: null, loading: false }
 *     // 1 second delay
 *     // log { latestValue: null, loading: true }
 *     // 2 seconds delay
 *     // log { latestValue: 0, loading: false }
 * */
export const switchMapWithLoadingState = <T, O>(project: (value: T, index: number) => ObservableInput<O>): OperatorFunction<T, TrackedResult<O>> => {
  return (observable$: Observable<T>) => {
    return new Observable<TrackedResult<O>>(subscriber => {
      subscriber.next({ latestValue: null, loading: false });

      let currentValue: O = null;
      const handler = observable$
        .pipe(
          tap(() => {
            subscriber.next({ latestValue: currentValue, loading: true });
          }),
          switchMap(project),
          finalize(() => subscriber.complete())
        )
        .subscribe(latestValue => {
          subscriber.next({ latestValue, loading: false });
          currentValue = latestValue;
        });

      return () => handler.unsubscribe();
    });
  };
};

export const stateSnapshot = <T>(observable$: Observable<T>): T => {
  let snapshot: T;
  let wasAssigned = false;
  observable$
    .subscribe(value => {
      snapshot = value;
      wasAssigned = true;
    })
    .unsubscribe();

  if (!wasAssigned) throw new Error('Observable is not synchronous');
  return snapshot;
};
