import { TreeNode } from '../route-builder';

export const OrgManagementModule = {
  segment: 'org-management',
  children: {
    OrgManagementComponent: {
      segment: '',
      params: ['validOn', 'orgCode', 'selectedOrgCode', 'employeeGid', 'employeeOrgCode'],
      children: {
        OrgManagementIntroductionComponent: { segment: 'introduction' },
        OrgManagementTreeViewComponent: { segment: 'tree-view' },
        OrgManagementListViewComponent: { segment: 'list-view' }
      }
    }
  }
} as const satisfies TreeNode;
