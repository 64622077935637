import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[controlDisabled][formControl]',
  standalone: true
})
export class ControlDisabledDirective implements OnChanges {
  @Input({ required: true }) controlDisabled: boolean;
  @Input({ required: true }) formControl: FormControl;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.controlDisabled) {
      this.formControl[this.controlDisabled ? 'disable' : 'enable']({ emitEvent: false });
    }
  }
}
