import { Injectable } from '@angular/core';

@Injectable()
export class MicrosoftTeamsService {
  private readonly MS_TEAMS_CHAT_LINK = 'https://teams.microsoft.com/l/chat/0/0';
  private readonly MS_TEAMS_MEETING_LINK = 'https://teams.microsoft.com/l/meeting/new';

  public openTeamsChat(userEmails: string[]): void {
    this.openDeepLink(this.createChatDeepLink(userEmails));
  }

  public openMeetingDialog(subject: string, startTime: Date, endTime: Date, attendeeEmails: string[], content?: string): void {
    this.openDeepLink(this.createScheduleMeetingDeepLink(subject, startTime, endTime, attendeeEmails, content));
  }

  private createChatDeepLink(userEmails: string[]): URL {
    const deepLink = new URL(this.MS_TEAMS_CHAT_LINK);
    deepLink.searchParams.append('users', userEmails.join(','));
    return deepLink;
  }

  private createScheduleMeetingDeepLink(subject: string, startTime: Date, endTime: Date, attendeeEmails: string[], content?: string): URL {
    const deepLink = new URL(this.MS_TEAMS_MEETING_LINK);

    const parameters: string[] = [];

    if (subject) deepLink.searchParams.append('subject', subject);
    if (startTime) deepLink.searchParams.append('startTime', startTime.toISOString());
    if (endTime) deepLink.searchParams.append('endTime', endTime.toISOString());
    if (content) deepLink.searchParams.append('content', content);
    if (attendeeEmails) deepLink.searchParams.append('attendees', attendeeEmails.join(','));

    return deepLink;
  }

  private openDeepLink(deepLink: URL): void {
    window.open(deepLink, '_blank', 'noopener noreferrer');
  }
}
