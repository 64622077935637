import { TreeNode } from '../route-builder';

export const SpecialPaymentModule = {
  segment: 'special-payment',
  children: {
    SpecialPaymentComponent: {
      segment: '',
      children: { SpecialPaymentProposalsComponent: { segment: 'proposals', params: ['employeeId'] } }
    }
  }
} as const satisfies TreeNode;
