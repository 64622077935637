<div class="comments-container mat-elevation-z4" [class.embedded]="embedded || inDialog" [class.in-dialog]="inDialog" cdkDrag cdkDragBoundary="body">
  <div *ngIf="!embedded && !inDialog" class="close-button" (click)="close()">
    <mat-icon>close</mat-icon>
  </div>
  <div [hidden]="embedded || inDialog" class="head" cdkDragHandle>
    <p class="bold">Comments: {{ employeeName }}</p>
  </div>
  <div class="bar-wrapper">
    <ngx-simplebar class="primary-scrollbar-vertical no-space modal-appearance" #simpleBar>
      <div class="comments">
        <coin-siemens-energy-comment *ngFor="let comment of comments" [comment]="comment" [user]="user" class="animate__animated animate__bounceInRight">
        </coin-siemens-energy-comment>
      </div>
    </ngx-simplebar>
  </div>
  <div class="add-comment">
    <input [(ngModel)]="commentText" (keyup.enter)="addComment()" placeholder="Enter comment..." />
    <mat-icon (click)="addComment()">send</mat-icon>
  </div>
</div>
