import { Component, DestroyRef, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CmsItem } from '@coin/admin/cms/util';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SelectImageComponent } from '../../shared/select-image/select-image.component';

@Component({
  selector: 'coin-a-email-main-body-image-text',
  templateUrl: './email-main-body-image-text.component.html',
  styleUrls: ['./email-main-body-image-text.component.scss']
})
export class EmailMainBodyImageTextComponent {
  @Input() item: CmsItem<'main-body-image-text'>;
  @Input() showAdditionalEditOptions = false;
  @Input() contentEdit: boolean;

  constructor(
    private dialog: MatDialog,
    private destroyRef: DestroyRef
  ) {}

  public editPicture(): void {
    this.dialog
      .open(SelectImageComponent, {})
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(newImage => {
        if (newImage) {
          this.item.content.img = newImage.imageUrl;
        }
      });
  }
}
