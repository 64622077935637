import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@coin/shared/util-environments';
import { CmsItem, DefaultColorPickerValues } from '@coin/admin/cms/util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss']
})
export class DownloadFileComponent implements OnInit {
  @Input() item: CmsItem<'download-file'> = { content: [], paddingTop: 0, paddingBottom: 0, backgroundColor: '', type: 'download-file' };
  @Input() contentEdit = false;
  @Input() isContentPage = false;
  @Input() showAdditionalEditOptions = false;

  public amountLinks = 1;
  public imagePath = environment.coinImagePath;

  public amountOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;

  ngOnInit(): void {
    this.item ??= {} as CmsItem<'download-file'>;
    this.amountLinks = this.item.content.length;
    this.setDefaultBackgroundColor();
  }

  private setDefaultBackgroundColor(): void {
    this.item.backgroundColor = this.item.backgroundColor || DefaultColorPickerValues.backgroundColor;
  }

  public amountChange(value: number): void {
    if (value > this.item.content.length) {
      for (let i = this.item.content.length; i < value; i++) {
        this.item.content.push({ text: 'Download Link Text', link: 'https://www.google.de' });
      }
    } else if (value < this.item.content.length) {
      this.item.content = this.item.content.slice(0, value);
    }
  }
}
