<div *ngIf="card" class="container" [class.small]="small">
  <div class="top" [ngStyle]="{ 'background-image': 'url(' + card?.backgroundUrl + ')', width: modifiedWidth ? 'calc(100% - 28px)' : '100%' }">
    <h4 *ngIf="!contentEdit" [innerHTML]="card.headline"></h4>
    <h4 *ngIf="contentEdit" [contentEditable]="contentEdit" [textContent]="card?.headline" (input)="card.headline = $event.target.textContent"></h4>
    <div class="icon-wrapper" *ngIf="card?.icon">
      <div class="icon" [ngStyle]="{ 'background-image': 'url(' + iconUrl + ')' }"></div>
    </div>
    <coin-action-button (click)="editPicture(card)" class="secondary cms-card-change-picture" svgIcon="edit" [noicon]="true" *ngIf="contentEdit">{{
      'cms.content-pages.change-image-button' | translate
    }}</coin-action-button>
  </div>
  <div class="bottom">
    <p *ngIf="!contentEdit" [innerHTML]="card?.text"></p>
    <p *ngIf="contentEdit" [contentEditable]="contentEdit" [textContent]="card?.text" (input)="card.text = $event.target.textContent"></p>
    <coin-action-button *ngIf="!contentEdit && card?.button.name && card.button.url" class="secondary" (click)="onButtonClick(card.button)">{{
      card.button?.name
    }}</coin-action-button>
    <coin-action-button *ngIf="contentEdit && card?.button">
      <span class="secondary cursor-text" [contentEditable]="contentEdit" [textContent]="card?.button.name" (input)="card.button.name = $event.target.textContent"></span>
    </coin-action-button>

    <div class="flex-one" *ngIf="contentEdit"></div>
    <mat-form-field class="cms-card-edit-icon" *ngIf="contentEdit">
      <mat-label>{{ 'cms.content-pages.button-link' | translate }}</mat-label>
      <input matInput aria-label="icon" [(ngModel)]="card.button.url" [placeholder]="'https://...'" type="test" />
    </mat-form-field>
  </div>
</div>
