/**
 * StandingPositionEvaluationAPI
 * API for standing position evaluation module.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dirk.ehrenschwender@nerdware.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Open RequestedHR RequestedCB Approved Rejected
 */
export type ValidationState = 'Open' | 'RequestedHR' | 'RequestedCB' | 'Approved' | 'Rejected';

export const ValidationState = {
    Open: 'Open' as ValidationState,
    RequestedHr: 'RequestedHR' as ValidationState,
    RequestedCb: 'RequestedCB' as ValidationState,
    Approved: 'Approved' as ValidationState,
    Rejected: 'Rejected' as ValidationState
};

