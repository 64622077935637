import { TreeNode } from '../route-builder';

export const TemplateLibraryModule = {
  segment: 'templates-library',
  title: 'home.templatesLibrary',
  navigationRoot: true,
  children: {
    TemplateLibraryLettersComponent: {
      segment: 'letters',
      title: 'templates-library.letters.title',
      icon: 'list'
    }
  }
} as const satisfies TreeNode;
