import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BeginTourComponent } from './dialogs/begin-tour/begin-tour.component';
import { G2mTourComponent } from './dialogs/g2m-tour/g2m-tour.component';

@Component({
  selector: 'app-first-visit-experience',
  templateUrl: './first-visit-experience.component.html',
  styleUrls: ['./first-visit-experience.component.scss']
})
export class FirstVisitExperienceComponent implements OnInit {
  @Input() G2M = false;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.G2M) {
      this.dialog.open(G2mTourComponent, {
        backdropClass: 'backdrop-hide-remove',
        maxHeight: '90vh',
        maxWidth: '90vw',
        panelClass: ['close-icon-and-transition', 'mat-mdc-dialog-no-background'],
        disableClose: true
      });
    } else {
      this.dialog.open(BeginTourComponent, {
        backdropClass: 'backdrop-hide-remove',
        maxHeight: '90vh',
        maxWidth: '90vw',
        panelClass: ['close-icon-and-transition', 'mat-mdc-dialog-no-background'],
        disableClose: true
      });
    }
  }
}
