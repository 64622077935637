import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GeneralUserService } from '@coin/modules/auth/data-access';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, of, shareReplay } from 'rxjs';
import { CoinUser, Employee, Permission } from '@coin/shared/util-models';
import { LoadLoggedInUser, LoadLoggedInUserSuccess, LoadUser, LoadUserSuccess, ResetUser, SetDynamicMercerAccess } from '@coin/modules/auth/util';

export interface UserStateModel {
  user: CoinUser; // can be the emulated user - otherwise identical with loggedInUser
  loggedInUser: CoinUser; // static - always the real user
  token: string;
  directs: Employee[];
  isDynamicMercerAccessAllowed: boolean;
}

@State<UserStateModel>({
  name: 'userState',
  defaults: {
    token: '',
    user: null,
    loggedInUser: null,
    directs: [],
    isDynamicMercerAccessAllowed: false
  }
})
@Injectable({ providedIn: 'root' })
export class UserState {
  constructor(
    private userService: GeneralUserService,
    private store: Store
  ) {}

  public currentUserDetails$ = this.store.select(UserState.user).pipe(
    switchMap(user => this.userService.getEmployeeDetailsByGid(user.gid)),
    catchError(() => of(null)),
    shareReplay({ bufferSize: 0, refCount: false })
  );

  @Selector()
  static user(state: UserStateModel): CoinUser {
    return state.user;
  }

  @Selector()
  static loggedInUser(state: UserStateModel): CoinUser {
    return state.loggedInUser;
  }

  @Selector()
  static token(state: UserStateModel): string {
    return state.token;
  }

  @Selector()
  static directs(state: UserStateModel): Employee[] {
    return state.directs;
  }

  @Selector()
  static allPermissions(state: UserStateModel): Permission[] {
    return state.user.roles.reduce((acc, role) => [...acc, ...role.permissions], []);
  }

  @Selector()
  static allPermissionsLoggedInUser(state: UserStateModel): Permission[] {
    return state.loggedInUser.roles.reduce((acc, role) => [...acc, ...role.permissions], []);
  }

  @Selector()
  static isDynamicMercerAccessAllowed(state: UserStateModel): boolean {
    return state.isDynamicMercerAccessAllowed;
  }

  @Action(LoadUser)
  loadUser(ctx: StateContext<UserStateModel>): Observable<void> {
    if (ctx.getState().user?.gid) {
      return;
    }

    return this.userService.getUserByToken().pipe(switchMap(user => ctx.dispatch(new LoadUserSuccess(user))));
  }

  @Action(LoadLoggedInUser)
  loadLoggedInUser(ctx: StateContext<UserStateModel>): Observable<void> {
    if (ctx.getState().loggedInUser?.gid) {
      return;
    }

    return this.userService.getUserByToken(true).pipe(switchMap(user => ctx.dispatch(new LoadLoggedInUserSuccess(user))));
  }

  @Action(LoadUserSuccess)
  loadUserSuccess({ patchState }: StateContext<UserStateModel>, { payload }: LoadUserSuccess): void {
    patchState({ token: payload.token });
    patchState({ user: payload.principal });
  }

  @Action(LoadLoggedInUserSuccess)
  loadLoggedInUserSuccess({ patchState }: StateContext<UserStateModel>, { payload }: LoadLoggedInUserSuccess): void {
    patchState({ loggedInUser: payload.principal });
  }

  @Action(SetDynamicMercerAccess)
  setDynamicMercerAccess({ patchState }: StateContext<UserStateModel>, { hasAccess }: SetDynamicMercerAccess): void {
    patchState({ isDynamicMercerAccessAllowed: hasAccess });
  }

  @Action(ResetUser)
  resetUser(ctx: StateContext<UserStateModel>): void {
    ctx.setState({
      token: '',
      user: null,
      loggedInUser: null,
      directs: [],
      isDynamicMercerAccessAllowed: false
    });
  }
}
