import { PermissionResource } from '@coin/shared/util-enums';
import { TreeNode } from '../route-builder';

export const MasterDataModule = {
  segment: 'master-data',
  title: 'home.masterData',
  navigationRoot: true,
  emptyPage: true,
  children: {
    ClusterMgmtComponent: {
      segment: 'org-geo-cluster',
      title: 'master-data.org-geo-cluster-headline',
      icon: 'settings_applications',
      permissions: [PermissionResource.GeneralCluster]
    },
    MercerDataMgmtComponent: {
      segment: 'mercer-data',
      title: 'master-data.mercer-data-headline',
      icon: 'schema',
      permissions: [PermissionResource.Mercer],
      children: {
        MercerDataDetailsComponent: { title: 'Details', segment: 'details' }
      }
    },
    MeritWorkflowsComponent: {
      segment: 'merit-workflows',
      title: 'master-data.merit-workflows-headline',
      icon: 'monetization_on',
      permissions: [PermissionResource.All]
    },
    CommercialRoundingComponent: {
      segment: 'commercial-rounding',
      title: 'master-data.commercial-rounding-headline',
      icon: 'toll',
      permissions: [PermissionResource.All]
    },
    MeritBenchmarkSettingsComponent: {
      segment: 'merit-benchmark-settings',
      title: 'master-data.merit-benchmark-settings-headline',
      icon: 'leaderboard',
      permissions: [PermissionResource.All]
    },
    OrganisationInformationComponent: {
      segment: 'organisation-information',
      title: 'master-data.organisation-information-headline',
      icon: 'account_tree',
      permissions: [PermissionResource.All]
    },
    CandidatePoolComponent: {
      segment: 'candidate-pool',
      title: 'master-data.candidate-pool-headline',
      icon: 'person_search',
      permissions: [PermissionResource.All]
    },
    HeadcountSettingsComponent: {
      segment: 'headcount-admin-settings',
      title: 'master-data.headcount-settings.headline',
      icon: 'badge',
      permissions: [PermissionResource.HeadcountApprovalAdmin]
    },
    EmployeeSecuritySettingsComponent: {
      segment: 'employee-security-settings',
      title: 'master-data.employee-security-settings-headline',
      icon: 'admin_panel_settings',
      permissions: [PermissionResource.All]
    }
  }
} as const satisfies TreeNode;
