import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'coin-v2-card',
  standalone: true,
  templateUrl: './v2-card.component.html',
  styleUrls: ['./v2-card.component.scss']
})
export class V2CardComponent {
  @Input() padding: CardPadding = 'medium';
  @Input() rounding: CardRounding = 'small';
  @Input() shadow: CardShadow = 'medium';

  @HostBinding('class')
  private get class(): string {
    const cardShadows = { none: '', medium: 'mat-elevation-z3' } satisfies Record<CardShadow, string>;

    return `${cardShadows[this.shadow]} padding-${this.padding} rounding-${this.rounding}`;
  }
}

export const CARD_PADDINGS = ['none', 'small', 'medium', 'large'] as const;
export type CardPadding = (typeof CARD_PADDINGS)[number];

export const CARD_ROUNDINGS = ['small', 'medium', 'large'] as const;
export type CardRounding = (typeof CARD_ROUNDINGS)[number];

export const CARD_SHADOWS = ['none', 'medium'] as const;
export type CardShadow = (typeof CARD_SHADOWS)[number];
