import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'coin-v2-dialog-frame',
  templateUrl: './v2-dialog-frame.component.html',
  styleUrls: ['./v2-dialog-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2DialogFrameComponent {
  @Input({ required: true }) header: string | TemplateRef<unknown>;

  @Output() closeButtonClick = new EventEmitter<void>();

  protected get showCloseButton(): boolean {
    return this.closeButtonClick.observed;
  }

  protected get isCustomHeader(): boolean {
    return this.header instanceof TemplateRef;
  }
}
