/**
 * SuccessionManagementAPI
 * API for succession management module.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: andre.weinkoetz@siemens-energy.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmployeeLight } from './employee-light.model';


/**
 * Approval model for every step of the approval processes
 */
export interface TalentRecommendationApproval { 
    id: string;
    stepName?: string;
    talentRecommendationId?: string;
    acknowledgedById?: string;
    acknowledgedBy?: EmployeeLight;
    acknowledgedAt?: string;
    state: TalentRecommendationApproval.StateEnum;
    reason?: string;
    isRequired: boolean;
    createdById?: string;
    createdAt?: string;
    updatedById?: string;
    updatedAt?: string;
}
export namespace TalentRecommendationApproval {
    export type StateEnum = 'Open' | 'Pending' | 'Rejected' | 'Approved';
    export const StateEnum = {
        Open: 'Open' as StateEnum,
        Pending: 'Pending' as StateEnum,
        Rejected: 'Rejected' as StateEnum,
        Approved: 'Approved' as StateEnum
    };
}


