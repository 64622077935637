import { TreeNode } from '../route-builder';

export const EmploymentConditionLibraryModule = {
  segment: 'ecl',
  children: {
    EmploymentConditionLibraryComponent: {
      segment: '',
      children: {
        EmploymentConditionLibraryOverviewComponent: { segment: 'overview' },
        EmploymentConditionLibraryTemplatesComponent: { segment: 'templates', params: ['filters'] },
        EmploymentConditionLibraryClausesComponent: { segment: 'clauses', params: ['filters'] },
        EmploymentConditionLibraryDocumentGeneratorComponent: { segment: 'document-generator', params: ['filters'] }
      }
    }
  }
} as const satisfies TreeNode;
