import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { ChangeDetectorRef, Directive, inject, Injector, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Fn } from '@coin/shared/util-models';
import { map, Observable, of, startWith } from 'rxjs';
import { errorsToText } from './error-to-text.helper';

@Directive() // only to enable lifecycle compatibility
export abstract class DefaultControlValueAccessor<T> implements ControlValueAccessor, OnInit {
  protected cd = inject(ChangeDetectorRef);
  protected injector = inject(Injector);
  protected ngControl: NgControl | null;
  protected translateService = inject(TranslateService);

  public errorTooltip$: Observable<string> = of(null);

  public ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);

    if (this.ngControl?.control) {
      this.errorTooltip$ = this.ngControl.statusChanges.pipe(
        startWith(null),
        map(() => {
          if (!this.ngControl.errors) return null;
          return errorsToText(this.ngControl.errors)
            .map(error => this.translateService.instant(error))
            .join('\n\n');
        })
      );
    }
  }

  // for manual (via ui) value changes
  get value(): T {
    return this._value;
  }
  set value(value: T) {
    this._value = value;
    this.onChange(value);
  }
  protected _value: T;

  protected get isRequired(): boolean {
    const validator = this.ngControl?.control?.validator;
    if (!validator) return false;

    return !!validator({} as AbstractControl)?.required;
  }

  // for programmatic value changes
  writeValue(value: T) {
    this._value = value;
    this.cd.markForCheck();
  }

  onChange: Fn = () => {};
  registerOnChange(fn: Fn) {
    this.onChange = fn;
  }

  onTouch: Fn = () => {};
  registerOnTouched(fn: Fn) {
    this.onTouch = fn;
  }

  public disabled = false;
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
