import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { PermissionResource } from '@coin/shared/util-enums';
import { UserState } from './user.state';

@Injectable({
  providedIn: 'root'
})
export class MercerGuard {
  constructor(
    private router: Router,
    private store: Store,
    private ngZone: NgZone,
    private toast: ToastrService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const isDynamicMercerAccessAllowed = this.store.selectSnapshot(UserState?.isDynamicMercerAccessAllowed);
    const permissions = !route.data?.useLoggedInUser ? this.store.selectSnapshot(UserState?.allPermissions) : this.store.selectSnapshot(UserState?.allPermissionsLoggedInUser);

    const isSuperAdmin = permissions?.some(permission => permission.resource === PermissionResource.All);
    if (isDynamicMercerAccessAllowed || isSuperAdmin) {
      return true;
    }
    this.toast.error('Not authorized.');
    return this.ngZone.run(() => this.router.navigate(['']));
  }
}
