import { environment } from '@coin/shared/util-environments';
import { RouteBuilder, RouteSelector, RouteTree, TypedParams } from '../route-builder';
import { LoginModule } from './login-module';
import { LogoutModule } from './logout-module';
import { HomeModule } from './home-module';
import { OrgManagementModule } from './org-management-module';
import { LegalEntitiesModule } from './legal-entities-module';

const routes = {
  LoginModule,
  LogoutModule,
  HomeModule,
  OrgManagementModule,
  LegalEntitiesModule
} satisfies RouteTree;
const host = environment.cosmosUrl;
export const Cosmos = new RouteBuilder(host, routes);
export type CosmosParams<T extends RouteSelector<(typeof Cosmos)['routes']>> = TypedParams<(typeof Cosmos)['routes'], T>;
