import { FilterOptions, FilterSetting } from '@coin/shared/util-models';

export class FilterSortHelper {
  public static getFilterQuery(filters: FilterSetting[]): string {
    let filterText = '';

    if (filters) {
      filterText += filters
        .map(filter => this.flattenValues(filter.key, filter.values))
        .filter(val => val)
        .join('');
    }
    return filterText;
  }

  private static flattenValues(key: FilterOptions, values: string[]): string {
    return values ? values.map(value => `&Query.${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('') : null;
  }
}
