import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { GlobalEventsService } from '@coin/shared/util-helpers';

@Injectable({
  providedIn: 'root'
})
export class ResponsivenessService {
  // equivalent to ui/styles/mixins widths
  public readonly breakpoints = { xxs: 750, xs: 940, s: 1024, m: 1366, l: 1920, xl: 2560 } as const;

  public screenSize$ = this.events.listen('resize').pipe(
    startWith(null),
    map(() => ({ width: window.innerWidth, height: window.innerHeight })),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  public isMobile$ = this.screenSize$.pipe(
    map(size => size.width <= this.breakpoints.xxs),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  public isSmallScreen$ = this.screenSize$.pipe(
    map(size => size.width <= this.breakpoints.s),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  public isMediumScreen$ = this.screenSize$.pipe(
    map(size => size.width <= this.breakpoints.m),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  public isMediumScreen = toSignal(this.isMediumScreen$);

  constructor(private events: GlobalEventsService) {
    this.applyClassesToBody();
  }

  private applyClassesToBody(): void {
    const bodyClassList = document.body.classList;

    this.isSmallScreen$.pipe(takeUntilDestroyed()).subscribe(isSmallScreen => {
      const className = 'small-screen';
      isSmallScreen ? bodyClassList.add(className) : bodyClassList.remove(className);
    });

    this.isMediumScreen$.pipe(takeUntilDestroyed()).subscribe(isMediumScreen => {
      const className = 'medium-screen';
      isMediumScreen ? bodyClassList.add(className) : bodyClassList.remove(className);
    });

    this.isMobile$.pipe(takeUntilDestroyed()).subscribe(isMobile => {
      const className = 'mobile-screen';
      isMobile ? bodyClassList.add(className) : bodyClassList.remove(className);
    });
  }
}
