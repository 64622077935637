import { Component, DestroyRef, Injector, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { EmployeeService, GetImageWithGidService } from '@coin/modules/auth/data-access';
import { UserState } from '@coin/modules/auth/data-management';
import { IntersectionObserverElementDirective } from '@coin/shared/util-directives';
import { UserImageStoreService } from '@coin/shared/util-helpers';
import { Employee } from '@coin/shared/util-models';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-direct-item-overview',
  templateUrl: './direct-item-overview.component.html',
  styleUrls: ['./direct-item-overview.component.scss']
})
export class DirectItemOverviewComponent implements OnInit {
  private _direct: Employee;

  @Input() set direct(direct: Employee) {
    this._direct = structuredClone(direct);
  }

  public get direct(): Employee {
    return this._direct;
  }

  hasLoadedImg = false;

  constructor(
    private router: Router,
    private store: Store,
    private employeeService: EmployeeService,
    private getUserImage: GetImageWithGidService,
    private userImageStore: UserImageStoreService,
    private injector: Injector,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.listenToVisibilityChanges();
  }

  private listenToVisibilityChanges(): void {
    try {
      const intersectionElement = this.injector.get(IntersectionObserverElementDirective);
      intersectionElement.visibilityChange
        .pipe(filter(isVisible => isVisible))
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.loadImage());
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  loadImage(): void {
    if (this.hasLoadedImg) {
      return;
    }

    this.hasLoadedImg = true;
    const storedImage = this.userImageStore.getUser(this.direct.gid);

    if (storedImage) {
      this.direct.image = storedImage === 'no-image' ? undefined : storedImage;
    } else {
      this.getUserImage
        .getImageWithStore(this.direct.gid)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(img => {
          this.direct.image = img;
        });
    }
  }

  goToDocumentCenter(): void {
    this.employeeService
      .getEmployeeDetails(this.store.selectSnapshot(UserState?.user)?.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(employee => {
        this.router.navigate(['/employee-detail-overview'], {
          queryParams: {
            receiverId: this.direct.id,
            managerId: employee?.id
          }
        });
      });
  }
}
