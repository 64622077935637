import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@coin/shared/util-environments';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LifecycleHelper, ObservableCachingHelper } from '@coin/shared/util-helpers';
import { Employee, EmployeeWithCompensation, PaginatedResult } from '@coin/shared/util-models';

import { LoadingService } from '@coin/shared/data-access';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends LifecycleHelper {
  private cachingHelper = new ObservableCachingHelper<Employee>();

  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService
  ) {
    super();
    this.addToLifecycle(this.cachingHelper);
  }

  getLineManagerDirectsForEmployee(id: string): Observable<Employee[]> {
    return this.httpClient.get<PaginatedResult<Employee>>(`${environment.api.baseUrl}/admin/api/v3/master/employees/line-managers/directs?page=1&size=1000&id=${id}`).pipe(
      map(data => data.content),
      catchError((res: HttpErrorResponse) => {
        return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }

  getInCompanyManagerDirectsForEmployee(id: string): Observable<Employee[]> {
    return this.httpClient.get<PaginatedResult<Employee>>(`${environment.api.baseUrl}/admin/api/v3/master/employees/in-company-managers/directs?page=1&size=1000&id=${id}`).pipe(
      map(data => data.content),
      catchError((res: HttpErrorResponse) => {
        return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }

  getEmployeeDetails(id: string): Observable<Employee> {
    return this.cachingHelper.cacheSetOnMiss$(
      this.httpClient.get<Employee>(`${environment.api.baseUrl}/admin/api/v3/master/employees/${id}`).pipe(
        catchError((res: HttpErrorResponse) => {
          return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
        })
      ),
      [id]
    );
  }

  getEmployeeDetailsWithCompensation(id: string): Observable<EmployeeWithCompensation> {
    return this.httpClient.get<EmployeeWithCompensation>(`${environment.api.baseUrl}/admin/api/v3/master/employees/${id}/compensation`).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }
}
