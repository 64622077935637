import { getProperty } from './configs/activity-stream-subtitle-item-type-ui-mapping.config';
import { ActivityStreamItemEventType } from './models/activity-stream-event-type.enum';
import { ActivityStreamChangedProperty, ActivityStreamItem } from './models/activity-stream-item.model';

export class ActivityStreamHelper {
  private static unformattedPropertyNames = ['EmployeeGid'];
  private static includingHtmlNames = [ActivityStreamItemEventType.ClauseTranslation, ActivityStreamItemEventType.TemplateVersionFooterTranslation];
  private static htmlProperties = ['Text', 'Body'];

  static isDisplayedUnformatted(property: ActivityStreamChangedProperty): boolean {
    return this.unformattedPropertyNames.includes(property?.propertyName);
  }

  public static hasSomePropertyChanged(item: ActivityStreamItem, ...names: string[]): boolean {
    return item.changedProperties.some(property => names.includes(property?.propertyName));
  }

  public static getProperty = getProperty;

  static isHtmlBlock(item: ActivityStreamItem, property: ActivityStreamChangedProperty): boolean {
    return this.includingHtmlNames.includes(item?.eventType) && this.htmlProperties.includes(property?.propertyName);
  }
}
