<coin-v2-dialog-frame [header]="header" (closeButtonClick)="cancel()">
  <ng-template #header>
    <div class="header">
      <h4 class="dialog-title">
        {{ 'deputy.settings' | translate }}
      </h4>
    </div>
  </ng-template>

  <div class="body-container">
    <div class="inner-content">
      <div class="title-container">
        <p class="title">{{ 'deputy.my-deputies' | translate }}</p>
      </div>
      <div class="deputy-list" [class.loading]="areCurrentDeputiesLoading">
        <p *ngIf="currentDeputies.length === 0 && !areCurrentDeputiesLoading">{{ 'deputy.no-current-deputies' | translate }}</p>
        <div
          *ngIf="!areCurrentDeputiesLoading; else loading"
          class="current-deputy-list"
          infiniteScroll
          [infiniteScrollDistance]="0.1"
          [infiniteScrollThrottle]="50"
          [scrollWindow]="false"
          (scrolled)="onScrollCurrentDeputies()">
          <div *ngFor="let deputy of currentDeputies" class="user-item">
            <coin-siemens-energy-employee-preview [employee]="deputy"></coin-siemens-energy-employee-preview>
            <div *ngIf="deputy.startsAt || deputy.endsAt" class="timeframe">
              <span class="start-label">{{ 'general.deputy-start' | translate }}: </span>
              <span class="start-date">{{ (deputy.startsAt | date) ?? '*' }}</span>
              <span class="end-label">{{ 'general.deputy-end' | translate }}: </span>
              <span class="end-date">{{ (deputy.endsAt | date) ?? '*' }}</span>
            </div>
            <mat-icon (click)="removeDeputy(deputy)">close</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="inner-content">
      <div class="title-container">
        <p class="title">{{ 'deputy.add-deputies' | translate }}</p>
        <span class="line"></span>
      </div>
      <div class="select-deputies">
        <div class="deputy-search">
          <coin-input addClass="shadow" (enterClicked)="search()" type="text" [(ngModel)]="user.gid" placeholder="Gid" label="User GID"></coin-input>
          <coin-input addClass="shadow" (enterClicked)="search()" type="text" [(ngModel)]="user.name.lastname" placeholder="Lastname" label="Lastname"></coin-input>
          <coin-input addClass="shadow" (enterClicked)="search()" type="text" [(ngModel)]="user.name.firstname" placeholder="Firstname" label="Firstname"></coin-input>
          <coin-action-button class="primary" svgIcon="create-order" (click)="search()">{{ 'general.search' | translate }} </coin-action-button>
        </div>

        <div class="deputy-list" [class.loading]="arePossibleDeputiesLoading">
          <p *ngIf="possibleDeputyEmployees.length === 0 && !arePossibleDeputiesLoading">{{ 'deputy.no-possible-deputies' | translate }}</p>
          <div
            *ngIf="!arePossibleDeputiesLoading; else loading"
            class="user-list"
            infiniteScroll
            [infiniteScrollDistance]="0"
            [infiniteScrollThrottle]="50"
            [scrollWindow]="false"
            (scrolled)="onScrollPossibleDeputies()">
            <div *ngFor="let userItem of possibleDeputyEmployees" @shrinkExpand (click)="addDeputy(userItem)" class="user-item potential-deputy">
              <coin-siemens-energy-employee-preview [employee]="userItem"></coin-siemens-energy-employee-preview>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #loading>
      <div class="loading-container">
        <app-loading-screen [local]="true" [contrast]="true"></app-loading-screen>
      </div>
    </ng-template>
  </div>
</coin-v2-dialog-frame>
