import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '@coin/modules/auth/data-access';
import { EquityPageComponentModel } from '../../models/equity-page-component.model';

@Component({
  selector: 'app-equity-media',
  templateUrl: './equity-media.component.html',
  styleUrls: ['./equity-media.component.scss']
})
export class EquityMediaComponent implements OnInit, EquityPageComponentModel {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() data: any;
  @HostBinding('class.contrast') contrast: boolean;

  constructor(
    private domSanitizer: DomSanitizer,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.contrast = this.data?.options?.contrast;
  }

  login(): void {
    this.authService.login();
  }

  getSanitizedUrl(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
