import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CmsItem, TransformEmailHtmlHelper } from '@coin/admin/cms/util';

interface SelectionItem {
  contentHtml: SafeHtml;
  id: string;
  type: string;
}

@Component({
  selector: 'coin-a-email-index-list-selection',
  templateUrl: './email-index-list-selection.component.html',
  styleUrls: ['./email-index-list-selection.component.scss']
})
export class EmailIndexListSelectionComponent implements OnInit {
  public selectionItems: SelectionItem[] = [];
  public selectedItem: SelectionItem;

  // pure  function
  public isSelected(id: string, selectedItemReferenceIds: string[]): boolean {
    return selectedItemReferenceIds?.includes(id);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { content: CmsItem[]; selectedItemReferenceIds: string[] },
    private dialogRef: MatDialogRef<EmailIndexListSelectionComponent>,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.setupSelectionItems();
  }

  private setupSelectionItems(): void {
    if (!this.data?.content?.length) {
      return;
    }
    for (const contentItem of this.data.content) {
      let replaceTableCellHTML = TransformEmailHtmlHelper.getEmailHtmlForContentItem(contentItem);
      replaceTableCellHTML = replaceTableCellHTML.replaceAll(
        '<td style="width: 25px; vertical-align: text-top; border-top: 15px solid transparent;">',
        '<td style="width: 25px; vertical-align: text-top; border-top: 0px solid transparent;">'
      );
      this.selectionItems.push({
        id: contentItem.id,
        type: contentItem.type,
        contentHtml: this.domSanitizer.bypassSecurityTrustHtml(replaceTableCellHTML)
      });
    }
  }

  public proceed(): void {
    this.dialogRef.close(this.selectedItem);
  }

  public close(): void {
    this.dialogRef.close();
  }
}
