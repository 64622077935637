import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { FeatureFlagsService } from '@coin/shared/data-management-state';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentGuard {
  constructor(
    private router: Router,
    private featureFlagService: FeatureFlagsService
  ) {}

  canMatch(route: Route): boolean {
    const { featureFlag } = (route as ActivatedRouteSnapshot).data;
    if (!featureFlag || (featureFlag && this.featureFlagService.isActive(featureFlag))) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }
}
