/**
 * SuccessionManagementAPI
 * API for succession management module.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: andre.weinkoetz@siemens-energy.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TalentPool } from './talent-pool.model';
import { TalentRecommendationApproval } from './talent-recommendation-approval.model';
import { ReferenceTrack } from './reference-track.model';
import { EmployeeLight } from './employee-light.model';


/**
 * Model for tracking a nomination of an employee
 */
export interface TalentRecommendation { 
    id?: string;
    employeeId?: string;
    talentPoolId?: string;
    referenceTrackId?: string;
    recommendationState?: TalentRecommendation.RecommendationStateEnum;
    state?: TalentRecommendation.StateEnum;
    createdById?: string;
    createdAt?: string;
    updatedById?: string;
    updatedAt?: string;
    reasonForRecommendation?: string;
    careerAspirations?: string;
    approvals?: Array<TalentRecommendationApproval>;
    referenceTrack?: ReferenceTrack;
    talentPool?: TalentPool;
    createdBy?: EmployeeLight;
    updatedBy?: EmployeeLight;
}
export namespace TalentRecommendation {
    export type RecommendationStateEnum = 'Recommended' | 'ReviewerAccepted' | 'ReviewerDeclined' | 'Approved' | 'Declined';
    export const RecommendationStateEnum = {
        Recommended: 'Recommended' as RecommendationStateEnum,
        ReviewerAccepted: 'ReviewerAccepted' as RecommendationStateEnum,
        ReviewerDeclined: 'ReviewerDeclined' as RecommendationStateEnum,
        Approved: 'Approved' as RecommendationStateEnum,
        Declined: 'Declined' as RecommendationStateEnum
    };
    export type StateEnum = 'Open' | 'Pending' | 'Rejected' | 'Approved';
    export const StateEnum = {
        Open: 'Open' as StateEnum,
        Pending: 'Pending' as StateEnum,
        Rejected: 'Rejected' as StateEnum,
        Approved: 'Approved' as StateEnum
    };
}


