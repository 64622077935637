import { Component, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ResetCategories, TopicsState, UpdateCategories } from '../../store';
import { TopicsFilter } from '../../models/topics-filter.model';

@Component({
  selector: 'app-topic-action-bar',
  templateUrl: './topic-action-bar.component.html',
  styleUrls: ['./topic-action-bar.component.scss']
})
export class TopicActionBarComponent {
  topicsFilter$ = this.store.select<TopicsFilter>(TopicsState.topicsFilter);

  @Input() calendarModification = false;

  constructor(private store: Store) {}

  public updateVisibleTopics(type: string): void {
    this.store.dispatch(new UpdateCategories(type));

    const allOff = this.store.snapshot().topicsState.topicsFilter.categories.every(category => !category.active);

    if (allOff) {
      this.store.dispatch(new ResetCategories());
    }
  }

  public resetCategoryFilter(): void {
    this.store.dispatch(new ResetCategories());
  }
}
