<div class="expand-box-edit" *ngIf="contentEdit">
  <mat-form-field class="expand-box-input">
    <mat-label>{{ 'cms.content-pages.headline-text' | translate }}</mat-label>
    <input *ngIf="item" matInput aria-label="image headline" [(ngModel)]="item.headline" [placeholder]="'cms.content-pages.headline-text' | translate" type="text" />
  </mat-form-field>
  <mat-form-field class="expand-box-input">
    <mat-label>{{ 'cms.content-pages.icon' | translate }}</mat-label>
    <input matInput aria-label="icon" [(ngModel)]="item.icon" [placeholder]="'cms.content-pages.icon' | translate" type="text" />
  </mat-form-field>
</div>

<div class="expand-box-main">
  <div class="expand-box-item" (click)="boxExpanded = !boxExpanded">
    <div class="expand-box-item-content">
      <span class="material-icons-outlined" *ngIf="item?.icon">{{ item?.icon }}</span>
      <p>{{ item?.headline }}</p>
      <span class="material-icons-outlined">expand_more</span>
    </div>
  </div>
  <div class="expand-box-content" [ngClass]="{ 'expand-box-content--expanded': boxExpanded || contentEdit }">
    <div *ngIf="!contentEdit" [innerHTML]="item?.text"></div>
    <app-html-editor *ngIf="contentEdit" [item]="item" [contentEdit]="true" [isNews]="true"></app-html-editor>
  </div>
</div>
