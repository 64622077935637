import { trigger, transition, style, animate } from '@angular/animations';

export const shrinkExpandAnimation = trigger('shrinkExpand', [
  transition(':enter', [
    style({ height: '0px', minHeight: '0px', maxHeight: '0px', padding: '0', margin: '0', opacity: 0, overflow: 'hidden' }),
    animate('250ms', style({ height: '*', minHeight: '*', maxHeight: '200px', padding: '*', margin: '*', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ height: '*', minHeight: '*', maxHeight: '200px', padding: '*', margin: '*', opacity: 1 }),
    animate('200ms', style({ height: '0px', minHeight: '0px', maxHeight: '0px', padding: '0', margin: '0', opacity: 0, overflow: 'hidden' }))
  ])
]);
