import { environment } from '@coin/shared/util-environments';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HealthCheckService {
  constructor(private httpClient: HttpClient) {}

  ping(): Observable<void> {
    const isInMaintenance = false;
    if (isInMaintenance) {
      return throwError(null);
    }
    return this.httpClient.get<void>(`${environment.api.baseUrl}/healthcheck`);
  }
}
