<div class="container max-width" [class.wide]="data?.options?.fullWidth">
  <div class="sections" *ngIf="data?.content?.sections?.length"
    [class.one-section]="data?.content?.sections?.length === 1"
    [ngStyle]="{'grid-template-columns': gridTemplateColumns}">
    <div *ngFor="let section of data.content.sections">
      <p [class.bold]="data?.options?.isBold" [innerHTML]="section.text"></p>
      <ol *ngIf="section.oList?.length">
        <div *ngFor="let oListItem of section.oList">
          <li class="p bold">
            <p [innerHTML]="oListItem?.text"></p>
          </li>
          <div *ngIf="oListItem?.uList?.length">
            <ul>
              <li *ngFor="let uListText of oListItem.uList" class="p">
                <p [innerHTML]="uListText"></p>
              </li>
            </ul>
          </div>
          <p class="space" *ngIf="oListItem?.additionalText" [innerHTML]="oListItem.additionalText"></p>
        </div>
      </ol>
      <ul *ngIf="section.uList?.length">
        <li *ngFor="let uListText of section.uList" class="p">
          <p [innerHTML]="uListText"></p>
        </li>
      </ul>
    </div>
  </div>
  <div class="buttons" *ngIf="data?.content?.buttons?.length">
    <coin-action-button [class.primary]="!!button.url" [class.secondary]="!button.url"
      *ngFor="let button of data.content.buttons" (click)="onButtonClick(button)">
      {{button.name}}
    </coin-action-button>
  </div>
</div>