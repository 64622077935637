export enum InputTableType {
  Merit = 'Merit',
  Equity = 'Equity',
  EquitySub = 'EquitySub',
  Workflows = 'Workflows',
  BenchmarkSettings = 'BenchmarkSettings',
  CommercialRounding = 'CommercialRounding',
  OrganisationInformation = 'OrganisationInformation',
  ValidationSettings = 'Validation',
  PlacementSettings = 'Placement',
  ValidationSettingsWithCluster = 'ValidationWithCluster',
  PlacementSettingsWithCluster = 'PlacementWithCluster',
  ValidationHr = 'ValidationHr',
  PlacementHr = 'PlacementHr',
  SpecialPayment = 'SpecialPayment',
  IndividualMultiplier = 'IndividualMultiplier',
  InformationVisibility = 'InformationVisibility',
  SignatureAuthorizations = 'SignatureAuthorizations',
  EclEmployees = 'EclEmployees'
}
