<div class="container">
  <div class="close-button" (click)="closeFirstVisit()">
    <mat-icon>close</mat-icon>
  </div>
  <div class="content">
    <div class="header" cdkDrag cdkDragRootElement=".container" [cdkDragFreeDragPosition]="offset" cdkDragHandle>
      <h3 #testITEM class="fv-header-text">{{'guided-tour.top-headline' | translate}}</h3>
      <coin-simple-lang-switch></coin-simple-lang-switch>
    </div>
    <div class="special-image" [ngStyle]="{'background-image': 'url(' + specialImageData + ')' }" *ngIf="specialImageData">
    </div>
    <mat-tab-group class="invisible-tabs" [selectedIndex]="selectedTab" (selectedIndexChange)="tabChange($event)">
      <mat-tab>
        <ng-template matTabContent>
          <app-intro class="max-height-area" [maxHeight]="tourMaxHeight"></app-intro>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template matTabContent>
          <app-fv-left [image]="imageLink" [text]="text" [maxHeight]="tourMaxHeight" [needsColumn]="needsColumn">
          </app-fv-left>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template matTabContent>
          <app-fv-right [image]="imageLink" [text]="text" [maxHeight]="tourMaxHeight" [needsColumn]="needsColumn">
          </app-fv-right>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template matTabContent>
          <app-fv-left [image]="imageLink" [text]="text" [maxHeight]="tourMaxHeight" [needsColumn]="needsColumn">
          </app-fv-left>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template matTabContent>
          <app-fv-right [image]="imageLink" [text]="text" [maxHeight]="tourMaxHeight" [needsColumn]="needsColumn">
          </app-fv-right>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template matTabContent>
          <app-fv-left [image]="imageLink" [text]="text" [maxHeight]="tourMaxHeight" [needsColumn]="needsColumn">
          </app-fv-left>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template matTabContent>
          <app-fv-right [image]="imageLink" [text]="text" [maxHeight]="tourMaxHeight" [needsColumn]="needsColumn">
          </app-fv-right>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <div class="fv-buttons">
      <coin-action-button class="secondary fv-btn fv-btn--left" (click)="back()" arrowPos="left" *ngIf="selectedTab > 0">Back</coin-action-button>
      <coin-action-button class="primary fv-btn fv-btn--right" (click)="next()" *ngIf="selectedTab < 6">
        {{selectedTab > 0 ? 'Next' : 'Start Guided Tour'}}</coin-action-button>
      <coin-action-button class="primary fv-btn fv-btn--right" (click)="completeTour()" *ngIf="selectedTab === 6">
        {{'Tour completed'}}</coin-action-button>
    </div>
    <div class="fv-fake-item">
      <app-topic-item *ngIf="showFakeItem && fakeItem" @shrinkExpand class="topic-item-container fake-child"
        [item]="fakeItem"></app-topic-item>
    </div>
  </div>
</div>
<div class="new-special-overlay" *ngIf="nso"
  [ngStyle]="{'border-top-width': nso.bt, 'border-bottom-width': nso.bb, 'border-left-width': nso.bl, 'border-right-width': nso.br}">
</div>