<div>
  <ng-container *ngIf="contentEdit && item">
    <mat-checkbox class="spacing-elements" [(ngModel)]="item.fullWidth" [checked]="item.fullWidth">
      {{ 'cms.emails.full-width' | translate }}
    </mat-checkbox>
    <ng-container *ngIf="showBackgroundPicker">
      <div class="spacing-elements">
        <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
      </div>
    </ng-container>
    <coin-a-email-padding-slider [(ngModel)]="item.paddingTop" label="Space-Top"></coin-a-email-padding-slider>
    <coin-a-email-padding-slider [(ngModel)]="item.paddingBottom" label="Space-Bottom"></coin-a-email-padding-slider>
  </ng-container>

  <div *ngIf="item" class="email-btn-container">
    <mat-form-field class="flex-one" *ngIf="contentEdit">
      <mat-label>Button Text</mat-label>
      <input matInput aria-label="link text" [(ngModel)]="item.content.text" />
    </mat-form-field>
    <mat-form-field class="flex-one" *ngIf="contentEdit">
      <mat-label>Button Link</mat-label>
      <input matInput aria-label="link link" [(ngModel)]="item.content.link" type="test" placeholder="https://..." />
    </mat-form-field>
    <a *ngIf="!contentEdit" [href]="item.content.link" class="email-btn-link" [ngClass]="{ 'btn-full-width': item.fullWidth }">{{ item.content.text }} <span>&#9002;</span></a>
  </div>
</div>
