import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, DestroyRef, forwardRef, QueryList } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultControlValueAccessor } from '@coin/shared/util-helpers';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TemplateWithValueDirective } from '@coin/shared/util-directives';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'coin-radio-pill',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './radio-pill.component.html',
  styleUrl: './radio-pill.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioPillComponent),
      multi: true
    }
  ]
})
export class RadioPillComponent<T = unknown> extends DefaultControlValueAccessor<T> implements AfterViewInit {
  @ContentChildren(TemplateWithValueDirective) options: QueryList<TemplateWithValueDirective<T>>;

  constructor(private destroyRef: DestroyRef) {
    super();
  }

  public ngAfterViewInit(): void {
    this.options.changes.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.cd.markForCheck());
  }

  public selectOption(value: T): void {
    if (value === this.value) return;

    this.value = value;
    this.onTouch(value);
  }
}
