import { COUNTRIES_AFRICA } from './countries_africa';
import { COUNTRIES_AMERICA } from './countries_america';
import { COUNTRIES_ASIA } from './countries_asia';
import { COUNTRIES_EUROPE } from './countries_europe';
import { COUNTRIES_OCEANIA } from './countries_oceania';
import { LegalEntityRegion } from '../../types/legal-entity-region.model';

export const LEGAL_ENTITY_COUNTRY_TO_REGION_MAPPING = new Map<LegalEntityRegion, string[]>([
  ['africa', COUNTRIES_AFRICA],
  ['america', COUNTRIES_AMERICA],
  ['asia', COUNTRIES_ASIA],
  ['europe', COUNTRIES_EUROPE],
  ['oceania', COUNTRIES_OCEANIA]
]);
