import { ContentLanguage, NumberFormatCountryIso } from '@coin/shared/util-enums';
import { TranslateService } from '@ngx-translate/core';

export class DynamicLocaleId extends String {
  constructor(protected translate: TranslateService) {
    super();
  }

  private get locale(): string {
    return this.translate.currentLang === ContentLanguage.GERMAN ? NumberFormatCountryIso.German : NumberFormatCountryIso.English;
  }

  public toString(): string {
    return this.locale;
  }

  public valueOf(): string {
    return this.locale;
  }
}
