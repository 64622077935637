import { Rule, RuleSetV2 } from '@coin/shared/util-models';
import { TinyHelpers } from './tiny-helpers';

export class RuleEngineHelper {
  public static ruleSetV2ToDto(ruleSet: RuleSetV2): RuleSetV2 {
    if (!ruleSet) {
      return undefined;
    }

    const reqQuery = TinyHelpers.nonThrowableStructuredCloneWorkaround(ruleSet);
    reqQuery.level = undefined;
    this.transformToDtoV2(reqQuery?.rules);

    return reqQuery;
  }

  public static transformToDtoV2(rules: Rule[]): void {
    let index = 0;
    if (Array.isArray(rules)) {
      for (const item of rules) {
        item.order = index++;
        item.level = undefined;

        if (!Array.isArray(item.value) && item.value) {
          item.value = [item.value];
        } else if (!item.value) {
          item.value = [];
        }

        if (!item.value?.length && Array.isArray(item.value)) {
          item.value = [];
        }

        if (item?.rules?.length) {
          this.transformToDtoV2(item.rules);
        }
      }
    }
  }
}
