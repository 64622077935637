import { CellValue, Row, Workbook, Worksheet, WorksheetState } from 'exceljs';

export interface ReadAllRowsOptions {
  // onlyFirstColumn?: boolean;
  ignoreEmptyRows?: boolean;
  worksheet?: number; // zero based
}

export class ExcelJSHelper {
  public static readFirstColumn(file: File, options?: ReadAllRowsOptions): Promise<CellValue[]> {
    return this.readAllRows(file, options).then(rows => rows.map(row => row[0]));
  }

  public static readAllRows(file: File, options?: ReadAllRowsOptions): Promise<CellValue[][]> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      // called only on success
      const onLoad = async (): Promise<void> => {
        const rows: CellValue[][] = [];
        const workbook: Workbook = new Workbook();
        workbook.xlsx.load(reader.result as ArrayBuffer).then(
          workbook => {
            try {
              const sheetNames: string[] = workbook.worksheets.map(ws => ws.name);
              if (options?.worksheet && sheetNames.length <= options?.worksheet) return [];
              const ws: Worksheet = workbook.getWorksheet(sheetNames[options?.worksheet ?? 0]);
              ws.eachRow({ includeEmpty: !options?.ignoreEmptyRows }, function (row, rowNumber) {
                // skip header
                if (rowNumber > 1) {
                  const cellValues = ExcelJSHelper.getRowCellValues(row);
                  rows.push(cellValues.map(value => (!value ? value : structuredClone(value))));
                }
                row?.destroy();
              });
              ws?.destroy();
            } finally {
              resolve(rows);
            }
          },
          () => {
            reject();
          }
        );
      };
      // called on success or failure
      const onLoadend = (): void => {
        if (reader.error) {
          reject();
        }
        reader.removeEventListener('load', onLoad);
        reader.removeEventListener('loadend', onLoadend);
      };
      reader.addEventListener('load', onLoad);
      reader.addEventListener('loadend', onLoadend);
      reader.readAsArrayBuffer(file);
    });
  }

  private static getRowCellValues(row: Row): CellValue[] {
    const cells: CellValue[] = [];
    for (let index = 1; index <= row.cellCount; index++) {
      const cell = row.getCell(index);
      cells.push(cell?.value);
    }

    return cells;
  }

  public static setWorksheetData(
    workbook: Workbook,
    sheetName: string,
    headings: string[],
    dataList: unknown[],
    defaultColWidth = 15,
    sheetState: WorksheetState = 'visible'
  ): Worksheet {
    const worksheet: Worksheet = workbook.addWorksheet(sheetName, { state: sheetState, properties: { defaultColWidth } });
    const headerRow = worksheet.addRow(headings);
    headerRow.font = { bold: true };
    headerRow.eachCell(cell => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFDAD9D9' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    for (const data of dataList) {
      worksheet.addRow(Object.values(data));
    }
    return worksheet;
  }

  public static convertCSVToGids(file: File, encoding = 'utf-8'): Promise<string[]> {
    function convertCsvStringToGids(text: string): string[] {
      const rows = text.split('\n');
      const rowColumns = rows.map(row => row.split(','));
      const firstTrimmedColumn = rowColumns.map(row => row[0].trim());
      return firstTrimmedColumn.filter(value => !!value);
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => {
        try {
          const gids = convertCsvStringToGids(e.target.result as string);
          resolve(gids);
        } catch (e) {
          reject('Could not parse csv');
        }
      };

      reader.readAsText(file, encoding);
    });
  }

  public static getRowValues(row: CellValue[]): string[] {
    return row.map(cellValue => cellValue?.toString()?.trim() ?? '');
  }
}
