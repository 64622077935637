import { RuleEngineConditionTypeEnum, RuleEngineOperators } from '@coin/shared/util-enums';
import { EmptyRuleSetV2, Rule, RuleSetV2 } from '@coin/shared/util-models';
import { EmptyRuleSet, RuleSet } from './rule-set.model';
import { RuleEngineItemType } from './rule-engine-item-type.enum';

export class RuleEngineOperations {
  private static readonly ruleLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  public static ruleSetToDto(ruleSet: RuleSet): RuleSet {
    if (!ruleSet) {
      return undefined;
    }

    const reqQuery = structuredClone(ruleSet);
    this.dropdownsToRuleGroup(reqQuery?.rules);
    this.levelToOrder(reqQuery?.rules);

    return reqQuery;
  }

  public static dtoToRuleSet(query: RuleSet): RuleSet {
    if (query) {
      const resQuery = structuredClone(query);
      this.ruleGroupToDropdowns(resQuery?.rules);
      this.orderToLevel(resQuery?.rules);
      return resQuery;
    }
    return new EmptyRuleSet();
  }

  public static dtoToRuleSetV2(query: RuleSetV2): RuleSetV2 {
    if (query) {
      const resQuery = structuredClone(query);
      this.orderToLevel(resQuery?.rules);
      return resQuery;
    }
    return new EmptyRuleSetV2();
  }

  public static dropdownsToRuleGroup(rules: Rule[]): void {
    if (Array.isArray(rules)) {
      for (const rule of rules) {
        if (rule?.rules?.length) {
          this.dropdownsToRuleGroup(rule.rules);
        }
        if (Array.isArray(rule?.value)) {
          if (rule.value?.length > 1) {
            this.ruleToGroup(rule);
          } else if (rule.value?.length === 1) {
            [rule.value] = rule.value;
          }
        }
      }
    }
  }

  private static ruleToGroup(rule: Rule): void {
    rule.rules = (rule.value as string[]).map(value => {
      return {
        type: RuleEngineItemType.Rule,
        field: rule.field,
        operator: rule.operator,
        value
      } as Rule;
    });

    rule.condition = rule.operator === RuleEngineOperators.NotEqual ? RuleEngineConditionTypeEnum.And : RuleEngineConditionTypeEnum.Or;
    rule.field = '';
    delete rule.operator;
    delete rule.value;
  }

  public static levelToOrder(rules: Rule[]): void {
    let index = 0;
    if (Array.isArray(rules)) {
      for (const item of rules) {
        item.order = index++;
        item.level = undefined;
        if (item?.rules?.length) {
          this.levelToOrder(item.rules);
        }
      }
    }
  }

  public static isRuleSetValid(ruleSet: RuleSet): boolean {
    return this.areRulesValid(ruleSet.rules);
  }

  private static areRulesValid(rules: Rule[]): boolean {
    let valid = true;

    rules?.forEach(rule => {
      valid = valid ? !!(rules?.length || (rule.field && rule.operator)) : false;

      if (valid && rule?.rules?.length) {
        valid = this.areRulesValid(rule.rules);
      }
    });
    return valid;
  }

  public static ruleGroupToDropdowns(rules: Rule[]): void {
    if (rules) {
      for (const rule of rules) {
        if (this.groupIsDropdown(rule)) {
          this.groupToRule(rule);
        }

        if (rule?.rules?.length) {
          this.ruleGroupToDropdowns(rule.rules);
        }
      }
    }
  }

  private static groupIsDropdown(group: Rule): boolean {
    return group?.rules?.length > 1 && group?.rules?.every(rule => this.typeIsRule(rule) && group.rules[0].field === rule.field && group.rules[0].operator === rule.operator);
  }

  public static typeIsRule(rule: Rule): boolean {
    return !rule?.rules?.length && rule?.value !== undefined;
  }

  private static groupToRule(rule: Rule): void {
    rule.field = rule.rules[0].field;
    rule.operator = rule.rules[0].operator;
    rule.value = rule.rules.map(groupRule => groupRule.value as string);
    delete rule.condition;
    delete rule.rules;
  }

  public static orderToLevel(rules: Rule[], currentLevel?: string): void {
    if (rules) {
      const sortedItems = rules.sort((a, b) => a.order - b.order);
      let index = 1;
      for (const item of sortedItems) {
        if (!this.typeIsRule(item)) {
          item.level = !currentLevel ? this.ruleLetters[index++] : `${currentLevel}.${index++}`;
        }
        if (item?.rules?.length) {
          this.orderToLevel(item.rules, item.level);
        }
      }
    }
  }

  public static removeGidUploadRules(ruleSet: RuleSet): void {
    ruleSet.rules = ruleSet.rules.filter(rule => !rule.isGidUpload);
  }
}
