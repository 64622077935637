import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MercerSupportDetailsComponentTab } from '../enums/mercer-support-details-tabs.enum';
import { MercerSupportHelper } from '../helpers/mercer-support.helper';
import { MercerDialogType } from '../enums/mercer-dialog-type.enum';
import { MercerEvaluationMember, MercerEvaluationMemberDetails } from '../models/mercer-support-evaluation-member.model';

interface CurrentTabInformation {
  currentTab: MercerSupportDetailsComponentTab;
  previousTab?: MercerSupportDetailsComponentTab;
}

@Injectable()
export class MercerSupportDetailsService {
  private readonly currentTabSubject$ = new BehaviorSubject<CurrentTabInformation>(null);
  private readonly loadMemberDetailsSubject$ = new Subject<void>();
  public readonly currentTab$ = this.currentTabSubject$.asObservable();
  public readonly loadMemberDetails$ = this.loadMemberDetailsSubject$.asObservable();

  public evaluationMember?: MercerEvaluationMemberDetails;
  public dialogType: MercerDialogType;

  public get isReadOnly(): boolean {
    const { evaluationMember } = this;
    return MercerSupportHelper.isReadOnly(evaluationMember, this.dialogType, this.store) || MercerSupportHelper.isLockedNewHire(evaluationMember);
  }

  public get canLock(): boolean {
    return MercerSupportHelper.canLock(this.store, this.evaluationMember, this.dialogType);
  }

  public get canUnlock(): boolean {
    return MercerSupportHelper.canUnlock(this.store, this.evaluationMember, this.dialogType);
  }

  public get canReviewNewHire(): boolean {
    return MercerSupportHelper.canReviewNewHire(this.store, this.evaluationMember, this.dialogType);
  }

  constructor(private store: Store) {}

  public canChangeValidationManager(member: MercerEvaluationMember): boolean {
    return this.dialogType === MercerDialogType.Support && !this.isReadOnly && MercerSupportHelper.isAdmin(this.store) && !member.isHead;
  }

  public updateTabInfo(tabInfo: CurrentTabInformation): void {
    this.currentTabSubject$.next(tabInfo);
  }

  public loadMemberDetails(): void {
    this.loadMemberDetailsSubject$.next();
  }

  public lock(): Observable<unknown> {
    return MercerSupportHelper.lock(this.store, this.evaluationMember.id);
  }

  public unlock(): Observable<unknown> {
    return MercerSupportHelper.unlock(this.store, this.evaluationMember.id);
  }
}
