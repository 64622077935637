let base = 'https://preprod.coin.siemens-energy.com';
if (typeof window !== 'undefined') {
  base = window.location.origin;
}

const apiBaseUrl = `${base}/ecs`;
const customerBaseUrl = 'https://preprod.coin.siemens-energy.com';
const adminBaseUrl = 'https://admin.preprod.coin.siemens-energy.com';
const cosmosBaseUrl = 'https://cosmos.preprod.coin.siemens-energy.com';

export const environment = {
  production: true,
  coinAssetsPath: 'https://d30qz857dkw45t.cloudfront.net',
  coinImagePath: 'https://d1ltwtrr1r0hn6.cloudfront.net/',
  authentication: {
    scopes: 'openid profile email',
    issuer: 'https://login.microsoftonline.com/common/v2.0/'
  },
  customerAuthentication: {
    callbackUrl: `${customerBaseUrl}/auth/callback`,
    clientId: '13d3841f-1c57-4069-be8a-36d3f759786b'
  },
  adminAuthentication: {
    callbackUrl: `${adminBaseUrl}/auth/callback`,
    clientId: '13d3841f-1c57-4069-be8a-36d3f759786b'
  },
  cosmosAuthentication: {
    callbackUrl: `${cosmosBaseUrl}/auth/callback`,
    clientId: '13d3841f-1c57-4069-be8a-36d3f759786b'
  },
  baseButtonColorLight: false,
  api: {
    baseUrl: apiBaseUrl,
    letterServiceUrl: `${apiBaseUrl}/letterservice`,
    pdfCreationService: `${base}/api/serverless/pdf_creation_service`,
    documentsUrl: `${base}/api/serverless/document_service`,
    exportDownloadServiceUrl: `${base}/api/serverless/file_download_service`,
    imagesService: `${base}/api/serverless/image_service/`,
    cmsService: `${base}/api/serverless/cms_service/`,
    cmsServiceProd: 'https://coin.siemens-energy.com/api/serverless/cms_service/',
    cosmosSearchService: `${base}/api/serverless/search_service/`,
    storageService: `${base}/api/serverless/storage_service`
  },
  device: 'desktop',
  cmsContentProdBucketCloudfront: 'https://cms.coin.siemens-energy.com/',
  cmsContentStageBucketCloudfront: 'https://cms.preprod.coin.siemens-energy.com/',
  documentDownloadCloudfront: 'https://d3uie4sj4d4ajd.cloudfront.net/',
  type: 'Pre-prod',
  setProfilePictureEndpoint: 'https://profile.hrs.siemens.cloud/',
  adminUrl: `${adminBaseUrl}/`,
  customerUrl: `${customerBaseUrl}/`,
  cosmosUrl: `${cosmosBaseUrl}/`,
  icon: 'verified',
  dataImportBucket: 'experts-preprod-dataimports',
  avatureJobUrl: 'http://siemensenergy.avature.net/internalcareers/FolderDetail/',
  helpdeskUrl: 'https://siemensenergy.service-now.com/sp?id=sc_cat_item&sys_id=29cfcd841bac061080ff62406b4bcbf1&referrer=popular_items'
};
