import { trigger, transition, style, animate } from '@angular/animations';

export const fadeInAnimation = trigger('fadeIn', [
  transition(':enter', [style({ opacity: 0 }), animate('850ms ease-in-out', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('300ms ease-in-out', style({ opacity: 0 }))])
]);
export const fadeInHalfAnimation = trigger('fadeInHalf', [
  transition(':enter', [style({ opacity: 0 }), animate('350ms ease-in-out', style({ opacity: 0.5 }))]),
  transition(':leave', [style({ opacity: 0.5 }), animate('300ms ease-in-out', style({ opacity: 0 }))])
]);
