import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHelpersService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { ObservableCachingHelper } from '@coin/shared/util-helpers';
import { Employee, EmployeeLight, EmployeeWithMaritalStatus, PaginatedResult, UserAuthReq } from '@coin/shared/util-models';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin, from, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralUserService {
  private cachingHelper = new ObservableCachingHelper<Employee>();

  constructor(
    private httpClient: HttpClient,
    private toast: ToastrService,
    private authService: AuthService,
    private httpHelper: HttpHelpersService,
    private store: Store
  ) {}

  public getUserByToken(forceLoggedInUser = false): Observable<UserAuthReq> {
    return forkJoin([from(this.authService.getAccessToken()), from(this.authService.getIdToken()), from(this.authService.getEmulation())]).pipe(
      map(([accessToken, identityToken, emulationToken]) => ({
        accessToken: emulationToken && !forceLoggedInUser ? emulationToken : accessToken,
        identityToken: emulationToken && !forceLoggedInUser ? emulationToken : identityToken
      })),
      switchMap(tokens => this.httpClient.post<UserAuthReq>(`${environment.api.baseUrl}/user/info`, tokens)),
      this.httpHelper.handleError('Error retrieving user')
    );
  }

  public getEmployeeDetailsByGid(gid: string): Observable<Employee> {
    return this.httpClient.get<Employee>(`${environment.api.baseUrl}/customer/api/v1/master/employees/gid/${gid}`).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }

  public getEmployeeLightDetailsByGid(gid: string): Observable<EmployeeLight> {
    return this.httpClient.get<PaginatedResult<EmployeeLight>>(`${environment.api.baseUrl}/admin/api/v3/master/employees?Page=0&Size=1&Gid=${gid}`).pipe(
      map(result => result?.content?.[0]),
      catchError((res: HttpErrorResponse) => {
        return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }

  public getGbrEmployeeDetailsByGid(gid: string): Observable<EmployeeWithMaritalStatus> {
    return this.httpClient.get<EmployeeWithMaritalStatus>(`${environment.api.baseUrl}/customer/api/v2/master/seasons/gbr/representatives/gid/${gid}`).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }

  public getEmployeeDetails(id: string): Observable<Employee> {
    return this.cachingHelper.cacheSetOnMiss$(
      this.httpClient.get<Employee>(`${environment.api.baseUrl}/admin/api/v3/master/employees/${id}`).pipe(
        catchError((res: HttpErrorResponse) => {
          return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
        })
      ),
      [id]
    );
  }

  public searchForEmployee(search: string, page = 1): Observable<PaginatedResult<Employee>> {
    const params = new HttpParams().append('Search', search).append('Pageable.Page', page);
    return this.httpClient
      .get<PaginatedResult<Employee>>(`${environment.api.baseUrl}/admin/api/v3/master/employees/query`, { params })
      .pipe(this.httpHelper.handleError('Could not get employees'));
  }
}
