import { DisplayedCurrency, SeasonTypeEnum } from '@coin/shared/util-enums';
import { ListViewTagFilterParameter } from '@coin/shared/util-models';
import { Season } from '@coin/admin/season-mgmt/util';
import { MercerEvaluationMember, PositionSearchSlim } from '../../shared/models/mercer-support-evaluation-member.model';
import { MercerDirectReportsFilter } from '../enums';

export class LoadMercerEvaluationMembers {
  static readonly type = '[Mercer] Load Evaluation Members';
}

export class LazyLoadMercerEvaluationMembers {
  static readonly type = '[Mercer] Lazy Load Evaluation Members';
}

export class SetMercerEvaluationMember {
  static readonly type = '[Mercer] Set Mercer Evaluation Member';
  constructor(public readonly payload: MercerEvaluationMember) {}
}

export class LoadMercerSeasons {
  static readonly type = '[Mercer] Load Seasons';
  constructor(public readonly managerEndDateAt?: string) {}
}

export class SetMercerSeason {
  static readonly type = '[Mercer] Set Season';
  constructor(public readonly season: Season) {}
}

export class ResetMercerState {
  static readonly type = '[Mercer] Reset Mercer State';
}

export class SetMercerFilterParameters {
  static readonly type = '[Mercer] Set Filter Parameters';
  constructor(public readonly payload: ListViewTagFilterParameter[]) {}
}

export class AddMercerFilterParameter {
  static readonly type = '[Mercer] Add Filter Parameter';
  constructor(public readonly payload: ListViewTagFilterParameter) {}
}

export class ClearMercerFilterParameterKey {
  static readonly type = '[Mercer] Clear Filter Parameter Key';
  constructor(public readonly category: string) {}
}

export class SetEvaluationMemberBySearch {
  static readonly type = '[Mercer] Set Evaluation Member By Search';
  constructor(public readonly payload: PositionSearchSlim) {}
}

export class SetMercerCurrency {
  static readonly type = '[Mercer] Set Currency';
  constructor(public readonly currency: DisplayedCurrency) {}
}

export class LoadMercerSeasonSettings {
  static readonly type = '[Mercer] Load Season Settings';
  constructor(public readonly payload: { id: string; type: SeasonTypeEnum }) {}
}

export class SetMercerDirectReportsFilter {
  static readonly type = '[Mercer] Set Direct Reports Filter';
  constructor(public readonly payload: MercerDirectReportsFilter) {}
}
