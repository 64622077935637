export interface LanguageWithCode {
  code: string;
  name: string;
  cc: string;
}

export const LANGUAGES: LanguageWithCode[] = [
  {
    code: 'ab',
    name: 'Abkhazian',
    cc: 'ge'
  },
  {
    code: 'aa',
    name: 'Afar',
    cc: 'dj'
  },
  {
    code: 'af',
    name: 'Afrikaans',
    cc: 'za'
  },
  {
    code: 'ak',
    name: 'Akan',
    cc: 'gh'
  },
  {
    code: 'sq',
    name: 'Albanian',
    cc: 'al'
  },
  {
    code: 'am',
    name: 'Amharic',
    cc: 'et'
  },
  {
    code: 'ar',
    name: 'Arabic',
    cc: 'sa'
  },
  {
    code: 'an',
    name: 'Aragonese',
    cc: 'es'
  },
  {
    code: 'hy',
    name: 'Armenian',
    cc: 'am'
  },
  {
    code: 'as',
    name: 'Assamese',
    cc: 'in'
  },
  {
    code: 'av',
    name: 'Avaric',
    cc: 'az'
  },
  {
    code: 'ae',
    name: 'Avestan',
    cc: 'ir'
  },
  {
    code: 'ay',
    name: 'Aymara',
    cc: 'bo'
  },
  {
    code: 'az',
    name: 'Azerbaijani',
    cc: 'az'
  },
  {
    code: 'bm',
    name: 'Bambara',
    cc: 'ml'
  },
  {
    code: 'ba',
    name: 'Bashkir',
    cc: 'ru'
  },
  {
    code: 'eu',
    name: 'Basque',
    cc: 'es'
  },
  {
    code: 'be',
    name: 'Belarusian',
    cc: 'by'
  },
  {
    code: 'bn',
    name: 'Bengali',
    cc: 'bd'
  },
  {
    code: 'bh',
    name: 'Bihari languages',
    cc: 'in'
  },
  {
    code: 'bi',
    name: 'Bislama',
    cc: 'vu'
  },
  {
    code: 'bs',
    name: 'Bosnian',
    cc: 'ba'
  },
  {
    code: 'br',
    name: 'Breton',
    cc: 'fr'
  },
  {
    code: 'bg',
    name: 'Bulgarian',
    cc: 'bg'
  },
  {
    code: 'my',
    name: 'Burmese',
    cc: 'mm'
  },
  {
    code: 'ca',
    name: 'Catalan; Valencian',
    cc: 'es'
  },
  {
    code: 'km',
    name: 'Central Khmer',
    cc: 'kh'
  },
  {
    code: 'ch',
    name: 'Chamorro',
    cc: 'us'
  },
  {
    code: 'ce',
    name: 'Chechen',
    cc: 'ru'
  },
  {
    code: 'ny',
    name: 'Chichewa; Chewa; Nyanja',
    cc: 'mw'
  },
  {
    code: 'zh',
    name: 'Chinese',
    cc: 'cn'
  },
  {
    code: 'cu',
    name: 'Church Slavic',
    cc: 'bg'
  },
  {
    code: 'cv',
    name: 'Chuvash',
    cc: 'ru'
  },
  {
    code: 'kw',
    name: 'Cornish',
    cc: 'gb'
  },
  {
    code: 'co',
    name: 'Corsican',
    cc: 'fr'
  },
  {
    code: 'cr',
    name: 'Cree',
    cc: 'us'
  },
  {
    code: 'hr',
    name: 'Croatian',
    cc: 'hr'
  },
  {
    code: 'cs',
    name: 'Czech',
    cc: 'cz'
  },
  {
    code: 'da',
    name: 'Danish',
    cc: 'dk'
  },
  {
    code: 'dv',
    name: 'Divehi; Dhivehi; Maldivian',
    cc: 'mv'
  },
  {
    code: 'nl',
    name: 'Dutch; Flemish',
    cc: 'nl'
  },
  {
    code: 'dz',
    name: 'Dzongkha',
    cc: 'bt'
  },
  {
    code: 'en',
    name: 'English',
    cc: 'gb'
  },
  {
    code: 'eo',
    name: 'Esperanto',
    cc: 'un'
  },
  {
    code: 'et',
    name: 'Estonian',
    cc: 'ee'
  },
  {
    code: 'ee',
    name: 'Ewe',
    cc: 'tg'
  },
  {
    code: 'fo',
    name: 'Faroese',
    cc: 'fo'
  },
  {
    code: 'fj',
    name: 'Fijian',
    cc: 'fj'
  },
  {
    code: 'fi',
    name: 'Finnish',
    cc: 'fi'
  },
  {
    code: 'fr',
    name: 'French',
    cc: 'fr'
  },
  {
    code: 'ff',
    name: 'Fulah',
    cc: 'sn'
  },
  {
    code: 'gd',
    name: 'Gaelic; Scottish Gaelic',
    cc: 'gb'
  },
  {
    code: 'gl',
    name: 'Galician',
    cc: 'gb'
  },
  {
    code: 'lg',
    name: 'Ganda',
    cc: 'ao'
  },
  {
    code: 'ka',
    name: 'Georgian',
    cc: 'ge'
  },
  {
    code: 'de',
    name: 'German',
    cc: 'de'
  },
  {
    code: 'el',
    name: 'Greek',
    cc: 'gr'
  },
  {
    code: 'gn',
    name: 'Guarani',
    cc: 'py'
  },
  {
    code: 'gu',
    name: 'Gujarati',
    cc: 'in'
  },
  {
    code: 'ht',
    name: 'Haitian; Haitian Creole',
    cc: 'ht'
  },
  {
    code: 'ha',
    name: 'Hausa',
    cc: 'ng'
  },
  {
    code: 'he',
    name: 'Hebrew',
    cc: 'il'
  },
  {
    code: 'hz',
    name: 'Herero',
    cc: 'na'
  },
  {
    code: 'hi',
    name: 'Hindi',
    cc: 'in'
  },
  {
    code: 'ho',
    name: 'Hiri Motu',
    cc: 'pg'
  },
  {
    code: 'hu',
    name: 'Hungarian',
    cc: 'hu'
  },
  {
    code: 'is',
    name: 'Icelandic',
    cc: 'is'
  },
  {
    code: 'io',
    name: 'Ido',
    cc: 'un'
  },
  {
    code: 'ig',
    name: 'Igbo',
    cc: 'ng'
  },
  {
    code: 'id',
    name: 'Indonesian',
    cc: 'id'
  },
  {
    code: 'ia',
    name: 'Interlingua',
    cc: 'un'
  },
  {
    code: 'ie',
    name: 'Interlingue; Occidental',
    cc: 'un'
  },
  {
    code: 'iu',
    name: 'Inuktitut',
    cc: 'ca'
  },
  {
    code: 'ik',
    name: 'Inupiaq',
    cc: 'us'
  },
  {
    code: 'ga',
    name: 'Irish',
    cc: 'ie'
  },
  {
    code: 'it',
    name: 'Italian',
    cc: 'it'
  },
  {
    code: 'ja',
    name: 'Japanese',
    cc: 'jp'
  },
  {
    code: 'jv',
    name: 'Javanese',
    cc: 'id'
  },
  {
    code: 'kl',
    name: 'Kalaallisut; Greenlandic',
    cc: 'gl'
  },
  {
    code: 'kn',
    name: 'Kannada',
    cc: 'in'
  },
  {
    code: 'kr',
    name: 'Kanuri',
    cc: 'ng'
  },
  {
    code: 'ks',
    name: 'Kashmiri',
    cc: 'in'
  },
  {
    code: 'kk',
    name: 'Kazakh',
    cc: 'kz'
  },
  {
    code: 'ki',
    name: 'Kikuyu; Gikuyu',
    cc: 'ke'
  },
  {
    code: 'rw',
    name: 'Kinyarwanda',
    cc: 'rw'
  },
  {
    code: 'ky',
    name: 'Kirghiz; Kyrgyz',
    cc: 'kg'
  },
  {
    code: 'kv',
    name: 'Komi',
    cc: 'ru'
  },
  {
    code: 'kg',
    name: 'Kongo',
    cc: 'cd'
  },
  {
    code: 'ko',
    name: 'Korean',
    cc: 'kr'
  },
  {
    code: 'kj',
    name: 'Kuanyama; Kwanyama',
    cc: 'ao'
  },
  {
    code: 'ku',
    name: 'Kurdish',
    cc: 'un'
  },
  {
    code: 'lo',
    name: 'Lao',
    cc: 'la'
  },
  {
    code: 'la',
    name: 'Latin',
    cc: 'va'
  },
  {
    code: 'lv',
    name: 'Latvian',
    cc: 'lv'
  },
  {
    code: 'li',
    name: 'Limburgan; Limburger; Limburgish',
    cc: 'nl'
  },
  {
    code: 'ln',
    name: 'Lingala',
    cc: 'cd'
  },
  {
    code: 'lt',
    name: 'Lithuanian',
    cc: 'lt'
  },
  {
    code: 'lu',
    name: 'Luba-Katanga',
    cc: 'cd'
  },
  {
    code: 'lb',
    name: 'Luxembourgish; Letzeburgesch',
    cc: 'lu'
  },
  {
    code: 'mk',
    name: 'Macedonian',
    cc: 'mk'
  },
  {
    code: 'mg',
    name: 'Malagasy',
    cc: 'mg'
  },
  {
    code: 'ms',
    name: 'Malay',
    cc: 'id'
  },
  {
    code: 'ml',
    name: 'Malayalam',
    cc: 'in'
  },
  {
    code: 'mt',
    name: 'Maltese',
    cc: 'mt'
  },
  {
    code: 'gv',
    name: 'Manx',
    cc: 'gb'
  },
  {
    code: 'mi',
    name: 'Maori',
    cc: 'nz'
  },
  {
    code: 'mr',
    name: 'Marathi',
    cc: 'in'
  },
  {
    code: 'mh',
    name: 'Marshallese',
    cc: 'mh'
  },
  {
    code: 'mn',
    name: 'Mongolian',
    cc: 'mn'
  },
  {
    code: 'na',
    name: 'Nauru',
    cc: 'nr'
  },
  {
    code: 'nv',
    name: 'Navajo; Navaho',
    cc: 'mx'
  },
  {
    code: 'nd',
    name: 'Ndebele, North; North Ndebele',
    cc: 'za'
  },
  {
    code: 'nr',
    name: 'Ndebele, South; South Ndebele',
    cc: 'za'
  },
  {
    code: 'ng',
    name: 'Ndonga',
    cc: 'na'
  },
  {
    code: 'ne',
    name: 'Nepali',
    cc: 'np'
  },
  {
    code: 'se',
    name: 'Northern Sami',
    cc: 'no'
  },
  {
    code: 'no',
    name: 'Norwegian',
    cc: 'no'
  },
  {
    code: 'nb',
    name: 'Norwegian Bokmål',
    cc: 'no'
  },
  {
    code: 'nn',
    name: 'Norwegian Nynorsk',
    cc: 'no'
  },
  {
    code: 'oc',
    name: 'Occitan (post 1500)',
    cc: 'es'
  },
  {
    code: 'oj',
    name: 'Ojibwa',
    cc: 'ca'
  },
  {
    code: 'or',
    name: 'Oriya',
    cc: 'in'
  },
  {
    code: 'om',
    name: 'Oromo',
    cc: 'et'
  },
  {
    code: 'os',
    name: 'Ossetian; Ossetic',
    cc: 'ru'
  },
  {
    code: 'pi',
    name: 'Pali',
    cc: 'in'
  },
  {
    code: 'pa',
    name: 'Panjabi; Punjabi',
    cc: 'in'
  },
  {
    code: 'fa',
    name: 'Persian',
    cc: 'ir'
  },
  {
    code: 'pl',
    name: 'Polish',
    cc: 'pl'
  },
  {
    code: 'pt',
    name: 'Portuguese',
    cc: 'pt'
  },
  {
    code: 'ps',
    name: 'Pushto; Pashto',
    cc: 'af'
  },
  {
    code: 'qu',
    name: 'Quechua',
    cc: 'pe'
  },
  {
    code: 'ro',
    name: 'Romanian; Moldavian; Moldovan',
    cc: 'ro'
  },
  {
    code: 'rm',
    name: 'Romansh',
    cc: 'ch'
  },
  {
    code: 'rn',
    name: 'Rundi',
    cc: 'bi'
  },
  {
    code: 'ru',
    name: 'Russian',
    cc: 'ru'
  },
  {
    code: 'sm',
    name: 'Samoan',
    cc: 'ws'
  },
  {
    code: 'sg',
    name: 'Sango',
    cc: 'cf'
  },
  {
    code: 'sa',
    name: 'Sanskrit',
    cc: 'un'
  },
  {
    code: 'sc',
    name: 'Sardinian',
    cc: 'it'
  },
  {
    code: 'sr',
    name: 'Serbian',
    cc: 'rs'
  },
  {
    code: 'sn',
    name: 'Shona',
    cc: 'za'
  },
  {
    code: 'ii',
    name: 'Sichuan Yi; Nuosu',
    cc: 'cn'
  },
  {
    code: 'sd',
    name: 'Sindhi',
    cc: 'pk'
  },
  {
    code: 'si',
    name: 'Sinhala; Sinhalese',
    cc: 'lk'
  },
  {
    code: 'sk',
    name: 'Slovak',
    cc: 'sk'
  },
  {
    code: 'sl',
    name: 'Slovenian',
    cc: 'si'
  },
  {
    code: 'so',
    name: 'Somali',
    cc: 'so'
  },
  {
    code: 'st',
    name: 'Sotho, Southern',
    cc: 'ls'
  },
  {
    code: 'es',
    name: 'Spanish',
    cc: 'es'
  },
  {
    code: 'su',
    name: 'Sundanese',
    cc: 'id'
  },
  {
    code: 'sw',
    name: 'Swahili',
    cc: 'ke'
  },
  {
    code: 'ss',
    name: 'Swati',
    cc: 'za'
  },
  {
    code: 'sv',
    name: 'Swedish',
    cc: 'se'
  },
  {
    code: 'tl',
    name: 'Tagalog',
    cc: 'ph'
  },
  {
    code: 'ty',
    name: 'Tahitian',
    cc: 'pf'
  },
  {
    code: 'tg',
    name: 'Tajik',
    cc: 'tj'
  },
  {
    code: 'ta',
    name: 'Tamil',
    cc: 'in'
  },
  {
    code: 'tt',
    name: 'Tatar',
    cc: 'ru'
  },
  {
    code: 'te',
    name: 'Telugu',
    cc: 'in'
  },
  {
    code: 'th',
    name: 'Thai',
    cc: 'th'
  },
  {
    code: 'bo',
    name: 'Tibetan',
    cc: 'cn'
  },
  {
    code: 'ti',
    name: 'Tigrinya',
    cc: 'er'
  },
  {
    code: 'to',
    name: 'Tonga (Tonga Islands)',
    cc: 'to'
  },
  {
    code: 'ts',
    name: 'Tsonga',
    cc: 'za'
  },
  {
    code: 'tn',
    name: 'Tswana',
    cc: 'bw'
  },
  {
    code: 'tr',
    name: 'Turkish',
    cc: 'tr'
  },
  {
    code: 'tk',
    name: 'Turkmen',
    cc: 'tm'
  },
  {
    code: 'tw',
    name: 'Twi',
    cc: 'gh'
  },
  {
    code: 'ug',
    name: 'Uighur; Uyghur',
    cc: 'cn'
  },
  {
    code: 'uk',
    name: 'Ukrainian',
    cc: 'ua'
  },
  {
    code: 'ur',
    name: 'Urdu',
    cc: 'pk'
  },
  {
    code: 'uz',
    name: 'Uzbek',
    cc: 'uz'
  },
  {
    code: 've',
    name: 'Venda',
    cc: 'za'
  },
  {
    code: 'vi',
    name: 'Vietnamese',
    cc: 'vn'
  },
  {
    code: 'vo',
    name: 'Volapük',
    cc: 'un'
  },
  {
    code: 'wa',
    name: 'Walloon',
    cc: 'be'
  },
  {
    code: 'cy',
    name: 'Welsh',
    cc: 'gb'
  },
  {
    code: 'fy',
    name: 'Western Frisian',
    cc: 'nl'
  },
  {
    code: 'wo',
    name: 'Wolof',
    cc: 'sn'
  },
  {
    code: 'xh',
    name: 'Xhosa',
    cc: 'za'
  },
  {
    code: 'yi',
    name: 'Yiddish',
    cc: 'un'
  },
  {
    code: 'yo',
    name: 'Yoruba',
    cc: 'ng'
  },
  {
    code: 'za',
    name: 'Zhuang; Chuang',
    cc: 'cn'
  },
  {
    code: 'zu',
    name: 'Zulu',
    cc: 'za'
  }
];

export const LanguagesByCode = LANGUAGES.reduce<Record<string, LanguageWithCode>>((map, language) => {
  map[language.code] = language;
  return map;
}, {});
