export enum RequestSummaryCustomType {
  DETAIL_COUNTER = 'DetailCounter',
  PLANS = 'Plans',
  MOVE_ORG = 'MoveOrg',
  MOVE_POSITION = 'MovePosition',
  DELETE_ORGANISATIONS = 'DeleteOrganisations',

  EDIT_ORGANISATION = 'EditOrganisation',
  DUPLICATE_CHART = 'DuplicateChart',

  CALCULATE_ORG_GUIDELINE_VIOLATIONS = 'CalculateOrgGuidelineViolations'
}
