import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FirstVisitService {
  @Output() reopen = new EventEmitter<boolean>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<boolean>();
  firstVisitActive = false;

  public reopenGuidedTour(): void {
    this.reopen.emit(true);
  }

  public closeGuidedTour(): void {
    this.close.emit(true);
  }

  public activate(): void {
    this.firstVisitActive = true;
  }

  public deactivate(): void {
    this.firstVisitActive = false;
  }

  /// Returns true if first visit is active
  public status(): boolean {
    return this.firstVisitActive;
  }
}
