import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AsyncPipe, NgIf } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DefaultControlValueAccessor } from '@coin/shared/util-helpers';
import { MatTooltip } from '@angular/material/tooltip';
import { MatFormFieldClass } from '../../input.types';

@Component({
  selector: 'coin-v2-number-input',
  templateUrl: './v2-number-input.component.html',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, NgIf, ReactiveFormsModule, FormsModule, MatTooltip, AsyncPipe],
  styleUrls: ['./v2-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => V2NumberInputComponent),
      multi: true
    }
  ]
})
export class V2NumberInputComponent extends DefaultControlValueAccessor<number> {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() matFormFieldClass: MatFormFieldClass = 'customer-grey';
  @ViewChild('numberInput') private numberInput: ElementRef<HTMLInputElement>;

  public focus(): void {
    this.numberInput?.nativeElement?.focus();
  }
}
