import { MeritProcessType } from '../enums/merit-process-type.enum';

export class SetMeritProcessType {
  static readonly type = '[Merit Process Type] Set Type';
  constructor(public readonly payload: MeritProcessType = MeritProcessType.Merit) {}
}

export class SetMeritProcessTypeWithRouterChange {
  static readonly type = '[Merit Process Type] Set Merit Process Type With Router Change';
  constructor(public readonly payload: MeritProcessType = MeritProcessType.Merit) {}
}
