import { Component, EventEmitter, Output } from '@angular/core';
import { DirectsState } from '@coin/modules/auth/data-management';
import { environment } from '@coin/shared/util-environments';
import { S3File } from '@coin/shared/util-models';
import { Store } from '@ngxs/store';
import { DocumentsState } from '../../../document-center/store';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent {
  @Output() toggle = new EventEmitter<void>();

  documentItems$ = this.store.select<S3File<never>[]>(DocumentsState.documentItems);

  public get isLineManager(): boolean {
    return this.store.selectSnapshot(DirectsState.isLineManager);
  }

  public environment = environment;

  constructor(private store: Store) {}
}
