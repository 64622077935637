// visibility.directive.ts
import { Directive, ElementRef, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[coinVisibleOnScreen]'
})
export class CoinVisibleOnScreenDirective implements AfterViewInit, OnDestroy {
  @Output() isVisible: EventEmitter<boolean> = new EventEmitter();
  private observer: IntersectionObserver;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.observer = new IntersectionObserver(entries => {
      const [entry] = entries;
      this.isVisible.emit(entry.isIntersecting);
    });

    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
