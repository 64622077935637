import { Pipe, PipeTransform } from '@angular/core';
import { COUNTRY_CODES, CountryCode } from '@coin/shared/util-models';

const CountryMap = COUNTRY_CODES.reduce<Record<string, CountryCode>>((map, country) => {
  map[country.alpha2] = country;
  return map;
}, {});

@Pipe({
  name: 'translateCountry',
  standalone: true
})
export class TranslateCountryPipe implements PipeTransform {
  static translate(code: string): string {
    const country = CountryMap[code];
    return country?.name || code;
  }

  transform(code: string): string {
    return TranslateCountryPipe.translate(code.toUpperCase());
  }
}
