/* eslint-disable max-lines-per-function */
import { environment } from '@coin/shared/util-environments';
import { basicCmsEmail } from '../constants/basic-cms-email';
import { CmsContentItemContent } from '../models/cms-content-item.model';
import { CmsItem } from '../models/cms-item.model';
import { CmsEmailIndexListElementHelper } from './cms-email-index-list-element.helper';

export class TransformEmailHtmlHelper {
  public static readonly defaultPadding = 'padding-left: 30px; padding-right: 30px;';
  public static readonly fullWidth = 600;
  public static readonly defaultItemFullWidth = 530;
  public static readonly fontFamily = 'font-family: Arial, Calibri, sans-serif !important;';
  public static readonly headlineContainerId = 'headline-container';

  public static setupEmailHtml(emailTemplateId: string, previewCmsEmail: CmsContentItemContent): string {
    let basicEmailBody = basicCmsEmail.toString();
    const { textBody, footer } = TransformEmailHtmlHelper.tranformContentElementsToMailHtml(previewCmsEmail);

    if (previewCmsEmail.sideData['no-header-image']) {
      basicEmailBody = basicEmailBody.replace(
        ` <img alt="header-image" width="600" style="line-height: 0px; width: 600px; max-width: 600px; vertical-align: bottom;" src="$|EMAIL_IMAGE|">`,
        ''
      );
    } else {
      basicEmailBody = basicEmailBody.replace('$|EMAIL_IMAGE|', previewCmsEmail.otherImage);
    }

    const headlineBody = this.getHeadline(previewCmsEmail);
    basicEmailBody = basicEmailBody.replace('$|TEMPLATE_ID|', emailTemplateId);
    basicEmailBody = basicEmailBody.replace('$|DATE_TEXT|', previewCmsEmail.sideData.datePartText as string);
    basicEmailBody = basicEmailBody.replace('$|MAIN_HEADLINE|', headlineBody);
    basicEmailBody = basicEmailBody.replace('$|HEADLINE_CONTAINER_ID|', TransformEmailHtmlHelper.headlineContainerId);
    basicEmailBody = basicEmailBody.replace('$|EMAIL_CONTENT|', textBody);
    basicEmailBody = basicEmailBody.replace('$|FOOTER_CONTENT|', footer);
    basicEmailBody = basicEmailBody.replaceAll('<a href', '<a style="color: #5E328B;" href');

    return basicEmailBody;
  }

  // all email content elements are stored in json and therefore are being transformed to html elements which email clients support
  private static tranformContentElementsToMailHtml(previewCmsEmail: CmsContentItemContent): { textBody: string; footer: string } {
    let textBody = '';
    let footer = '';
    const footerItem = previewCmsEmail.content?.find(content => content.type === 'email-footnotes') as CmsItem<'email-footnotes'>;

    for (const item of previewCmsEmail.content) {
      textBody += this.getEmailHtmlForContentItem(item);
    }

    if (footerItem) {
      const fontSize = '9pt';
      const lineHeight = '11pt';
      footer += this.replaceTextElement(footerItem, fontSize, lineHeight);
    }

    footer += this.addFooterLegalText();
    return { textBody, footer };
  }

  public static getEmailHtmlForContentItem(item: CmsItem): string {
    if (item.type === 'editor' || item.type === 'email-headline' || item.type === 'bullet-list') {
      return this.replaceTextElement(item);
    }
    if (item.type === 'single-or-multi-image') {
      return this.transformSingleOrMultiImageComponent(item);
    }
    if (item.type === 'spacer') {
      return this.transformSpacerComponent(item);
    }
    if (item.type === 'download-file') {
      return this.transformDownloadFileComponent(item);
    }
    if (item.type === 'email-button') {
      return this.transformEmailButtonComponent(item);
    }
    if (item.type === 'email-quote') {
      return this.transformEmailQuoteComponent(item);
    }
    if (item.type === 'main-body-image-text') {
      return this.transformMainBodyImageText(item);
    }
    if (item.type === 'line') {
      return this.getLineComponentHtml(item);
    }
    if (item.type === 'index-list') {
      return CmsEmailIndexListElementHelper.getIndexListHtml(item);
    }

    return '';
  }

  private static transformSingleOrMultiImageComponent(item: CmsItem<'single-or-multi-image'>): string {
    let fileBody = `<table id="${item.id}" cellpadding="0" cellspacing="0" border="0" style="width: 100%; ${this.defaultPadding} background-color: ${
      item.backgroundColor
    };  text-align: center;"><tbody>
    ${this.getMicrosoftOutlookAnchorReferenceTag(item.id)}
    ${this.getHtmlSpacerRow(item.paddingTop)}`;
    const paddingWidth = (item.content.length - 1) * 10;
    const imageWidth = this.defaultItemFullWidth / item.content?.length - paddingWidth;

    for (const [index, image] of item.content.entries()) {
      fileBody += `
      <td style="position: relative; display: inline-block; height: ${item.height ? `${item.height}px` : 'auto'}"> 
      <img alt="content-image" src="${image.link}" width="${imageWidth}" style="${
        item.content.length === 2 && index === 1 ? 'margin-left: 10px;' : item.content.length === 3 && index === 1 ? 'margin-left: 10px; margin-right: 10px;' : ''
      } height: 100%; width: ${imageWidth}px; max-width: ${imageWidth}px;">
      ${
        image?.anchorLink
          ? `<a style="position: absolute; height: 100%; width: 100%; top: 0; left: 0;" href="${image?.anchorLink}" rel="noopener noreferrer" target="_blank"></a>`
          : ''
      }</td>`;
    }

    return `${fileBody}${this.getHtmlSpacerRow(item.paddingBottom)}</tbody></table>`;
  }

  private static transformDownloadFileComponent(item: CmsItem<'download-file'>): string {
    let fileBody = `<table id="${item.id}" cellpadding="0" cellspacing="0" border="0" style="width: 100%; ${this.defaultPadding}  background-color: ${
      item.backgroundColor
    };"><tbody>${this.getHtmlSpacerRow(item.paddingTop)} ${this.getMicrosoftOutlookAnchorReferenceTag(item.id)}<tr><td>`;
    for (const file of item.content) {
      fileBody += `
      <table
        cellpadding="0"
        cellspacing="0"
        border="0"
        style="width: 100%"
      >
        <tbody>
          <tr>
            <td>
              <tr>
                <!--[if mso]>
                  <td style="width: 25px; border-top: 5px solid transparent;">
                <![endif]-->
                <!--[if !mso]> <!---->
                  <td style="width: 25px;">
                <!-- <![endif]-->

                <!--[if mso]>
                  <img
                  style="
                    width: 18px;
                    height: 18px;
                  "
                    src="${environment.coinImagePath}coin/images/originals/download_icon_SE.png"
                  />
                <![endif]-->
                <!--[if !mso]> <!---->
                  <img
                  style="
                    display:block;
                    width: 18px;
                    height: 18px;
                    margin: auto;
                  "
                    src="${environment.coinImagePath}coin/images/originals/download_icon_SE.png"
                  />
                <!-- <![endif]-->
                </td>
                <td>
                  <a
                    style="
                      color: #5e328b;
                    "
                    href="${file.link}"
                    ><span
                      style="${this.fontFamily}"
                      >${file.text}</span
                    ></a
                  >
                </td>
              </tr>
            </td>
          </tr>
        </tbody>
      </table>`;
    }
    return `${fileBody}</td></tr> ${this.getHtmlSpacerRow(item.paddingBottom)}</tbody></table>`;
  }

  private static transformSpacerComponent(item: CmsItem<'spacer'>): string {
    return `<table height="${item.height}" id="${item.id}" cellpadding="0" cellspacing="0" border="0" style="color: ${item.textColor}; background-color: ${
      item.backgroundColor
    }; width: 100%; height: ${item.height}px; max-height: ${item.height}px; ${this.defaultPadding}"><tbody>
    ${this.getMicrosoftOutlookAnchorReferenceTag(item.id)}
    <tr>
      <td height="${item.height}" style="display: block; width: 100%; height: ${item.height}px; max-height: ${item.height}px; line-height: 2px;">&nbsp;</td></tr></tbody></table>`;
  }

  private static transformDefaultActionButton(item: CmsItem<'email-button'>): string {
    const buttonContent = `
    ${this.getMicrosoftOutlookAnchorReferenceTag(item.id)}
        <a id="${item.id}" style="display: inline-block; border-radius: 20px; color: white; background: #8A00E5; padding-left: 16px; padding-right: 16px; 
        margin-top: 6px margin-bottom: 6px padding-top: 6px padding-bottom: 6px 
        ${this.fontFamily} font-size: 11pt; color: white; font-weight: bold; mso-padding-alt: 0;
        text-decoration: none; href="${item.content.link}">
        <!--[if mso]>
        <i style="letter-spacing: 16px; mso-font-width: -100%; mso-text-raise: 11pt;">&nbsp;</i>
        <![endif]-->
        <span align="center" style="mso-text-raise: 5pt; ${this.fontFamily} font-size: 11pt; line-height: 15pt;">${item.content.text} &#9002;</span>
        <!--[if mso]>
        <i style="letter-spacing: 16px; mso-font-width: -100%; mso-text-raise: 11pt;">&nbsp;</i>
        <![endif]-->
        </a>
 `;
    return buttonContent;
  }

  private static transformEmailButtonComponent(item: CmsItem<'email-button'>): string {
    const containerTag = item.fullWidth ? 'div' : 'span';
    return `<table id="${item.id}" cellpadding="0" cellspacing="0" border="0" style="color: ${item.textColor}; ${this.defaultPadding} background-color: ${
      item.backgroundColor
    }; width: 100%;"><tbody>
    ${this.getMicrosoftOutlookAnchorReferenceTag(item.id)}
    ${this.getHtmlSpacerRow(item.paddingTop, 1)}
    <tr>
      <td>
        <${containerTag} style="width: ${item.fullWidth ? '100%' : 'auto'}; border-radius: 20px;  background: #8A00E5;  
        margin-top: 6px; margin-bottom: 6px; padding-top: 6px; padding-bottom: 6px; mso-padding-alt: 0;
        ${item.fullWidth ? 'text-align: center;' : ''}">
          <a style="color: white; font-weight: bold; text-decoration: none; ${this.fontFamily} font-size: 11pt;
          margin-left: 16px; margin-right: 16px;" href="${item.content.link}">
            <!--[if mso]>
            <i style="letter-spacing: 16px; mso-font-width: -100%; mso-text-raise: 11pt;">&nbsp;</i>
            <![endif]-->
            <span  align="center" style="mso-text-raise: 5pt; ${this.fontFamily} font-size: 11pt; line-height: 15pt;">${item.content.text} &#9002;</span>
            <!--[if mso]>
            <i style="letter-spacing: 16px; mso-font-width: -100%; mso-text-raise: 11pt;">&nbsp;</i>
            <![endif]-->
          </a>
        </${containerTag}>
      </td>
    </tr>
    ${this.getHtmlSpacerRow(item.paddingBottom, 1)}
    </tbody></table>
 `;
  }

  private static transformEmailQuoteComponent(item: CmsItem<'email-quote'>): string {
    const buttonContent = `<table id="${item.id}" cellpadding="0" cellspacing="0" border="0" style="width: 100%; color: ${item.textColor}; background-color: ${
      item.backgroundColor
    }; ${this.defaultPadding}"><tbody>
    ${this.getMicrosoftOutlookAnchorReferenceTag(item.id)}
    ${this.getHtmlSpacerRow(item.paddingTop, 3)}
    <tr>
      <td style="vertical-align: top; padding: 0">
          <div style="
            text-align: right;
            line-height: initial;
            font-size: 66px;
            ${this.fontFamily} 
            color: #5E328B;">&ldquo;</div>
      </td>
      <td style="${this.fontFamily} padding: 0">
          <p style="font-size: 11pt; text-align: center;">
            <span style="${this.fontFamily} font-size: 11pt; line-height: 15pt;">${item.content.text}</span>
          </p>
      </td>
      <td style="vertical-align: bottom; padding: 0">
        <span style="
          text-align: left;
          line-height: normal;
          font-size: 66px;
          ${this.fontFamily} 
          color: #5E328B;">„</span>
      </td>    
    </tr>
    ${this.getHtmlSpacerRow(item.paddingBottom, 3)}
    </tbody></table>
    `;
    return buttonContent;
  }

  private static transformMainBodyImageText(item: CmsItem<'main-body-image-text'>): string {
    const content = [
      `<td style="width: 33%; height: 150px;">
        <img alt="main-body-image" width="100%" style="width: 100%; height: 100%;" src="${item.content.img}" >
        ${
          item?.content?.link
            ? `<a style="position: absolute; height: 100%; width: 100%; top: 0; left: 0;" href="${item?.content?.link}" rel="noopener noreferrer" target="_blank"></a>`
            : ''
        }
      </td>`,
      `<td style="width: 2%;"></td>`,
      `<td style="width: 65%;">
          <span style="${this.fontFamily} font-size: 11pt; line-height: 15pt;">${item.content.text}</span>
      </td>`
    ];

    if (item.inverted) content.reverse();

    return `<table id="${item.id}" style="color: ${item.textColor}; background-color: ${item.backgroundColor}; ${
      this.defaultPadding
    }" border="0" width="auto" cellpadding="0" cellspacing="0" ><tbody>
    ${this.getMicrosoftOutlookAnchorReferenceTag(item.id)}
    ${this.getHtmlSpacerRow(item.paddingTop)}
    <tr>
      <td>${this.addGhostTable(content.join(''))}</td>
    </tr>
    ${this.getHtmlSpacerRow(item.paddingBottom)}
  </tbody></table>
 `;
  }

  private static replaceTextElement(item: CmsItem<'email-headline' | 'editor' | 'bullet-list' | 'email-footnotes'>, fontSize?: string, lineHeight?: string): string {
    let existingContent = item.content;

    // the quill editor removes wrapping div tags, so we enforce them here
    if (!existingContent?.startsWith('<div')) {
      existingContent = `<div>${existingContent}</div>`;
    }

    existingContent = existingContent?.replaceAll(
      '<p>',
      `<p style="margin: 0; ${item.isHighlighted ? 'text-align:center;' : ''}"><span style="${this.fontFamily} font-size: ${fontSize ?? '11pt'}; line-height: ${
        lineHeight ?? '15pt'
      };">`
    );
    existingContent = existingContent?.replaceAll('</p>', '</span></p>');
    existingContent = existingContent?.replaceAll(
      '<li>',
      `<li style="margin: 0"><span style="${this.fontFamily} font-size: ${fontSize ?? '11pt'}; line-height: ${lineHeight ?? '15pt'};">`
    );
    existingContent = existingContent?.replaceAll('</li>', '</span></li>');

    existingContent = existingContent?.replaceAll('<sup>', '<sup style="line-height: 0">');
    existingContent = existingContent?.replaceAll('<sub>', '<sub style="line-height: 0">');
    existingContent = existingContent?.replaceAll('<ul>', '<ul style="margin: 0;">');
    existingContent = existingContent?.replaceAll('<ol>', '<ol style="margin: 0;">');

    existingContent = existingContent?.replaceAll('<h2>', `<h2 style="margin: 0; font-size: 18pt; line-height: 21pt;"><span style="${this.fontFamily}">`);
    existingContent = existingContent?.replaceAll('</h2>', `</span></h2>`);
    existingContent = existingContent?.replaceAll('<h4>', `</span><h4 style="margin: 5pt 0 0; font-size: 12pt; line-height: 21pt;"><span style="${this.fontFamily}">`);
    existingContent = existingContent?.replaceAll('</h4>', '</span></h4>');

    return existingContent
      ?.replace(
        '<div',
        `<table id="${item.id}" cellpadding="0" cellspacing="0" border="0" style="${this.defaultPadding} width: 100%; color: ${item.textColor}; background-color: ${
          item.backgroundColor
        };"><tbody>
        ${this.getMicrosoftOutlookAnchorReferenceTag(item.id)}
        ${this.getHtmlSpacerRow(item.paddingTop)}
        <tr><span style="${this.fontFamily} font-size: ${fontSize ?? '11pt'}; line-height: ${lineHeight ?? '15pt'};"><td style="display: block; overflow:hidden; font-size: ${
          fontSize ?? '11pt'
        }; line-height: ${lineHeight ?? '15pt'};"><table width="100%"><td style="padding: 8px; ${item.isHighlighted ? 'border:2px solid #8A00E5; color: #8A00E5;' : ''}"`
      )
      ?.replace(
        '</div>',
        `</td></table></td></span></tr>
        ${this.getHtmlSpacerRow(item.paddingBottom)}
        </tbody></table>`
      );
  }

  private static getLineComponentHtml(item: CmsItem<'line'>): string {
    return `<table id="${item.id}" border="0" width="100%" cellpadding="0" cellspacing="0" style="color: ${item.textColor}; background-color: ${item.backgroundColor}; ${
      this.defaultPadding
    }"><tbody>
    ${this.getMicrosoftOutlookAnchorReferenceTag(item.id)}
    ${this.getHtmlSpacerRow(item.paddingTop)}
      <tr>
        <td style="background:none; border-bottom: 2px solid #DEE4EF; height:2px; width:100%; margin:0px 0px 0px 0px;">&nbsp;</td>
      </tr>
    ${this.getHtmlSpacerRow(item.paddingBottom)}
    </tbody></table>
    `;
  }

  private static getHeadline(item: CmsContentItemContent): string {
    return `${this.getHtmlSpacerRow(item.paddingTop)}<td id="${item.id}" style="${this.defaultPadding} padding-top: 20px;
    padding-bottom: 20px; display: block; background-color: ${item.sideData.backgroundColor}; color: ${item.sideData.textColor};">
                <h1 style="font-size: 24pt; margin: 0; ${this.fontFamily} font-weight: bold;"><span style="${this.fontFamily}">${item.sideData.mainHeadline ?? ''}</span></h1>
                ${this.getSubHeadline(item)}
                ${this.showDefaultActionButtonInPreview(item)}
            </td>
  ${this.getHtmlSpacerRow(item.paddingBottom)}`;
  }

  private static showDefaultActionButtonInPreview(item: CmsContentItemContent): string {
    return item.sideData.actionButtonLink || item.sideData.actionButtonText
      ? this.transformDefaultActionButton({
          type: 'email-button' as const,
          paddingTop: 10,
          paddingBottom: 10,
          content: {
            text: item.sideData.actionButtonText,
            link: item.sideData.actionButtonLink
          }
        })
      : '';
  }

  private static getSubHeadline(item: CmsContentItemContent): string {
    return item.sideData.subHeadline ? `<h1 style="font-size: 13pt; margin: 10px 0 10px; ${this.fontFamily} ">${item.sideData.subHeadline ?? ''}</h1>` : '';
  }

  private static addFooterLegalText(): string {
    return `
    <table border="0" width="100%" cellpadding="0" cellspacing="0" style="${this.defaultPadding} width: 100%;"><tbody>
     ${this.getHtmlSpacerRow(10)}
      <tr>
        <td>
          <p style="${this.fontFamily}  color: #AAA6C0; font-size: 9pt; margin: 0;"><span style="color: white; ${
            this.fontFamily
          } line-height: 11pt;">siemens-energy.com </span><span style="${this.fontFamily} line-height: 11pt;">Restricted &copy; Siemens Energy, ${new Date().getFullYear()}</span></p>
        </td>
      </tr>
    </tbody></table>`;
  }

  public static getHtmlSpacerRow(height: number | string, amountOfTableDataElements = 1): string {
    const usedHeight = typeof height === 'string' ? parseFloat(height) : height;
    const heightInPt = Math.round((usedHeight ?? 0) * 0.75);

    let tdElements = '';

    for (let i = 0; i < amountOfTableDataElements; i++) {
      tdElements += ` <td style="height: ${heightInPt}pt; line-height: 2px;">&nbsp;</td>`;
    }

    const row = usedHeight
      ? `
      <tr>
        ${tdElements}
      </tr>`
      : '';

    return row;
  }

  public static getMicrosoftOutlookAnchorReferenceTag(id: string): string {
    return `<a style="height: 0; line-height: 0;" name="${id}"></a>`;
  }

  public static addGhostTable(content: string): string {
    return `
    <!--[if mso]>
      <table cellpadding="0" cellspacing="0" border="0" style="width: 100%;">
        <tbody>
          <tr>
    <![endif]-->
            ${content}
    <!--[if mso]>
          </tr>
        </tbody>
      </table>
    <![endif]-->`;
  }
}
