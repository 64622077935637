import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpecialPaymentSeason } from '@coin/admin/season-mgmt/util';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { SeasonTypeEnum, SpecialPaymentProposalState } from '@coin/shared/util-enums';
import { environment } from '@coin/shared/util-environments';
import { BenchmarkDto, EmployeeWithCompensation, MassExportTransactionStatus, PaginatedResult, SearchItemService, TransactionStatus } from '@coin/shared/util-models';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { SpecialPaymentCompensationType } from '../enums/special-payment-compensation-type.enum';
import { SpecialPaymentCandidate } from '../models/special-payment-candidate.model';
import { SpecialPaymentDashboard } from '../models/special-payment-dashboard.model';
import { SpecialPaymentProposal } from '../models/special-payment-proposal.model';

@Injectable({
  providedIn: 'root'
})
export class SpecialPaymentService implements SearchItemService {
  constructor(
    private httpClient: HttpClient,
    private httpHelpersService: HttpHelpersService,
    private loadingService: LoadingService,
    private toastrService: ToastrService
  ) {}

  public getSeasons(compensationType: SpecialPaymentCompensationType, employeeId: string): Observable<SpecialPaymentSeason[]> {
    const seasonType = {
      [SpecialPaymentCompensationType.Cash]: SeasonTypeEnum.SpecialPaymentCash,
      [SpecialPaymentCompensationType.Equity]: SeasonTypeEnum.SpecialPaymentEquity
    };

    return this.httpClient
      .get<
        SpecialPaymentSeason[]
      >(`${environment.api.baseUrl}/admin/api/v3/master/seasons/special-payment?employeeId=${employeeId}&seasonType=${seasonType[compensationType]}&isEligible=true`)
      .pipe(
        map(seasons => seasons.map(season => ({ ...season, plan: season?.plans[0] ?? null }))), // here only one plan is returned - always the eligible
        catchError(() => of(null))
      );
  }

  public getSearchItemsByText(text: string, page: number, seasonId?: string, searchSuffix?: string): Observable<SpecialPaymentCandidate[]> {
    const encodedText = encodeURIComponent(text);
    let queryString = '';
    if (encodedText) {
      queryString = `&Query.gid=${encodedText}&Query.firstName=${encodedText}&Query.lastName=${encodedText}&Query.orgCode=${encodedText}`;
    }
    return this.httpClient
      .get<
        PaginatedResult<SpecialPaymentCandidate>
      >(`${environment.api.baseUrl}/customer/api/v1/master/special-payment/candidates?Paging.page=${page + 1}&Paging.size=50${queryString}${searchSuffix ?? ''}`)
      .pipe(
        map(result => result.content),
        this.httpHelpersService.handleError('Cannot get search item')
      );
  }

  public getEmployeeDetails(id: string): Observable<EmployeeWithCompensation> {
    return this.httpClient
      .get<EmployeeWithCompensation>(`${environment.api.baseUrl}/customer/api/v1/master/employees/${id}?seasonType=${SeasonTypeEnum.SpecialPaymentCash}`)
      .pipe(this.httpHelpersService.handleError('Cannot get employee detail data'));
  }

  public getEmployeeBenchmarks(id: string): Observable<BenchmarkDto[]> {
    return this.httpClient
      .get<BenchmarkDto[]>(`${environment.api.baseUrl}/customer/api/v1/master/employees/${id}/benchmarks?seasonType=${SeasonTypeEnum.SpecialPaymentCash}`)
      .pipe(this.httpHelpersService.handleError('Cannot get employee detail data'));
  }

  public getSpecialPaymentProposal(id: string): Observable<SpecialPaymentProposal> {
    return this.httpClient
      .get<SpecialPaymentProposal>(`${environment.api.baseUrl}/customer/api/v1/master/records/special-payment/${id}`)
      .pipe(this.httpHelpersService.handleError('Cannot load proposal'));
  }

  public getSpecialPaymentTaskProposals(
    page: number,
    size: number,
    isCurrentApprover?: boolean,
    state?: SpecialPaymentProposalState,
    filterAndSortQuery = '',
    allProposals = false
  ): Observable<PaginatedResult<SpecialPaymentProposal>> {
    let query = `?Paging.page=${page + 1}&Paging.size=${size}`;

    if (allProposals) {
      query += '&allProposals=true';
    }

    if (isCurrentApprover !== undefined) {
      query += `&Query.isCurrentApprover=${isCurrentApprover}`;
    }

    if (state) {
      query += `&Query.ProposalState=${state}`;
    }

    return this.httpClient
      .get<PaginatedResult<SpecialPaymentProposal>>(`${environment.api.baseUrl}/customer/api/v1/master/records/special-payment${query}${filterAndSortQuery}`)
      .pipe(this.httpHelpersService.handleError('Cannot load proposals'));
  }

  public approveOrReject(task: SpecialPaymentProposal, state: SpecialPaymentProposalState): Observable<SpecialPaymentProposal> {
    this.loadingService.present();
    return this.httpClient
      .post<SpecialPaymentProposal>(`${environment.api.baseUrl}/customer/api/v1/master/records/special-payment/${task.id}/acknowledgement`, {
        state
      })
      .pipe(
        tap(() => this.toastrService.success('Updated.')),
        this.httpHelpersService.handleError('Cannot approve or reject proposal'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public resetProposalRequest(proposalId: string, steps: number): Observable<SpecialPaymentProposal> {
    this.loadingService.present();
    return this.httpClient
      .post<SpecialPaymentProposal>(`${environment.api.baseUrl}/customer/api/v1/master/records/special-payment/${proposalId}/reset`, {
        steps
      })
      .pipe(
        tap(() => this.toastrService.success('Resetted proposal.')),
        this.httpHelpersService.handleError('Cannot reset proposal'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public createProposal(proposal: SpecialPaymentProposal): Observable<SpecialPaymentProposal> {
    this.loadingService.present();
    return this.httpClient.post<SpecialPaymentProposal>(`${environment.api.baseUrl}/customer/api/v1/master/records/special-payment`, proposal).pipe(
      tap(() => this.toastrService.success('Created new proposal.')),
      this.httpHelpersService.handleError('Cannot create proposal'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  public updateProposal(proposal: SpecialPaymentProposal): Observable<SpecialPaymentProposal> {
    this.loadingService.present();
    return this.httpClient.patch<SpecialPaymentProposal>(`${environment.api.baseUrl}/customer/api/v1/master/records/special-payment/${proposal.id}`, proposal).pipe(
      tap(() => this.toastrService.success('Updated proposal.')),
      this.httpHelpersService.handleError('Cannot update proposal'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  public deleteProposal(proposalId: string): Observable<void> {
    this.loadingService.present();
    return this.httpClient.delete<void>(`${environment.api.baseUrl}/customer/api/v1/master/records/special-payment/${proposalId}`).pipe(
      tap(() => this.toastrService.success('Deleted proposal.')),
      this.httpHelpersService.handleError('Cannot delete proposal'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  public getDashboard(allProposals?: boolean): Observable<SpecialPaymentDashboard> {
    let params = new HttpParams();
    if (allProposals) {
      params = params.set('allProposals', true);
    }

    this.loadingService.present();
    return this.httpClient.get<SpecialPaymentDashboard>(`${environment.api.baseUrl}/customer/api/v1/master/special-payment/dashboard`, { params }).pipe(
      this.httpHelpersService.handleError('Cannot load dashboard'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  public getFieldValues(
    page: number,
    size: number,
    key: string,
    searchText = '',
    sessionId?: string,
    parentOrgCode?: string,
    searchSuffix = ''
  ): Observable<PaginatedResult<string>> {
    const searchQuery = searchText?.trim() ? `&Query.${key}=${encodeURIComponent(searchText)}` : '';

    return this.httpClient
      .get<
        PaginatedResult<string>
      >(`${environment.api.baseUrl}/customer/api/v1/master/records/special-payment/field-values?Paging.page=${page + 1}&Paging.size=${size}&targetProperty=${key}${searchQuery}${searchSuffix}`)
      .pipe(catchError(() => of(null)));
  }

  public startExcelExport(fileName: string, filterQuery: string): Observable<TransactionStatus<MassExportTransactionStatus>> {
    return this.httpClient
      .post<TransactionStatus<MassExportTransactionStatus>>(`${environment.api.baseUrl}/customer/api/v1/master/records/special-payment/export${filterQuery}`, { fileName })
      .pipe(
        tap(() => this.toastrService.success('Exported to Excel.')),
        this.loadingService.withLoadingScreen,
        this.httpHelpersService.handleError('Cannot export to Excel')
      );
  }
}
