import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SeasonTypeEnum } from '@coin/shared/util-enums';
import { Observable } from 'rxjs';
import { environment } from '@coin/shared/util-environments';
import { finalize, map, tap } from 'rxjs/operators';
import { GenericSeasonSettings, MeritSeasonSetting, PositionEvaluationSetting, SpecialPaymentSetting } from '@coin/admin/season-mgmt/util';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';

@Injectable({
  providedIn: 'root'
})
export class SeasonSettingsService {
  constructor(
    private httpClient: HttpClient,
    private toast: ToastrService,
    private loadingService: LoadingService,
    private httpHelper: HttpHelpersService
  ) {}

  public getSeasonSettings(seasonId: string, seasonType: SeasonTypeEnum): Observable<GenericSeasonSettings> {
    return this.httpClient.get<GenericSeasonSettings>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/settings/${seasonId}`).pipe(
      map(data => (!data ? this.getSeasonSettingsEntity(seasonType, seasonId) : data)),
      this.httpHelper.handleError('Cannot get settings')
    );
  }

  public updateSeasonSettings<T = GenericSeasonSettings>(seasonSettings: T): Observable<T> {
    this.loadingService.present();
    return this.httpClient.put<T>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/settings`, seasonSettings).pipe(
      tap(() => this.toast.success('Updated Settings.')),
      this.httpHelper.handleError('Cannot update settings'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  public getSeasonSettingsEntity(seasonType: SeasonTypeEnum, seasonId: string): GenericSeasonSettings {
    switch (seasonType) {
      case SeasonTypeEnum.Merit:
        return new MeritSeasonSetting({ seasonId });
      case SeasonTypeEnum.SpecialPayment:
      case SeasonTypeEnum.SpecialPaymentCash:
      case SeasonTypeEnum.SpecialPaymentEquity:
        return new SpecialPaymentSetting({ seasonId });
      case SeasonTypeEnum.PositionEvaluation:
        return new PositionEvaluationSetting({ seasonId });
    }
  }
}
