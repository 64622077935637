/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, DestroyRef, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@coin/shared/util-environments';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, interval, Observable } from 'rxjs';
import { find, map, shareReplay, switchMap } from 'rxjs/operators';
import { SimplebarAngularComponent } from 'simplebar-angular';
import { v4 as uuid } from 'uuid';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ContentLanguage, StorageKey } from '@coin/shared/util-enums';
import { GlobalEventsService } from '@coin/shared/util-helpers';
import { ResponsivenessService } from '@coin/shared/data-management-state';
import { StorageService } from '@coin/shared/data-access';
import { AuthService } from '@coin/modules/auth/data-access';
import { CmsContentItem, CmsContentPage, CmsContentPageSettings } from '@coin/admin/cms/util';
import { EquityLegalDisclaimerComponent } from '../equity/dialogs/equity-legal-disclaimer/equity-legal-disclaimer.component';

@Component({
  selector: 'coin-siemens-energy-content-pages',
  templateUrl: './content-pages.component.html',
  styleUrls: ['./content-pages.component.scss'],
  animations: [
    trigger('animateMenu', [
      transition(':enter', [style({ opacity: '0' }), animate('200ms', style({ opacity: '1' }))]),
      transition(':leave', [style({ opacity: '1' }), animate('100ms', style({ opacity: '0' }))])
    ]),
    trigger('animateSubMenu', [
      transition(':enter', [style({ opacity: '0', transform: 'translateY(-50%)' }), animate('200ms', style({ opacity: '1', transform: 'translateY(0%)' }))]),
      transition(':leave', [style({ opacity: '1', transform: 'translateY(0%)' }), animate('200ms', style({ opacity: '0', transform: 'translateY(50%)' }))])
    ])
  ]
})
export class ContentPagesComponent implements OnInit {
  public programsMenuActive = false;
  public scrollPercentage = 0;
  public collapsedHeader$: Observable<boolean>;
  public contentHidden = false;
  private dialogOpened = false;
  public pageContent: CmsContentItem;
  public pageSettings: CmsContentPageSettings;
  public parent: CmsContentPage;
  public currentRoute: string;
  public germanyRowSlideToggle = true;
  public germanyRowType = 'row';
  public isAuthenticated = false;
  public usedLang: ContentLanguage = ContentLanguage.ENGLISH;
  private structure: Record<string, CmsContentPage>;
  private routesplit: string[];
  @ViewChild('cpmenu') cpmenu: ElementRef;
  @ViewChild(SimplebarAngularComponent) simplebarRef: SimplebarAngularComponent;

  constructor(
    private router: Router,
    public authService: AuthService,
    private http: HttpClient,
    private translate: TranslateService,
    private dialog: MatDialog,
    private storageService: StorageService,
    public responsive: ResponsivenessService,
    private events: GlobalEventsService,
    private destroyRef: DestroyRef
  ) {
    router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        if (this.currentRoute !== ev.url && this.structure) {
          this.currentRoute = ev.url;
          this.routesplit = ev.url.split('/').filter(Boolean);
          this.setupContent();
        }
      }
      // this.setupContent();
    });
  }

  public clickout = (e: MouseEvent): void => {
    if (this.cpmenu) {
      const clickedInside = this.cpmenu.nativeElement.contains(e.target);
      if (!clickedInside && this.programsMenuActive) {
        this.programsMenuActive = false;
      }
    }
  };

  public slideChange(e: MatSlideToggleChange): void {
    this.germanyRowType = e.checked ? 'row' : 'germany';
  }

  ngOnInit(): void {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    this.http
      .get(`${environment.cmsContentStageBucketCloudfront}content-pages/Published/content-pages-setup.json`, { headers, withCredentials: true })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((file: Record<string, CmsContentPage>) => {
        this.currentRoute = this.router.url;
        this.routesplit = this.currentRoute.split('/').filter(Boolean);
        const structureHolder = file;
        this.structure = {};
        for (const key in structureHolder) {
          if (Object.prototype.hasOwnProperty.call(structureHolder, key)) {
            if (structureHolder[key].settings.pageVisible) {
              this.structure[key] = structureHolder[key];
            }
          }
        }
        this.setupContent();
      });
    this.translate.onLangChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.pageContent = undefined;
      setTimeout(() => {
        this.setupContent();
      }, 100);
    });

    const getScrollElement = (): HTMLElement => this.simplebarRef?.SimpleBar?.getScrollElement();
    this.collapsedHeader$ = interval(100).pipe(
      find(() => !!getScrollElement()),
      switchMap(() => fromEvent(getScrollElement(), 'scroll')),
      map(() => getScrollElement().scrollTop > 0),
      shareReplay({ refCount: true, bufferSize: 1 }),
      takeUntilDestroyed(this.destroyRef)
    );

    this.events.listen('click').pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.clickout);
  }

  private getChildContent(element: CmsContentPage, routesplit: string[], num: number): CmsContentPage {
    for (const child of element.pages) {
      if (child.settings.routing.toLowerCase() === routesplit[num].toLowerCase()) {
        return child;
      }
    }
  }

  public menuItemClicked(event: MouseEvent, item: CmsContentPage, itemParent?: CmsContentPage): void {
    event.stopPropagation();

    if (item.overviewPage) {
      this.router.navigate([`/${this.parent.settings.routing}${itemParent ? `/${itemParent.settings.routing}` : ''}/${item.settings.routing}`]);
      this.pageContent = undefined;
      this.currentRoute = this.router.url;
      this.routesplit = this.currentRoute.split('/').filter(Boolean);
      this.setupContent();
    }
  }

  public openOverview(): void {
    this.router.navigate([`/${this.parent.settings.routing}`]);
    setTimeout(() => {
      this.pageContent = undefined;
      this.ngOnInit();
    }, 100);
  }

  public openDocuments(): void {
    this.router.navigate([`/${this.parent.settings.routing}/documents`]);
    setTimeout(() => {
      this.pageContent = undefined;
      this.ngOnInit();
    }, 100);
  }

  public getMenuActive(menu: CmsContentPage, child?: CmsContentPage): boolean {
    return this.currentRoute === `/${menu.settings.routing}/${menu.settings.routing}${child ? `/${child.settings.routing}` : ''}`;
  }

  private async setupContent(): Promise<void> {
    if (this.routesplit && this.routesplit[0] === 'equity') {
      this.openLegalDisclaimer();
    } else {
      this.contentHidden = false;
    }
    this.usedLang = this.translate.currentLang as ContentLanguage;
    for (const key in this.structure) {
      if (Object.prototype.hasOwnProperty.call(this.structure, key)) {
        const element = this.structure[key];
        if (element.settings?.routing?.toLowerCase() === this.routesplit[0]?.toLowerCase()) {
          this.parent = element;
          if (this.routesplit.length > 1) {
            if (this.routesplit[1] === 'documents') {
              this.pageContent = this.parent.documentsPage.content;
              this.pageSettings = this.parent.documentsPage.settings;
            } else {
              const foundChild = this.getChildContent(element, this.routesplit, 1);
              if (this.routesplit.length > 2) {
                const finalChild = this.getChildContent(foundChild, this.routesplit, 2);
                this.pageContent = finalChild.overviewPage.content;
                this.pageSettings = finalChild.settings;
              } else {
                this.pageContent = foundChild.overviewPage.content;
                this.pageSettings = foundChild.settings;
              }
            }
          } else {
            this.pageContent = element.overviewPage.content;
            this.pageSettings = element.settings;
          }
          break;
        }
      }
    }
    this.isAuthenticated = this.authService.isAuthenticated$.value;
    if (this.parent?.settings?.requiresAuthentication && !this.isAuthenticated) {
      this.pageContent = undefined;
      this.authService.login(this.currentRoute);
    } else {
      const authUser = await this.authService.getUser();
      if (authUser) {
        if (authUser && authUser['custom:country'] === 'DE') {
          this.germanyRowType = 'germany';
          this.germanyRowSlideToggle = false;
        }
      }
      if (!this.usedLang || !this.pageContent?.[this.usedLang]?.content?.length) {
        this.usedLang = ContentLanguage.ENGLISH;
      }
      if (this.pageContent?.[this.usedLang]?.content) {
        for (const itm of this.pageContent[this.usedLang].content) {
          if (itm.type === 'chapter') {
            itm.id = uuid();
          }
        }
      }
    }
  }

  private async openLegalDisclaimer(): Promise<void> {
    const oneHourInMilliSeconds = 3600000;
    const lsTime = await this.storageService.getAsync(StorageKey.LS_TIME);
    const lsConfirmed = await this.storageService.getAsync(StorageKey.LS_CONFIRMED);

    const confirmedOutdated: boolean = lsTime ? Date.now() > parseInt(lsTime, 10) + oneHourInMilliSeconds : true;
    if ((confirmedOutdated || !lsConfirmed || lsConfirmed === 'false') && !this.dialogOpened) {
      this.contentHidden = true;
      this.dialogOpened = true;
      this.dialog
        .open(EquityLegalDisclaimerComponent, { width: '880px', disableClose: true, position: { top: '70px' } })
        .afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(async confirm => {
          if (confirm) {
            this.contentHidden = false;
            await this.storageService.setAsync(StorageKey.LS_CONFIRMED, 'true');
            await this.storageService.setAsync(StorageKey.LS_TIME, Date.now().toString());
            this.setupContent();
          } else {
            this.contentHidden = true;
          }
          this.dialogOpened = false;
        });
    }
  }

  public toggleProgramsMenu(): void {
    this.programsMenuActive = !this.programsMenuActive;
  }
}
