import { HttpParams } from '@angular/common/http';
import { isDevMode } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { PermissionResource } from '@coin/shared/util-enums';
import { FeatureFlagName, Override } from '@coin/shared/util-models';

export type TreeNode = {
  segment: string;
  title?: string | { [key: string]: { [key: string]: Readonly<string> } };
  disabledTooltip?: string;
  paramTitle?: string;
  isVisible?: { [key: string]: Readonly<string[]> };
  params?: Readonly<string[]> & Readonly<[string, ...string[]]>;
  children?: RouteTree;
  navigationRoot?: boolean;
  emptyPage?: boolean;
  hiddenNavigation?: boolean;
  tooltip?: string;
  icon?: string;
  preserveParams?: readonly string[];
  permissions?: readonly PermissionResource[];
  controlledByFeatureFlag?: FeatureFlagName;
};
type TreeNodeName = `${string}Component` | `${string}Module`;
export type RouteTree = Record<TreeNodeName, TreeNode>;
export type Breadcrumb = {
  url: string;
  title: string;
  paramTitle: string;
  params: Readonly<string[]> & Readonly<[string, ...string[]]>;
  emptyPage: boolean;
};
export type NavigationLink = {
  url: string;
  title: string;
  hasChilds: boolean;
  icon: string;
  tooltip?: string;
  disabledTooltip?: string;
  preserveParams?: Readonly<string[]> & Readonly<[string, ...string[]]>;
  permissions?: readonly PermissionResource[];
  controlledByFeatureFlag?: FeatureFlagName;
};
// Recursion Limit of 5, raise as needed
type Decr = [never, 0, 1, 2, 3, 4, 5];
export type RouteSelector<T extends RouteTree> = RouteSelectorRec<T, 5> & TreeNodeName;
type RouteSelectorRec<T extends RouteTree, Depth> = Depth extends number
  ?
      | keyof T
      | {
          [key in keyof T]: T[key] extends {
            children: RouteTree;
          }
            ? RouteSelectorRec<T[key]['children'], Decr[Depth]>
            : never;
        }[keyof T]
  : never;
type FindNode<T extends RouteTree, S extends RouteSelector<T>> = FindNodeRec<T, S, 5>;
type FindNodeRec<T extends RouteTree, S extends RouteSelector<T>, Depth> = Depth extends number
  ? {
      [Key in keyof T]: Key extends S
        ? T[Key]
        : T[Key] extends {
              children: RouteTree;
            }
          ? FindNodeRec<T[Key]['children'], S, Decr[Depth]>
          : never;
    }[keyof T]
  : never;
export type TypedParams<T extends RouteTree, S extends RouteSelector<T>> = FindNode<T, S>['params'] extends Readonly<(infer Keys)[]> ? { [key in Keys & string]?: string } : never;
type ParamInput<T extends RouteTree, S extends RouteSelector<T>> = TypedParams<T, S> extends never ? never : { [key in keyof TypedParams<T, S>]: string | number | boolean };

export class RouteBuilder<T extends RouteTree> {
  constructor(
    private host: string,
    private routes: T
  ) {
    this.assertNoDuplicates();
  }

  /** @return single segment of a route, e.g. 'news' */
  public segment(selector: RouteSelector<T>): string {
    return this.findNode(selector).segment;
  }

  /** @return full path to a route, e.g. 'master-data/mercer-data/details/:id' */
  public route(selector: RouteSelector<T>): string {
    return this.findNode(selector).route;
  }

  public open<S extends RouteSelector<T>>(
    selector: S,
    options?: {
      queryParams?: ParamInput<T, S>;
      features?: string;
    }
  ): Window {
    let route = this.host + this.route(selector);
    if (options?.queryParams) route += `?${new HttpParams().appendAll(options.queryParams).toString()}`;
    return window.open(route, '_blank', options?.features);
  }

  public async navigate<S extends RouteSelector<T>>(
    router: Router,
    selector: S,
    extras?: Override<
      NavigationExtras,
      {
        queryParams?: ParamInput<T, S>;
      }
    >
  ): Promise<boolean> {
    if (!window.location.href.includes(this.host)) throw new Error('Routing is only possible within the same app. Use "open()" for cross-app navigation.');
    const route = `/${this.route(selector)}`;

    const navigationResult = await router.navigate([route], extras);
    if (navigationResult && isDevMode()) this.validateSelectorName(selector, router);

    return navigationResult;
  }

  public getBreadcrumbs(url: URL): Breadcrumb[] {
    const segments = url.pathname.split('/').map((segment, i, arr) => arr.slice(0, i + 1).join('/'));
    return Object.keys(this.routes)
      .flatMap(selector => this.mapBreadcrumb(this.routes, selector, '', url))
      .filter(info => !!info.url && segments.includes(info.url));
  }

  private mapBreadcrumb(tree: RouteTree, selector: string, parentSegment: string, url: URL): Breadcrumb[] {
    const node = tree[selector] as TreeNode;
    const nodeSegment = node.segment ? `${parentSegment}/${node.segment}` : parentSegment;
    const nodeInfo = {
      url: nodeSegment,
      title: this.resolveTitle(url, node),
      paramTitle: node.paramTitle,
      params: node.params,
      emptyPage: node.emptyPage
    };
    if (node.children) {
      const childs = Object.keys(node.children).flatMap(selector => this.mapBreadcrumb(node.children, selector, nodeSegment, url));
      const hasRootChild = childs.some(child => child.url === nodeInfo.url);
      return hasRootChild ? childs : [nodeInfo, ...childs];
    }

    return [nodeInfo];
  }

  private resolveTitle(url: URL, node: TreeNode): string {
    if (!node.title || typeof node.title === 'string') {
      return node.title as string;
    }

    const key = Object.keys(node.title)[0];
    const value = url.searchParams.get(key);
    return node.title[key][value];
  }

  public findNavigationRoot(url: URL): { navigationRoot: TreeNode; firstSegment: string } {
    const firstSegment = url.pathname.split('/')[1];
    const moduleTree = Object.keys(this.routes)
      .map(selector => this.routes[selector] as TreeNode)
      .filter(tree => tree.segment === firstSegment);

    if (moduleTree.length !== 1) {
      console.error(`No exact navigation module match for root-segment ${firstSegment} found!`);
      return { firstSegment, navigationRoot: null };
    }

    const module = moduleTree[0];
    return { navigationRoot: this.findNavigationRootRecursive(module), firstSegment };
  }

  private findNavigationRootRecursive(node: TreeNode): TreeNode {
    if (node.navigationRoot) {
      return node;
    }

    for (const childSelector of Object.keys(node.children || {})) {
      const result = this.findNavigationRootRecursive(node.children[childSelector]);
      if (result) {
        return result;
      }
    }

    return null;
  }

  private findNode(selector: string): { segment: string; route: string } {
    const route: string[] = [];
    let tree = this.routes;
    let match: TreeNode;

    while (!match) {
      const matchOrParent = Object.entries(tree).find(([key, value]) => key === selector || JSON.stringify(value).includes(selector));
      if (matchOrParent[0] === selector) {
        // eslint-disable-next-line prefer-destructuring
        match = matchOrParent[1];
      } else {
        tree = matchOrParent[1].children as T;
      }
      route.push(matchOrParent[1].segment);
    }

    return { route: route.filter(s => !!s).join('/'), segment: match.segment };
  }

  private assertNoDuplicates(): void {
    const names = JSON.stringify(this.routes).match(/\b\w+(Module|Component)\b/g);
    const duplicates = names.filter((item, index) => names.indexOf(item) !== index);

    if (duplicates.length) {
      console.error('Duplicates: ', duplicates);
      throw new Error('Duplicates in Route name');
    }
  }

  private validateSelectorName(selector: string, router: Router): void {
    if (!selector.toLowerCase().endsWith('component')) return;

    const activatedRouteNames: string[] = [];
    const getActivatedRouteNamesRec = (route: ActivatedRoute): void => {
      if (route.component) activatedRouteNames.push(route.component.name);
      if (route.children.length) route.children.forEach(getActivatedRouteNamesRec);
    };
    getActivatedRouteNamesRec(router.routerState.root);

    if (!activatedRouteNames.includes(selector)) {
      throw new Error(`Selector name does not match match component name.\nSelector: ${selector}\nActive Components: ${activatedRouteNames.join(', ')}`);
    }
  }
}
