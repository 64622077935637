import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UserState } from '@coin/modules/auth/data-management';
import { MeritBudgetAllocationState } from '../../merit-budget-allocation/store/merit-budget-allocation.state';
import { MeritAllocationGeneralService } from './merit-allocation-general.service';
import { MeritAllocationImpersonatedEmployeeService } from './merit-allocation-impersonated-employee.service';

@Injectable()
export class AllocationUserOrgCodeService {
  private userOrgCode: string;

  constructor(
    private meritAllocationGeneralService: MeritAllocationGeneralService,
    private store: Store,
    private meritAllocationImpersonatedEmployeeService: MeritAllocationImpersonatedEmployeeService
  ) {}

  public getUserOrgCode(): Observable<string> {
    const userId = this.store.selectSnapshot(UserState.user).id;
    const seasonId = this.store.selectSnapshot(MeritBudgetAllocationState.season).id;

    if (this.userOrgCode) {
      return of(this.userOrgCode);
    }

    return this.loadAndStoreUserOrgCode(userId, seasonId);
  }

  private loadAndStoreUserOrgCode(userId: string, seasonId: string): Observable<string> {
    const impersonatedEmployeeId = this.meritAllocationImpersonatedEmployeeService.employeeId;
    return this.meritAllocationGeneralService.getEmployeeSnapshotById(seasonId, impersonatedEmployeeId || userId).pipe(
      map(employee => employee?.paOrgCode),
      tap(orgCode => {
        this.userOrgCode = orgCode;
      })
    );
  }
}
