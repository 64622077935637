export enum SeasonSimulationState {
  Created = 'Created',
  Edited = 'Edited',
  Simulating = 'Simulating',
  Simulated = 'Simulated',
  RecalculationRequired = 'RecalculationRequired',
  Ready = 'Ready',
  Frozen = 'Frozen',
  Recalculating = 'Recalculating',
  InAllocation = 'InAllocation',
  PrePublished = 'PrePublished',
  Published = 'Published',
  UnPrePublish = 'UnPrePublish'
}
