import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Comment } from '@coin/shared/util-models';
import { environment } from '@coin/shared/util-environments';
import { finalize } from 'rxjs/operators';
import { HttpHelpersService } from './http-helpers';
import { LoadingService } from './loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class PositionEvaluationCommentsService {
  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private httpHelpersService: HttpHelpersService
  ) {}

  getComments(seasonId: string, memberId: string): Observable<Comment[]> {
    this.loadingService.present();
    return this.httpClient.get<Comment[]>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/comments`).pipe(
      this.httpHelpersService.handleError('Cannot get comments'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  postComment(seasonId: string, memberId: string, comment: Comment): Observable<Comment> {
    this.loadingService.present();
    return this.httpClient
      .post<Comment>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/comments`, comment)
      .pipe(
        this.httpHelpersService.handleError('Cannot post comment'),
        finalize(() => this.loadingService.dismiss())
      );
  }
}
