import { trigger, transition, style, animate } from '@angular/animations';

export const shrinkExpandSlowAnimation = trigger('shrinkExpandSlow', [
  transition(':enter', [
    style({
      height: '0px',
      minHeight: '0px',
      maxHeight: '0px',
      width: '0px',
      minWidth: '0px',
      maxWidth: '0px',
      padding: '0',
      margin: 'auto',
      opacity: 0,
      overflow: 'hidden',
      transform: 'translate(0%, 0%)',
      position: 'absolute',
      left: '50%',
      top: '50%'
    }),
    animate(
      '1050ms ease-in-out',
      style({
        height: '*',
        minHeight: '*',
        maxHeight: '*',
        width: '*',
        minWidth: '*',
        maxWidth: '*',
        padding: '*',
        margin: '*',
        opacity: 1,
        transform: 'translate(0%, 0%)',
        position: 'absolute',
        left: '0%',
        top: '0%'
      })
    )
  ]),
  transition(':leave', [
    style({ height: '*', minHeight: '*', maxHeight: '*', width: '*', minWidth: '*', maxWidth: '*', padding: '*', margin: '*', opacity: 1 }),
    animate(
      '1000ms ease-in-out',
      style({ height: '0px', minHeight: '0px', maxHeight: '0px', width: '0px', minWidth: '0px', maxWidth: '0px', padding: '0', margin: 'auto', opacity: 0, overflow: 'hidden' })
    )
  ])
]);
