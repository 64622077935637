import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { PaginatedResult } from '@coin/shared/util-models';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IncentiveAllocationEmployee } from '../../merit-shared/models/incentive-allocation-employee.model';
import { AllocationOrgDetailsService } from '../../merit-shared/services/allocation-org-details-mapping.service';
import { MeritAllocationImpersonatedEmployeeService } from '../../merit-shared/services/merit-allocation-impersonated-employee.service';

@Injectable({
  providedIn: 'root'
})
export class IncentiveAllocationsService implements AllocationOrgDetailsService {
  constructor(
    private httpClient: HttpClient,
    private httpHelpersService: HttpHelpersService,
    private loadingService: LoadingService,
    private meritAllocationImpersonatedEmployeeService: MeritAllocationImpersonatedEmployeeService
  ) {}

  public getIncentiveDirectAllocations(seasonId: string, filterText = ''): Observable<PaginatedResult<IncentiveAllocationEmployee>> {
    this.loadingService.present();
    const defaultSize = 1000;
    return this.httpClient
      .get<
        PaginatedResult<IncentiveAllocationEmployee>
      >(`${environment.api.baseUrl}/customer/api/v2/master/seasons/incentive/allocations/directs?paging.page=1&paging.size=${defaultSize}&meritSeasonId=${seasonId}${filterText}${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery()}`)
      .pipe(
        this.httpHelpersService.handleError('Could not load allocations.'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getIncentiveDirectAllocation(allocationMemberId: string): Observable<IncentiveAllocationEmployee> {
    this.loadingService.present();
    return this.httpClient
      .get<IncentiveAllocationEmployee>(
        `${environment.api.baseUrl}/customer/api/v2/master/seasons/incentive/allocations/${allocationMemberId}${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery(
          '?'
        )}`
      )
      .pipe(
        this.httpHelpersService.handleError('Could not load allocation.'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getIncentiveAllocationByEmployeeId(seasonId: string, employeeId: string, orgCode?: string): Observable<IncentiveAllocationEmployee> {
    this.loadingService.present();
    const separator = this.meritAllocationImpersonatedEmployeeService.employeeId ? '&' : '?';
    const query = orgCode ? `${separator}orgCode=${encodeURIComponent(orgCode)}` : '';
    return this.httpClient
      .get<IncentiveAllocationEmployee>(
        `${
          environment.api.baseUrl
        }/customer/api/v2/master/seasons/incentive/allocations/${seasonId}/employee/${employeeId}${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery('?')}${query}`
      )
      .pipe(
        this.httpHelpersService.handleError('Could not load allocation.'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  // Ugly hack. When the orgCode is set, then this is the starting request for the org-details view after the organisation is selected. Then the ID belongs to an employee.
  public getAllocationMember = (seasonId: string, allocationMemberId: string, orgCode?: string): ReturnType<IncentiveAllocationsService['getIncentiveDirectAllocation']> =>
    orgCode ? this.getIncentiveAllocationByEmployeeId(seasonId, allocationMemberId, orgCode) : this.getIncentiveDirectAllocation(allocationMemberId);

  public getTotalAllocations(page: number, size: number, seasonId: string, filterText = ''): Observable<PaginatedResult<IncentiveAllocationEmployee>> {
    this.loadingService.present();
    return this.httpClient
      .get<
        PaginatedResult<IncentiveAllocationEmployee>
      >(`${environment.api.baseUrl}/customer/api/v2/master/seasons/incentive/allocations/total?meritSeasonId=${seasonId}&Paging.Page=${page}&Paging.Size=${size}${filterText}${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery()}`)
      .pipe(
        this.httpHelpersService.handleError('Could not load allocations.'),
        finalize(() => this.loadingService.dismiss())
      );
  }
}
