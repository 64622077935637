<div *ngIf="contentEdit && item" class="expand-links-edit">
  <mat-form-field class="expand-links-input flex-one">
    <mat-label>{{ 'cms.content-pages.headline-text' | translate }}</mat-label>
    <input matInput aria-label="image headline" [(ngModel)]="item.headline" [placeholder]="'cms.content-pages.headline-text' | translate" type="text" />
  </mat-form-field>
  <mat-form-field class="expand-links-input">
    <mat-label>{{ 'cms.content-pages.icon' | translate }}</mat-label>
    <input matInput aria-label="icon" [(ngModel)]="item.icon" [placeholder]="'cms.content-pages.icon' | translate" type="text" />
  </mat-form-field>
  <div>
    <mat-form-field class="expand-links-input">
      <mat-label>{{ 'cms.content-pages.amount-links' | translate }}</mat-label>
      <input matInput aria-label="icon" [(ngModel)]="item.amountLinks" [placeholder]="'cms.content-pages.amount-links' | translate" type="number" />
    </mat-form-field>
    <coin-action-button class="primary" (click)="applyAmountLinks()" [noicon]="true">Apply amount links</coin-action-button>
  </div>
</div>

<div *ngIf="item" class="expand-links-main">
  <div class="expand-links-box" (click)="boxExpanded = !boxExpanded">
    <div class="expand-links-text">
      <span class="material-icons-outlined" *ngIf="item.icon">{{ item.icon }}</span>
      <p>{{ item.headline }}</p>
      <span class="material-icons-outlined">expand_more</span>
    </div>
  </div>
  <div class="expand-box-linkcontent" [ngClass]="{ 'expand-box-linkcontent--expanded': boxExpanded || contentEdit }">
    <mat-form-field class="expand-links-search-input">
      <mat-label>{{ 'content-pages.search' | translate }}</mat-label>
      <input matInput aria-label="image headline" [(ngModel)]="expandSearch" (input)="searchAllLinks($event)" [placeholder]="'content-pages.search' | translate" type="text" />
    </mat-form-field>
    <ngx-simplebar class="content-container primary-scrollbar-vertical simple-table no-space">
      <div *ngFor="let linkItem of item.allLinks; let i = index" @shrinkExpandAll class="expand-links-item">
        <img aria-label="download icon" [src]="imagePath + 'coin/images/originals/download_icon_SE.png'" />
        <mat-form-field class="flex-one" *ngIf="contentEdit">
          <mat-label>Link text</mat-label>
          <input matInput aria-label="link text" [(ngModel)]="linkItem.text" />
        </mat-form-field>
        <mat-form-field class="flex-one" *ngIf="contentEdit">
          <mat-label>Link Href</mat-label>
          <input matInput aria-label="link link" [(ngModel)]="linkItem.link" type="test" placeholder="https://..." />
        </mat-form-field>
        <coin-action-button class="secondary button-spacing" *ngIf="contentEdit" (click)="deleteItem(i)" [noicon]="true">
          <span class="material-icons">clear</span>
        </coin-action-button>
        <a (click)="openLink(linkItem.link)" class="flex-one text-black" *ngIf="!contentEdit" target="_blank">{{ linkItem.text }}</a>
      </div>
    </ngx-simplebar>
  </div>
</div>
