import { AfterViewInit, DestroyRef, Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appTwoDecimalPlaces]',
  standalone: true
})
export class TwoDecimalPlacesDirective implements AfterViewInit, OnChanges {
  @Output() ngModelChange = new EventEmitter<string>();

  @Input() value: string | number;
  @Input() isDecimal = true;
  @Input() maxValue: number;
  @Input() minValue: number;

  private parse$ = new Subject<void>();

  constructor(
    private el: ElementRef,
    private destroyRef: DestroyRef
  ) {}

  ngAfterViewInit(): void {
    this.parse$
      .pipe(
        map(() => this.el.nativeElement.value),
        filter(value => !!value),
        tap(() => this.parseTwoDecimals())
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();

    setTimeout(() => {
      this.parse$.next();
    });
  }

  @HostListener('change', ['$event'])
  public onChange(event: InputEvent): void {
    this.parse$.next();
  }

  // prevent certain chars on number input: '+', 'e' & '-' if it is not a preceding value
  @HostListener('keydown', ['$event'])
  public onKeyDown({ key }: KeyboardEvent): boolean {
    const currentValue = this.el.nativeElement.value;
    const areKeysValid = key !== '+' && key !== 'e';
    if (!currentValue) {
      return areKeysValid;
    }
    const currentValueChars = currentValue.toString().split('');
    const foundMinusValue = currentValueChars.find(char => char === '-');
    return key === '-' ? !foundMinusValue && currentValueChars[0] === '-' : areKeysValid;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.parse$.next();
    }
  }

  public parseTwoDecimals(): void {
    let parsedValue = this.isDecimal ? parseFloat(this.el.nativeElement.value) : parseInt(this.el.nativeElement.value);

    if (!Number.isNaN(parsedValue)) {
      if (this.maxValue !== undefined && parsedValue > this.maxValue) {
        parsedValue = this.maxValue;
      } else if (this.minValue !== undefined && parsedValue < this.minValue) {
        parsedValue = this.minValue;
      }

      const value = this.isDecimal ? parsedValue.toFixed(2) : parsedValue.toString();
      this.ngModelChange.emit(value);
      this.el.nativeElement.value = value;
    } else {
      this.ngModelChange.emit(null);
      this.el.nativeElement.value = null;
    }
  }
}
