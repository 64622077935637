import { BudgetComponentType } from '@coin/shared/util-enums';
import { RoundOperations } from '@coin/shared/util-helpers';
import { Employee } from '@coin/shared/util-models';
import { MeritAllocationSnapshot } from '../models/merit-allocation-snapshot.model';
import { MeritBudgetComponent } from '../models/merit-budget-component.model';
import { EmployeeWithCompensationInfo } from '../models/merit-budget-direct-with-compensation.model';

export class MeritAllocationSnapshotMapOperations {
  public static mapSnapshotsToEmployees(snapshots: MeritAllocationSnapshot[]): EmployeeWithCompensationInfo[] {
    return snapshots?.map(snapshot => {
      let employeeSnapshot: Employee = {};
      employeeSnapshot = snapshot.employeeSnapshot ?? {};

      if (snapshot.organisationSnapshot) {
        const { organisationSnapshot } = snapshot;
        employeeSnapshot.placeOfAction = organisationSnapshot.headPlaceOfAction ?? employeeSnapshot.placeOfAction;
        employeeSnapshot.paOrgCode = organisationSnapshot.headPAOrgCode ?? employeeSnapshot.paOrgCode;
        employeeSnapshot.orgCode = organisationSnapshot.orgCode ?? employeeSnapshot.orgCode;
        employeeSnapshot.mercerJobCode = organisationSnapshot.headMercerJobCode ?? employeeSnapshot.mercerJobCode;
        employeeSnapshot.managementGroup = organisationSnapshot.headManagementGroup ?? employeeSnapshot.managementGroup;
        employeeSnapshot.positionClass = organisationSnapshot.headMercerPositionClass ?? employeeSnapshot.positionClass;
        employeeSnapshot.contractStatus = organisationSnapshot.contractStatus ?? employeeSnapshot.contractStatus;
        employeeSnapshot.dateContractStatus = organisationSnapshot.headDateContractStatus ?? employeeSnapshot.contractStatus;
        employeeSnapshot.headId = organisationSnapshot.headId ?? employeeSnapshot.headId;
        employeeSnapshot.headGid = organisationSnapshot.headGid ?? employeeSnapshot.headGid;
        employeeSnapshot.firstname = organisationSnapshot.headFirstname ?? employeeSnapshot.firstname;
        employeeSnapshot.lastname = organisationSnapshot.headLastname ?? employeeSnapshot.lastname;
      }

      delete snapshot.employeeSnapshot;
      delete snapshot.organisationSnapshot;

      return { ...snapshot, ...employeeSnapshot, compensationComponents: this.mapCompensationComponents(snapshot.compensationComponents) };
    });
  }

  public static mapCompensationComponents(compensationComponents: MeritBudgetComponent[]): MeritBudgetComponent[] {
    const originTTCComponent = structuredClone(compensationComponents?.find(comp => comp?.type === BudgetComponentType.TotalTargetCash));

    return (
      compensationComponents?.map(comp => {
        switch (comp?.type) {
          case BudgetComponentType.BaseSalary:
            return this.setBaseSalaryMinValues(comp, originTTCComponent);
          default:
            return this.setMinValueComponents(comp);
        }
      }) ?? []
    );
  }

  private static setBaseSalaryMinValues(baseSalaryComponent: MeritBudgetComponent, originTTCComponent: MeritBudgetComponent): MeritBudgetComponent {
    if (!!originTTCComponent?.minTotalValue && !originTTCComponent?.minPercentageValue && baseSalaryComponent?.currentAmount) {
      baseSalaryComponent.isMinTotalValueIncrease = true;
      baseSalaryComponent.minPercentageValue = this.calcMinPercentageValue(originTTCComponent.minTotalValue, baseSalaryComponent.currentAmount);
    } else if (!originTTCComponent?.minTotalValue && originTTCComponent?.minPercentageValue && baseSalaryComponent?.currentAmount) {
      baseSalaryComponent.minTotalValue = this.calcMinTotalValue(baseSalaryComponent.currentAmount, originTTCComponent.minPercentageValue);
    }
    return baseSalaryComponent;
  }

  private static setMinValueComponents(comp: MeritBudgetComponent): MeritBudgetComponent {
    if (!!comp?.minTotalValue && !comp?.minPercentageValue && comp?.currentAmount) {
      comp.isMinTotalValueIncrease = true;
      comp.minPercentageValue = this.calcMinPercentageValue(comp.minTotalValue, comp.currentAmount);
    } else if (!comp?.minTotalValue && comp?.minPercentageValue && comp?.currentAmount) {
      comp.minTotalValue = this.calcMinTotalValue(comp.currentAmount, comp.minPercentageValue);
    }
    return comp;
  }

  private static calcMinPercentageValue(minTotalValue: number, currentAmount: number): number {
    return (minTotalValue / currentAmount) * 100;
  }

  private static calcMinTotalValue(currentAmount: number, minPercentageValue: number): number {
    return RoundOperations.round((minPercentageValue / 100) * currentAmount);
  }
}
