<div class="top-part" [class.responsive]="showSmallScreenDropdown">
  <div class="selection-part" [class.disabled]="disabled">
    <ng-container *ngFor="let tab of tabs; let i = index">
      <button
        *ngIf="!tab.hidden"
        class="no-style selection-item"
        [ngClass]="{ 'tab-selected': tab.name === selectedTab?.name || tab.isSelected, 'tab-disabled': tab.isDisabled }"
        [matTooltip]="tab.tooltip | translate"
        [matTooltipDisabled]="!tab.isDisabled"
        (click)="changeSelection(tab, i)">
        {{ tab.name | translate }}
      </button>
    </ng-container>
  </div>
  <coin-dropdown
    addClass="customer-grey"
    [displayFn]="displayFn"
    [sort]="false"
    [placeholder]="'general.click-to-select' | translate"
    [items]="tabs"
    (selectionChange)="changeSelection($event.value)"
    [(ngModel)]="selectedTab"></coin-dropdown>
</div>
