import { TreeNode } from '../route-builder';

export const RestructuringModule = {
  segment: 'restructuring',
  children: {
    RestructuringComponent: { segment: '' },
    RestructuringIntroductionComponent: { segment: 'introduction' },
    RestructuringRequestListComponent: { segment: 'requests' },
    RestructuringReportingListComponent: { segment: 'reporting' }
  }
} as const satisfies TreeNode;
