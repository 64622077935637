import { Injectable } from '@angular/core';
import { SeasonSettingsService } from '@coin/admin/season-mgmt/data-access';
import { GenericSeasonSettings, PositionEvaluationSetting, Season } from '@coin/admin/season-mgmt/util';
import { UserState } from '@coin/modules/auth/data-management';
import { LoadingService } from '@coin/shared/data-access';
import { DisplayedCurrency, SeasonTypeEnum } from '@coin/shared/util-enums';
import { ListViewTagFilterParameter, PaginatedResult } from '@coin/shared/util-models';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { produce } from 'immer';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { MeritSupportSortingParameter } from '../../merit/merit-support/enums/merit-support-sorting-parameter.enum';
import { FilterSortState } from '../../shared/components/filter-sort-bar/store/filter-sort.state';
import { MercerEvaluationMember, MercerEvaluationMemberDetails } from '../../shared/models/mercer-support-evaluation-member.model';
import { MercerService } from '../../shared/services/mercer.service';
import { MercerDirectReportsFilter } from '../enums';
import {
  AddMercerFilterParameter,
  ClearMercerFilterParameterKey,
  LazyLoadMercerEvaluationMembers,
  LoadMercerEvaluationMembers,
  LoadMercerSeasons,
  LoadMercerSeasonSettings,
  ResetMercerState,
  SetEvaluationMemberBySearch,
  SetMercerCurrency,
  SetMercerDirectReportsFilter,
  SetMercerEvaluationMember,
  SetMercerFilterParameters,
  SetMercerSeason
} from './mercer.actions';

const STATE_DEFAULTS: MercerStateModel = {
  evaluationMembers: [],
  seasons: [],
  season: undefined,
  filterParameter: [],
  currentPage: 0,
  maxPage: 0,
  sortingParameter: MeritSupportSortingParameter.Default,
  selectedCurrency: DisplayedCurrency.Local,
  seasonSettings: undefined,
  directReportsFilter: MercerDirectReportsFilter.DirectReports
};

const DEFAULT_PAGE_SIZE = 15;

interface MercerStateModel {
  evaluationMembers: MercerEvaluationMember[];
  seasons: Season[];
  season: Season;
  filterParameter: ListViewTagFilterParameter[];
  currentPage: number;
  maxPage: number;
  sortingParameter: string;
  selectedCurrency: DisplayedCurrency;
  seasonSettings: PositionEvaluationSetting;
  directReportsFilter: MercerDirectReportsFilter;
}

@State<MercerStateModel>({
  name: 'MercerState',
  defaults: STATE_DEFAULTS
})
@Injectable()
export class MercerState {
  constructor(
    private store: Store,
    private seasonSettingsService: SeasonSettingsService,
    private mercerService: MercerService,
    private loadingService: LoadingService
  ) {}

  @Selector()
  static evaluationMembers(state: MercerStateModel): MercerEvaluationMember[] {
    return state.evaluationMembers;
  }

  @Selector()
  static filterParameter(state: MercerStateModel): ListViewTagFilterParameter[] {
    return state.filterParameter;
  }

  @Selector()
  static seasons(state: MercerStateModel): Season[] {
    return state.seasons;
  }

  @Selector()
  static season(state: MercerStateModel): Season {
    return state.season;
  }

  @Selector()
  static selectedCurrency(state: MercerStateModel): DisplayedCurrency {
    return state.selectedCurrency;
  }

  @Selector()
  static seasonSettings(state: MercerStateModel): PositionEvaluationSetting {
    return state.seasonSettings;
  }

  @Selector()
  static directReportsFilter(state: MercerStateModel): MercerDirectReportsFilter {
    return state.directReportsFilter;
  }

  @Selector()
  static isLastPage(state: MercerStateModel): boolean {
    return state.currentPage >= state.maxPage;
  }

  static getQueryText(store: Store): (s: MercerStateModel) => string {
    return createSelector([MercerState], (state: MercerStateModel) => {
      return MercerState.getFilterParameterText(store, state, false);
    });
  }

  static getEvaluationMember(id: string): (s: MercerStateModel) => MercerEvaluationMember {
    return createSelector([MercerState], (state: MercerStateModel) => {
      return state.evaluationMembers.find(evaluationMember => evaluationMember.id === id);
    });
  }

  private getSortingParameterText(ctx: StateContext<MercerStateModel>): string {
    return ctx.getState().sortingParameter !== MeritSupportSortingParameter.Default ? `&Sorting.Property=${ctx.getState().sortingParameter}` : '';
  }

  static getFilterParameterText(store: Store, state: MercerStateModel, showQueryPrefix = true): string {
    let filterText = '';

    state.filterParameter.forEach(param => {
      filterText += `&${showQueryPrefix ? 'Query.' : ''}${encodeURIComponent(param.category)}=${encodeURIComponent(param.value)}`;
    });

    if (state.directReportsFilter === MercerDirectReportsFilter.DirectReports) {
      filterText += `&Query.validationManagerId=${store.selectSnapshot(UserState.user)?.id}`;
    }

    return filterText ?? '';
  }

  @Action(LoadMercerEvaluationMembers)
  loadMercerEvaluationMembers(ctx: StateContext<MercerStateModel>): Observable<PaginatedResult<MercerEvaluationMember>> {
    const seasonId = ctx.getState().season.id;
    let filterText = this.store.selectSnapshot(FilterSortState.getSortAndFilterText);

    filterText += MercerState.getFilterParameterText(this.store, ctx.getState()) + this.getSortingParameterText(ctx);

    return this.mercerService.getEvaluationMembers(seasonId, 0, DEFAULT_PAGE_SIZE, filterText).pipe(
      this.loadingService.withLoadingScreen,
      tap(result => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.evaluationMembers = result.content;
            state.currentPage = 0;
            state.maxPage = result.pageCount - 1;
          })
        );
      })
    );
  }

  @Action(LazyLoadMercerEvaluationMembers)
  lazyLoadMercerEvaluationMembers(ctx: StateContext<MercerStateModel>): Observable<PaginatedResult<MercerEvaluationMember>> {
    const seasonId = ctx.getState().season.id;
    const { currentPage, maxPage } = ctx.getState();

    if (currentPage >= maxPage) {
      return;
    }
    let filterText = this.store.selectSnapshot(FilterSortState.getSortAndFilterText);
    filterText += MercerState.getFilterParameterText(this.store, ctx.getState()) + this.getSortingParameterText(ctx);
    return this.mercerService.getEvaluationMembers(seasonId, currentPage + 1, DEFAULT_PAGE_SIZE, filterText).pipe(
      this.loadingService.withLoadingScreen,
      tap(result => {
        ctx.setState(
          produce(ctx.getState(), state => {
            const evaluationMembers = [...state.evaluationMembers, ...result.content];

            state.evaluationMembers = evaluationMembers?.filter((evaluationMember, index) => evaluationMembers.findIndex(e => e.id === evaluationMember.id) === index); // delete all duplicates
            state.currentPage += 1;
          })
        );
      })
    );
  }

  @Action(SetEvaluationMemberBySearch)
  setEvaluationMemberBySearch(ctx: StateContext<MercerStateModel>, { payload }: SetEvaluationMemberBySearch): Observable<MercerEvaluationMemberDetails> {
    const seasonId = ctx.getState().season.id;
    return this.mercerService.getEvaluationMemberDetails(seasonId, payload.evaluationMemberId).pipe(
      tap(newEvaluationMember => {
        ctx.setState(
          produce(ctx.getState(), state => {
            const evaluationMembersWithoutSearchedEmployee = state.evaluationMembers.filter(evaluationMember => evaluationMember.id !== newEvaluationMember.id);
            state.evaluationMembers = [newEvaluationMember, ...evaluationMembersWithoutSearchedEmployee];
          })
        );
      })
    );
  }

  @Action(SetMercerEvaluationMember)
  setMercerEvaluationMember(ctx: StateContext<MercerStateModel>, { payload }: SetMercerEvaluationMember): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const index = state.evaluationMembers?.findIndex(member => payload.id === member.id);
        state.evaluationMembers[index] = payload;
      })
    );
  }

  @Action(LoadMercerSeasons)
  loadMercerSeasons(ctx: StateContext<MercerStateModel>, { managerEndDateAt }: LoadMercerSeasons): Observable<void> {
    return this.mercerService.getSeasons(managerEndDateAt).pipe(
      tap(seasons => ctx.patchState({ seasons })),
      filter(seasons => seasons?.length === 1),
      switchMap(seasons => ctx.dispatch(new SetMercerSeason(seasons[0])))
    );
  }

  @Action(SetMercerSeason)
  setMercerSeason(ctx: StateContext<MercerStateModel>, { season }: SetMercerSeason): Observable<void> {
    return ctx
      .dispatch(
        new LoadMercerSeasonSettings({
          id: season.id,
          type: SeasonTypeEnum.PositionEvaluation
        })
      )
      .pipe(tap(() => ctx.patchState({ season })));
  }

  @Action(ResetMercerState)
  resetMercerState(ctx: StateContext<MercerStateModel>): void {
    ctx.patchState(STATE_DEFAULTS);
  }

  @Action(SetMercerCurrency)
  setMercerCurrency(ctx: StateContext<MercerStateModel>, { currency }: SetMercerCurrency): void {
    ctx.patchState({ selectedCurrency: currency });
  }

  @Action(AddMercerFilterParameter)
  addMercerFilterParameter(ctx: StateContext<MercerStateModel>, { payload }: AddMercerFilterParameter): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.filterParameter.push(payload);
      })
    );
  }

  @Action(SetMercerFilterParameters)
  setMercerFilterParameters(ctx: StateContext<MercerStateModel>, { payload }: SetMercerFilterParameters): void {
    ctx.patchState({ filterParameter: payload });
  }

  @Action(ClearMercerFilterParameterKey)
  clearMercerFilterParameterKey(ctx: StateContext<MercerStateModel>, { category }: ClearMercerFilterParameterKey): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.filterParameter = state.filterParameter.filter(param => param.category !== category);
      })
    );
  }

  @Action(LoadMercerSeasonSettings)
  loadMercerSeasonSettings(ctx: StateContext<MercerStateModel>, { payload }: LoadMercerSeasonSettings): Observable<GenericSeasonSettings> {
    return this.seasonSettingsService.getSeasonSettings(payload.id, payload.type).pipe(tap((seasonSettings: PositionEvaluationSetting) => ctx.patchState({ seasonSettings })));
  }

  @Action(SetMercerDirectReportsFilter)
  setMercerDirectReportsFilter(ctx: StateContext<MercerStateModel>, { payload }: SetMercerDirectReportsFilter): void {
    ctx.patchState({
      directReportsFilter: payload
    });
  }
}
