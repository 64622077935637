import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { EquityPageComponentModel } from '../../models/equity-page-component.model';

@Component({
  selector: 'app-equity-text',
  templateUrl: './equity-text.component.html',
  styleUrls: ['./equity-text.component.scss']
})
export class EquityTextComponent implements OnInit, EquityPageComponentModel {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() data: any;
  @HostBinding('class.contrast') contrast: boolean;

  get gridTemplateColumns(): string {
    return `repeat(${this.data?.content?.sections?.length}, minmax(0, 1fr))`;
  }

  ngOnInit(): void {
    this.contrast = this.data?.options?.contrast;
  }

  onButtonClick(button): void {
    if (button.url) {
      window.open(button.url, '_blank');
    } else {
      window.print();
    }
  }
}
