<mat-form-field
  [matTooltip]="errorTooltip$ | async"
  [matTooltipDisabled]="ngControl?.untouched || !showErrorTooltip"
  [class.rtl]="direction === 'rtl'"
  matTooltipClass="error-tooltip"
  class="lib-input coin-form-field-outline coin-form-field-outline--{{ matFormFieldClass }} no-margin"
  appearance="outline"
  floatLabel="always">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <ng-container *ngIf="prefix" matPrefix>
    <p *ngIf="prefix | typeof: 'string'; else templatePrefix">{{ prefix }}</p>
    <ng-template #templatePrefix><ng-container *ngTemplateOutlet="prefix"></ng-container></ng-template>
  </ng-container>
  <input
    #textInput
    type="text"
    (blur)="onTouch(value)"
    [placeholder]="placeholder"
    [matAutocompleteDisabled]="!autoCompleteOptions?.length"
    [matAutocomplete]="autoComplete"
    matInput
    [ngModel]="value"
    (ngModelChange)="setValueDebounced$.next($event)"
    [disabled]="disabled"
    [dir]="direction"
    autocomplete="off" />
  <button *ngIf="clearable && !loading; else showIcon" (click)="onClear()" class="no-style">
    <mat-icon>close</mat-icon>
  </button>
  <ng-template #showIcon>
    <!-- TODO: use animated loading icon -->
    <mat-icon *ngIf="icon || loading">{{ loading ? 'hourglass_empty' : icon }}</mat-icon>
  </ng-template>
  <mat-autocomplete #autoComplete="matAutocomplete">
    <mat-option *ngFor="let option of autoCompleteOptions" [value]="option">{{ option }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
