<div [class.invalid]="!!errorMessage" [matTooltip]="errorMessage" class="dropdown-container lib-dropdown">
  <span class="material-icons preicon" *ngIf="preIcon">{{ preIcon }}</span>
  <mat-form-field
    class="coin-form-field-outline coin-form-field-outline--dropdown"
    [ngClass]="classAdditions"
    [appearance]="appearance"
    [floatLabel]="floatLabel"
    [class.small]="small"
    [class.tiny]="tiny"
    [class.readonly]="readonly"
    *ngIf="!showLoadSkeleton">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <mat-select
      (selectionChange)="onSelectionChange($event)"
      (openedChange)="onOpenedChange($event)"
      (blur)="onBlur()"
      [panelClass]="long ? 'lib-dropdown-long' : 'lib-dropdown'"
      [ngModel]="value"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [multiple]="multiple"
      [compareWith]="compareWith"
      [value]="value"
      #select>
      <mat-select-trigger *ngIf="!displayFn && (!multiple || !value?.length)">
        <p class="option-text" [class.need-bold]="isBold" *ngIf="!showSvgImages">
          {{ translateMe ? (translateMe + value | translate) : value }}
        </p>

        <div *ngIf="showSvgImages" class="svg-images">
          <img [src]="'/assets/svgs/flags/' + value + '.svg'" [alt]="'Flag of ' + value" style="border-radius: 3px; border: 1px solid lightgrey" />
        </div>
      </mat-select-trigger>

      <mat-select-trigger *ngIf="multiple && value?.length > 1">
        <p>
          <i
            >Selected: <b>{{ value.length }}</b></i
          >
        </p>
      </mat-select-trigger>

      <div class="filter-container" *ngIf="withSearch">
        <div class="loading" *ngIf="loading">
          <app-turbine [withPole]="false" [shadow]="false"></app-turbine>
        </div>
        <mat-checkbox *ngIf="multiple && !selectAllDisabled && !showSortIndicator" class="check-all" (change)="selectAll($event.checked)" [checked]="allItemsSelected">
        </mat-checkbox>
        <div *ngIf="showSortIndicator" (click)="isAscSort = !isAscSort" class="sort-direction-indicator">
          <mat-icon *ngIf="isAscSort">south</mat-icon>
          <mat-icon *ngIf="!isAscSort">north</mat-icon>
          <p class="sort-indicator">{{ isAscSort ? 'Asc' : 'Desc' }}</p>
        </div>
        <coin-input
          (input)="searchChanged($event.target.value)"
          [label]="'general.dropdown-search-label' | translate"
          addClass="shadow"
          class="search-input"
          (keydown)="$event.stopPropagation()"
          #searchField></coin-input>
      </div>

      <ng-container *ngIf="!lazyLoadFn; else lazyLoadedOptions">
        <mat-option *ngIf="!multiple && emptyOptionValue !== undefined" [value]="emptyOptionValue"
          ><p>{{ 'general.dropdown-no-option' | translate }}</p></mat-option
        >
        <mat-option *ngFor="let itm of items | pure: filteredAndSortedItems : sort : translateMe : search" [value]="bindedObjectProperty ? itm[bindedObjectProperty] : itm">
          <ng-container *ngIf="itm | pure: getText as itemText">
            <div class="option-content">
              <div>
                <p
                  *ngIf="!showSvgImages"
                  class="option-text"
                  [class.need-bold]="isBold"
                  [matTooltip]="showOptionsAsTooltips ? (translateMe ? (translateMe + itemText | translate) : itemText) : ''">
                  {{ translateMe ? (translateMe + itemText | translate) : itemText }}
                </p>
                <div *ngIf="showSvgImages" style="height: 20px; width: 30px; margin-left: auto; margin-right: auto">
                  <img [src]="'/assets/svgs/flags/' + itm + '.svg'" [alt]="'Flag of ' + itm" style="border-radius: 3px" />
                </div>
              </div>
              <mat-icon
                *ngIf="translateMe && (translateMe + itemText + '-info-tooltip' | translate) !== translateMe + itemText + '-info-tooltip'"
                class="option-info-icon"
                [matTooltip]="translateMe ? (translateMe + itemText + '-info-tooltip' | translate) : itemText"
                >info_outlined</mat-icon
              >
            </div>
          </ng-container>
        </mat-option>
      </ng-container>
      <ng-template #lazyLoadedOptions>
        <mat-option *ngFor="let itm of lazyLoadedItems$ | async" [value]="itm">
          <ng-container *ngIf="itm | pure: getText as itemText">
            <p
              *ngIf="!showSvgImages"
              class="option-text"
              [class.need-bold]="isBold"
              [matTooltip]="showOptionsAsTooltips ? (translateMe ? (translateMe + itemText | translate) : itemText) : ''">
              {{ translateMe ? (translateMe + itemText | translate) : itemText }}
            </p>
            <div *ngIf="showSvgImages" style="height: 20px; width: 30px; margin-left: auto; margin-right: auto">
              <img [src]="'/assets/svgs/flags/' + itm + '.svg'" [alt]="'Flag of ' + itm" style="border-radius: 3px" />
            </div>
          </ng-container>
        </mat-option>
      </ng-template>

      <mat-option [disabled]="true" *ngIf="!lazyLoadFn ? !(items | pure: filteredAndSortedItems : sort : translateMe : search).length : !(lazyLoadedItems$ | async)?.length"
        >- - - -</mat-option
      >

      <div class="button-container" *ngIf="showApplyButton">
        <div class="inner-container">
          <coin-action-button class="primary" (click)="onPressApply()">{{ bottomButtonText }}</coin-action-button>
        </div>
      </div>
    </mat-select>
  </mat-form-field>
  <coin-action-button class="secondary skeleton" [noicon]="true" [loading]="loading" *ngIf="showLoadSkeleton"></coin-action-button>
  <span class="material-icons aftericon" *ngIf="afterIcon">{{ afterIcon }}</span>
  <mat-icon class="remove" *ngIf="canDeselect && value" (click)="value = null">cancel </mat-icon>
</div>
