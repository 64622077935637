<div class="header">
  <div class="left">
    <ng-container *ngIf="actionContainerLeft">
      <ng-container *ngTemplateOutlet="actionContainerLeft"> </ng-container>
    </ng-container>
    <div class="title">
      <h3 *ngIf="!titleContainer">{{ title }}</h3>
      <ng-container *ngIf="titleContainer">
        <ng-container *ngTemplateOutlet="titleContainer"> </ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="actionContainerMiddle">
      <ng-container *ngTemplateOutlet="actionContainerMiddle"> </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="actionContainerRight">
    <ng-container *ngTemplateOutlet="actionContainerRight"> </ng-container>
  </ng-container>
</div>
