import { EmployeeSlim } from './employee-slim.model';

export enum SpecialRoleType {
  SpecialResponsibility = 'SpecialResponsibility',
  BusinessPartner = 'BusinessPartner'
}

export class SpecialRole {
  id?: string;
  employee?: EmployeeSlim;
  employeeId?: string;
  title?: string;
  organisationId?: string;
  validFrom?: string;
  type: SpecialRoleType;
}
