<div class="unsaved-changes-dialog">
  <div class="dialog-headline">
    <h3>{{ data.title | translate }}</h3>
  </div>
  <div class="uc-dialog content">
    <p>{{ data.msg | translate }}</p>

    <div class="buttons-container">
      <coin-action-button (click)="goBack()" svgIcon="cancel">{{ 'dialog.unsaved-changes.cancel' | translate }}</coin-action-button>
      <div class="spacer"></div>
      <coin-action-button class="secondary" (click)="proceed('noSave')" svgIcon="forward">{{ 'dialog.unsaved-changes.no-save' | translate }}</coin-action-button>
      <coin-action-button class="primary" (click)="proceed('save')" [disabled]="!data.canSave" svgIcon="save">{{ 'dialog.unsaved-changes.save' | translate }}</coin-action-button>
    </div>
  </div>
</div>
