export class RoundOperations {
  public static round(value: number, decimals = 0): number {
    const factor = 10 ** decimals;
    return Math.round(value * factor) / factor;
  }

  // rounds number to integer according to given rounding length
  // examples:
  // 2 => rounds number to integer regarding the last two digits (1456 -> 1460)
  // 3 => rounds number to integer regarding the last three digits (1456 -> 1500)
  public static roundToInteger(value: number, roundingLength = 1): number {
    const amount = 10 ** roundingLength - 1;
    return Math.round(value / amount) * amount;
  }

  public static getRoundingLength(value: number): number {
    if (RoundOperations.isOverMillion(value)) {
      return 3;
    }
    if (RoundOperations.isOverThousand(value)) {
      return 2;
    }

    return 1;
  }

  public static isOverThousand(value: number): boolean {
    return Math.trunc(Math.abs(value)) / 1_000 > 1;
  }
  public static isOverMillion(value: number): boolean {
    return Math.trunc(Math.abs(value)) / 1_000_000 > 1;
  }
}
