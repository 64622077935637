import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@coin/shared/util-environments';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { Employee, PaginatedResult, SearchItemService } from '@coin/shared/util-models';
import { MercerEvaluationMember } from '../../shared/models/mercer-support-evaluation-member.model';
import { MercerSupportAddPosition } from '../models/mercer-support-add-position.model';

@Injectable({
  providedIn: 'root'
})
export class MercerSupportPositionService implements SearchItemService {
  constructor(
    private httpClient: HttpClient,
    private toast: ToastrService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private httpHelpersService: HttpHelpersService
  ) {}

  public getSearchItemsByText(text: string, page: number, seasonId: string, searchSuffix?: string): Observable<Employee[]> {
    const encodedText = encodeURIComponent(text);
    return this.httpClient
      .get<
        PaginatedResult<Employee>
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/partner/${seasonId}/employees?Paging.page=${page + 1}&Paging.size=50&Query.firstName=${encodedText}&Query.lastName=${encodedText}&Query.gid=${encodedText}&Query.orgCode=${encodedText}${searchSuffix ?? ''}`)
      .pipe(map(res => res.content));
  }

  public addPosition(seasonId: string, body: MercerSupportAddPosition): Observable<MercerEvaluationMember> {
    return this.httpClient.post<MercerEvaluationMember>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/partner/${seasonId}/positions`, body).pipe(
      this.loadingService.withLoadingScreen,
      tap(() => this.toast.success(this.translate.instant('mercer-support.toast.create-position-success'))),
      this.httpHelpersService.handleError(this.translate.instant('mercer-support.toast.create-position-error'))
    );
  }
}
