<mat-form-field
  [matTooltip]="errorTooltip$ | async"
  [matTooltipDisabled]="ngControl?.untouched"
  matTooltipClass="error-tooltip"
  class="lib-input coin-form-field-outline coin-form-field-outline--{{ matFormFieldClass }} no-margin"
  appearance="outline"
  floatLabel="always">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select
    #matSelect="matSelect"
    [placeholder]="placeholder"
    [multiple]="multiple"
    [(ngModel)]="value"
    [disabled]="disabled"
    (closed)="onTouch(value)"
    [compareWith]="compareWith"
    (openedChange)="focusSearchInputIfPresent($event)">
    <!--Custom Trigger -->
    <mat-select-trigger [ngSwitch]="selectTriggerType">
      <ng-container *ngSwitchCase="'template'">
        <ng-container *ngTemplateOutlet="selectedValueTemplate"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'multiple-values'">
        <p>
          <i>
            {{ 'general.dropdown-selected' | translate }} <b>{{ value.length }}</b>
          </i>
        </p>
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ matSelect.triggerValue || ('general.unknown-value' | translate) }}
      </ng-container>
    </mat-select-trigger>

    <!--Search Input -->
    <ng-container *ngIf="search.observed">
      <div class="filter-container">
        <coin-v2-text-input
          [loading]="loading"
          [label]="'general.dropdown-search-label' | translate"
          [formControl]="searchControl"
          [inputDebounce]="searchDebounce"
          (keydown)="$event.stopPropagation()"
          [clearable]="true"></coin-v2-text-input>
      </div>
      <!-- Enables search opening with no available options -->
      <mat-option *ngIf="!options.length" hidden></mat-option>
    </ng-container>

    <!--Dropdown Options -->
    <mat-option *ngIf="!isRequired && !multiple" [value]="null">---</mat-option>
    <mat-option *ngFor="let option of options" [value]="option.value" [disabled]="option.disabled">
      <ng-container *ngTemplateOutlet="option.template"></ng-container>
    </mat-option>

    <!-- Temporary Workaround: provide already selected values as options so that they don't deselect when gone -->
    <ng-container *ngIf="multiple">
      <mat-option *ngFor="let item of value" hidden [value]="item"></mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
