/* eslint-disable max-lines-per-function */
import { CMS_TEXT_ARROW_RIGHT } from '../constants/cms-text-arrow.file';
import { CmsEmailIndexListItemContent } from '../models/cms-email-index-list-item.model';
import { CmsItem } from '../models/cms-item.model';
import { TransformEmailHtmlHelper } from './transform-email-html.helper';

export class CmsEmailIndexListElementHelper {
  public static getIndexListHtml(item: CmsItem<'index-list'>): string {
    let body = `
    <table 
      id="${item.id}" 
      cellpadding="0"
      cellspacing="0"
      border="0"
      style="
        color: ${item.textColor};
        ${TransformEmailHtmlHelper.defaultPadding} 
        background-color: ${item.backgroundColor};
        width: 100%
      ">
    <tbody>
      ${TransformEmailHtmlHelper.getHtmlSpacerRow(item.paddingTop)}
    `;

    item.content.forEach(indexItem => {
      body += this.getEmailIndexListItemContentHtml(indexItem, item.textColor);
    });

    body += `
    ${TransformEmailHtmlHelper.getHtmlSpacerRow(item.paddingBottom)}
      </table>`;

    return body;
  }

  private static getEmailIndexListItemContentHtml(content: CmsEmailIndexListItemContent, color: string): string {
    return `
    <tr>
      <td>
        <table cellpadding="0" cellspacing="0" border="0" style="width: 100%">
          <tr>
            <td style="width: 25px; vertical-align: text-top;">
              <a href="#${content.referenceId}">
              <img
              src="${CMS_TEXT_ARROW_RIGHT}"
              style="
                display: block;
                width: 16px;
                height: 18px;
                margin: auto;
              "
              /></a>
            </td>
            <td style="padding-left: 5px">
            ${TransformEmailHtmlHelper.addGhostTable(
              `<a style="
              display: inline-block; 
              ${TransformEmailHtmlHelper.fontFamily} 
              font-size: 11pt; 
              color: ${color ?? 'rgb(0,0,0)'}; 
              mso-padding-alt: 0;
              text-decoration: none;
            " 
            href="#${content.referenceId}">
            ${
              this.replaceParagraph(content.title) ||
              `<p style="
                display: inline-block; margin: 0;
                padding-bottom: ${content.title ?? '15px'};"> </p>`
            }</a>`
            )}
            </td>
          </tr>
        </table>
      </td>
    </tr>`;
  }

  private static replaceParagraph(value: string): string {
    return value?.replaceAll('<p', '<span style="margin: 0;"').replaceAll('</p>', '</span>');
  }
}
