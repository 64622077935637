import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { PermissionAction, PermissionResource } from '@coin/shared/util-enums';
import { UserState } from './user.state';

interface Permissions {
  allowedResources?: PermissionResource[];
  allowedActions?: PermissionAction[];
  useLoggedInUser?: boolean;
  directAllowAccess?: boolean;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[permissions]',
  standalone: true
})
export class PermissionsDirective implements OnDestroy {
  @Input()
  public set permissions(permissions: Permissions) {
    this.newValue$.next();
    this.updateVisibilityBasedOnPermissions(permissions);
  }

  private newValue$: Subject<void> = new Subject();

  private set hasPermission(allow: boolean) {
    if (allow) {
      if (!this.viewContainer.length) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private store: Store,
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef
  ) {}

  private updateVisibilityBasedOnPermissions(permissions: Permissions): void {
    const storeReq$ = !permissions.useLoggedInUser ? this.store.select(UserState?.allPermissions) : this.store.select(UserState?.allPermissionsLoggedInUser);

    storeReq$.pipe(takeUntil(this.newValue$)).subscribe(userPermissions => {
      if (permissions.directAllowAccess) {
        this.hasPermission = true;
      } else if (userPermissions && permissions.allowedResources?.length) {
        const allowedActions = permissions.allowedActions || [PermissionAction.All, PermissionAction.Read];

        this.hasPermission = userPermissions.some(
          permission =>
            permission.resource === PermissionResource.All ||
            (permissions.allowedResources.some(res => res === permission.resource) &&
              (permission.action === PermissionAction.All || allowedActions.some(action => action === permission.action)))
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.newValue$.complete();
  }
}
