import { TreeNode } from '../route-builder';

export const OrgPlanningModule = {
  segment: 'org-planning',
  title: '',
  children: {
    OrgPlanningComponent: {
      segment: '',
      title: 'org-planning.title',
      navigationRoot: true,
      children: {
        OrgPlanningChartsComponent: {
          segment: 'charts',
          icon: 'show_chart',
          title: 'org-planning.interfaces.charts.title',
          children: {
            OrgPlanningChartMasterDataComponent: { segment: 'master-data', params: ['chartId', 'orgCode'], paramTitle: 'chartId', title: 'org-planning.charts.master-data.title' },
            OrgPlanningChartPermissionsComponent: { segment: 'admin-permissions', params: ['chartId'], paramTitle: 'chartId', title: 'org-planning.charts.admin-permissions.title' }
          }
        },

        OrgPlanningSimulationsComponent: {
          segment: 'simulations',
          icon: 'space_dashboard',
          children: {
            OrgPlanningSimulationOverviewComponent: { segment: '', title: 'org-planning.interfaces.simulations.title' },
            OrgPlanningSimulationPermissionsComponent: {
              segment: 'permissions',
              title: 'org-planning.simulations.permissions.title',
              paramTitle: 'simulationId',
              params: ['simulationId']
            },
            OrgPlanningSimulationAdminPermissionsComponent: {
              segment: 'admin-permissions',
              title: 'org-planning.simulations.admin-permissions.title',
              paramTitle: 'simulationId',
              params: ['simulationId']
            }
          }
        }
      }
    }
  }
} as const satisfies TreeNode;
