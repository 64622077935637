import { Pipe, PipeTransform } from '@angular/core';

/** Type of tuple array without the first element */
type Tail<Tuple extends unknown[]> = Tuple extends [infer H, ...infer T] ? T : never;

@Pipe({ name: 'pure', standalone: true })
export class PureFunctionPipe implements PipeTransform {
  transform<T extends (...args: unknown[]) => unknown>(value: Parameters<T>[0], func: T, ...args: Tail<Parameters<T>>): ReturnType<T> {
    return func(value, ...args) as ReturnType<T>;
  }
}
