import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInjector, UnauthorizedInterceptorService } from '@coin/modules/auth/data-access';
import { RequestTrackerInterceptorService } from '@coin/shared/data-access';

export const httpInterceptorProviders = [
  RequestTrackerInterceptorService,
  { provide: HTTP_INTERCEPTORS, useExisting: RequestTrackerInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInjector, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptorService, multi: true }
];
