/**
 * StandingPositionEvaluationAPI
 * API for standing position evaluation module.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dirk.ehrenschwender@nerdware.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ActivityStreamPage } from '../model/activity-stream-page.model';
// @ts-ignore
import { DirectPositionClassComparison } from '../model/direct-position-class-comparison.model';
// @ts-ignore
import { ExportTransactionStatus } from '../model/export-transaction-status.model';
// @ts-ignore
import { GetMercerValidationMemberState200Response } from '../model/get-mercer-validation-member-state200-response.model';
// @ts-ignore
import { MercerStandingComment } from '../model/mercer-standing-comment.model';
// @ts-ignore
import { MercerStandingDetails } from '../model/mercer-standing-details.model';
// @ts-ignore
import { MercerStandingFieldValuePage } from '../model/mercer-standing-field-value-page.model';
// @ts-ignore
import { MercerStandingResponsePage } from '../model/mercer-standing-response-page.model';
// @ts-ignore
import { PostCommentRequest } from '../model/post-comment-request.model';
// @ts-ignore
import { PostMercerStandingPartnerApiEmployeesExportRequest } from '../model/post-mercer-standing-partner-api-employees-export-request.model';
// @ts-ignore
import { StandingPositionEvaluationDashboardValues } from '../model/standing-position-evaluation-dashboard-values.model';
// @ts-ignore
import { StandingPositionEvaluationValidationRequest } from '../model/standing-position-evaluation-validation-request.model';
// @ts-ignore
import { StandingPositionEvaluationValidationResponse } from '../model/standing-position-evaluation-validation-response.model';
// @ts-ignore
import { UpdateMercerStanding } from '../model/update-mercer-standing.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface GetActivityStreamRequestParams {
    /** employee id for which the entries are returned */
    employeeId: string;
    pagingPage?: number;
    pagingSize?: number;
}

export interface GetCommentsRequestParams {
    /** employee id */
    employeeId: string;
}

export interface GetComparisonRequestParams {
    /** employee id of manager */
    id: string;
    /** direct employees are filtered to be in this orgCode if provided */
    orgCode?: string;
}

export interface GetMercerDashboardValuesRequestParams {
    queryPositionClass?: Array<string>;
    queryOrgLevel?: Array<string>;
    queryCountry?: Array<string>;
    queryOrgSize?: Array<string>;
    queryGid?: Array<string>;
    queryAre?: Array<string>;
    queryOrgCode?: Array<string>;
    queryOrgCodeStartsWith?: string;
    queryLineManagerGid?: Array<string>;
    queryMercerJobFamily?: Array<string>;
    queryMercerJobSubFamily?: Array<string>;
    queryMercerJobSpecialization?: Array<string>;
    queryMercerJobCode?: Array<string>;
    queryValidationState?: string;
    queryIsHead?: Array<boolean>;
}

export interface GetMercerValidationMemberRequestParams {
    /** employee id */
    employeeId: string;
}

export interface GetMercerValidationMemberFieldValuesRequestParams {
    queryPositionClass?: string;
    queryOrgLevel?: string;
    queryCountry?: string;
    queryOrgSize?: string;
    queryGid?: string;
    queryAre?: string;
    queryOrgCode?: string;
    queryOrgCodeStartsWith?: string;
    queryLineManagerGid?: string;
    queryMercerJobFamily?: string;
    queryMercerJobSubFamily?: string;
    queryMercerJobSpecialization?: string;
    /** Property for which the field value is to be returned */
    queryTargetProperty?: string;
    pagingPage?: number;
    pagingSize?: number;
    queryMercerJobCode?: string;
}

export interface GetMercerValidationMemberPartnerRequestParams {
    /** employee id */
    employeeId: string;
}

export interface GetMercerValidationMemberStateRequestParams {
    /** employee id */
    employeeId: string;
}

export interface GetMercerValidationMembersRequestParams {
    queryPositionClass?: Array<string>;
    queryOrgLevel?: Array<string>;
    queryCountry?: Array<string>;
    queryOrgSize?: Array<string>;
    queryGid?: Array<string>;
    queryAre?: Array<string>;
    queryOrgCode?: Array<string>;
    queryOrgCodeStartsWith?: string;
    queryLineManagerGid?: Array<string>;
    queryMercerJobFamily?: Array<string>;
    queryMercerJobSubFamily?: Array<string>;
    queryMercerJobSpecialization?: Array<string>;
    /** Maps to Firstname, Lastname, Gid and OrgCode */
    querySearch?: string;
    pagingPage?: number;
    pagingSize?: number;
    sortingOrderBy?: string;
    sortingProperty?: string;
    queryMercerJobCode?: Array<string>;
    queryValidationState?: string;
    queryIsHead?: Array<boolean>;
}

export interface GetPartnerCommentsRequestParams {
    /** employee id */
    employeeId: string;
}

export interface GetPositionClassesRequestParams {
    orgCode: string;
    jobCode: string;
    orgId?: string;
}

export interface PostCommentRequestParams {
    /** employee id */
    employeeId: string;
    postCommentRequest?: PostCommentRequest;
}

export interface PostMercerStandingPartnerApiEmployeesExportRequestParams {
    queryPositionClass?: Array<string>;
    queryOrgLevel?: Array<string>;
    queryCountry?: Array<string>;
    queryOrgSize?: Array<string>;
    queryGid?: Array<string>;
    queryAre?: Array<string>;
    queryOrgCode?: Array<string>;
    queryOrgCodeStartsWith?: string;
    queryLineManagerGid?: Array<string>;
    queryMercerJobFamily?: Array<string>;
    queryMercerJobSubFamily?: Array<string>;
    queryMercerJobSpecialization?: Array<string>;
    /** Maps to Firstname, Lastname, Gid and OrgCode */
    querySearch?: string;
    pagingPage?: number;
    pagingSize?: number;
    sortingOrderBy?: string;
    sortingProperty?: string;
    queryMercerJobCode?: Array<string>;
    queryValidationState?: string;
    queryIsHead?: Array<boolean>;
    postMercerStandingPartnerApiEmployeesExportRequest?: PostMercerStandingPartnerApiEmployeesExportRequest;
}

export interface PostPartnerCommentRequestParams {
    /** employee id */
    employeeId: string;
    postCommentRequest?: PostCommentRequest;
}

export interface PutCustomerApiMercerValidationMemberEmployeeIdRequestParams {
    /** employee id */
    employeeId: string;
    updateMercerStanding?: UpdateMercerStanding;
}

export interface PutCustomerPlausibilityCheckRequestParams {
    /** employee id */
    employeeId: string;
    standingPositionEvaluationValidationRequest: StandingPositionEvaluationValidationRequest;
}

export interface PutPartnerApiMercerValidationMemberEmployeeIdRequestParams {
    /** employee id */
    employeeId: string;
    updateMercerStanding?: UpdateMercerStanding;
}

export interface PutPartnerPlausibilityCheckRequestParams {
    /** employee id */
    employeeId: string;
    standingPositionEvaluationValidationRequest: StandingPositionEvaluationValidationRequest;
}


@Injectable({
  providedIn: 'root'
})
export class StandingPositionEvaluationService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get activity stream entries for MercerStanding
     * Returns items from activty stream for specific employee id. Available for line manager of employee, HR of assigned cluster node from employee, C&amp;B Admin and Super Admin.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityStream(requestParameters: GetActivityStreamRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ActivityStreamPage>;
    public getActivityStream(requestParameters: GetActivityStreamRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ActivityStreamPage>>;
    public getActivityStream(requestParameters: GetActivityStreamRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ActivityStreamPage>>;
    public getActivityStream(requestParameters: GetActivityStreamRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling getActivityStream.');
        }
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'paging.page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'paging.size');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/admin/api/v1/master/activity-streams/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<ActivityStreamPage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get comments
     * Gets comments made for this employee in the customer app
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getComments(requestParameters: GetCommentsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<MercerStandingComment>>;
    public getComments(requestParameters: GetCommentsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<MercerStandingComment>>>;
    public getComments(requestParameters: GetCommentsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<MercerStandingComment>>>;
    public getComments(requestParameters: GetCommentsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling getComments.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/customer/api/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/comments`;
        return this.httpClient.request<Array<MercerStandingComment>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get comparison data of position classes for directs
     * Returns direct employees with their validated position class, if PC is not validated then position class is null.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getComparison(requestParameters: GetComparisonRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<DirectPositionClassComparison>>;
    public getComparison(requestParameters: GetComparisonRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<DirectPositionClassComparison>>>;
    public getComparison(requestParameters: GetComparisonRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<DirectPositionClassComparison>>>;
    public getComparison(requestParameters: GetComparisonRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getComparison.');
        }
        const orgCode = requestParameters.orgCode;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (orgCode !== undefined && orgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orgCode, 'orgCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/customer/api/v1/master/employees/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/directs/position-class-comparisons`;
        return this.httpClient.request<Array<DirectPositionClassComparison>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Dashboard Values for Partner App
     * Provides dashboard values for partner app 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMercerDashboardValues(requestParameters: GetMercerDashboardValuesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<StandingPositionEvaluationDashboardValues>;
    public getMercerDashboardValues(requestParameters: GetMercerDashboardValuesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<StandingPositionEvaluationDashboardValues>>;
    public getMercerDashboardValues(requestParameters: GetMercerDashboardValuesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<StandingPositionEvaluationDashboardValues>>;
    public getMercerDashboardValues(requestParameters: GetMercerDashboardValuesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const queryPositionClass = requestParameters.queryPositionClass;
        const queryOrgLevel = requestParameters.queryOrgLevel;
        const queryCountry = requestParameters.queryCountry;
        const queryOrgSize = requestParameters.queryOrgSize;
        const queryGid = requestParameters.queryGid;
        const queryAre = requestParameters.queryAre;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgCodeStartsWith = requestParameters.queryOrgCodeStartsWith;
        const queryLineManagerGid = requestParameters.queryLineManagerGid;
        const queryMercerJobFamily = requestParameters.queryMercerJobFamily;
        const queryMercerJobSubFamily = requestParameters.queryMercerJobSubFamily;
        const queryMercerJobSpecialization = requestParameters.queryMercerJobSpecialization;
        const queryMercerJobCode = requestParameters.queryMercerJobCode;
        const queryValidationState = requestParameters.queryValidationState;
        const queryIsHead = requestParameters.queryIsHead;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryPositionClass) {
            queryPositionClass.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.positionClass');
            })
        }
        if (queryOrgLevel) {
            queryOrgLevel.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgLevel');
            })
        }
        if (queryCountry) {
            queryCountry.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.country');
            })
        }
        if (queryOrgSize) {
            queryOrgSize.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgSize');
            })
        }
        if (queryGid) {
            queryGid.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.gid');
            })
        }
        if (queryAre) {
            queryAre.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.are');
            })
        }
        if (queryOrgCode) {
            queryOrgCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgCode');
            })
        }
        if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCodeStartsWith, 'query.orgCodeStartsWith');
        }
        if (queryLineManagerGid) {
            queryLineManagerGid.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.lineManagerGid');
            })
        }
        if (queryMercerJobFamily) {
            queryMercerJobFamily.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.mercerJobFamily');
            })
        }
        if (queryMercerJobSubFamily) {
            queryMercerJobSubFamily.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.mercerJobSubFamily');
            })
        }
        if (queryMercerJobSpecialization) {
            queryMercerJobSpecialization.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.mercerJobSpecialization');
            })
        }
        if (queryMercerJobCode) {
            queryMercerJobCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.mercerJobCode');
            })
        }
        if (queryValidationState !== undefined && queryValidationState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryValidationState, 'query.validationState');
        }
        if (queryIsHead) {
            queryIsHead.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.isHead');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/partner/api/employees/dashboard`;
        return this.httpClient.request<StandingPositionEvaluationDashboardValues>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get MercerStanding details
     * Gets the details of MercerStanding for an employee that is current.  Meaning that the current date is checked and the data returned for the currently active time frame.  Available for line manager of employee and Super Admin.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMercerValidationMember(requestParameters: GetMercerValidationMemberRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MercerStandingDetails>;
    public getMercerValidationMember(requestParameters: GetMercerValidationMemberRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MercerStandingDetails>>;
    public getMercerValidationMember(requestParameters: GetMercerValidationMemberRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MercerStandingDetails>>;
    public getMercerValidationMember(requestParameters: GetMercerValidationMemberRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling getMercerValidationMember.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/customer/api/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<MercerStandingDetails>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get MercerStanding field values
     * Gets the field values for the list view of MercerStandings. Available for HR, C&amp;B Admin and Super Admin. 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMercerValidationMemberFieldValues(requestParameters: GetMercerValidationMemberFieldValuesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MercerStandingFieldValuePage>;
    public getMercerValidationMemberFieldValues(requestParameters: GetMercerValidationMemberFieldValuesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MercerStandingFieldValuePage>>;
    public getMercerValidationMemberFieldValues(requestParameters: GetMercerValidationMemberFieldValuesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MercerStandingFieldValuePage>>;
    public getMercerValidationMemberFieldValues(requestParameters: GetMercerValidationMemberFieldValuesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const queryPositionClass = requestParameters.queryPositionClass;
        const queryOrgLevel = requestParameters.queryOrgLevel;
        const queryCountry = requestParameters.queryCountry;
        const queryOrgSize = requestParameters.queryOrgSize;
        const queryGid = requestParameters.queryGid;
        const queryAre = requestParameters.queryAre;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgCodeStartsWith = requestParameters.queryOrgCodeStartsWith;
        const queryLineManagerGid = requestParameters.queryLineManagerGid;
        const queryMercerJobFamily = requestParameters.queryMercerJobFamily;
        const queryMercerJobSubFamily = requestParameters.queryMercerJobSubFamily;
        const queryMercerJobSpecialization = requestParameters.queryMercerJobSpecialization;
        const queryTargetProperty = requestParameters.queryTargetProperty;
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;
        const queryMercerJobCode = requestParameters.queryMercerJobCode;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryPositionClass !== undefined && queryPositionClass !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryPositionClass, 'query.positionClass');
        }
        if (queryOrgLevel !== undefined && queryOrgLevel !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgLevel, 'query.orgLevel');
        }
        if (queryCountry !== undefined && queryCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryCountry, 'query.country');
        }
        if (queryOrgSize !== undefined && queryOrgSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgSize, 'query.orgSize');
        }
        if (queryGid !== undefined && queryGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryGid, 'query.gid');
        }
        if (queryAre !== undefined && queryAre !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAre, 'query.are');
        }
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'query.orgCode');
        }
        if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCodeStartsWith, 'query.orgCodeStartsWith');
        }
        if (queryLineManagerGid !== undefined && queryLineManagerGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryLineManagerGid, 'query.lineManagerGid');
        }
        if (queryMercerJobFamily !== undefined && queryMercerJobFamily !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryMercerJobFamily, 'query.mercerJobFamily');
        }
        if (queryMercerJobSubFamily !== undefined && queryMercerJobSubFamily !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryMercerJobSubFamily, 'query.mercerJobSubFamily');
        }
        if (queryMercerJobSpecialization !== undefined && queryMercerJobSpecialization !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryMercerJobSpecialization, 'query.mercerJobSpecialization');
        }
        if (queryTargetProperty !== undefined && queryTargetProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryTargetProperty, 'query.targetProperty');
        }
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'paging.page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'paging.size');
        }
        if (queryMercerJobCode !== undefined && queryMercerJobCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryMercerJobCode, 'query.mercerJobCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/partner/api/employees/field-values`;
        return this.httpClient.request<MercerStandingFieldValuePage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get MercerStanding details
     * Gets the details of MercerStanding for an employee that is current.  Meaning that the current date is checked and the data returned for the currently active time frame.  Available for HR of assigned cluster node from employee, C&amp;B Admin and Super Admin.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMercerValidationMemberPartner(requestParameters: GetMercerValidationMemberPartnerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MercerStandingDetails>;
    public getMercerValidationMemberPartner(requestParameters: GetMercerValidationMemberPartnerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MercerStandingDetails>>;
    public getMercerValidationMemberPartner(requestParameters: GetMercerValidationMemberPartnerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MercerStandingDetails>>;
    public getMercerValidationMemberPartner(requestParameters: GetMercerValidationMemberPartnerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling getMercerValidationMemberPartner.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/partner/api/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<MercerStandingDetails>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the current validation state
     * Gets the validation state of the JobCode and PositionClass of the requested employee. Available for line manager of employee and super admin.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMercerValidationMemberState(requestParameters: GetMercerValidationMemberStateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GetMercerValidationMemberState200Response>;
    public getMercerValidationMemberState(requestParameters: GetMercerValidationMemberStateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GetMercerValidationMemberState200Response>>;
    public getMercerValidationMemberState(requestParameters: GetMercerValidationMemberStateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GetMercerValidationMemberState200Response>>;
    public getMercerValidationMemberState(requestParameters: GetMercerValidationMemberStateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling getMercerValidationMemberState.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/customer/api/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/validation-state`;
        return this.httpClient.request<GetMercerValidationMemberState200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get MercerStanding list
     * Gets the MercerStanding for every employee (excluding terminated employees) the viewer has rights to see.  Available for HR, C&amp;B Admin and Super Admin. 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMercerValidationMembers(requestParameters: GetMercerValidationMembersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MercerStandingResponsePage>;
    public getMercerValidationMembers(requestParameters: GetMercerValidationMembersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MercerStandingResponsePage>>;
    public getMercerValidationMembers(requestParameters: GetMercerValidationMembersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MercerStandingResponsePage>>;
    public getMercerValidationMembers(requestParameters: GetMercerValidationMembersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const queryPositionClass = requestParameters.queryPositionClass;
        const queryOrgLevel = requestParameters.queryOrgLevel;
        const queryCountry = requestParameters.queryCountry;
        const queryOrgSize = requestParameters.queryOrgSize;
        const queryGid = requestParameters.queryGid;
        const queryAre = requestParameters.queryAre;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgCodeStartsWith = requestParameters.queryOrgCodeStartsWith;
        const queryLineManagerGid = requestParameters.queryLineManagerGid;
        const queryMercerJobFamily = requestParameters.queryMercerJobFamily;
        const queryMercerJobSubFamily = requestParameters.queryMercerJobSubFamily;
        const queryMercerJobSpecialization = requestParameters.queryMercerJobSpecialization;
        const querySearch = requestParameters.querySearch;
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;
        const sortingOrderBy = requestParameters.sortingOrderBy;
        const sortingProperty = requestParameters.sortingProperty;
        const queryMercerJobCode = requestParameters.queryMercerJobCode;
        const queryValidationState = requestParameters.queryValidationState;
        const queryIsHead = requestParameters.queryIsHead;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryPositionClass) {
            queryPositionClass.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.positionClass');
            })
        }
        if (queryOrgLevel) {
            queryOrgLevel.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgLevel');
            })
        }
        if (queryCountry) {
            queryCountry.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.country');
            })
        }
        if (queryOrgSize) {
            queryOrgSize.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgSize');
            })
        }
        if (queryGid) {
            queryGid.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.gid');
            })
        }
        if (queryAre) {
            queryAre.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.are');
            })
        }
        if (queryOrgCode) {
            queryOrgCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgCode');
            })
        }
        if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCodeStartsWith, 'query.orgCodeStartsWith');
        }
        if (queryLineManagerGid) {
            queryLineManagerGid.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.lineManagerGid');
            })
        }
        if (queryMercerJobFamily) {
            queryMercerJobFamily.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.mercerJobFamily');
            })
        }
        if (queryMercerJobSubFamily) {
            queryMercerJobSubFamily.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.mercerJobSubFamily');
            })
        }
        if (queryMercerJobSpecialization) {
            queryMercerJobSpecialization.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.mercerJobSpecialization');
            })
        }
        if (querySearch !== undefined && querySearch !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>querySearch, 'query.search');
        }
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'paging.page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'paging.size');
        }
        if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingOrderBy, 'sorting.orderBy');
        }
        if (sortingProperty !== undefined && sortingProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingProperty, 'sorting.property');
        }
        if (queryMercerJobCode) {
            queryMercerJobCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.mercerJobCode');
            })
        }
        if (queryValidationState !== undefined && queryValidationState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryValidationState, 'query.validationState');
        }
        if (queryIsHead) {
            queryIsHead.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.isHead');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/partner/api/employees`;
        return this.httpClient.request<MercerStandingResponsePage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get comments
     * Gets comments made for this employee in the partner app
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPartnerComments(requestParameters: GetPartnerCommentsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<MercerStandingComment>>;
    public getPartnerComments(requestParameters: GetPartnerCommentsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<MercerStandingComment>>>;
    public getPartnerComments(requestParameters: GetPartnerCommentsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<MercerStandingComment>>>;
    public getPartnerComments(requestParameters: GetPartnerCommentsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling getPartnerComments.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/partner/api/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/comments`;
        return this.httpClient.request<Array<MercerStandingComment>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get suggested position class
     * Gets position class suggestion based on orgCode and jobCode
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPositionClasses(requestParameters: GetPositionClassesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<number>;
    public getPositionClasses(requestParameters: GetPositionClassesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<number>>;
    public getPositionClasses(requestParameters: GetPositionClassesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<number>>;
    public getPositionClasses(requestParameters: GetPositionClassesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const orgCode = requestParameters.orgCode;
        if (orgCode === null || orgCode === undefined) {
            throw new Error('Required parameter orgCode was null or undefined when calling getPositionClasses.');
        }
        const jobCode = requestParameters.jobCode;
        if (jobCode === null || jobCode === undefined) {
            throw new Error('Required parameter jobCode was null or undefined when calling getPositionClasses.');
        }
        const orgId = requestParameters.orgId;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (orgCode !== undefined && orgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orgCode, 'orgCode');
        }
        if (orgId !== undefined && orgId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orgId, 'orgId');
        }
        if (jobCode !== undefined && jobCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>jobCode, 'jobCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/api/v3/master/position-classes`;
        return this.httpClient.request<number>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post comment
     * Posts comment for this employee
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postComment(requestParameters: PostCommentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MercerStandingComment>;
    public postComment(requestParameters: PostCommentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MercerStandingComment>>;
    public postComment(requestParameters: PostCommentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MercerStandingComment>>;
    public postComment(requestParameters: PostCommentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling postComment.');
        }
        const postCommentRequest = requestParameters.postCommentRequest;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/customer/api/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/comments`;
        return this.httpClient.request<MercerStandingComment>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: postCommentRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post MercerStanding download
     * Starts process for download of the MercerStandings for every employee the viewer has rights to see and fits the query. Available for HR, C&amp;B Admin and Super Admin.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMercerStandingPartnerApiEmployeesExport(requestParameters: PostMercerStandingPartnerApiEmployeesExportRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ExportTransactionStatus>;
    public postMercerStandingPartnerApiEmployeesExport(requestParameters: PostMercerStandingPartnerApiEmployeesExportRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ExportTransactionStatus>>;
    public postMercerStandingPartnerApiEmployeesExport(requestParameters: PostMercerStandingPartnerApiEmployeesExportRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ExportTransactionStatus>>;
    public postMercerStandingPartnerApiEmployeesExport(requestParameters: PostMercerStandingPartnerApiEmployeesExportRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const queryPositionClass = requestParameters.queryPositionClass;
        const queryOrgLevel = requestParameters.queryOrgLevel;
        const queryCountry = requestParameters.queryCountry;
        const queryOrgSize = requestParameters.queryOrgSize;
        const queryGid = requestParameters.queryGid;
        const queryAre = requestParameters.queryAre;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgCodeStartsWith = requestParameters.queryOrgCodeStartsWith;
        const queryLineManagerGid = requestParameters.queryLineManagerGid;
        const queryMercerJobFamily = requestParameters.queryMercerJobFamily;
        const queryMercerJobSubFamily = requestParameters.queryMercerJobSubFamily;
        const queryMercerJobSpecialization = requestParameters.queryMercerJobSpecialization;
        const querySearch = requestParameters.querySearch;
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;
        const sortingOrderBy = requestParameters.sortingOrderBy;
        const sortingProperty = requestParameters.sortingProperty;
        const queryMercerJobCode = requestParameters.queryMercerJobCode;
        const queryValidationState = requestParameters.queryValidationState;
        const queryIsHead = requestParameters.queryIsHead;
        const postMercerStandingPartnerApiEmployeesExportRequest = requestParameters.postMercerStandingPartnerApiEmployeesExportRequest;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryPositionClass) {
            queryPositionClass.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.positionClass');
            })
        }
        if (queryOrgLevel) {
            queryOrgLevel.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgLevel');
            })
        }
        if (queryCountry) {
            queryCountry.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.country');
            })
        }
        if (queryOrgSize) {
            queryOrgSize.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgSize');
            })
        }
        if (queryGid) {
            queryGid.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.gid');
            })
        }
        if (queryAre) {
            queryAre.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.are');
            })
        }
        if (queryOrgCode) {
            queryOrgCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgCode');
            })
        }
        if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCodeStartsWith, 'query.orgCodeStartsWith');
        }
        if (queryLineManagerGid) {
            queryLineManagerGid.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.lineManagerGid');
            })
        }
        if (queryMercerJobFamily) {
            queryMercerJobFamily.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.mercerJobFamily');
            })
        }
        if (queryMercerJobSubFamily) {
            queryMercerJobSubFamily.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.mercerJobSubFamily');
            })
        }
        if (queryMercerJobSpecialization) {
            queryMercerJobSpecialization.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.mercerJobSpecialization');
            })
        }
        if (querySearch !== undefined && querySearch !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>querySearch, 'query.search');
        }
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'paging.page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'paging.size');
        }
        if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingOrderBy, 'sorting.orderBy');
        }
        if (sortingProperty !== undefined && sortingProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingProperty, 'sorting.property');
        }
        if (queryMercerJobCode) {
            queryMercerJobCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.mercerJobCode');
            })
        }
        if (queryValidationState !== undefined && queryValidationState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryValidationState, 'query.validationState');
        }
        if (queryIsHead) {
            queryIsHead.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.isHead');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/partner/api/employees/export`;
        return this.httpClient.request<ExportTransactionStatus>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: postMercerStandingPartnerApiEmployeesExportRequest,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post comment
     * Posts comment for this employee
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPartnerComment(requestParameters: PostPartnerCommentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MercerStandingComment>;
    public postPartnerComment(requestParameters: PostPartnerCommentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MercerStandingComment>>;
    public postPartnerComment(requestParameters: PostPartnerCommentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MercerStandingComment>>;
    public postPartnerComment(requestParameters: PostPartnerCommentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling postPartnerComment.');
        }
        const postCommentRequest = requestParameters.postCommentRequest;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/partner/api/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/comments`;
        return this.httpClient.request<MercerStandingComment>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: postCommentRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Validation Member
     * Change JobCode or PositionClass and Finalize aka Accept current state EP available for line manager, HR, C&amp;B Admin and Super Admin.  To reject a request, rejectValidation must be set to true. The only additional parameter is the Employee id which needs to be included in the request.  For the following cases, these properties always should be included in the request (in some edge cases they might be optional): - positionClass - originalProposedPositionClass - positionClassDeviationReason - jobCodeId  To finalize a request, finalizeValidation must be set to true.   To save a request, finalizeValidation must be set to false.  To unfinalize a request, finalizeValidation must be set to false and undoValidation must be set to true.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putCustomerApiMercerValidationMemberEmployeeId(requestParameters: PutCustomerApiMercerValidationMemberEmployeeIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MercerStandingDetails>;
    public putCustomerApiMercerValidationMemberEmployeeId(requestParameters: PutCustomerApiMercerValidationMemberEmployeeIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MercerStandingDetails>>;
    public putCustomerApiMercerValidationMemberEmployeeId(requestParameters: PutCustomerApiMercerValidationMemberEmployeeIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MercerStandingDetails>>;
    public putCustomerApiMercerValidationMemberEmployeeId(requestParameters: PutCustomerApiMercerValidationMemberEmployeeIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling putCustomerApiMercerValidationMemberEmployeeId.');
        }
        const updateMercerStanding = requestParameters.updateMercerStanding;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/customer/api/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<MercerStandingDetails>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateMercerStanding,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Perform plausibility check
     * These checks validate the chosen job code and position class against the superior position and within the overall organizational context.  This feature ensures data integrity and consistency in job evaluation.  Available for Line Manager, and Super Admin.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putCustomerPlausibilityCheck(requestParameters: PutCustomerPlausibilityCheckRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<StandingPositionEvaluationValidationResponse>;
    public putCustomerPlausibilityCheck(requestParameters: PutCustomerPlausibilityCheckRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<StandingPositionEvaluationValidationResponse>>;
    public putCustomerPlausibilityCheck(requestParameters: PutCustomerPlausibilityCheckRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<StandingPositionEvaluationValidationResponse>>;
    public putCustomerPlausibilityCheck(requestParameters: PutCustomerPlausibilityCheckRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling putCustomerPlausibilityCheck.');
        }
        const standingPositionEvaluationValidationRequest = requestParameters.standingPositionEvaluationValidationRequest;
        if (standingPositionEvaluationValidationRequest === null || standingPositionEvaluationValidationRequest === undefined) {
            throw new Error('Required parameter standingPositionEvaluationValidationRequest was null or undefined when calling putCustomerPlausibilityCheck.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/customer/api/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/validate`;
        return this.httpClient.request<StandingPositionEvaluationValidationResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: standingPositionEvaluationValidationRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Validation Member
     * Change JobCode or PositionClass and Finalize aka Accept current state EP available for line manager, HR, C&amp;B Admin and Super Admin.  To reject a request, rejectValidation must be set to true. The only additional parameter is the Employee id which needs to be included in the request.  For the following cases, these properties always should be included in the request (in some edge cases they might be optional): - positionClass - originalProposedPositionClass - positionClassDeviationReason - jobCodeId  To finalize a request, finalizeValidation must be set to true.   To save a request, finalizeValidation must be set to false.  To unfinalize a request, finalizeValidation must be set to false and undoValidation must be set to true. 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putPartnerApiMercerValidationMemberEmployeeId(requestParameters: PutPartnerApiMercerValidationMemberEmployeeIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MercerStandingDetails>;
    public putPartnerApiMercerValidationMemberEmployeeId(requestParameters: PutPartnerApiMercerValidationMemberEmployeeIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MercerStandingDetails>>;
    public putPartnerApiMercerValidationMemberEmployeeId(requestParameters: PutPartnerApiMercerValidationMemberEmployeeIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MercerStandingDetails>>;
    public putPartnerApiMercerValidationMemberEmployeeId(requestParameters: PutPartnerApiMercerValidationMemberEmployeeIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling putPartnerApiMercerValidationMemberEmployeeId.');
        }
        const updateMercerStanding = requestParameters.updateMercerStanding;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/partner/api/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<MercerStandingDetails>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateMercerStanding,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Perform plausibility check
     * These checks validate the chosen job code and position class against the superior position and within the overall organizational context.  This feature ensures data integrity and consistency in job evaluation.  Available for HR of assigned cluster node, C&amp;B Admin, and Super Admin.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putPartnerPlausibilityCheck(requestParameters: PutPartnerPlausibilityCheckRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<StandingPositionEvaluationValidationResponse>;
    public putPartnerPlausibilityCheck(requestParameters: PutPartnerPlausibilityCheckRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<StandingPositionEvaluationValidationResponse>>;
    public putPartnerPlausibilityCheck(requestParameters: PutPartnerPlausibilityCheckRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<StandingPositionEvaluationValidationResponse>>;
    public putPartnerPlausibilityCheck(requestParameters: PutPartnerPlausibilityCheckRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling putPartnerPlausibilityCheck.');
        }
        const standingPositionEvaluationValidationRequest = requestParameters.standingPositionEvaluationValidationRequest;
        if (standingPositionEvaluationValidationRequest === null || standingPositionEvaluationValidationRequest === undefined) {
            throw new Error('Required parameter standingPositionEvaluationValidationRequest was null or undefined when calling putPartnerPlausibilityCheck.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/mercer-standing/partner/api/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/validate`;
        return this.httpClient.request<StandingPositionEvaluationValidationResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: standingPositionEvaluationValidationRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
