import { TinyHelpers } from './tiny-helpers';

export class DownloadFileHelper {
  public static download(base64: string, name = 'coin-download'): void {
    if (!base64) {
      return;
    }

    const link = document.createElement('a');
    link.href = base64;
    link.download = name;
    document.body.append(link);
    link.click();
    link.remove();
  }

  public static extractFile(target: EventTarget): File {
    TinyHelpers.assert(target instanceof HTMLInputElement);
    const file = target.files[0];
    TinyHelpers.assert(!!file);
    target.value = '';
    return file;
  }

  public static downloadBase64File(data: string, filename: string): void {
    const { type } = /data:(?<type>.*);/.exec(data).groups;

    const bufferArray = this.base64ToArrayBuffer(data.replace(`data:${type};base64,`, ''));
    const blobStore = new Blob([bufferArray], { type });
    const dataItem = window.URL.createObjectURL(blobStore);
    DownloadFileHelper.download(dataItem, filename);
    window.URL.revokeObjectURL(dataItem);
  }

  private static base64ToArrayBuffer(data: string): Uint8Array {
    const bString = window.atob(data);
    const bLength = bString.length;
    const bytes = new Uint8Array(bLength);
    for (let i = 0; i < bLength; i++) {
      const ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
}
