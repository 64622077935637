<div class="faq-menu" [ngClass]="{ 'more-width': faqMoreWidth }">
  <div class="faq-top-bar">
    <button class="no-style top-faq-text" (click)="reduceOnePage()" [ngClass]="{ 'top-faq-text--return': faqTabIndex > 0 || generalTabIndex > 0 }" aria-label="Faq Back Button">
      {{ (faqTabIndex > 0 ? topicTitle : 'faq.' + topicTitle) | translate }}
      <span @shrinkExpand *ngIf="subtitle"> - {{ subtitle }}</span>
    </button>
    <div class="faq-close-container">
      <coin-action-button (click)="closeFaq()" class="secondary faq-close" svgIcon="cancel" btnPadding="0.55em 0.5em" [iconTextMargin]="0" role="button" aria-label="Close Faq">
        <p class="bold"></p>
      </coin-action-button>
    </div>
  </div>
  <div *ngIf="!helpData && !isLoading" class="error-container">
    <div class="warning-icon">
      <mat-icon>warning</mat-icon>
    </div>
    <div class="warning-text">
      <p class="top-faq-text">{{ 'faq.no-faq-load-info' | translate }}</p>
      <p>{{ 'faq.reload-page-info' | translate }}</p>
    </div>
  </div>
  <ng-container *ngIf="!isLoading && helpData; else loading">
    <div *ngIf="isSearchBarVisible" class="faq-search-input">
      <coin-input
        class="faq-search"
        [placeholder]="'general.search-faq' | translate"
        (input)="searchFaq()"
        icon="search"
        [(ngModel)]="search.faq"
        type="text"
        aria-label="Faq Search"></coin-input>
    </div>
    <div class="menu-container">
      <mat-tab-group [selectedIndex]="generalTabIndex" class="main-tab-height">
        <mat-tab>
          <mat-tab-group [selectedIndex]="faqTabIndex" class="main-tab-height">
            <mat-tab>
              <coin-siemens-energy-faq-top-level
                class="faq-base-tab"
                [items]="helpData"
                (openFaq)="openFaqFromSub($event)"
                (openOther)="onOpenOtherTopic($event)"></coin-siemens-energy-faq-top-level>
            </mat-tab>
            <mat-tab *ngFor="let tab of faqTabs">
              <p class="faq-padding" *ngIf="tab?.content?.length === 0">{{ 'faq.no-items-found' | translate }}</p>
              <coin-siemens-energy-faq-inbetween-level
                class="faq-base-tab"
                *ngIf="!tab.isAnswer"
                [items]="tab"
                (openFaq)="openFaqFromSub($event)"></coin-siemens-energy-faq-inbetween-level>
              <coin-siemens-energy-faq-answer-level class="faq-base-tab" *ngIf="tab.isAnswer" [items]="tab"></coin-siemens-energy-faq-answer-level>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>
  <div>
    <ng-template #loading>
      <div *ngIf="isLoading" class="loading-container">
        <app-loading-screen [local]="true" [contrast]="true"></app-loading-screen>
      </div>
    </ng-template>
  </div>
</div>
