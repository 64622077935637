import { v4 as uuid } from 'uuid';
import { PopulationScope, RuleEngineConditionTypeEnum } from '@coin/shared/util-enums';
import { RuleV2 } from './rule-v2.model';

export class RuleSetV2 {
  condition: RuleEngineConditionTypeEnum = RuleEngineConditionTypeEnum.Or;
  rules: RuleV2[] = [];
  id? = uuid();
  name? = '';
  scope? = PopulationScope.Employee;
  tags?: string[] = [];
  active?: boolean;
  version?: number;
  createdById?: string;
  createdAt?: string;
  updatedBayId?: string;
  updatedAt?: string;
  level?: string;
}

export class EmptyRuleSetV2 implements RuleSetV2 {
  name = `ruleset-${uuid()}`;
  scope = PopulationScope.Employee;
  level = 'A';
  active = true;
  condition = RuleEngineConditionTypeEnum.Or;
  rules: RuleV2[] = [];

  constructor(ruleSet?: Partial<EmptyRuleSetV2>) {
    if (!ruleSet) {
      return;
    }

    Object.assign(this, ruleSet);
  }
}
