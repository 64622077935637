export const COUNTRIES_OCEANIA = [
  'AS',
  'AU',
  'CX',
  'CC',
  'CK',
  'FJ',
  'PF',
  'GU',
  'HM',
  'KI',
  'MH',
  'FM',
  'NR',
  'NC',
  'NZ',
  'NU',
  'NF',
  'MP',
  'PW',
  'PG',
  'PN',
  'WS',
  'SB',
  'TK',
  'TO',
  'TV',
  'UM',
  'VU',
  'WF'
];
