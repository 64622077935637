<div class="app-header-container" *ngIf="isApp"></div>
<div class="custom-header" [class.deactivated]="deactivated">
  <div class="left" role="banner">
    <div class="mod-logo">
      <button class="no-style with-focus logo-wrapper" (click)="gotoStartPage()" aria-label="Home Button"></button>
      <a
        *ngIf="(responsive.isMobile$ | async) === false"
        class="mod-logo design-logo"
        href="https://ifdesign.com/en/winner-ranking/project/siemens-energy-compensation-insights-coin/350912"
        target="_blank">
        <img src="assets/svgs/design_award.svg" />
      </a>
      <ng-container *featureFlag="'displayEnvironment'">
        <div *ngIf="(responsive.isMobile$ | async) === false" class="environment">
          <mat-icon>{{ currentEnvironment.icon }}</mat-icon>
          <p class="bold">{{ currentEnvironment.type }}</p>
        </div>
      </ng-container>
    </div>
  </div>
  <coin-user-menu></coin-user-menu>
  <div class="right" [class.big-margin]="isAuthenticated$ | async">
    <coin-action-button
      class="secondary"
      (click)="toggleFaq()"
      [noicon]="true"
      aria-label="Open Faq"
      [matBadge]="totalNewFlags"
      [matBadgeHidden]="totalNewFlags === 0"
      [matBadgeOverlap]="true">
      {{ (responsive.isMobile$ | async) ? '' : ('faq.whats-new' | translate) }} ?
    </coin-action-button>
    <coin-action-button class="primary" *ngIf="!(isAuthenticated$ | async)" (click)="login()" [noicon]="true" aria-label="Login">
      {{ 'general.login' | translate }}
    </coin-action-button>
  </div>
  <div *ngIf="showFaq" #faqSidebar @slideFaq class="faq-sidebar" [ngClass]="{ 'no-menu-spacing': !(isAuthenticated$ | async) }">
    <coin-siemens-energy-faq-menu
      [helpData]="helpData[languageSelected]"
      [isLoading]="isHelpDataLoading"
      (close)="toggleFaq()"
      (openOtherTopic)="onOpenOtherFaqTopic($event)"></coin-siemens-energy-faq-menu>
  </div>
</div>
