export interface ServiceProvider {
  title: string;
  header: ServiceProviderHeader[];
  data: ServiceProviderContent[];
  footnote: ServiceProviderFootnote[];
}

export interface ServiceProviderContent {
  id: number;
  country: string;
  companyName: string;
  companyAddress: string;
  applicationUsedBySP?: string;
  spForSharePlans?: string;
  spForBudgetAllocation?: string;
}

export interface ServiceProviderHeader {
  attribute: string;
}

export interface ServiceProviderFootnote {
  attribute: string;
}

const financialSpFootnote: ServiceProviderFootnote[] = [{ attribute: 'financialSp' }];

const otherSpFootnote: ServiceProviderFootnote[] = [{ attribute: 'otherSp' }];

const finacialSpHeader: ServiceProviderHeader[] = [
  { attribute: 'id' },
  { attribute: 'country' },
  { attribute: 'companyName' },
  { attribute: 'companyAddress' },
  { attribute: 'applicationUsedBySP' },
  { attribute: 'spForSharePlans' }
];

const financialServiceProvider: ServiceProviderContent[] = [
  {
    id: 1,
    country: 'CH',
    companyName: 'Equatex AG',
    companyAddress: 'Vulkanstrasse 106<br/>8048 Zürich<br/>Schweiz',
    applicationUsedBySP: 'EquatePlus',
    spForSharePlans: 'direct-match-sap-sp-js'
  },
  {
    id: 2,
    country: 'DE',
    companyName: 'flatexDEGIRO Bank AG',
    companyAddress: 'Rotfeder-Ring 7<br/>60327 Frankfurt<br/>Deutschland',
    applicationUsedBySP: 'EquatePlus',
    spForSharePlans: 'direct-match-sap-sp-js'
  },
  {
    id: 3,
    country: 'US',
    companyName: 'Computershare Trust* Company, N.A.',
    companyAddress: '150 Royall Street<br>Canton, MA 02021<br>USA',
    applicationUsedBySP: 'EquatePlus',
    spForSharePlans: 'direct-match-sap-ip'
  }
];

const otherSpHeader: ServiceProviderHeader[] = [
  { attribute: 'id' },
  { attribute: 'country' },
  { attribute: 'companyName' },
  { attribute: 'companyAddress' },
  { attribute: 'spForBudgetAllocation' },
  { attribute: '' }
];

const otherServiceProvider: ServiceProviderContent[] = [
  {
    id: 1,
    country: 'US',
    companyName: 'First Asset HR, Inc.',
    companyAddress: 'First Asset HR, Inc<br/>175 Fifth Ave, Suite 603<br/>New York, NY 10010<br/>USA',
    spForBudgetAllocation: 'sa-perf-oriented-ratable'
  },
  {
    id: 2,
    country: 'US',
    companyName: 'Amazon Web Services Inc.*',
    companyAddress: '1200 12th  Avenue South<br/>Suite 1200<br/>Seattle, WA 98144<br/>USA',
    spForBudgetAllocation: 'sa-perf-oriented-ratable'
  },
  {
    id: 3,
    country: 'DE',
    companyName: 'Siemens AG',
    companyAddress: 'Werner-von-Siemens-Str.1<br/>80333 München<br/>Deutschland',
    spForBudgetAllocation: 'direct-match-sap-sp-js'
  }
];

export const availableServiceProvider: ServiceProvider[] = [
  {
    title: 'financial-service-provider',
    header: finacialSpHeader,
    data: financialServiceProvider,
    footnote: financialSpFootnote
  },
  {
    title: 'other-service-provider',
    header: otherSpHeader,
    data: otherServiceProvider,
    footnote: otherSpFootnote
  }
];
