export enum StorageKey {
  LANGUAGE = 'language',
  ACCESS_TOKEN = 'access_token',
  ID_TOKEN = 'id_token',
  EMULATION_TOKEN = 'emulation_token',
  USER_GUID = 'userGuid',
  FIRST_VISIT_COMPLETED = 'firstVisitCompleted',
  FIRST_G2M_COMPLETED = 'firstG2mVisitCompleted',
  SIDEBAR_VISIBLE = 'isHomeSidebarVisible',
  LS_CONFIRMED = 'legalConfirmed',
  LS_TIME = 'confirmedTime',
  REDIRECT_URL = 'redirectUrl',
  // potentially unused
  ELIGIBILITIES = 'eligibilities',

  COSMOS_PREFERENCES = 'cosmos_preferences',
  ADMIN_NEW_STYLING = 'adminNewStylingEnabled',
  RECENTLY_EMULATED = 'recentlyEmulated',

  TRANSLATIONS_DEBUG_MODE_ENABLED = 'translationsDebugModeEnabled'
}
