<span
  ><p *ngIf="!usages">
    {{ 'cms.click-calculate-usages-info' | translate }}
    <mat-icon class="material-icons-outlined" [matTooltip]="'cms.calculate-image-usages-tooltip' | translate" matTooltipPosition="above">info_outlined</mat-icon>
  </p>
  <p *ngIf="usages">{{ 'cms.selected-image-used-in-header' | translate }}</p>
</span>
<mat-accordion *ngIf="usages">
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [disabled]="!usages?.emails?.length">
    <mat-expansion-panel-header>
      <mat-panel-title> Emails </mat-panel-title>
      <mat-panel-description>
        {{ usages?.emails?.length ? getCountTranslation + usages?.emails?.length + ' Emails' : getNoUsageFoundTranslation }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-list *ngIf="usages?.emails?.length">
      <mat-list-item *ngFor="let emailKey of usages.emails">
        <a [href]="getEmailHref(emailKey)" target="_blank"> &#x2022; {{ getEmailDisplayName(emailKey) }}</a>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>

  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [disabled]="!usages?.news?.length">
    <mat-expansion-panel-header>
      <mat-panel-title> News </mat-panel-title>
      <mat-panel-description>
        {{ usages?.news?.length ? getCountTranslation + usages?.news?.length + ' News' : getNoUsageFoundTranslation }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-list *ngIf="usages?.news?.length" class="scroll-list">
      <mat-list-item *ngFor="let newsKey of usages.news">
        <a [href]="getNewsHref(newsKey)" target="_blank"> &#x2022; {{ getNewsDisplayName(newsKey) }}</a>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>

  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [disabled]="!isImageInContentPageUsed">
    <mat-expansion-panel-header>
      <mat-panel-title> Content Pages </mat-panel-title>
      <mat-panel-description>
        {{ contentPageCount ? getCountTranslation + contentPageCount + ' Content Pages' : getNoUsageFoundTranslation }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-list *ngIf="!!contentPageCount" class="scroll-list">
      <ng-container *ngFor="let item of usages.contentPages | keyvalue">
        <mat-list-item>
          <span matListItemTitle>{{ getContentPageKey(item.key) }}</span>
        </mat-list-item>
        <mat-list *ngFor="let contentPage of item.value">
          <mat-list-item>
            <a [href]="getContentPageHref(contentPage)" target="_blank"> &#x2022; {{ contentPage }}</a>
          </mat-list-item>
        </mat-list>
      </ng-container>
    </mat-list>
  </mat-expansion-panel>
</mat-accordion>
