import { Injectable } from '@angular/core';
import { RtlLanguage } from '@coin/shared/util-enums';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public isRightToLeftLanguage(languageCode: string): boolean {
    return (Object.values(RtlLanguage) as string[]).includes(languageCode);
  }
}
