import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LottieFilesService {
  private currLottieFiles: Record<string, unknown> = {};

  constructor(private http: HttpClient) {}

  public setLottieFile(name: string, obj: unknown): void {
    this.currLottieFiles[name] = obj;
  }

  public getLottieFile(name: string): unknown {
    return this.currLottieFiles[name];
  }

  public getLottieJson(name: string): Observable<unknown> {
    return this.http.get(`assets/svgs/icon-data/${name}.json`);
  }
}
