import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export class FormsHelper {
  public static trimValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const trimmedValue = control.value.trim();
        if (trimmedValue !== control.value) {
          // Update the control value to the trimmed value
          control.setValue(trimmedValue);
        }
      }
      return null; // Return null if validation passes
    };
  }

  public static noWhiteSpaceValidator(control: FormControl<string>): ValidationErrors | null {
    if (/\s/.test(control.value)) {
      return { whitespace: 'general.whitespace-validation-error' };
    }
    return null;
  }
}
