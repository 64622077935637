import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { EquityPageComponentModel } from '../../models/equity-page-component.model';

@Component({
  selector: 'app-equity-headline',
  templateUrl: './equity-headline.component.html',
  styleUrls: ['./equity-headline.component.scss']
})
export class EquityHeadlineComponent implements OnInit, EquityPageComponentModel {
  @HostBinding('class.contrast') contrast: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() data: any;

  ngOnInit(): void {
    this.contrast = this.data?.options?.contrast;
  }
}
