import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FaqDrill, FaqItem } from '@coin/shared/util-models';

import { AuthService } from '@coin/modules/auth/data-access';

@Component({
  selector: 'coin-siemens-energy-faq-top-level',
  templateUrl: './faq-top-level.component.html',
  styleUrls: ['./faq-top-level.component.scss']
})
export class FaqTopLevelComponent implements OnInit {
  @Input() items: FaqItem[];
  @Output() openFaq = new EventEmitter<FaqDrill>();
  @Output() openOther = new EventEmitter<{ title: string }>();

  public isAuthenticated: boolean;

  constructor(public authService: AuthService) {}

  async ngOnInit(): Promise<void> {
    this.isAuthenticated = this.authService.isAuthenticated$.value;
  }

  public authRequired(item: FaqItem): boolean {
    return item.requiresAuthentication ? this.isAuthenticated : true;
  }

  public openFaqItem(content: FaqItem, level: number, title?: boolean): void {
    if (content.special) {
      this.openOther.emit({ title: content.special });
    } else {
      this.openFaq.emit({ content, level, moreWidth: false, title, subtitle: undefined });
    }
  }
}
