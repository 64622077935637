import { Injectable } from '@angular/core';
import VCard from 'vcard-creator';

export interface VCardData {
  firstname: string;
  lastname: string;
  middlename: string;
  orgCode: string;
  email: string;
  landlineNumber: string;
  mobileNumber: string;
  imgData: string;
}

@Injectable()
export class VcfService {
  public generateVCard(data: VCardData): VCard {
    const vCard = new VCard();

    vCard.addName(data.lastname, data.firstname, data.middlename).addCompany('Siemens Energy AG', data.orgCode);

    if (data.email) vCard.addEmail(data.email, 'type=WORK;type=INTERNET;type=pref');
    if (data.landlineNumber) vCard.addPhoneNumber(data.landlineNumber, 'type=WORK;type=pref');
    if (data.mobileNumber) vCard.addPhoneNumber(data.mobileNumber, 'type=CELL');
    if (data.imgData) vCard.addPhoto(data.imgData);

    return vCard;
  }
}
