/* eslint-disable max-lines */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Season } from '@coin/admin/season-mgmt/util';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { IsoToCountryService } from '@coin/shared/data-management-state';
import { MercerSurveyType, SeasonSimulationState, SeasonTypeEnum } from '@coin/shared/util-enums';
import { environment } from '@coin/shared/util-environments';
import { CountryCode, MassExportTransactionStatus, PaginatedResult, SearchItemService, TransactionStatus } from '@coin/shared/util-models';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { MercerSupportTreeViewItem } from '../enums/mercer-support-tree-view-item.model';
import { MercerValidationState } from '../enums/mercer-support-validation-state.enum';
import { ResetFilterService } from '../interfaces/reset-filter-service.interface';
import { EvaluationMemberExpertPositionsDropDown } from '../models/mercer-support-evaluation-member-expert.model';
import { MercerEvaluationMember, MercerEvaluationMemberDetails, PositionSnapshotSearchResult } from '../models/mercer-support-evaluation-member.model';
import { MercerSupportIncumbentMember, UpdateJobAssignmentProposal, UpdateJobAssignmentProposalResponse } from '../models/mercer-support-incumbent-member.model';
import { MercerSupportJobCodeSuggestion } from '../models/mercer-support-job-code-suggestion.model';
import { PositionEvaluationValidationRequest, PositionEvaluationValidationResponse } from '../models/position-evaluation-validation.model';
import { PositionMetaData } from '../models/position-meta-data.model';
import { MercerPositionClassService } from './mercer-position-class.service';

@Injectable({
  providedIn: 'root'
})
export class MercerService implements SearchItemService, ResetFilterService {
  public readonly resetFilters$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private httpHelpersService: HttpHelpersService,
    private toast: ToastrService,
    private mercerPositionClassService: MercerPositionClassService,
    private isoToCountryService: IsoToCountryService
  ) {}

  public getEvaluationMembers(seasonId: string, page: number, size: number, sortQuery = '', filterQuery = ''): Observable<PaginatedResult<MercerEvaluationMember>> {
    return this.httpClient
      .get<
        PaginatedResult<MercerEvaluationMember>
      >(`${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members?Paging.page=${page + 1}&Paging.size=${size}${sortQuery}${filterQuery}`)
      .pipe(this.httpHelpersService.handleError('Cannot get members'));
  }

  public getEvaluationMemberDetails(seasonId: string, memberId: string): Observable<MercerEvaluationMemberDetails> {
    this.loadingService.present();
    return this.httpClient
      .get<MercerEvaluationMemberDetails>(`${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}`)
      .pipe(
        this.httpHelpersService.handleError('Cannot get member'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public updateEvaluationMemberDetails(seasonId: string, member: MercerEvaluationMemberDetails & { surveyType?: MercerSurveyType }): Observable<MercerEvaluationMemberDetails> {
    return this.httpClient
      .patch<MercerEvaluationMemberDetails>(`${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${member.id}`, member)
      .pipe(
        tap(() => this.toast.success('Updated successfully')),
        this.httpHelpersService.handleError('Cannot update member'),
        this.loadingService.withLoadingScreen
      );
  }

  public undoPositionClassValidation(seasonId: string, memberId: string, derivedPositionClass: number): Observable<void> {
    this.loadingService.present();
    return this.httpClient
      .patch<void>(`${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}`, {
        undoValidation: true,
        originalProposedPositionClass: derivedPositionClass,
        newPositionClass: this.mercerPositionClassService.adjustedPositionClass$.value
      })
      .pipe(
        this.httpHelpersService.handleError('Cannot undo validation'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public finalizePositionClass(
    seasonId: string,
    memberId: string,
    newPositionClass?: number,
    originalProposedPositionClass?: number,
    validationState?: MercerValidationState,
    deviationReason?: string,
    surveyType?: MercerSurveyType
  ): Observable<MercerEvaluationMember> {
    this.loadingService.present();
    return this.httpClient
      .patch<MercerEvaluationMember>(`${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}`, {
        finalizeValidation: true,
        newPositionClass,
        originalProposedPositionClass,
        validationState,
        deviationReason,
        surveyType
      })
      .pipe(
        this.httpHelpersService.handleError('Cannot finalize position'),
        finalize(() => this.loadingService.dismiss())
      );
  }
  public getSeasons(managerEndDateAt = new Date().toISOString()): Observable<Season[]> {
    const simulationStatesFilter = [SeasonSimulationState.Frozen, SeasonSimulationState.InAllocation, SeasonSimulationState.PrePublished, SeasonSimulationState.Published]
      .map(state => `&simulationState=${state}`)
      .join('');

    this.loadingService.present();
    return this.httpClient
      .get<
        PaginatedResult<Season>
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons?seasonType=${SeasonTypeEnum.PositionEvaluation}${simulationStatesFilter}&query.managerEndDateAt=${managerEndDateAt}&Paging.page=1&Paging.size=1000`)
      .pipe(
        map(data => data?.content),
        this.httpHelpersService.handleError('Cannot get seasons'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getFieldValues(
    page: number,
    size: number,
    key: string,
    searchText: string,
    seasonId: string,
    parentOrgCode?: string,
    searchSuffix = ''
  ): Observable<PaginatedResult<string>> {
    const searchQuery = searchText?.trim() ? `&Query.${key}=${encodeURIComponent(searchText)}` : '';

    return this.httpClient
      .get<
        PaginatedResult<string>
      >(`${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/field-values?page=${page + 1}&size=${size}&targetProperty=${key}${searchQuery}${searchSuffix}`)
      .pipe(catchError(() => of(null)));
  }

  public getSearchItemsByText(text: string, page: number, seasonId: string, searchSuffix?: string): Observable<PositionSnapshotSearchResult[]> {
    const encodedText = encodeURIComponent(text);
    return this.httpClient
      .get<
        PaginatedResult<PositionSnapshotSearchResult>
      >(`${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/${seasonId}/positions?Paging.page=${page + 1}&Paging.size=50&Query.positionName=${encodedText}&Query.incumbentFirstName=${encodedText}&Query.incumbentLastName=${encodedText}&Query.incumbentGid=${encodedText}&Query.orgCode=${encodedText}${searchSuffix ?? ''}`)
      .pipe(
        map(res => res.content),
        this.httpHelpersService.handleError('Cannot get search item')
      );
  }

  public downloadExcelExport(seasonId: string, fileName: string, filterQuery = ''): Observable<TransactionStatus<MassExportTransactionStatus>> {
    return this.httpClient.post<TransactionStatus<MassExportTransactionStatus>>(
      `${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/export${filterQuery}`,
      {
        seasonId: seasonId,
        fileName: fileName
      }
    );
  }

  public addNewCandidate(seasonId: string, memberId: string, employeeId: string): Observable<void> {
    this.loadingService.present();
    return this.httpClient
      .post<void>(`${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/proposals`, { employeeId })
      .pipe(
        this.httpHelpersService.handleError('Cannot add candidate'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getIncumbentCandidates(seasonId: string, memberId: string): Observable<MercerSupportIncumbentMember[]> {
    return this.httpClient
      .get<
        MercerSupportIncumbentMember[]
      >(`${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/proposals/details`)
      .pipe(this.httpHelpersService.handleError('Cannot get incumbent candidates'));
  }

  public updateJobAssignmentProposal(
    seasonId: string,
    memberId: string,
    proposalId: string,
    update: UpdateJobAssignmentProposal
  ): Observable<UpdateJobAssignmentProposalResponse[]> {
    this.loadingService.present();
    return this.httpClient
      .put<
        UpdateJobAssignmentProposalResponse[]
      >(`${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/proposals/${proposalId}`, update)
      .pipe(
        tap(() => this.toast.success('Updated successfully')),
        this.httpHelpersService.handleError('Cannot update proposal'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public deleteJobAssignmentProposal(seasonId: string, memberId: string, id: string): Observable<void> {
    this.loadingService.present();
    return this.httpClient
      .delete<void>(`${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/proposals`, { body: { id } })
      .pipe(
        tap(() => this.toast.success('Deleted successfully')),
        this.httpHelpersService.handleError('Cannot delete proposal'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getTreeViewItems(seasonId: string, parentOrgCode: string, filterQuery = ''): Observable<MercerSupportTreeViewItem[]> {
    this.loadingService.present();
    return this.httpClient
      .get<
        MercerSupportTreeViewItem[]
      >(`${environment.api.baseUrl}/customer/api/v3/master/position-snapshots/${seasonId}?parentOrgCode=${encodeURIComponent(parentOrgCode)}${filterQuery}`)
      .pipe(
        this.httpHelpersService.handleError('Cannot get positions'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public loadAllParentHeadPositions(seasonId: string, orgCode: string): Observable<MercerSupportTreeViewItem[]> {
    this.loadingService.present();
    return this.httpClient
      .get<MercerSupportTreeViewItem[]>(`${environment.api.baseUrl}/customer/api/v3/master/position-snapshots/${seasonId}?orgCode=${encodeURIComponent(orgCode)}`)
      .pipe(
        map(positions => positions.sort((itemA, itemB) => itemA?.orgLevel - itemB?.orgLevel)),
        this.httpHelpersService.handleError('Cannot get parent head positions'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getJobCodeSuggestions(seasonId: string, memberId: string): Observable<MercerSupportJobCodeSuggestion[]> {
    this.loadingService.present();
    return this.httpClient
      .get<
        MercerSupportJobCodeSuggestion[]
      >(`${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${memberId}/job-code-suggestions`)
      .pipe(
        this.httpHelpersService.handleError('Cannot get job code suggestions'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getExperts(seasonId: string, evaluationMemberId: string): Observable<EvaluationMemberExpertPositionsDropDown> {
    this.loadingService.present();
    return this.httpClient
      .get<EvaluationMemberExpertPositionsDropDown>(
        `${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${evaluationMemberId}/experts`
      )
      .pipe(finalize(() => this.loadingService.dismiss()));
  }

  public performConsistencyCheck(seasonId: string, evaluationMemberId: string, body: PositionEvaluationValidationRequest): Observable<PositionEvaluationValidationResponse> {
    return this.httpClient
      .put<PositionEvaluationValidationResponse>(
        `${environment.api.baseUrl}/admin/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/${evaluationMemberId}/validate`,
        body
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.toast.error(err.error?.reason ?? err.error?.message);
          return throwError(() => err);
        }),
        tap(validationResponse => {
          validationResponse.warnings?.forEach(warning => this.toast.warning(warning));
        }),
        this.loadingService.withLoadingScreen
      );
  }

  public loadCountries(): Observable<CountryCode[]> {
    return this.isoToCountryService.loadCountries();
  }

  public massValidatePositionClass(
    seasonId: string,
    body: { ids: string[]; newJobCodeId: string; newPositionClass: number; originalProposedPositionClass: number; deviationReason: string }
  ): Observable<TransactionStatus<PositionMetaData>> {
    return this.httpClient
      .put<TransactionStatus<PositionMetaData>>(`${environment.api.baseUrl}/customer/api/v2/master/seasons/position-evaluation/${seasonId}/evaluation-members/mass-validate`, body)
      .pipe(this.httpHelpersService.handleError('Cannot finalize position'), this.loadingService.withLoadingScreen);
  }
}
