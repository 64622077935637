import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GenericSimulationResponse, RuleSetV2, SecuritySetting, SimpleTableService, TransactionStatus } from '@coin/shared/util-models';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@coin/shared/util-environments';
import { map, tap } from 'rxjs/operators';
import { RuleEngineHelper } from '@coin/shared/util-helpers';
import { PlanType } from '@coin/shared/util-enums';
import { HttpHelpersService } from './http-helpers';
import { LoadingService } from './loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeSecuritySettingsService implements SimpleTableService {
  public queryPagedAndFilteredData = this.getSecuritySettings;
  private readonly adminRoute = 'admin/api/v3/master/employees/security-settings';
  private securitySettingsEmployeeSubject$ = new BehaviorSubject<SecuritySetting>(null);
  public securitySettingsEmployee$: Observable<SecuritySetting> = this.securitySettingsEmployeeSubject$.asObservable();

  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private httpHelper: HttpHelpersService
  ) {}

  // #region Admin
  public createSecuritySetting(body: Pick<SecuritySetting, 'name'>): Observable<Partial<SecuritySetting>> {
    return this.httpClient
      .post<SecuritySetting>(`${environment.api.baseUrl}/${this.adminRoute}`, body)
      .pipe(this.loadingService.withLoadingScreen, this.httpHelper.handleError('Cannot create security setting'));
  }

  public updateSecuritySetting(body: Partial<SecuritySetting>, id: string): Observable<SecuritySetting> {
    return this.httpClient
      .put<SecuritySetting>(`${environment.api.baseUrl}/${this.adminRoute}/${id}`, body)
      .pipe(this.loadingService.withLoadingScreen, this.httpHelper.handleError('Cannot update security setting'));
  }

  public deleteSecuritySetting(id: string): Observable<unknown> {
    return this.httpClient
      .delete(`${environment.api.baseUrl}/${this.adminRoute}/${id}`)
      .pipe(this.loadingService.withLoadingScreen, this.httpHelper.handleError('Cannot delete security setting'));
  }

  public getSecuritySettingById(id: string): Observable<SecuritySetting> {
    return this.httpClient
      .get<SecuritySetting>(`${environment.api.baseUrl}/${this.adminRoute}/${id}`)
      .pipe(this.loadingService.withLoadingScreen, this.httpHelper.handleError('Cannot get security setting'));
  }

  public getSecuritySettings(): Observable<SecuritySetting[]> {
    return this.httpClient.get<SecuritySetting[]>(`${environment.api.baseUrl}/${this.adminRoute}`).pipe(
      map(settings =>
        settings
          .map(setting => ({
            ...setting,
            isDragDisabled: setting.type === PlanType.Default
          }))
          .sort((a, b) => (b.isDragDisabled ? -1 : a.priority - b.priority))
      ),
      this.loadingService.withLoadingScreen,
      this.httpHelper.handleError('Cannot get security settings')
    );
  }

  public recalculate(): Observable<TransactionStatus> {
    return this.httpClient
      .put<TransactionStatus>(`${environment.api.baseUrl}/${this.adminRoute}/recalculation`, {})
      .pipe(this.loadingService.withLoadingScreen, this.httpHelper.handleError('Cannot recalculate security settings'));
  }

  public getSimulatedPopulation(ruleSet: RuleSetV2, size: number, skip: number, securitySettingId: string): Observable<GenericSimulationResponse> {
    const page = !skip ? 1 : Math.floor(skip / size) + 1;
    const params = new HttpParams().append('Paging.Size', size).append('Paging.Page', page);

    return this.httpClient
      .post<GenericSimulationResponse>(`${environment.api.baseUrl}/${this.adminRoute}/${securitySettingId}/population-simulation`, RuleEngineHelper.ruleSetV2ToDto(ruleSet), {
        params
      })
      .pipe(this.loadingService.withLoadingScreen, this.httpHelper.handleError('Cannot get population'));
  }

  // #endregion

  // #region Customer
  public getActiveSecuritySettingForEmployee(id: string): Observable<SecuritySetting> {
    return this.httpClient.get<SecuritySetting>(`${environment.api.baseUrl}/customer/api/v1/master/employees/${id}/security-settings`).pipe(
      this.loadingService.withLoadingScreen,
      tap(settings => this.securitySettingsEmployeeSubject$.next(settings)),
      this.httpHelper.handleError('Cannot get security settings')
    );
  }

  // #endregion
}
