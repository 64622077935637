<coin-c-org-tree-overview-item
  *ngFor="let head of heads$ | async as heads; let i = index"
  [head]="head"
  [level]="level"
  [last]="heads.length - 1 === i"
  [lastParent]="lastParent"
  [selectedEmployeeId]="selectedEmployeeId"
  [customOpenEmployeeInViewAction]="customOpenEmployeeInViewAction"
  [service]="service"
  [seasonId]="seasonId"
  (clickTreeItem)="itemSelect.emit()">
</coin-c-org-tree-overview-item>
