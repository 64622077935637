<!-- --------------------------------------- -->

<div class="container">
  <div class="close-button" (click)="close()">
    <!-- <coin-anim-icon svgIcon="cancel" [hoverAnimate]="true"></coin-anim-icon> -->
    <mat-icon>close</mat-icon>
  </div>
  <div class="content">
    <div></div>
    <h4 class="top-headline">{{ 'main-menu.my-directs' | translate }}</h4>
    <mat-tab-group mat-align-tabs="center" [dynamicHeight]="false" style="margin-top: -45px" (selectedTabChange)="onTabSelectedChange($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'main-menu.directs-all' | translate }}
          <p class="counter">{{ directs.allDirects?.length }}</p>
        </ng-template>
        <coin-directs-overview-tab-view [(searchEmployee)]="searchEmployee" [employeeList]="directs.allDirects" #tabAllDirects></coin-directs-overview-tab-view>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'main-menu.directs-line-manager' | translate }}
          <p class="counter">{{ directs.lineManager?.length }}</p>
        </ng-template>
        <coin-directs-overview-tab-view [(searchEmployee)]="searchEmployee" [employeeList]="directs.lineManager" #tabLineManager></coin-directs-overview-tab-view>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'main-menu.directs-in-company' | translate }}
          <p class="counter">{{ directs.inCompanyManager?.length }}</p>
        </ng-template>
        <coin-directs-overview-tab-view [(searchEmployee)]="searchEmployee" [employeeList]="directs.inCompanyManager" #tabInCompanyManager></coin-directs-overview-tab-view>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
