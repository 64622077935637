export enum SeasonProcessState {
  SeasonOverview = 'SeasonOverview',
  Population = 'Population',
  PlanManagement = 'PlanManagement',
  Monitoring = 'Monitoring',
  TargetAchievement = 'TargetAchievement',
  SchemeManagement = 'SchemeManagement',
  SimulationManagement = 'SimulationManagement',
  BudgetAllocation = 'BudgetAllocation',
  Evaluation = 'Evaluation',
  PlanAssignment = 'PlanAssignment',
  PlanDesign = 'PlanDesign',
  BudgetSchemeAssignment = 'SchemeAssignment',
  BudgetSchemeDesign = 'SchemeDesign',
  ParameterInput = 'ParameterInput',
  PlanProcess = 'PlanProcess',
  BudgetSettings = 'BudgetSettings',
  BudgetCalculation = 'BudgetCalculation',
  SeasonSettings = 'SeasonSettings',
  LetterAndPayroll = 'LetterAndPayroll',
  SimulationProcess = 'SimulationProcess',
  PublicationSettings = 'PublicationSettings',
  Paused = 'Paused'
}
