<div *ngIf="item?.content?.length" class="container">
  <app-equity-cms-card-item
    *ngFor="let subitem of item.content"
    [modifiedWidth]="modifiedWidth"
    [card]="subitem"
    [contentEdit]="contentEdit"
    [small]="$any(item).small"></app-equity-cms-card-item>
</div>

<div *ngIf="contentEdit && item">
  <mat-form-field appearance="fill">
    <mat-label>{{ 'cms.amount-cards' | translate }}</mat-label>
    <mat-select (selectionChange)="amountChange($event.value)" [(ngModel)]="amountCards">
      <mat-option *ngFor="let option of amountOptions" [value]="option">{{ option }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-checkbox [(ngModel)]="item['small']" [checked]="item['small']">
    {{ 'cms.content-pages.small-image' | translate }}
  </mat-checkbox>
  <mat-checkbox [(ngModel)]="item.requiresAuthentication" [checked]="item.requiresAuthentication">
    {{ 'cms.content-pages.requires-authentication' | translate }}
  </mat-checkbox>
</div>
