import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from './loading.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {
  @Input() local?: boolean;
  @Input() contrast?: boolean;

  constructor(private loadingService: LoadingService) {}

  public ngOnInit() {
    if (!this.local) {
      this.loadingService.isLoading$.next(true);
    }
  }

  public ngOnDestroy(): void {
    if (!this.local) {
      this.loadingService.isLoading$.next(false);
    }
  }
}
