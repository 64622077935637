import { Component, OnInit } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmationAddDeputyDialogConfirm } from '@coin/shared/util-models';

@Component({
  selector: 'coin-c-confirmation-add-deputy-dialog',
  templateUrl: './confirmation-add-deputy-dialog.component.html',
  styleUrls: ['./confirmation-add-deputy-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class ConfirmationAddDeputyDialogComponent implements OnInit {
  startsAt: Date;
  endsAt: Date;

  constructor(private dialogRef: MatDialogRef<ConfirmationAddDeputyDialogComponent>) {}

  public ngOnInit(): void {
    // temporary workaround to not break unit test
    if (typeof this.dialogRef.addPanelClass === 'function') {
      this.dialogRef.addPanelClass('d1r-dialog-container');
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public confirm(): void {
    this.dialogRef.close({
      startsAt: this.startsAt,
      endsAt: this.endsAt,
      clickedButton: 'Confirm'
    } as ConfirmationAddDeputyDialogConfirm);
  }
}
