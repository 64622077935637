import { Injectable } from '@angular/core';
import { LifecycleHelper, ObservableCachingHelper } from '@coin/shared/util-helpers';
import { HttpClient } from '@angular/common/http';
import { ExchangeConversionRequest, ExchangeConversionResponse } from '@coin/shared/util-models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@coin/shared/util-environments';

@Injectable({
  providedIn: 'root'
})
export class CurrencyConversionService extends LifecycleHelper {
  private cachingHelper = new ObservableCachingHelper<number>();

  constructor(private httpClient: HttpClient) {
    super();
    this.addToLifecycle(this.cachingHelper);
  }

  /**
   * Retrieves the conversion rate for the given currencies
   * If the origin currency is EUR the origin and target are switched and the result is '1 / fxRate'
   * This is done, because mainly see rates to EUR are stored in the BE
   * @param originalRequest Origin Currency, Target Currency and Conversion Date
   * @returns fx rate
   */
  public getFxRate(originalRequest: ExchangeConversionRequest, forceConversion?: boolean): Observable<number> {
    if (originalRequest.to === originalRequest.from) {
      return of(1);
    }

    if (forceConversion) {
      return this.getConversion(originalRequest);
    }

    return this.getConversion(this.transformRequest(originalRequest)).pipe(map(conversionRate => (originalRequest.from === 'EUR' ? 1 / conversionRate : conversionRate)));
  }

  private getConversion(request: ExchangeConversionRequest): Observable<number> {
    return this.cachingHelper.cacheSetOnMiss$(
      this.httpClient
        .get<ExchangeConversionResponse[]>(`${environment.api.baseUrl}/admin/api/v1/master/finances/exchange-rates?from=${request.from}&to=${request.to}&date=${request.date}`)
        .pipe(map(result => result?.[0]?.scalingFactor ?? 1)),
      [request.date, request.from, request.to],
      true
    );
  }

  // We use only fx rates in direction local -> EUR and build the inverse ourselves
  private transformRequest(data: ExchangeConversionRequest): ExchangeConversionRequest {
    if (data.to === 'EUR' || data.from !== 'EUR') {
      return data;
    }

    return {
      to: data.from, // this will be 'EUR'
      from: data.to,
      date: data.date
    } as ExchangeConversionRequest;
  }
}
