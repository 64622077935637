<div *ngIf="item" class="topic-color topic-color--{{ item.type }}" [class.task-done]="isHistoric"></div>
<div *ngIf="item" style="height: 100%; width: calc(100% - 1em)" [class.task-done]="isHistoric">
  <div style="display: flex; height: 100%; overflow: hidden" *ngIf="!isModal">
    <div class="date" [ngClass]="{ modal: isModal }">
      <div class="date-icon date-icon--{{ item.type }}"></div>
      <p>{{ item?.dateStart | date: dateFormat }}</p>
      <p *ngIf="isTypeTask">- {{ item?.dateEnd | date: dateFormat }}</p>
    </div>
    <img aria-label="topic item image" class="image-wrapper" [src]="topicImage" />
    <div class="letter-task-info" *ngIf="isLetterTask || isAllocationTask">
      <div class="chart-wrapper">
        <canvas #infoChart></canvas>
        <div class="percentage">
          <p>{{ getPercentage(allDoneTodos?.length / item?.todos?.length) }} %</p>
        </div>
      </div>
      <div class="done-status">
        <p>{{ 'general.completed' | translate }}</p>
        <span> <mat-icon>people</mat-icon> {{ allDoneTodos?.length }} / {{ item?.todos?.length }} </span>
      </div>
    </div>
    <div class="text-info">
      <h3>{{ languageContent?.title || item?.title }}</h3>
      <div class="text" style="flex-direction: column">
        <p>{{ languageContent?.description }}</p>
      </div>
    </div>
    <div class="more-details">
      <coin-action-button class="primary" *ngIf="isShowcase; else actionButtons">
        {{ 'season-overview.action-btn' | translate }}
      </coin-action-button>

      <ng-template #actionButtons>
        <coin-action-button
          class="primary"
          *ngIf="
            (contentDto?.elements?.length || contentDto?.useCmsNews || !(contentDto?.useOldNews && contentDto?.elements?.length)) &&
            !isLetterTask &&
            !isAllocationTask &&
            !isFinalIncumbentTask &&
            !isHeadcountTask
          "
          (click)="openContent()">
          <ng-container *ngIf="item.type === communicationTypeEnum.News">{{ 'season-overview.' + item.type + '-other-btn' | translate }}</ng-container>
          <ng-container *ngIf="item.type === communicationTypeEnum.Task">{{ 'season-overview.' + item.type + '-other-btn' | translate }}</ng-container>
          <ng-container *ngIf="item.type === communicationTypeEnum.Announcement">{{ 'season-overview.' + item.type + '-other-btn' | translate }}</ng-container>
        </coin-action-button>
        <coin-action-button class="primary" *ngIf="item.type === communicationTypeEnum.Task && item.season && item.simulation" (click)="openBudgetDistribution()">{{
          'season-overview.' + item.type + '-bd-btn' | translate
        }}</coin-action-button>
        <coin-action-button class="primary" *ngIf="item.type === communicationTypeEnum.Task && isLetterTask" (click)="openTafDialog()">{{
          'season-overview.' + item.type + '-other-btn' | translate
        }}</coin-action-button>
        <coin-action-button class="primary" *ngIf="item.type === communicationTypeEnum.Task && isAllocationTask" (click)="openAllocation()">{{
          'season-overview.' + item.type + '-other-btn' | translate
        }}</coin-action-button>
        <coin-action-button class="primary" *ngIf="item.type === communicationTypeEnum.Task && isFinalIncumbentTask" (click)="openManagerApp()">{{
          'season-overview.' + item.type + '-other-btn' | translate
        }}</coin-action-button>
        <coin-action-button class="primary" *ngIf="item.type === communicationTypeEnum.News && isHeadcountTask" (click)="openHeadcountApproval()"
          >{{ 'season-overview.' + item.type + '-other-btn' | translate }}
        </coin-action-button>
        <coin-action-button
          class="primary"
          *ngIf="item.type === communicationTypeEnum.Task && (!item.taskType || item.taskType !== todoTaskTypeEnum.ActiveConfirmation) && !item.season && !item.simulation"
          [disabled]="true">
          {{ 'season-overview.' + item.type + '-no-season-btn' | translate }}</coin-action-button
        >
        <coin-action-button
          class="primary"
          *ngIf="item.type === communicationTypeEnum.Task && item.taskType === todoTaskTypeEnum.ActiveConfirmation && !item.season && !item.simulation"
          (click)="finishActiveTask()"
          >{{ 'season-overview.' + item.type + '-finish-task-btn' | translate }}</coin-action-button
        >
      </ng-template>
    </div>
  </div>

  <div style="display: flex; flex-direction: column; margin-left: 10px" *ngIf="isModal" [class.task-done]="item?.done">
    <div class="date" [ngClass]="{ modal: isModal }">
      <div class="date-icon date-icon--{{ item.type }}" style="width: 50px; height: 50px; font-size: 46px"></div>
      <div style="margin-top: -15px">
        <p>{{ item?.dateStart | date: dateFormat }} -</p>
        <p>{{ item?.dateEnd | date: dateFormat }}</p>
      </div>
    </div>

    <div class="text-info">
      <h3>{{ languageContent?.title }}</h3>
      <div class="text" style="flex-direction: column">
        <p>{{ languageContent?.description }}</p>
      </div>

      <div class="more-details"></div>
    </div>
  </div>
</div>
