import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, tap } from 'rxjs/operators';
import { MonoTypeOperatorFunction, Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RequestHandlerService {
  constructor(private toast: ToastrService) {}

  public showSuccess(message: string) {
    return tap(() => {
      this.toast.success(message);
    });
  }

  public handleError<T>(message: string, showMessage?: boolean): MonoTypeOperatorFunction<T> {
    return catchError<T, Observable<never>>((res: HttpErrorResponse) => {
      const errorMessage = `${message}. ${res?.error?.reason || 'Unknown'}`;
      if (showMessage) {
        this.toast.error(errorMessage);
      }
      return throwError(errorMessage);
    });
  }
}
