export class GetDirectsInCompanyManager {
  static readonly type = '[Directs] Get Directs InCompanyManager';
  constructor(public readonly payload: { userId: string }) {}
}

export class GetDirectsLineManager {
  static readonly type = '[Directs] Get Directs LineManager';
  constructor(public readonly payload: { userId: string }) {}
}

export class GetDirects {
  static readonly type = '[Directs] Get Directs';
  constructor(public readonly payload: { userId: string }) {}
}

export class ClearDirects {
  static readonly type = '[Directs] Clear Directs';
}
