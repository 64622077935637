import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { RoundOperations } from '@coin/shared/util-helpers';

@Pipe({ name: 'coinNumberView', standalone: true })
export class ThousandsSeparatorPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(originalValue: number, shortNumber = false, twoDecimals = false, isNumberInput = false, forceNumberReturn = true): string | number {
    if (!originalValue) {
      if (forceNumberReturn) {
        return '0';
      }

      return null;
    }

    if (isNumberInput) {
      return originalValue;
    }

    let value = originalValue;

    if (shortNumber) {
      value = this.shorten(value);
    } else {
      value = RoundOperations.round(value, 2);
    }

    const format = twoDecimals ? '1.2-2' : '1.0-2';
    let result = this.decimalPipe.transform(value, format);

    if (shortNumber) {
      result += this.getShortenText(originalValue);
    }
    return result;
  }

  private shorten(value: number): number {
    if (RoundOperations.isOverMillion(value)) {
      return RoundOperations.round(value / 1_000_000, 2);
    }
    if (RoundOperations.isOverThousand(value)) {
      return RoundOperations.round(value / 1_000, 1);
    }

    return RoundOperations.round(value, 2);
  }

  private getShortenText(value: number): string {
    if (RoundOperations.isOverMillion(value)) {
      return ' Mio.';
    }
    if (RoundOperations.isOverThousand(value)) {
      return 'k';
    }

    return '';
  }
}
