/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MercerPositionClassService {
  public adjustedPositionClass$ = new BehaviorSubject<number>(null);
  public isFinalizeForManagersAllowed$ = new BehaviorSubject<boolean>(false);
  public isSavePositionClassAllowed$ = new BehaviorSubject<boolean>(false);
}
