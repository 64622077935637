import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { PaginatedResult } from '@coin/shared/util-models';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { BudgetAllocationOverview, BudgetAllocationOverviewDto } from '../models/budget-allocation-overview.model';
import { MeritAllocationSnapshot } from '../models/merit-allocation-snapshot.model';
import { EmployeeWithCompensationInfo } from '../models/merit-budget-direct-with-compensation.model';
import { MeritAllocationSnapshotMapOperations } from '../operations/merit-allocation-snapshot-map.operations';
import { MeritAllocationImpersonatedEmployeeService } from './merit-allocation-impersonated-employee.service';

@Injectable({
  providedIn: 'root'
})
export class MeritAllocationDirectsService {
  constructor(
    private httpClient: HttpClient,
    private httpHelpersService: HttpHelpersService,
    private loadingService: LoadingService,
    private meritAllocationImpersonatedEmployeeService: MeritAllocationImpersonatedEmployeeService
  ) {}

  getDirects(seasonId: string, filterText = ''): Observable<EmployeeWithCompensationInfo[]> {
    const defaultSize = 1000;

    this.loadingService.present();
    return this.httpClient
      .get<
        PaginatedResult<MeritAllocationSnapshot>
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/directs?paging.page=1&paging.size=${defaultSize}${filterText}${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery()}`)
      .pipe(
        map(data => MeritAllocationSnapshotMapOperations.mapSnapshotsToEmployees(data.content)),
        this.httpHelpersService.handleError('Cannot get allocation directs'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getDirect(seasonId: string, employeeId: string): Observable<EmployeeWithCompensationInfo> {
    this.loadingService.present();
    return this.httpClient
      .get<MeritAllocationSnapshot>(
        `${
          environment.api.baseUrl
        }/admin/api/v2/master/seasons/merit/${seasonId}/allocations/total/${employeeId}${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery('?')}`
      )
      .pipe(
        map(direct => MeritAllocationSnapshotMapOperations.mapSnapshotsToEmployees([direct])[0]),
        this.httpHelpersService.handleError('Cannot get allocation direct'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getAllocationDirectsOverview(seasonId: string): Observable<BudgetAllocationOverview> {
    this.loadingService.present();
    return this.httpClient
      .get<BudgetAllocationOverviewDto>(
        `${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/directs/overview${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery(
          '?'
        )}`
      )
      .pipe(
        map(overview => new BudgetAllocationOverview(overview)),
        catchError(() => of(null)),
        finalize(() => this.loadingService.dismiss())
      );
  }
}
