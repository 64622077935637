<div class="item-container">
  <div class="pic-container">
    <div class="pic-image" [ngStyle]="{ backgroundImage: 'url(' + item.img + ')' }"></div>
  </div>
  <div class="text-container">
    <h3 *ngIf="!contentEdit">{{ item.headline }}</h3>
    <h3
      *ngIf="contentEdit"
      [contentEditable]="contentEdit"
      [textContent]="item.headline"
      (input)="item.headline = $event.target.textContent ? $event.target.textContent : 'headline'"></h3>
    <p *ngIf="!contentEdit" style="margin-top: 30px; margin-bottom: 30px" [innerHTML]="item.text"></p>
    <app-html-editor *ngIf="contentEdit" [item]="item" [contentEdit]="true" [isNews]="true" style="margin-top: 15px"></app-html-editor>
    <div class="link" *ngIf="item.link && item.link.length">
      <coin-action-button (click)="gotoPage(item.link)" class="primary" icon="arrow" *ngIf="!contentEdit">{{ item.buttonText }}</coin-action-button>
      <!-- <a *ngIf="!contentEdit" [href]="item.link" target="_blank">{{getTranslateText(item.buttonText)}}</a> -->
      <mat-form-field *ngIf="contentEdit" style="width: 100%; margin-top: 20px">
        <mat-label>{{ 'cms.button-text' | translate }}</mat-label>
        <input matInput aria-label="Button text" [(ngModel)]="item.buttonText" [placeholder]="'Button text'" [disabled]="!contentEdit" type="text" />
      </mat-form-field>
      <mat-form-field *ngIf="contentEdit" style="width: 100%; margin-top: 10px">
        <mat-label>{{ 'cms.button-link-url' | translate }}</mat-label>
        <input matInput aria-label="Link url" [(ngModel)]="item.link" [placeholder]="'https://...'" [disabled]="!contentEdit" type="test" />
      </mat-form-field>
      <!-- <a *ngIf="contentEdit" [contentEditable]="contentEdit" [textContent]="getTranslateText(item.buttonText)" ((input)="updateText($event.target.textContent, item.buttonText)" target="_blank"></a> -->
      <!-- <i class="icon icon--arrow-right"></i> -->
    </div>
  </div>
</div>

<div class="inputs" *ngIf="contentEdit">
  <!-- <mat-form-field *ngIf="contentEdit">
        <mat-label>{{'cms.link-url' | translate}}</mat-label>
        <input matInput aria-label="Link url" [(ngModel)]="item.link" [placeholder]="'https://...'" [disabled]="!contentEdit" type="test">
    </mat-form-field> -->
  <coin-action-button (click)="editPicture(item)" class="secondary" svgIcon="edit" [noicon]="true" *ngIf="contentEdit">Change Picture</coin-action-button>
</div>
