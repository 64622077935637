import { DestroyRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { FeatureFlagName } from '@coin/shared/util-models';
import { FeatureFlagsService } from '@coin/shared/data-management-state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * @example
 * // with else-template
 * <ng-container *featureFlag="'exampleFlag'; flagDisabledTemplate: oldState">
 *     <h1>NEW STATE<h1>
 * </ng-container>
 * <ng-template #oldState>
 * 	<h1>OLD STATE</h1>
 * </ng-template>
 * @example
 * // without else-template
 * <ng-container *featureFlag="'exampleFlag'">
 *     <h1>NEW STATE<h1>
 * </ng-container>
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[featureFlag]',
  standalone: true
})
export class FeatureFlagDirective implements OnInit, OnDestroy {
  @Input() featureFlag: FeatureFlagName;

  // Intended duplication in Input-Name because Angular works like this
  @Input('featureFlagFlagDisabledTemplate') flagDisabledTemplate?: TemplateRef<unknown>;

  private lastFlagState: boolean;
  private subscription: Subscription;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private featureFlagService: FeatureFlagsService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.subscribeToFlagChanges();
    this.checkFeatureFlag();
  }

  private subscribeToFlagChanges(): void {
    this.subscription = this.featureFlagService.flagHasChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.checkFeatureFlag();
    });
  }

  private checkFeatureFlag(): void {
    if (this.featureFlagService.isActive(this.featureFlag) === this.lastFlagState) {
      return;
    }
    this.viewContainerRef.clear();
    if (this.featureFlagService.isActive(this.featureFlag)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (this.flagDisabledTemplate) {
      this.viewContainerRef.createEmbeddedView(this.flagDisabledTemplate);
    }
    this.lastFlagState = this.featureFlagService.isActive(this.featureFlag);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
