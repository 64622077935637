import { TreeNode } from '../route-builder';

export const SuccessionManagementSupportModule = {
  segment: 'succession-management-support',
  children: {
    SuccessionManagementSupportComponent: { segment: '' },
    SuccessionManagementSupportListViewComponent: { segment: 'list-view' },
    SuccessionManagementSupportTalentPoolsComponent: { segment: 'talent-pools' }
  }
} as const satisfies TreeNode;
