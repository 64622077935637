<button (click)="navigateHome.emit()" aria-label="Go home" class="no-style navigation-item home"><mat-icon>home</mat-icon></button>
<div #showMoreContainer>
  <button
    *ngIf="(hiddenItems$ | async)?.length > 0"
    (click)="isShowMoreContainerVisible.set(!isShowMoreContainerVisible())"
    aria-label="Show more"
    class="no-style navigation-item">
    <mat-icon>more_horiz</mat-icon>
  </button>

  <div *ngIf="isShowMoreContainerVisible()" class="show-more-container mat-elevation-z3">
    <button
      *ngFor="let item of hiddenItems$ | async; trackBy: trackById"
      (click)="onNavigateToItem(item.id)"
      [attr.aria-label]="'Navigate to ' + item.title"
      class="no-style show-more-navigation-item">
      <ng-container *ngTemplateOutlet="buttonContent; context: { item }"></ng-container>
    </button>
  </div>
</div>

<button
  *ngFor="let item of displayedItems$ | async; let i = index; let last = last; trackBy: trackById"
  (click)="onNavigateToItem(item.id)"
  [attr.aria-label]="'Navigate to ' + item.title"
  [disabled]="last && !itemTemplate"
  class="no-style navigation-item">
  <ng-container *ngTemplateOutlet="buttonContent; context: { item }"></ng-container>
</button>

<ng-template #buttonContent let-item="item">
  <ng-template [ngIf]="itemTemplate" [ngIfElse]="defaultButton">
    <div>
      <ng-container *ngTemplateOutlet="itemTemplate; context: { item }"></ng-container>
    </div>
  </ng-template>
  <ng-template #defaultButton>
    <div>
      <div>
        <p class="bold">{{ item.title }}</p>
        <p *ngIf="item.subtitle">{{ item.subtitle }}</p>
      </div>
    </div>
  </ng-template>
</ng-template>
