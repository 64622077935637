<div *ngIf="item" class="container">
  <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
  <coin-color-picker [(ngModel)]="item.textColor" [isTextColorPicker]="true"></coin-color-picker>
  <coin-a-email-padding-slider [(ngModel)]="item.paddingTop" label="Space-Top"></coin-a-email-padding-slider>
  <coin-a-email-padding-slider [(ngModel)]="item.paddingBottom" label="Space-Bottom"></coin-a-email-padding-slider>
  <mat-checkbox [(ngModel)]="item.automaticSyncEnabled" (click)="confirmAutoSync(item, $event)">{{ 'cms.emails.auto-sync-index-list' | translate }}</mat-checkbox>
  <div class="index-list" cdkDropList (cdkDropListDropped)="drop($event)">
    <div *ngFor="let index of item.content; let i = index; trackBy: trackByIndexItemFn" class="index" cdkDrag cdkDragBoundary=".index-list">
      <div class="reference-title">
        <mat-icon class="drag" [class.disabled]="item.automaticSyncEnabled" cdkDragHandle>drag_indicator</mat-icon>
        <mat-label> {{ index.referenceTitle }}</mat-label>
      </div>
      <div class="editor">
        <quill-editor class="customize-editor" [(ngModel)]="index.title"> </quill-editor>
      </div>
      <div class="delete">
        <mat-icon class="delete" [class.disabled]="item.automaticSyncEnabled" (click)="deleteItem(index)">delete</mat-icon>
      </div>
    </div>
  </div>

  <div class="buttons">
    <coin-action-button [disabled]="item.automaticSyncEnabled" (click)="addItem()">{{ 'cms.emails.add-index-item' | translate }}</coin-action-button>
  </div>
</div>
