import { v4 as uuid } from 'uuid';

export const CMS_LETTER_TEMPLATE_LANDSCAPE_USER_BLOCK_HTML =
  `<table id="${uuid()}" class="cms-simple-table landscape-user-block">` +
  `<tbody>
  <tr>
    <td>NAME</td>
    <td>GID</td>
    <td>DEPARTMENT (current)</td>
    <td>LOCATION (current)</td>
    <td>MANAGER (current)</td>
  </tr>
  <tr>
    <td>{firstname} {lastname}</td>
    <td>{gid}</td>
    <td>{orgCode}</td>
    <td>{locationOffice}</td>
    <td>{lineManager}</td>
  </tr>
</tbody>` +
  `</table>`;
