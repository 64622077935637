import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@coin/shared/util-environments';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoadingService } from '@coin/shared/data-access';
import { BudgetAllocationOverviewDto } from '../models/budget-allocation-overview.model';
import { MeritAllocationImpersonatedEmployeeService } from './merit-allocation-impersonated-employee.service';

@Injectable({
  providedIn: 'root'
})
export class MeritTotalResponsibilityService {
  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private meritAllocationImpersonatedEmployeeService: MeritAllocationImpersonatedEmployeeService
  ) {}

  getBudgetOverview(seasonId: string, employeeId: string, orgCode: string): Observable<BudgetAllocationOverviewDto> {
    this.loadingService.present();
    return this.httpClient
      .get<BudgetAllocationOverviewDto>(
        `${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/total/overview/${employeeId}?orgCode=${encodeURIComponent(
          orgCode
        )}${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery()}`
      )
      .pipe(
        catchError(() => of(null)),
        finalize(() => this.loadingService.dismiss())
      );
  }
}
