import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, DestroyRef, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SelectImageComponent } from '../../shared/select-image/select-image.component';

export interface CardModel {
  headline: string;
  backgroundUrl: string;
  text: string;
  icon: string;
  button: {
    name: string;
    url: string;
  };
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-equity-cms-card-item',
  templateUrl: './equity-cms-card-item.component.html',
  styleUrls: ['./equity-cms-card-item.component.scss']
})
export class EquityCmsCardItemComponent {
  @Input() card: CardModel;
  @Input() small: boolean;
  @Input() contentEdit = false;
  @Input() modifiedWidth = false;

  public get iconUrl(): string {
    return `assets/equity/icons/general/${this.card?.icon}.svg`;
  }

  constructor(
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private destroyRef: DestroyRef
  ) {}

  public onButtonClick(button: CardModel['button']): void {
    if (button.url) {
      window.open(button.url, '_blank');
    }
  }

  getSanitizedUrl(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  /**
   * Open popup to set a new image from existing or upload a new one
   * @param item current image that is used in card item
   */
  public editPicture(item: CardModel): void {
    this.dialog
      .open(SelectImageComponent, { data: { img: item.backgroundUrl } })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(newImage => {
        if (newImage) {
          item.backgroundUrl = newImage.imageUrl;
        }
      });
  }
}
