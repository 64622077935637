import { TreeNode } from '../route-builder';

export const CmsModule = {
  segment: 'cms',
  title: 'home.cms',
  navigationRoot: true,
  children: {
    FaqModule: { segment: 'faq', icon: 'help_outline', children: { FaqComponent: { segment: '', title: 'cms.faq.top-headline' } } },
    LettersModule: {
      segment: 'letters',
      icon: 'local_shipping',
      children: {
        LettersComponent: { segment: '', title: 'templates-library.letters.title', children: { EditLetterPageComponent: { segment: 'edit', paramTitle: 'id', params: ['id'] } } }
      }
    },
    EmailsModule: {
      segment: 'emails',
      icon: 'email',
      children: {
        EmailsComponent: { segment: '', title: 'cms.emails.top-headline', children: { EditEmailsComponent: { segment: 'edit', paramTitle: 'key', params: ['key'] } } }
      }
    },
    EquityModule: { segment: 'content-pages', icon: 'description', children: { EquityComponent: { segment: '', title: 'cms.content-pages.top-headline' } } },
    NewsModule: {
      segment: 'news',
      icon: 'newspaper',
      children: {
        NewsComponent: { segment: '', title: 'cms.news.top-headline', children: { EditNewsComponent: { segment: 'edit', paramTitle: 'key', params: ['key'] } } }
      }
    },
    DocumentsUploadModule: {
      segment: 'documents-upload',
      icon: 'cloud_upload',
      children: { DocumentsUploadComponent: { segment: '', title: 'cms.documents-upload.top-headline' } }
    }
  }
} as const satisfies TreeNode;
