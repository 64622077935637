import { ActivityStreamItemType } from '../models/activity-stream-item-type.enum';
import { ActivityStreamChangedProperty, ActivityStreamItem } from '../models/activity-stream-item.model';

export const getProperty = (item: ActivityStreamItem, key: string): ActivityStreamChangedProperty => {
  return item.changedProperties.find(property => property.propertyName === key);
};

const getFullName = (item: ActivityStreamItem, useCurrentValue: boolean): string => {
  const firstNameProperty = getProperty(item, 'EmployeeFirstname');
  const lastNameProperty = getProperty(item, 'EmployeeLastname');
  return useCurrentValue ? `${firstNameProperty?.currentValue} ${lastNameProperty?.currentValue}` : `${firstNameProperty?.originalValue} ${lastNameProperty?.originalValue}`;
};

const getOrgCodeAndTitle = (item: ActivityStreamItem, useCurrentValue: boolean): string => {
  const key = useCurrentValue ? 'currentValue' : 'originalValue';
  const orgCodeProperty = getProperty(item, 'OrgCode');
  const orgTitleProperty = getProperty(item, 'Title');
  return orgCodeProperty && orgTitleProperty ? `${orgCodeProperty[key]} (${orgTitleProperty[key]})` : '';
};

export const ACTIVITY_STREAM_SUBTITLE_ITEM_TYPE_UI_MAPPING: Record<string, (item: ActivityStreamItem) => string> = {
  [ActivityStreamItemType.AssignedEmployee]: (item: ActivityStreamItem) => getFullName(item, true),
  [ActivityStreamItemType.UnassignedEmployee]: (item: ActivityStreamItem) => getFullName(item, false),
  [ActivityStreamItemType.AssignedPosition]: (item: ActivityStreamItem) => getOrgCodeAndTitle(item, true),
  [ActivityStreamItemType.UnassignedPosition]: (item: ActivityStreamItem) => getOrgCodeAndTitle(item, false)
};
