<coin-v2-dropdown *ngIf="!disabled; else displayFieldName" [(ngModel)]="rule.field" (ngModelChange)="resetOperatorAndValue()" required>
  <ng-template *ngFor="let option of config | keyvalue" [coinValue]="option.key">{{
    translateKeys ? ('general.rule-engine.keys.' + option.key | translate) : option.key
  }}</ng-template>
</coin-v2-dropdown>
<ng-template #displayFieldName>
  <span>{{ rule.field }}</span>
</ng-template>

<div [matMenuTriggerFor]="rule.field ? operatorOptions : null" [class.disabled]="!rule.field" class="operator">
  <ng-container *ngIf="rule.operator">
    <coin-v2-lightweight-operator-icon [operator]="rule.operator"></coin-v2-lightweight-operator-icon>
  </ng-container>
</div>
<mat-menu #operatorOptions="matMenu">
  <button *ngFor="let operator of ruleConfig?.operators ?? []" [disabled]="operator === rule.operator" (click)="setOperator(operator)" mat-menu-item class="text">
    <div class="menu-option-container">
      <coin-v2-lightweight-operator-icon [operator]="operator"></coin-v2-lightweight-operator-icon>
      <span>{{ operator | enumToText }}</span>
    </div>
  </button>
</mat-menu>

<div *ngIf="!disabled && !isValuelessOperator(rule.operator); else displayValue" class="rule-engine-input">
  <ng-container
    *ngIf="ruleConfig?.type === 'System.String' || ruleConfig?.type === 'System.DateTime' || ruleConfig?.type === 'System.Int32' || ruleConfig?.type === 'System.Double'">
    <ng-container *ngIf="!freeTextOperators.has(rule.operator) && (ruleConfig.fieldValues$ | async | pure: castToMetadata) | async as metadata; else rawInput">
      <coin-v2-dropdown
        *ngIf="!metadata.isRawInput; else rawInput"
        [(ngModel)]="rule.value"
        [disabled]="isRuleValueDisabled"
        [multiple]="true"
        [searchDebounce]="0"
        (search)="search$.next($event)"
        (ngModelChange)="changes.emit()"
        required>
        <ng-container *ngIf="rule.field">
          <ng-template *ngFor="let fieldValue of metadata.filteredFieldValues" [coinValue]="fieldValue">
            {{ fieldValue }}
          </ng-template>
        </ng-container>
      </coin-v2-dropdown>
    </ng-container>

    <ng-template #rawInput>
      <coin-v2-text-input
        *ngIf="ruleConfig?.type === 'System.String'"
        [(ngModel)]="rule.value[0]"
        [disabled]="isRuleValueDisabled"
        (ngModelChange)="changes.emit()"
        required></coin-v2-text-input>
      <coin-v2-date-input
        *ngIf="ruleConfig?.type === 'System.DateTime'"
        [(ngModel)]="rule.value[0]"
        [disabled]="isRuleValueDisabled"
        (ngModelChange)="changes.emit()"
        required></coin-v2-date-input>
      <coin-v2-number-input
        *ngIf="ruleConfig?.type === 'System.Int32' || ruleConfig?.type === 'System.Double'"
        [(ngModel)]="rule.value[0]"
        [disabled]="isRuleValueDisabled"
        (ngModelChange)="changes.emit()"
        required>
      </coin-v2-number-input>
    </ng-template>
  </ng-container>
  <coin-v2-checkbox
    *ngIf="ruleConfig?.type === 'System.Boolean'"
    [(ngModel)]="rule.value[0]"
    [disabled]="isRuleValueDisabled"
    (ngModelChange)="changes.emit()"
    required></coin-v2-checkbox>
</div>
<ng-template #displayValue>
  <span *ngIf="ruleConfig?.type !== 'System.DateTime'; else displayDateValue">{{ rule.value }}</span>
  <ng-template #displayDateValue>
    <span>{{ $any(rule.value) | date }}</span>
  </ng-template>
</ng-template>

<mat-icon
  *ngIf="!disabled"
  [class.disabled]="!deletable"
  [matTooltipDisabled]="deletable"
  [matTooltip]="'general.rule-tooltip' | translate"
  (click)="onDeleteClick()"
  class="delete"
  >delete</mat-icon
>
