<mat-accordion>
  <coin-customer-survey-question
    *ngFor="let question of value?.questions | filter : 'isActive' : true; let i = index"
    [index]="i"
    [question]="question"
    [expandedPanelIndex]="expandedPanelIndex"
    [isLocked]="isLocked"
    [isEditable]="isEditable"
    (togglePanel)="onTogglePanel(i)"
    (expandNextPanel)="expandNextPanel(i)"
    (answerToggle)="onAnswerToggle($event)"></coin-customer-survey-question>
</mat-accordion>
