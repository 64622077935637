import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { ILetter, LetterComment } from '@coin/shared/util-models';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LetterCreationService {
  constructor(
    private httpClient: HttpClient,
    private toast: ToastrService,
    private loadingService: LoadingService
  ) {}

  getLetterByEmployeeId(employeeId: string): Observable<ILetter[]> {
    return this.httpClient.get<ILetter[]>(`${environment.api.baseUrl}/admin/api/v1/master/letters?receiverId=${employeeId}`).pipe(
      switchMap(letters => (letters?.length ? forkJoin(letters.map(letter => this.addSizeToLetter(letter))) : of([]))),
      catchError((res: HttpErrorResponse) => {
        this.toast.error(`Error getting letter. ${res?.error?.reason || 'Unknown'} `);
        return of([]);
      })
    );
  }

  getLetterByDirect(employeeId: string, managerId: string): Observable<ILetter[]> {
    return this.httpClient.get<ILetter[]>(`${environment.api.baseUrl}/admin/api/v1/master/letters?receiverId=${employeeId}&managerId=${managerId}`).pipe(
      switchMap(letters => (letters?.length ? forkJoin(letters.map(letter => this.addSizeToLetter(letter))) : of([]))),
      catchError((res: HttpErrorResponse) => {
        this.toast.error(`Error getting letter. ${res?.error?.reason || 'Unknown'} `);
        return of([]);
      })
    );
  }

  private addSizeToLetter(letter: ILetter): Observable<ILetter> {
    return this.httpClient.get<number>(`${environment.api.letterServiceUrl}/letters/${encodeURIComponent(letter.name)}/size`).pipe(
      map(size => ({ ...letter, size }) as ILetter),
      catchError((res: HttpErrorResponse) => {
        console.error('Could not load size for letter: ', letter);
        return of(letter);
      })
    );
  }

  confirmLetterTodo(letterId: string, workflowInstanceId: string): Observable<object> {
    return this.httpClient
      .patch(`${environment.api.baseUrl}/admin/api/v1/master/letters/${letterId}/confirm`, {
        letterId,
        workflowInstanceId
      })
      .pipe(
        catchError((res: HttpErrorResponse) => {
          return throwError(`Error confirming todo. ${res?.error?.reason || 'Unknown'} `);
        })
      );
  }

  signLetterTodo(letterId: string, workflowInstanceId: string, letterName: string): Observable<object> {
    return this.httpClient
      .patch(`${environment.api.baseUrl}/admin/api/v1/master/letters/${letterId}/sign`, {
        letterId,
        workflowInstanceId
      })
      .pipe(
        catchError((res: HttpErrorResponse) => {
          this.toast.error(`Error signing todo. ${res?.error?.reason || 'Unknown'} `);
          return throwError(`Error signing todo. ${res?.error?.reason || 'Unknown'} `);
        })
      );
  }

  rejectLetterTodo(letterId: string, workflowInstanceId: string, letterName: string): Observable<void> {
    this.loadingService.present();
    return this.httpClient
      .patch<void>(`${environment.api.baseUrl}/admin/api/v1/master/letters/${letterId}/reject`, {
        letterId,
        workflowInstanceId
      })
      .pipe(
        switchMap(() => this.createLetterUserDocument(letterId, letterName)),
        tap(() => this.toast.warning('Rejected')),
        catchError((res: HttpErrorResponse) => {
          this.toast.error(`Error rejecting todo. ${res?.error?.reason || 'Unknown'} `);
          return throwError(`Error rejecting todo. ${res?.error?.reason || 'Unknown'} `);
        }),
        finalize(() => this.loadingService.dismiss())
      );
  }

  forwardLetterTodo(letterId: string, workflowInstanceId: string): Observable<object> {
    this.loadingService.present();
    return this.httpClient
      .patch(`${environment.api.baseUrl}/admin/api/v1/master/letters/${letterId}/forward`, {
        letterId,
        workflowInstanceId
      })
      .pipe(
        tap(() => this.toast.success('Forwarded.')),
        catchError((res: HttpErrorResponse) => {
          this.toast.error(`Error forwarding todo. ${res?.error?.reason || 'Unknown'} `);
          return throwError(`Error forwarding todo. ${res?.error?.reason || 'Unknown'} `);
        }),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getComments(letterId: string): Observable<LetterComment[]> {
    return this.httpClient.get<LetterComment[]>(`${environment.api.baseUrl}/admin/api/v1/master/letters/${letterId}/comments`).pipe(
      catchError((res: HttpErrorResponse) => {
        this.toast.error(`Error getting comments. ${res?.error?.reason || 'Unknown'} `);
        return throwError(`Error getting comments. ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }

  public postComment(letterId: string, comment: LetterComment): Observable<LetterComment> {
    comment.letterId = letterId;
    return this.httpClient.post<LetterComment>(`${environment.api.baseUrl}/admin/api/v1/master/letters/${letterId}/comments`, comment).pipe(
      catchError((res: HttpErrorResponse) => {
        this.toast.error(`Cannot send comment: ${res?.error?.reason || 'Unknown'}`);
        return throwError(`Error sending comment ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }

  addIdToSeenBy(letterId: string, employeeId: string): Observable<void> {
    return this.httpClient
      .patch<void>(`${environment.api.baseUrl}/admin/api/v1/master/letters/${letterId}/seen`, {
        id: letterId,
        employeeId
      })
      .pipe(
        catchError((res: HttpErrorResponse) => {
          return throwError(`Error confirming seeing this letter. ${res?.error?.reason || 'Unknown'} `);
        })
      );
  }

  createLetterUserDocument(letterId: string, name: string): Observable<void> {
    return this.httpClient.put<void>(`${environment.api.pdfCreationService}/creation/${letterId}`, {
      name,
      letterId
    });
  }

  createLetterUserDocumentBatch(letters: { letterId: string; name: string }[]): Observable<unknown> {
    if (!letters.length) {
      return of([]);
    }

    return this.httpClient.put(`${environment.api.pdfCreationService}/creation/batch`, letters);
  }

  getLetterUserDocument(letterName: string, letterId?: string): Observable<string> {
    return this.httpClient
      .get(`${environment.api.letterServiceUrl}/letters/${encodeURIComponent(letterName)}`, {
        responseType: 'text',
        params: {
          letterId
        }
      })
      .pipe(
        catchError((res: HttpErrorResponse) => {
          this.toast.error(`Error getting letter. ${res?.error?.reason || 'Unknown'} `);
          return throwError(`Error getting letter. ${res?.error?.reason || 'Unknown'} `);
        })
      );
  }
}
