import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmployeeDto, PaginatedResult } from '@coin/shared/util-models';
import { environment } from '@coin/shared/util-environments';

@Injectable({
  providedIn: 'root'
})
export class GetEmulationPeopleService {
  constructor(private http: HttpClient) {}

  public getEmulatePeople(
    userInput: {
      gid: string;
      name: { firstname: string; lastname: string };
    },
    page = 1
  ): Observable<PaginatedResult<EmployeeDto>> {
    let params = new HttpParams().append('Page', page);

    if (userInput.gid) {
      params = params.set('gid', userInput.gid);
    }
    if (userInput.name.firstname) {
      params = params.set('firstname', userInput.name.firstname);
    }
    if (userInput.name.lastname) {
      params = params.set('lastname', userInput.name.lastname);
    }

    return this.http.get<PaginatedResult<EmployeeDto>>(`${environment.api.baseUrl}/admin/api/v3/master/employees/cluster-nodes/members`, { params });
  }
}
