import { PermissionResource } from '@coin/shared/util-enums';
import { TreeNode } from '../route-builder';

export const RestructuringModule = {
  segment: 'restructuring',
  title: 'home.restructuring',
  navigationRoot: true,
  emptyPage: true,
  children: {
    RestructuringAttributesComponent: {
      segment: 'attributes',
      title: 'restructuring.attributes-headline',
      icon: 'attribution',
      permissions: [PermissionResource.RestructuringAdmin],
      params: ['attributeType']
    },
    RestructuringEmployeesComponent: {
      segment: 'employees',
      title: 'restructuring.employees-headline',
      icon: 'people_alt',
      permissions: [PermissionResource.RestructuringAdmin, PermissionResource.RestructuringAdminRestricted],
      params: ['country', 'legalEntity']
    }
  }
} as const satisfies TreeNode;
