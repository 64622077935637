import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, DestroyRef, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { CmsItem, fadeInHalfAnimation } from '@coin/admin/cms/util';
import { GlobalEventsService } from '@coin/shared/util-helpers';
import { SelectImageComponent } from '../../shared/select-image/select-image.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'gmm-picture-container',
  templateUrl: './picture-container.component.html',
  styleUrls: ['./picture-container.component.scss'],
  animations: [fadeInHalfAnimation]
})
export class PictureContainerComponent implements OnInit {
  @Input() item: CmsItem<'picture-container'>;
  @Input() contentEdit = false;
  @Input() isMobile = false;
  @Input() heightModified = false;
  @Input() otherPadding = false;

  public containerLength = 0;
  public scrollPosition = 0;
  public refresh = false;

  @ViewChildren('item') itemsList: QueryList<ElementRef<HTMLDivElement>>;

  public overflow: 'hidden' | 'auto' = 'auto';

  constructor(
    private dialog: MatDialog,
    private events: GlobalEventsService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    if (!this.contentEdit) {
      this.overflow = 'hidden';
    }
    this.containerLength = this.item?.pictureContainers ? this.item?.pictureContainers.length : 99999;
    this.checkContainerLength();
    this.events
      .listen('resize')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.onResize());
  }

  public checkContainerLength(): void {
    setTimeout(() => {
      this.containerLength = this.item?.pictureContainers ? this.item?.pictureContainers.length : 99999;
      this.checkContainerLength();
    }, 3000);
  }

  public deleteItem(index: number): void {
    this.item.pictureContainers.splice(index, 1);
  }

  public drop(event: CdkDragDrop<string[]>, list: PictureContainerComponent['item']['pictureContainers']): void {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }

  public editPicture(index: number): void {
    this.dialog
      .open(SelectImageComponent, { data: this.item.pictureContainers[index] })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(newImage => {
        if (newImage) {
          this.item.pictureContainers[index].img = newImage.imageUrl;
        }
      });
  }

  public onResize = (): void => {
    const container = document.getElementsByClassName('picture-cont-drag-list')[0] as HTMLElement;
    container.scrollTo({ left: 0, behavior: 'smooth' });
  };

  public addImage(): void {
    this.item.pictureContainers.push({
      img: '',
      subTitle: 'New Picture Container Subtitle',
      mainTitle: 'New Picture Container Main Title',
      text: 'New Picture Container Text',
      link: ''
    });
  }

  public scroll(direction: 'left' | 'right'): void {
    if (this.containerLength > 1) {
      this.refresh = true;
      const el = document.getElementsByClassName('picture-cont-drag-box')[1] as HTMLElement;
      const { width } = el.getBoundingClientRect();
      const container = document.getElementsByClassName('picture-cont-drag-list')[0] as HTMLElement;
      if (direction === 'right') {
        container.scrollBy({ top: 0, left: width, behavior: 'smooth' });
      } else {
        container.scrollBy({ top: 0, left: -width, behavior: 'smooth' });
      }
      setTimeout(() => {
        this.refresh = false;
      }, 400);
    }
  }

  public showArrow(arrow: 'left' | 'right'): boolean {
    const container = document.getElementsByClassName('picture-cont-drag-list')[0] as HTMLElement;
    if (arrow === 'left') {
      return container?.scrollLeft > 20;
    }
    const scrollWidth = container?.scrollWidth;
    const offsetWidth = container?.offsetWidth;
    const scrollLeft = container?.scrollLeft;
    return scrollWidth - scrollLeft > offsetWidth;
  }
}
