/* eslint-disable max-lines-per-function */
import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { LoadingService } from '@coin/shared/data-access';
import { ContractStatus, Employee } from '@coin/shared/util-models';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { produce } from 'immer';
import { asapScheduler, Observable, of } from 'rxjs';
import { concatAll, finalize, switchMap, tap } from 'rxjs/operators';
import { OrgTreeFilterType } from '../../../enums/org-tree-filter.enum';
import { OrgTreeSortType } from '../../../enums/org-tree-sorting.enum';
import { OrgTreeService } from '../services/org-tree.service';
import * as actions from './org-tree.actions';

const MAX_DEPTH_LEVEL = 10;
const DEFAULT_GID = 'n/a';

export interface OrgTreeSearchNode {
  node: Employee;
  isActive: boolean;
}
export interface OrgTreeStateModel {
  nodes: Employee[];
  breadcrumbItems: { id?: string; version?: number; childrenActive?: boolean; disabledDepth?: boolean; isRoot?: boolean }[];
  sortingType: OrgTreeSortType;
  filterType: OrgTreeFilterType;
  searchedNode: OrgTreeSearchNode;
}
@State<OrgTreeStateModel>({
  name: 'OrgTreeState',
  defaults: {
    nodes: [],
    breadcrumbItems: [],
    sortingType: OrgTreeSortType.Default,
    filterType: OrgTreeFilterType.NoFilters,
    searchedNode: { node: null, isActive: false }
  }
})
@Injectable()
export class OrgTreeState {
  constructor(
    private orgTreeService: OrgTreeService,
    private store: Store,
    private loadingService: LoadingService,
    private router: Router,
    private destroyRef: DestroyRef
  ) {}

  @Selector()
  static breadcrumbItems(state: OrgTreeStateModel) {
    return state.breadcrumbItems;
  }

  @Selector()
  static sortingType(state: OrgTreeStateModel): OrgTreeSortType {
    return state.sortingType;
  }

  @Selector()
  static currentHeadInView(state: OrgTreeStateModel): Employee {
    const id = state.breadcrumbItems[state.breadcrumbItems.length - 1]?.id;
    return state.nodes?.find(node => node.id === id);
  }

  @Selector()
  static sortedAndFilteredNodesByLastBCItem(state: OrgTreeStateModel): Employee[] {
    const id = state.breadcrumbItems[state.breadcrumbItems.length - 1]?.id;
    const parent = state.nodes?.find(node => node.id === id);
    return this.getSortedNodes(state, this.getFilteredNodes(state, parent?.childNodes));
  }

  static getFilteredNodes(state: OrgTreeStateModel, ids: string[]): Employee[] {
    const nodes = ids?.map(id => state.nodes.find(node => node.id === id));
    const baseFilter = (node: Employee): boolean => [ContractStatus.Active, ContractStatus.Dormant].includes(node.contractStatus);

    switch (state.filterType) {
      case OrgTreeFilterType.NoLineManager:
        return nodes.filter(node => baseFilter(node) && !node.lineManager && node.paOrgCode === node.orgCode);
      case OrgTreeFilterType.MultipleLineManager:
        return nodes.filter(node => baseFilter(node) && node.lineManagers?.length > 1 && node.paOrgCode === node.orgCode);
      case OrgTreeFilterType.Authorized:
        return nodes.filter(node => baseFilter(node) && node.rowLevelSecurityPass);
      default:
        return nodes.filter(node => baseFilter(node));
    }
  }

  static getSortedNodes(state: OrgTreeStateModel, nodes: Employee[]): Employee[] {
    let sortedNodes: Employee[];

    // Extendable for every employee property
    switch (state.sortingType) {
      case OrgTreeSortType.MercerJobCode:
        sortedNodes = this.sort(nodes, 'mercerJobCode');
        break;
      case OrgTreeSortType.MercerPositionClass:
        sortedNodes = this.sort(nodes, 'mercerPositionClass');
        break;
      case OrgTreeSortType.ManagementGroup:
        sortedNodes = this.sort(nodes, 'managementGroup');
        break;
      default: {
        let heads = nodes.filter(node => node.headGid);
        let employees = nodes.filter(node => !node.headGid);
        heads = this.defaultSort(heads, 'orgCode');
        employees = this.defaultSort(employees, 'paOrgCode');
        sortedNodes = [...heads, ...employees];
        break;
      }
    }
    if (state.searchedNode.isActive && sortedNodes.length > 1) {
      const primaryOrgCodeEmployee = state.nodes.find(node => node.gid === state?.searchedNode?.node?.gid && !node?.headGid && node?.paOrgCode === node?.paOrgCode);
      const foundEmployee = state.nodes.find(node => node.id === state?.searchedNode?.node?.id || node?.headId === state?.searchedNode?.node?.id);
      const matchingEmployee = primaryOrgCodeEmployee ?? foundEmployee;
      const nodeIndex = sortedNodes.findIndex(node => node?.id === matchingEmployee?.id);
      const node = sortedNodes[nodeIndex];

      if (node) {
        sortedNodes.splice(nodeIndex, 1);
        sortedNodes.unshift(node);
      }
    }

    return sortedNodes || [];
  }

  static defaultSort(orgs: Employee[], orgType: keyof Employee): Employee[] {
    return orgs.sort((nodeA, nodeB) => {
      const nameA = nodeA.firstname + nodeA.lastname;
      const nameB = nodeB.firstname + nodeB.lastname;
      return nodeA[orgType] > nodeB[orgType] ? 1 : nodeA[orgType] < nodeB[orgType] ? -1 : nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
    });
  }

  static sort(orgs: Employee[], type: keyof Employee): Employee[] {
    return orgs.sort((nodeA, nodeB) => {
      return nodeA[type] > nodeB[type] ? 1 : nodeA[type] < nodeB[type] ? -1 : 0;
    });
  }

  static node(id: string): (state: OrgTreeStateModel) => Employee {
    return createSelector([OrgTreeState], (state: OrgTreeStateModel) => {
      return state.nodes.find(node => node.id === id);
    });
  }

  static rootNode(): (state: OrgTreeStateModel) => Employee {
    return createSelector([OrgTreeState], (state: OrgTreeStateModel) => {
      return state.nodes.find(node => node.parentOrgCode === 'root');
    });
  }

  static managerNodes(ids: string[]): (state: OrgTreeStateModel) => Employee[] {
    return createSelector([OrgTreeState], (state: OrgTreeStateModel) => {
      return ids?.map(id => state.nodes.find(node => node.id === id))?.filter(node => node.numberOfEmployees > 0) || [];
    });
  }

  @Action(actions.UpdateSortingState)
  updateSortingType(ctx: StateContext<OrgTreeStateModel>, { payload }: actions.UpdateSortingState): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.sortingType = payload;
      })
    );
  }

  @Action(actions.UpdateFilterState)
  updateFilterType(ctx: StateContext<OrgTreeStateModel>, { payload }: actions.UpdateFilterState): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.filterType = payload;
      })
    );
  }

  @Action(actions.LoadNodes)
  loadNodes({ dispatch, setState, getState }: StateContext<OrgTreeStateModel>, { payload }: actions.LoadNodes): Observable<string | void> {
    const root = { id: 'root', paOrgCode: 'root' };

    if (getState().nodes?.some(node => node.id === 'root')) {
      return of('');
    }

    setState(
      produce(getState(), state => {
        this.updateNodes(state.nodes, { ...root });
      })
    );
    return dispatch(new actions.LoadNodeChildren({ node: root, reload: payload.reload, resetSearchNode: true }));
  }

  @Action(actions.LoadNodeChildren)
  loadNodeChildren(ctx: StateContext<OrgTreeStateModel>, { payload }: actions.LoadNodeChildren): Observable<unknown> {
    if (!payload.reload) {
      if (this.store.selectSnapshot(OrgTreeState.node(payload.node.id))?.childNodes?.length) {
        // do not fire req if childNodes already existent, but update breadcrumb
        return ctx.dispatch(new actions.UpdateBreadcrumbItems({ id: payload.node.id }));
      }
    }

    if (payload.resetSearchNode) {
      ctx.dispatch(new actions.UpdateSearchedNode({ node: null, isActive: false }));
    }

    this.loadingService.present();
    const orgCode = payload.node.headGid ? payload.node.orgCode : payload.node.paOrgCode;

    return this.orgTreeService.getChildOrganisations(orgCode).pipe(
      switchMap(orgs => {
        // load sub organisation heads
        const requests: Observable<unknown>[] = [];

        orgs.forEach(org => {
          requests.push(
            of('').pipe(
              tap(() => {
                asapScheduler.schedule(() => {
                  ctx.dispatch(new actions.LoadNodeChildrenSuccess({ children: [org], id: payload.node.id, reload: payload.reload }));
                });
              })
            )
          );
        });

        requests.push(
          this.orgTreeService.getEmployees(orgCode, 0).pipe(
            tap((nodes: Employee[]) => {
              const filteredNodes = nodes?.filter(node => payload?.node?.headId !== node.id);
              asapScheduler.schedule(() => {
                ctx.dispatch(new actions.LoadNodeChildrenSuccess({ children: filteredNodes, id: payload.node.id, reload: payload.reload }));
              });
            })
          )
        );
        return of(...requests);
      }),
      concatAll(),
      tap(() =>
        this.router.navigate(['org-review'], { queryParams: { headId: payload.node.headId ?? payload.node.id, orgCode: payload.node.orgCode }, queryParamsHandling: 'merge' })
      ),
      finalize(() => this.loadingService.dismiss())
    );
  }

  @Action(actions.LoadNodeChildrenSuccess)
  loadNodeChildrenSuccess(ctx: StateContext<OrgTreeStateModel>, { payload }: actions.LoadNodeChildrenSuccess): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        for (const child of payload.children) {
          this.updateNodes(state.nodes, { ...child, ...{ parentId: payload.id } });
        }

        const nodeIndex = state.nodes.findIndex(node => node.id === payload.id);
        const nodes = state.nodes[nodeIndex].childNodes || [];
        state.nodes[nodeIndex].childNodes = [...new Set([...payload.children.map(child => child.id), ...nodes])];
      })
    );
    ctx.dispatch(new actions.UpdateBreadcrumbItems({ id: payload.id }));
  }

  // TODO: refactoring & ORG CODE / ORGANISATION REFACTORING IN BACKEND (ONLY 1 VALUE!!!!)
  @Action(actions.OpenEmployeeInView)
  async openEmployeeInView(ctx: StateContext<OrgTreeStateModel>, { payload }: actions.OpenEmployeeInView): Promise<void> {
    try {
      this.loadingService.present();

      let parents: Employee[] = [];

      await this.collectAllParents(payload.node, parents, true, payload.fullTree, payload.deepLinkOrgCode);

      if (payload.node?.id === parents[0]?.headId && payload?.fullTree && !payload?.isDeepLink) {
        parents.shift();
      }

      ctx.dispatch(new actions.ResetChildrenVisibility());
      const loadActions: actions.UpdateBreadcrumbItems[] = [];

      if (parents.length > 0) {
        const [newHeadInView] = parents;

        parents = parents.filter(parent => parent.paOrgCode !== 'root')?.reverse();

        parents.forEach(parent => {
          ctx.setState(
            produce(ctx.getState(), state => {
              this.updateNodes(state.nodes, { ...parent });
            })
          );
          loadActions.push(new actions.UpdateBreadcrumbItems({ id: parent.id }));
        });

        for (const [index, action] of loadActions.entries()) {
          setTimeout(async () => {
            ctx.dispatch(action);
          }, index * 200);
        }

        setTimeout(() => {
          ctx
            .dispatch(new actions.LoadNodeChildren({ node: newHeadInView, reload: true, resetSearchNode: false }))
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
              ctx.dispatch(new actions.UpdateSearchedNode({ node: payload.node, isActive: true }));
            });
        }, 200);
      } else {
        setTimeout(() => {
          ctx.dispatch(new actions.LoadNodes({ reload: false }));
        }, 200);
      }
    } catch (e) {
      throw Error(e);
    } finally {
      this.loadingService.dismiss();
    }
  }

  @Action(actions.UpdateBreadcrumbItems)
  updateBreadcrumbItems(ctx: StateContext<OrgTreeStateModel>, { payload }: actions.UpdateBreadcrumbItems): void {
    // case for reloading root hubs
    if (payload?.id === null) {
      payload.id = 'root';
    }

    ctx.setState(
      produce(ctx.getState(), state => {
        if (!payload) {
          // reset items
          state.breadcrumbItems.splice(1, state.breadcrumbItems.length);
          return;
        }

        if (state.breadcrumbItems.some(item => item.id === payload.id)) {
          // update item

          let pos: number;
          for (let i = 0; i < state.breadcrumbItems.length; i++) {
            if (state.breadcrumbItems[i].id === payload.id) {
              pos = i;
              state.breadcrumbItems[i].version += 1;
            }
          }
          if (payload.id !== 'root') {
            state.breadcrumbItems.splice(pos + 1, state.breadcrumbItems.length); // delete unnecessary breadcrumb items
          }
        } else if (state.breadcrumbItems.length === MAX_DEPTH_LEVEL) {
          state.breadcrumbItems.push({ id: payload.id, childrenActive: true, disabledDepth: true, isRoot: payload.id === 'root', version: 0 });
        } else {
          state.breadcrumbItems.push({ id: payload.id, childrenActive: true, isRoot: payload.id === 'root', version: 0 });
        }
      })
    );
  }

  @Action(actions.ResetState)
  resetState(ctx: StateContext<OrgTreeStateModel>): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.nodes = [];
        state.breadcrumbItems = [];
        state.sortingType = OrgTreeSortType.Default;
      })
    );
  }

  @Action(actions.ResetChildrenVisibility)
  resetChildrenVisibility(ctx: StateContext<OrgTreeStateModel>): Observable<void> {
    return ctx.dispatch(new actions.UpdateBreadcrumbItems());
  }

  @Action(actions.UpdateSearchedNode)
  updateSearchedNode(ctx: StateContext<OrgTreeStateModel>, { payload }: actions.UpdateSearchedNode): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.searchedNode.node = payload.node;
        state.searchedNode.isActive = payload.isActive;
      })
    );
  }

  @Action(actions.UpdateNode)
  updateNode(ctx: StateContext<OrgTreeStateModel>, { payload }: actions.UpdateNode): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        this.updateNodes(state.nodes, payload);
      })
    );
  }

  private updateNodes(nodes: Employee[], updatedNode: Employee): void {
    // check if node already exists
    if (nodes.find(node => node.id === updatedNode.id && node.paOrgCode === updatedNode.paOrgCode)) {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].id === updatedNode.id) {
          nodes[i] = { ...nodes[i], ...updatedNode };
        }
      }
    } else {
      nodes.push(updatedNode);
    }
  }

  async collectAllParents(employee: Employee, parents: Employee[], isInital: boolean, isFullTree: boolean, deepLinkOrgCode?: string): Promise<void> {
    const orgCode = deepLinkOrgCode ?? (employee?.headGid ? employee?.orgCode : employee?.paOrgCode);

    let parentHead: Employee;
    if (orgCode) {
      if (isInital) {
        parentHead = await this.orgTreeService.getOrganisation(orgCode).toPromise();
      } else {
        parentHead = await this.orgTreeService.getParentOrganisation(orgCode).toPromise();
      }
      if (parentHead?.headGid && parentHead?.headId && parentHead?.headGid !== DEFAULT_GID) {
        const headDetails = await this.orgTreeService.getEmployeeDetails(parentHead.headId).toPromise();

        parentHead.countryCode = headDetails.countryCode || headDetails.placeOfAction; // hack
        parentHead.paOrgCode = headDetails.paOrgCode; // hack
      }
      parents.push(parentHead);

      if (parentHead.orgCode !== 'SE' && isFullTree) {
        await this.collectAllParents(parentHead, parents, false, true);
      }
    }
  }
}
