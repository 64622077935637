import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FaqDrill, FaqItem } from '@coin/shared/util-models';
import { AuthService } from '@coin/modules/auth/data-access';

@Component({
  selector: 'coin-siemens-energy-faq-inbetween-level',
  templateUrl: './faq-inbetween-level.component.html',
  styleUrls: ['./faq-inbetween-level.component.scss']
})
export class FaqInbetweenLevelComponent implements OnInit {
  @Input() items: FaqItem;
  @Output() openFaq = new EventEmitter<FaqDrill>();

  public isAuthenticated: boolean;

  constructor(private authService: AuthService) {}

  async ngOnInit(): Promise<void> {
    this.isAuthenticated = this.authService.isAuthenticated$.value;
  }

  public openFaqItem(content: FaqItem, level: number, moreWidth = false, title?: boolean, subtitle?: string): void {
    this.openFaq.emit({ content, level, moreWidth, title, subtitle });
  }

  public authRequired(item: FaqItem): boolean {
    return item.requiresAuthentication ? this.isAuthenticated : true;
  }
}
