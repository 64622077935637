import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-collapsed-bar',
  templateUrl: './collapsed-bar.component.html',
  styleUrls: ['./collapsed-bar.component.scss']
})
export class CollapsedBarComponent {
  @Input() titles: string[];
  @Output() toggleItem = new EventEmitter<number>();

  openMenu(i: number): void {
    this.toggleItem.emit(i);
  }
}
