import * as moment from 'moment';

export class DateOperations {
  public static dueDateOverBasedOnDay(endDateString: string): boolean {
    const currentDate = moment.utc().startOf('day');
    const endDate = moment.utc(endDateString).startOf('day');

    return currentDate.valueOf() > endDate.valueOf();
  }
}
