import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@coin/modules/auth/data-access';
import { DirectsState, FirstVisitService, UserState } from '@coin/modules/auth/data-management';
import { HealthCheckService, StorageService } from '@coin/shared/data-access';
import { ContentLanguage, PermissionResource, StorageKey } from '@coin/shared/util-enums';
import { notifyUpdates } from '@coin/shared/util-helpers';
import { Permission } from '@coin/shared/util-models';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GuestAccessComponent } from './shared/dialogs/guest-access/guest-access.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'List',
      url: '/list',
      icon: 'list'
    }
  ];
  showG2MPopup = true;
  public hideHeader = true;
  public isSidebarVisible = true;
  public healthcheckFail = false;
  public selectedSidebarItems: number[] = [0, 1];
  public showFirstVisitPopup = false;
  private previousPath: string;
  public permissionResource = PermissionResource;
  public isAuthenticated: boolean;
  public isAuthenticated$: Observable<boolean>;

  private permissionSub$: Subscription;
  private titles: string[] = ['Apps', 'Directs'];

  public get hasDirects(): boolean {
    return this.store.selectSnapshot(DirectsState.hasDirects);
  }

  public get filteredDetails(): string[] {
    return this.hasDirects ? this.titles : this.titles.filter(title => title !== 'Directs');
  }

  public get sideBarWidth(): string {
    return this.isAuthenticated ? (this.healthcheckFail ? '100%' : 'calc(100% - 55px)') : '100%';
  }

  constructor(
    private translate: TranslateService,
    private store: Store,
    private dialog: MatDialog,
    private router: Router,
    public authService: AuthService,
    private guidedTour: FirstVisitService,
    private healthCheckService: HealthCheckService,
    private storageService: StorageService,
    private destroyRef: DestroyRef
  ) {
    notifyUpdates();
  }

  async ngOnInit(): Promise<void> {
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.listenOnIsAuthenticated();
    this.checkBackendStatus();
    this.initializeApp();
    this.hideHeader = window.location.href.includes('/login');
    await this.setupLang();
    // only allow guided tour on dashboard
    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async event => {
      if (event instanceof NavigationEnd) {
        const isFirstVisitCompleted = await this.storageService.get(StorageKey.FIRST_VISIT_COMPLETED);
        const firstVisit = isFirstVisitCompleted === 'true';
        this.showFirstVisitPopup = event.url === '/' && !firstVisit && this.isAuthenticated;
      }
    });
  }

  private listenOnIsAuthenticated(): void {
    this.isAuthenticated$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
    });
  }

  private async setupLang(): Promise<void> {
    const lang = await this.storageService.get(StorageKey.LANGUAGE);

    this.translate.use(lang || ContentLanguage.ENGLISH);
    this.translate.setDefaultLang(ContentLanguage.ENGLISH);
  }

  private checkBackendStatus(): void {
    this.healthCheckService
      .ping()
      .pipe(
        catchError(err => {
          this.healthcheckFail = true;
          this.router.navigate(['fallback'], { skipLocationChange: true });
          return throwError(err);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private initializeApp(): void {
    this.guidedTour.reopen.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.showFirstVisitPopup = true;
    });
    this.guidedTour.close.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.showFirstVisitPopup = false;
    });

    this.permissionSub$ = this.store
      .select(UserState?.allPermissions)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(allPermissions => {
        if (!allPermissions) {
          return;
        }

        this.permissionSub$.unsubscribe();

        // TODO Quickfix for now with the 'includes('App')', should be reviewed later
        if (this.onlyHasGuestAccess(allPermissions)) {
          this.dialog.open(GuestAccessComponent, { disableClose: true });
        }
      });
  }

  private onlyHasGuestAccess(permissions: Permission[]): boolean {
    return !permissions.some(permission => permission.resource.includes('App') || permission.resource === PermissionResource.All);
  }

  public onSidebarToggle(): void {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  public onItemSelect(index: number): void {
    if (!this.selectedSidebarItems.includes(index)) {
      this.selectedSidebarItems.push(index);
    }
  }

  public onItemToggle(index: number): void {
    if (this.selectedSidebarItems.includes(index)) {
      this.selectedSidebarItems = this.selectedSidebarItems.filter(item => item !== index);
    } else {
      this.selectedSidebarItems.push(index);
    }
  }
}
