import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { LifecycleHelper } from './lifecycle-helper';
import { ObservableCachingHelper } from './observable-caching-helper';

@Injectable({
  providedIn: 'root'
})
export class GlobalEventsService extends LifecycleHelper {
  private cache = new ObservableCachingHelper<Event>();

  constructor() {
    super();
    this.addToLifecycle(this.cache);
  }

  public listen<T extends keyof WindowEventMap>(type: T): Observable<WindowEventMap[T]> {
    return this.cache.cacheSetOnMiss$(fromEvent(window, type), [type]) as never;
  }
}
