export enum MercerAssignmentSearchablePropertyEnum {
  Gid = 'Gid',
  Firstname = 'Firstname',
  Lastname = 'Lastname',
  OrgCode = 'OrgCode',
  Country = 'Country',
  Are = 'Are',
  JobCode = 'JobCode',
  MercerFamilyName = 'MercerFamilyName',
  MercerSubFamilyName = 'MercerSubFamilyName',
  MercerSpecializationName = 'MercerSpecializationName',
  MercerCareerLevelName = 'MercerCareerLevelName',
  MercerPositionClass = 'MercerPositionClass',
  MercerCareerStream = 'mercerCareerStream'
}
