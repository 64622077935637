import { DestroyRef, Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AuthGmsClientConfig, AuthUser, LoadLoggedInUser, LoadUser, ResetUser } from '@coin/modules/auth/util';
import { StorageService } from '@coin/shared/data-access';
import { StorageKey } from '@coin/shared/util-enums';
import { Store } from '@ngxs/store';
import { AuthConfig, OAuthEvent, OAuthInfoEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

export const AuthGmsClientConfigService = new InjectionToken<AuthGmsClientConfig>('AuthGmsClientConfig');

@Injectable()
export class AuthService {
  private authConfig: AuthConfig;
  public isAuthenticated$ = new BehaviorSubject<boolean>(false);

  private get router(): Router {
    return this.injector.get(Router);
  }

  public set emulation(emulationToken: string) {
    this.storageService.setAsync(StorageKey.EMULATION_TOKEN, emulationToken);
  }

  public async getUser(): Promise<AuthUser> {
    const user: string = await this.getIdToken();
    if (user) {
      return this.decodeToken(user);
    }
  }

  public async getEmulationDecoded(): Promise<AuthUser> {
    const emulatedUser = await this.getEmulation();
    if (emulatedUser) {
      return this.decodeToken(emulatedUser);
    }
  }

  public async getEmulation(): Promise<string> {
    return this.storageService.getAsync(StorageKey.EMULATION_TOKEN);
  }

  public getIdToken(): Promise<string> {
    return Promise.resolve(this.oauthService.getIdToken() || '');
  }

  public getAccessToken(): Promise<string> {
    return Promise.resolve(this.oauthService.getIdToken() || '');
  }

  public async getActiveUser(): Promise<AuthUser> {
    const decoded = await this.getEmulationDecoded();
    const user = await this.getUser();
    return decoded ? { ...decoded, gid: decoded.Gid } : user;
  }

  public async getIsEmulated(): Promise<boolean> {
    const emulation = await this.getEmulation();
    return !!emulation;
  }

  constructor(
    @Inject(AuthGmsClientConfigService) config,
    private injector: Injector,
    private store: Store,
    private storageService: StorageService,
    private oauthService: OAuthService,
    private destroyRef: DestroyRef
  ) {
    this.authConfig = {
      clientId: config.clientId,
      issuer: config.issuer,
      redirectUri: config.callbackUrl,
      responseType: 'code',
      scope: config.scopes,
      showDebugInformation: true,
      strictDiscoveryDocumentValidation: false,
      skipIssuerCheck: true,
      openUri: this.openLoginUri.bind(this),
      timeoutFactor: 1
    };
    this.listenOnOAuthEvents();
  }

  public overwriteConfig(authConfig: AuthConfig): void {
    this.authConfig = authConfig;
  }

  public openLoginUri(uri: string): void {
    window.location.href = uri;
  }

  /**
   * @param redirectPath where to redirect to after an successful login
   */
  public async login(redirectPath?: string): Promise<void> {
    this.clearEmulation();
    await this.initOAuthService();

    try {
      await this.oauthService.initLoginFlow();
      if (redirectPath) {
        await this.storageService.setAsync(StorageKey.REDIRECT_URL, redirectPath);
      }
    } catch (e) {
      console.error(e);
    }
  }

  public async refreshToken(): Promise<void> {
    await this.initOAuthService();
    await this.oauthService.refreshToken();
  }

  public logOff(): void {
    this.oauthService.logOut();
    this.store.dispatch(new ResetUser());

    // redirect to root of page
    this.router.navigate(['/logout']);
  }

  public getRemainingTime(): number {
    const expirationInMilliseconds = this.oauthService.getIdTokenExpiration() - Date.now();
    return expirationInMilliseconds / 1000;
  }

  private listenOnOAuthEvents(): void {
    this.isAuthenticated$.next(this.oauthService.hasValidIdToken());
    this.oauthService.events
      .pipe(tap(() => this.isAuthenticated$.next(this.oauthService.hasValidIdToken())))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  public listenOnTokenExpiration(): Observable<OAuthEvent> {
    return this.oauthService.events.pipe(filter(event => event instanceof OAuthInfoEvent && event.type === 'token_expires' && event.info === StorageKey.ID_TOKEN));
  }

  public async handleLoginCallback(): Promise<void> {
    await this.initOAuthService();
    await this.oauthService.tryLoginCodeFlow();

    const redirectUrl = await this.storageService.getAsync(StorageKey.REDIRECT_URL);
    const stateUrl = redirectUrl === 'undefined' ? '/' : redirectUrl || '/';
    this.storageService.remove(StorageKey.REDIRECT_URL);
    await this.store.dispatch(new LoadUser()).pipe(take(1)).toPromise();
    await this.store.dispatch(new LoadLoggedInUser()).pipe(take(1)).toPromise();
    await this.router.navigateByUrl(stateUrl);
  }

  private async initOAuthService(): Promise<void> {
    this.oauthService.configure(this.authConfig);
    await this.oauthService.loadDiscoveryDocument();
  }

  private decodeToken(tokenValue: string): AuthUser {
    let decoded = '{}';

    if (tokenValue) {
      decoded = this.b64DecodeUnicode(tokenValue.split('.')[1].replace(/-/g, '+').replace(/_/g, '/'));
    }

    return JSON.parse(decoded);
  }

  private b64DecodeUnicode(str: string): string {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(char => `%${`00${char.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
  }

  public clearEmulation(): void {
    this.storageService.remove(StorageKey.EMULATION_TOKEN);
  }
}
