import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrencyIsoCode } from '@coin/shared/util-models';
import { RoundOperations } from '@coin/shared/util-helpers';
import { DisplayedCurrency } from '@coin/shared/util-enums';
import { FxRatePipe } from './fx-rate.pipe';

@Pipe({ name: 'fxConversion', standalone: true })
export class FxConversionPipe implements PipeTransform {
  constructor(private fxRate: FxRatePipe) {}

  transform(
    value: number,
    freezeDate: string | Date,
    originCurrency: CurrencyIsoCode,
    targetCurrency?: CurrencyIsoCode | DisplayedCurrency,
    forceConversion?: boolean
  ): Observable<number> {
    if (!value) {
      return of(0);
    }
    if (!freezeDate || !originCurrency) {
      return of(undefined);
    }

    if (targetCurrency === DisplayedCurrency.Local) {
      return of(RoundOperations.round(value, 2));
    }
    return this.fxRate.transform(freezeDate, originCurrency, targetCurrency, forceConversion).pipe(map(fxRate => RoundOperations.round(value * fxRate, 2)));
  }
}
