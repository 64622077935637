import { Pipe, PipeTransform } from '@angular/core';
import { TinyHelpers } from '@coin/shared/util-helpers';

@Pipe({
  name: 'multiply',
  standalone: true
})
export class MultiplicationPipe implements PipeTransform {
  transform(value: number, multiplier: number): number {
    if (!value || TinyHelpers.isString(value) || !multiplier) {
      return value;
    }
    return value * multiplier;
  }
}
