<div class="profile-main" *ngIf="user && (token.isLoggedIn$ | async)">
  <div *ngIf="isEmulated" (click)="removeEmulation()" class="emulation-icon" [class.deputy]="isLoggedInAsDeputy">
    <mat-icon>person</mat-icon>
    <p>{{ (isLoggedInAsDeputy ? 'general.stop-deputy' : 'general.stop-emulation') | translate }}</p>
  </div>
  <div *ngIf="userImageSrc && userImageSrc !== ''" (contextmenu)="onRightClick($event)" class="image" [ngStyle]="{ 'background-image': 'url(' + userImageSrc + ')' }"></div>
  <div *ngIf="!userImageSrc || userImageSrc === ''" class="avatar" slot="start">
    <mat-icon>person</mat-icon>
  </div>

  <button class="profile-info no-style" [class.show-user-menu]="showMenu" (click)="openMenu()" aria-label="Open User Menu">
    <p class="orgCode">{{ userDetails?.paOrgCode ? userDetails.paOrgCode + ', ' : '' }}{{ userDetails?.placeOfAction }}</p>
    <ng-container *ngIf="userDetails">
      <p class="bold">{{ userDetails?.firstname }} {{ userDetails?.lastNamePrefix }}{{ userDetails?.lastNamePrefix ? ' ' : '' }}{{ userDetails?.lastname }}</p>
    </ng-container>
    <ng-container *ngIf="!userDetails">
      <p class="bold">{{ user?.firstname }} {{ user?.lastname }}</p>
    </ng-container>

    <ng-container *ngIf="token.remainingTimeText$ | async as remainingTime">
      <button
        *ngIf="token.timeWarningStyle$ | async as timeWarning"
        class="remaining-time no-style"
        [class.show-yellow]="timeWarning === 'alarming'"
        [class.show-red]="timeWarning === 'urgent'"
        (mouseenter)="remainingTimeHover('enter')"
        (mouseleave)="remainingTimeHover('leave')"
        (click)="renewToken($event)"
        aria-label="Refresh User Token"
        matTooltipPosition="right">
        <mat-icon *ngIf="!remainingTimeHovered" @fade>access_time</mat-icon>
        <mat-icon *ngIf="remainingTimeHovered" @slide class="renew">autorenew</mat-icon>
        <p>{{ remainingTime }}</p>
      </button>
    </ng-container>
  </button>

  <div *ngIf="showMenu" class="menu-part-element mat-elevation-z3" @shrinkExpand>
    <div class="menu-element-item disabled">
      <div class="spacing-holder">
        <div class="border"></div>
      </div>
    </div>

    <div *ngIf="isProfileAllowed" class="menu-element-item" @shrinkExpand>
      <button (click)="profileClicked()" class="no-style spacing-holder">
        <div class="menu-entry avatar" slot="start">
          <mat-icon>person</mat-icon>
        </div>
        <p class="bold">{{ 'general.profile' | translate }}</p>
        <div class="border"></div>
      </button>
    </div>

    <div class="menu-element-item" @shrinkExpand>
      <button (click)="profilePictureClicked()" class="no-style spacing-holder">
        <div class="menu-entry avatar" slot="start">
          <mat-icon>edit</mat-icon>
        </div>
        <p class="bold">{{ (!this.user || !this.userImageSrc ? 'general.add-your-picture' : 'general.modify-your-picture') | translate }}</p>
        <div class="border"></div>
      </button>
    </div>

    <div class="menu-element-item" cdkOverlayOrigin #languageTrigger="cdkOverlayOrigin">
      <button (click)="languageChangeClicked()" class="no-style spacing-holder">
        <div class="menu-entry avatar" slot="start">
          <mat-icon>language</mat-icon>
        </div>
        <p class="bold">{{ 'general.language' | translate }}</p>
        <div class="border"></div>
      </button>

      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="languageTrigger"
        [cdkConnectedOverlayOpen]="languageSubmenuVisible"
        cdkConnectedOverlayPanelClass="menu-overlay"
        [cdkConnectedOverlayPositions]="[{ originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top' }]"
        [cdkConnectedOverlayOffsetY]="2">
        <div class="mat-elevation-z3 sub-item-container" @fade>
          <div class="spacing-holder">
            <div class="user-language-box-header">
              <div class="language-item" [ngClass]="{ active: languageSelected === lang.key }" (click)="changeLanguage(lang.key)" *ngFor="let lang of selectableLangs | keyvalue">
                <div class="lang-image" [ngStyle]="{ 'background-image': 'url(/assets/svgs/flags/' + lang.value + '.svg)' }"></div>
                <p>{{ 'general.' + lang.key | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <div *ngIf="isAdmin" class="menu-element-item" @shrinkExpand>
      <button (click)="translationViewerClicked()" class="no-style spacing-holder">
        <div class="menu-entry avatar" slot="start">
          <mat-icon>{{ isDebugModeEnabled ? 'visibility_off' : 'visibility' }}</mat-icon>
        </div>
        <p class="bold">{{ (isDebugModeEnabled ? 'general.disable-translation-viewer' : 'general.enable-translation-viewer') | translate }}</p>
        <div class="border"></div>
      </button>
    </div>

    <div *ngIf="emulationEnabled || isEmulated" class="menu-element-item" @shrinkExpand>
      <button (click)="emulateClicked()" class="no-style spacing-holder">
        <div class="menu-entry avatar" slot="start">
          <mat-icon>{{ !isEmulated ? 'person' : 'person_off' }}</mat-icon>
        </div>
        <p class="bold">{{ (!isEmulated ? 'general.emulate' : 'general.stop-emulation') | translate }}</p>
        <div class="border"></div>
      </button>
    </div>

    <div *ngIf="isDeputyMenuEntryShown" class="menu-element-item" @shrinkExpand>
      <button (click)="manageDeputiesClicked()" class="no-style spacing-holder" cdkOverlayOrigin #deputyTrigger="cdkOverlayOrigin">
        <div class="menu-entry avatar" slot="start">
          <mat-icon [svgIcon]="'manager_white'"></mat-icon>
        </div>
        <p class="bold">{{ 'general.deputies' | translate }}</p>
        <div class="border"></div>
      </button>

      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="deputyTrigger"
        [cdkConnectedOverlayOpen]="deputySubmenuVisible"
        cdkConnectedOverlayPanelClass="menu-overlay"
        [cdkConnectedOverlayPositions]="[{ originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top' }]"
        [cdkConnectedOverlayOffsetY]="2">
        <div class="mat-elevation-z3 sub-item-container" @fade>
          <div class="spacing-holder">
            <div class="user-language-box-header">
              <ng-container *ngIf="!isLoggedInAsDeputy">
                <div class="deputy-menu-item" (click)="showDeputies()">
                  <p>{{ 'deputy.act-as' | translate }}</p>
                  <span class="material-icons">navigate_next</span>
                </div>
                <div *ngIf="hasDirects" class="deputy-menu-item" (click)="openDeputySettings()">
                  <p>{{ 'deputy.settings' | translate }}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="isLoggedInAsDeputy">
                <div (click)="removeEmulation()" class="language-item">
                  <div class="lang-image no-image">
                    <span class="material-icons">person_off</span>
                  </div>
                  <p>Stop acting as Deputy</p>
                </div>
              </ng-container>

              <div *ngIf="isDeputyListVisible" class="mat-elevation-z3 deputy-list">
                <div *ngFor="let deputy of deputies" class="deputy" (click)="emulateAsDeputy(deputy)">
                  <coin-siemens-energy-employee-preview [employee]="deputy" [small]="true" [showOrgCode]="false" [showFlag]="false"></coin-siemens-energy-employee-preview>
                </div>
                <div *ngIf="!deputies.length" class="deputy no-deputies">
                  <p>{{ 'deputy.no-employees' | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <div class="menu-element-item" @shrinkExpand>
      <button (click)="logoutClicked()" class="no-style spacing-holder">
        <div class="menu-entry avatar" slot="start">
          <mat-icon>login</mat-icon>
        </div>
        <p class="bold">{{ 'general.log-out' | translate }}</p>
      </button>
    </div>
  </div>
</div>

<div class="profile" *ngIf="!user || (token.isLoggedIn$ | async) === false">
  <div class="profile-image-container">
    <div class="image language-icon" *ngIf="languageSelected" [ngStyle]="{ 'background-image': 'url(/assets/svgs/flags/' + languageSelected + '.svg)' }"></div>
  </div>
  <div class="profile-info" [ngClass]="{ 'show-user-menu': showMenu }" (click)="openMenu()">
    <p class="bold">{{ 'general.' + languageSelected | translate }}</p>
  </div>
  <div class="menu-part-element mat-elevation-z3" *ngIf="showMenu" @shrinkExpand>
    <div class="menu-element-item disabled" style="height: 79px">
      <div class="spacing-holder">
        <div class="border"></div>
      </div>
    </div>
    <div class="menu-element-item" (click)="changeLanguage(lang.key)" @shrinkExpand *ngFor="let lang of selectableLangs | keyvalue; let i = index">
      <div class="spacing-holder">
        <div class="image" *ngIf="languageSelected" [ngStyle]="{ 'background-image': 'url(/assets/svgs/flags/' + lang.key + '.svg)' }"></div>
        <p style="margin-top: auto; margin-bottom: auto" class="bold">{{ 'general.' + lang.key | translate }}</p>
        <div class="border" *ngIf="i !== menuItems.length - 1"></div>
      </div>
    </div>
  </div>
</div>
<div style="flex: 1"></div>
