import { DestroyRef, Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BudgetAllocationSettings, Topic } from '@coin/shared/util-models';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { produce } from 'immer';
import { from, Observable, of } from 'rxjs';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { DateOperations } from '@coin/shared/util-helpers';
import { MeritPromotionState } from '@coin/customer/merit-incentive/util';
import { PermissionResource, SeasonProcessState, TodoTaskType } from '@coin/shared/util-enums';
import { LoadingService } from '@coin/shared/data-access';
import { MeritSeasonSetting } from '@coin/admin/season-mgmt/util';
import { SeasonSettingsService } from '@coin/admin/season-mgmt/data-access';
import { UserState } from '@coin/modules/auth/data-management';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FilterSortState } from '../../../shared/components/filter-sort-bar/store/filter-sort.state';
import { TopicsState } from '../../../home/store';
import { MeritBudgetResponsibilityType } from '../../merit-shared/enums/merit-budget-responsibility-type.enum';
import { BudgetAllocationOverview } from '../../merit-shared/models/budget-allocation-overview.model';
import { EmployeeSelector } from '../../merit-shared/models/employee-selector.enum';
import { IncentiveAllocationOverview } from '../../merit-shared/models/incentive-budget-overview.model';
import { MeritAllocationSeason } from '../../merit-shared/models/merit-allocation-season.model';
import { EmployeeWithCompensationInfo } from '../../merit-shared/models/merit-budget-direct-with-compensation.model';
import { MeritTask } from '../../merit-shared/models/merit-tasks.model';
import { UpdateAllocationValuesDto } from '../../merit-shared/models/update-allocation-values-dto.model';
import { MeritAllocationDirectsService } from '../../merit-shared/services/merit-allocation-directs.service';
import { MeritAllocationGeneralService } from '../../merit-shared/services/merit-allocation-general.service';
import { MeritAllocationImpersonatedEmployeeService } from '../../merit-shared/services/merit-allocation-impersonated-employee.service';
import { MeritTotalResponsibilityService } from '../../merit-shared/services/merit-total-responsibility.service';
import { IncentiveAllocationService } from '../services/incentive-allocation.service';
import { MeritTasksService } from '../services/merit-tasks.service';
import { UpdateNode } from './merit-budget-allocation-total.actions';
import { MeritBudgetAllocationTotalState } from './merit-budget-allocation-total.state';
import {
  ChangeMeritTaskStatus,
  FinalizeSelectedDirects,
  LoadAllocationSettings,
  LoadDirect,
  LoadDirects,
  LoadIncentiveTaskByEmployeeOrgCode,
  LoadMeritDirectBudgetOverview,
  LoadMeritTaskByEmployeeOrgCode,
  LoadSeasons,
  LoadSeasonSettings,
  PromoteAllocation,
  ResetMeritBudgetAllocationState,
  ResetSeason,
  SetAdminRights,
  SetAllocationTask,
  SetEmployeeSelector,
  SetResponsibilityType,
  SetSearchedDirect,
  SetSeason,
  SetSelectedDirectsToBudget,
  SetTotalBudgetOverview,
  ToggleMultiSelect,
  ToggleMultiSelectForEmployee,
  ToggleOnlyOpenItemsVisible,
  UndoSelectedDirects,
  UpdateDirect,
  UpdateDirectCompensationComponents
} from './merit-budget-allocation.actions';

interface MeritBudgetAllocationStateModel {
  allocationTask: Topic;
  isAdmin: boolean;
  tasks: { [orgCode: string]: MeritTask };
  incentiveAllocations: { [orgCode: string]: IncentiveAllocationOverview };
  season: MeritAllocationSeason;
  allocationSettings: BudgetAllocationSettings;
  seasonSettings: MeritSeasonSetting;
  seasons: MeritAllocationSeason[];
  directs: EmployeeWithCompensationInfo[];
  areDirectsLoading: boolean;
  responsibilityType: MeritBudgetResponsibilityType;
  budgetOverview: {
    totalBudget: BudgetAllocationOverview;
    directBudget: BudgetAllocationOverview;
  };
  totalBudgetSyncDate: Date;
  onlyOpenItemsVisible: boolean;
  showMultiSelect: boolean;
  selectedEmployeeIds: string[];
  employeeSelector: EmployeeSelector;
  hasLinkedIncentiveSeason: boolean;
}
@State<MeritBudgetAllocationStateModel>({
  name: 'MeritBudgetAllocationState',
  defaults: {
    allocationTask: null,
    isAdmin: false,
    tasks: {},
    incentiveAllocations: {},
    seasonSettings: undefined,
    season: undefined,
    allocationSettings: undefined,
    seasons: [],
    directs: [],
    areDirectsLoading: false,
    responsibilityType: MeritBudgetResponsibilityType.DirectResponsibility,
    budgetOverview: {
      totalBudget: null,
      directBudget: null
    },
    totalBudgetSyncDate: null,
    onlyOpenItemsVisible: false,
    showMultiSelect: false,
    selectedEmployeeIds: [],
    employeeSelector: undefined,
    hasLinkedIncentiveSeason: false
  }
})
@Injectable()
export class MeritBudgetAllocationState {
  constructor(
    private store: Store,
    private meritAllocationGeneralService: MeritAllocationGeneralService,
    private meritTasksService: MeritTasksService,
    private meritAllocationDirectsService: MeritAllocationDirectsService,
    private loadingService: LoadingService,
    private seasonSettingsService: SeasonSettingsService,
    private meritTotalResponsibilityService: MeritTotalResponsibilityService,
    private activatedRoute: ActivatedRoute,
    private incentiveAllocationService: IncentiveAllocationService,
    private meritAllocationImpersonatedEmployeeService: MeritAllocationImpersonatedEmployeeService,
    private router: Router,
    private ngZone: NgZone,
    private destroyRef: DestroyRef
  ) {}

  @Selector()
  static season(state: MeritBudgetAllocationStateModel): MeritAllocationSeason {
    return state.season;
  }

  @Selector()
  static isTaskDueDateReached(state: MeritBudgetAllocationStateModel): boolean {
    const dueDateReached = state.allocationTask?.dateEnd ? DateOperations.dueDateOverBasedOnDay(state.allocationTask.dateEnd) : !state.isAdmin;
    return dueDateReached;
  }

  @Selector()
  static directs(state: MeritBudgetAllocationStateModel): EmployeeWithCompensationInfo[] {
    return this.sortedAndFilteredDirects(state);
  }

  @Selector()
  static areDirectsLoading(state: MeritBudgetAllocationStateModel): boolean {
    return state.areDirectsLoading;
  }

  private static sortedAndFilteredDirects(state: MeritBudgetAllocationStateModel): EmployeeWithCompensationInfo[] {
    let sortedAndFilteredDirects = state.directs;

    if (state.onlyOpenItemsVisible) {
      sortedAndFilteredDirects = sortedAndFilteredDirects.filter(
        direct => !direct.isMeritExcluded && !direct.isDone && ![MeritPromotionState.InProgress, MeritPromotionState.Finalized].includes(direct.promotion)
      );
    }

    return sortedAndFilteredDirects;
  }

  @Selector()
  static seasons(state: MeritBudgetAllocationStateModel): MeritAllocationSeason[] {
    return state.seasons;
  }

  @Selector()
  static isAdmin(state: MeritBudgetAllocationStateModel): boolean {
    return state.isAdmin;
  }

  @Selector()
  static allocationSettings(state: MeritBudgetAllocationStateModel): BudgetAllocationSettings {
    return state.allocationSettings;
  }

  @Selector()
  static seasonSettings(state: MeritBudgetAllocationStateModel): MeritSeasonSetting {
    return state.seasonSettings;
  }

  @Selector()
  static responsibilityType(state: MeritBudgetAllocationStateModel): MeritBudgetResponsibilityType {
    return state.responsibilityType;
  }

  @Selector()
  static totalBudget(state: MeritBudgetAllocationStateModel): BudgetAllocationOverview {
    return state.budgetOverview.totalBudget;
  }

  @Selector()
  static totalBudgetSyncDate(state: MeritBudgetAllocationStateModel): Date {
    return state.totalBudgetSyncDate;
  }

  @Selector()
  static directBudget(state: MeritBudgetAllocationStateModel): BudgetAllocationOverview {
    return state.budgetOverview.directBudget;
  }

  @Selector()
  static onlyOpenItemsVisible(state: MeritBudgetAllocationStateModel): boolean {
    return state.onlyOpenItemsVisible;
  }

  @Selector()
  static showMultiSelect(state: MeritBudgetAllocationStateModel): boolean {
    return state.showMultiSelect;
  }

  @Selector()
  public static employeeSelector(state: MeritBudgetAllocationStateModel): EmployeeSelector {
    return state.employeeSelector;
  }

  @Selector()
  public static hasLinkedIncentiveSeason(state: MeritBudgetAllocationStateModel): boolean {
    return state.hasLinkedIncentiveSeason;
  }

  static taskByEmployeeOrgCode(orgCode: string): (state: MeritBudgetAllocationStateModel) => MeritTask {
    return createSelector([MeritBudgetAllocationState], (state: MeritBudgetAllocationStateModel) => state.tasks[orgCode]);
  }

  static incentiveAllocationByEmployeeOrgCode(orgCode: string): (state: MeritBudgetAllocationStateModel) => IncentiveAllocationOverview {
    return createSelector([MeritBudgetAllocationState], (state: MeritBudgetAllocationStateModel) => state.incentiveAllocations[orgCode]);
  }

  static isEmployeeSelected(id: string): (state: MeritBudgetAllocationStateModel) => boolean {
    return createSelector([MeritBudgetAllocationState], (state: MeritBudgetAllocationStateModel) => state.selectedEmployeeIds.includes(id));
  }

  @Action(LoadDirects)
  loadDirects(ctx: StateContext<MeritBudgetAllocationStateModel>): Observable<void> {
    ctx.patchState({ areDirectsLoading: true });
    this.loadingService.present();
    return this.meritAllocationDirectsService.getDirects(ctx.getState().season?.id, this.store.selectSnapshot(FilterSortState.getSortAndFilterText)).pipe(
      tap(directs => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.directs = directs;
          })
        );
      }),
      switchMap(() => ctx.dispatch(new LoadMeritDirectBudgetOverview())),
      finalize(() => {
        this.loadingService.dismiss();
        ctx.patchState({ areDirectsLoading: false });
      })
    );
  }

  @Action(LoadDirect)
  loadDirect(ctx: StateContext<MeritBudgetAllocationStateModel>, { employeeId }: LoadDirect): Observable<unknown> {
    this.loadingService.present();
    return this.meritAllocationDirectsService.getDirect(ctx.getState().season?.id, employeeId).pipe(
      map(direct =>
        ctx.setState(
          produce(ctx.getState(), state => {
            const index = state.directs.findIndex(stateDirect => stateDirect.id === direct.id);
            if (index > -1) {
              state.directs[index] = { ...state.directs[index], ...direct };
            } else {
              state.directs = [...state.directs, direct];
            }
          })
        )
      ),
      finalize(() => this.loadingService.dismiss())
    );
  }

  @Action(LoadSeasons)
  loadSeasons(ctx: StateContext<MeritBudgetAllocationStateModel>): Observable<unknown> {
    return ctx.dispatch(new SetAdminRights()).pipe(
      switchMap(() => {
        if (ctx.getState().isAdmin) {
          return this.meritAllocationGeneralService.getMeritAllocationSeasons().pipe(
            tap(seasons =>
              ctx.patchState({
                seasons
              })
            ),
            filter(seasons => seasons?.length === 1),
            switchMap(seasons => this.setSeasonInUrl(seasons[0].id))
          );
        }
        return of('').pipe(
          switchMap(() => this.store.select(TopicsState.currentTopics)),
          map(tasks => tasks?.filter(task => task.taskType === TodoTaskType.Allocation)),
          filter(allocationTasks => !!allocationTasks?.length),
          tap(allocationTasks =>
            ctx.patchState({
              seasons: allocationTasks?.map(task => task.season)
            })
          ),
          switchMap(allocationTasks => {
            const seasonsInStateBudgetAllocation = ctx.getState().seasons?.filter(season => season.state === SeasonProcessState.BudgetAllocation);

            if (allocationTasks.length === 1) {
              return this.setSeasonInUrl(allocationTasks[0].season.id);
            }

            if (seasonsInStateBudgetAllocation?.length === 1) {
              return this.setSeasonInUrl(seasonsInStateBudgetAllocation[seasonsInStateBudgetAllocation.length - 1].id);
            }

            return of(null);
          })
        );
      })
    );
  }

  private setSeasonInUrl(id: string): Observable<boolean> {
    return from(
      this.ngZone.run(
        function (this: Router, navigationExtras): Promise<boolean> {
          return this.navigate([], navigationExtras);
        },
        this.router,
        [{ relativeTo: this.activatedRoute, queryParams: { seasonId: id }, queryParamsHandling: 'merge' }]
      )
    );
  }

  @Action(LoadAllocationSettings)
  loadAllocationSettings(ctx: StateContext<MeritBudgetAllocationStateModel>, { payload }: LoadAllocationSettings): Observable<BudgetAllocationSettings> {
    return this.meritAllocationGeneralService.getMeritSeasonSettings(payload).pipe(
      tap(settings => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.allocationSettings = settings;
          })
        );
      })
    );
  }

  @Action(LoadSeasonSettings)
  loadSeasonSettings(ctx: StateContext<MeritBudgetAllocationStateModel>, { payload }: LoadSeasonSettings): Observable<MeritSeasonSetting> {
    return this.seasonSettingsService.getSeasonSettings(payload.id, payload.type).pipe(
      tap((settings: MeritSeasonSetting) => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.seasonSettings = settings;
            state.hasLinkedIncentiveSeason = !!settings.incentiveSeasonId;
          })
        );
      })
    );
  }

  @Action(SetAllocationTask)
  setAllocationTask(ctx: StateContext<MeritBudgetAllocationStateModel>, { payload }: SetAllocationTask): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.allocationTask = payload;
      })
    );
  }

  @Action(SetAdminRights)
  setAdminRights(ctx: StateContext<MeritBudgetAllocationStateModel>): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const roles = this.store.selectSnapshot(UserState?.allPermissionsLoggedInUser);
        const hasAdminRights = roles?.some(role => [PermissionResource.All, PermissionResource.PreviewAllocation].includes(role.resource));
        state.isAdmin = hasAdminRights;
      })
    );
  }

  @Action(SetResponsibilityType)
  setResponsibilityType(ctx: StateContext<MeritBudgetAllocationStateModel>, { payload }: SetResponsibilityType): void {
    ctx.patchState({ responsibilityType: payload });
  }

  @Action(ToggleOnlyOpenItemsVisible)
  toggleOnlyOpenItemsVisible(ctx: StateContext<MeritBudgetAllocationStateModel>): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.onlyOpenItemsVisible = !state.onlyOpenItemsVisible;
      })
    );
  }

  @Action(ToggleMultiSelect)
  toggleMultiSelect(ctx: StateContext<MeritBudgetAllocationStateModel>): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.showMultiSelect = !state.showMultiSelect;
        if (!state.showMultiSelect) {
          state.selectedEmployeeIds = [];
        }
      })
    );
  }

  @Action(ToggleMultiSelectForEmployee)
  toggleMultiSelectForEmployee(ctx: StateContext<MeritBudgetAllocationStateModel>, { payload }: ToggleMultiSelectForEmployee): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const index = state.selectedEmployeeIds.indexOf(payload.id);

        if (index !== -1) {
          state.selectedEmployeeIds.splice(index, 1);
        } else {
          state.selectedEmployeeIds.push(payload.id);
        }
      })
    );
  }

  // TODO:this functionality is available only in the design, but not yet required for development due to missing approval
  @Action(SetSelectedDirectsToBudget)
  setSelectedDirectsToBudget(ctx: StateContext<MeritBudgetAllocationStateModel>): void {}

  @Action(FinalizeSelectedDirects)
  finalizeSelectedDirects(ctx: StateContext<MeritBudgetAllocationStateModel>): Observable<UpdateAllocationValuesDto[]> {
    return this.updateSelectedDirects(ctx, { isDone: true });
  }

  @Action(UndoSelectedDirects)
  undoSelectedDirects(ctx: StateContext<MeritBudgetAllocationStateModel>): Observable<UpdateAllocationValuesDto[]> {
    return this.updateSelectedDirects(ctx, { isDone: false });
  }

  private updateSelectedDirects(ctx: StateContext<MeritBudgetAllocationStateModel>, updateBody: Partial<EmployeeWithCompensationInfo>): Observable<UpdateAllocationValuesDto[]> {
    const directs = ctx.getState().selectedEmployeeIds.map(id => ctx.getState().directs.find(direct => direct.id === id));

    if (!directs?.length) {
      return;
    }

    return this.meritAllocationGeneralService.updateDirects(directs, updateBody, ctx.getState().season.id).pipe(
      tap(() => {
        ctx.setState(
          produce(ctx.getState(), state => {
            directs.forEach(direct => {
              const foundIndex = state.directs.findIndex(item => item.id === direct.id);

              state.directs[foundIndex] = { ...state.directs[foundIndex], ...{ ...direct, ...updateBody } };
            });

            ctx.dispatch(new LoadMeritDirectBudgetOverview());
            state.selectedEmployeeIds = [];
          })
        );
      })
    );
  }

  @Action(SetSeason)
  setSeason(ctx: StateContext<MeritBudgetAllocationStateModel>, { payload }: SetSeason): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const tasks = this.store.selectSnapshot(TopicsState.topics);
        const allocationTasks = tasks?.filter(task => task?.taskType === TodoTaskType.Allocation);
        state.allocationTask = allocationTasks?.find(task => task?.season?.id === payload?.id);
        state.season = payload;
      })
    );
  }

  @Action(LoadMeritDirectBudgetOverview)
  loadMeritDirectBudgetOverview(ctx: StateContext<MeritBudgetAllocationStateModel>): Observable<BudgetAllocationOverview> {
    return this.meritAllocationDirectsService.getAllocationDirectsOverview(ctx.getState().season.id).pipe(
      tap(overview => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.budgetOverview.directBudget = overview;
          })
        );
      })
    );
  }

  @Action(SetTotalBudgetOverview)
  setTotalBudgetOverview(ctx: StateContext<MeritBudgetAllocationStateModel>): void {
    const user = this.store.selectSnapshot(UserState?.user);
    const orgCode = this.store.selectSnapshot(MeritBudgetAllocationTotalState.selectedOrganisation)?.orgCode;
    const state = ctx.getState();
    if (state && orgCode && user) {
      this.meritTotalResponsibilityService
        .getBudgetOverview(state.season.id, this.meritAllocationImpersonatedEmployeeService.employeeId || user.id, orgCode)
        .pipe(filter(totalBudget => !!totalBudget && !!Object.keys(totalBudget)?.length && !(!totalBudget.employeeDone && !totalBudget.employeeTotal)))
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(totalBudget => {
          ctx.setState(
            produce(ctx.getState(), state => {
              state.budgetOverview.totalBudget = new BudgetAllocationOverview(totalBudget);
              state.totalBudgetSyncDate = new Date();
            })
          );
        });
    }
  }

  @Action(ResetSeason)
  resetSeason(ctx: StateContext<MeritBudgetAllocationStateModel>): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.season = undefined;
      })
    );
  }

  @Action(SetSearchedDirect)
  setSearchedDirect(ctx: StateContext<MeritBudgetAllocationStateModel>, { payload }: SetSearchedDirect): Observable<EmployeeWithCompensationInfo> {
    return this.meritAllocationDirectsService.getDirect(ctx.getState().season?.id, payload.employee.employeeId).pipe(
      tap(direct => {
        ctx.patchState({ directs: [direct, ...ctx.getState().directs.filter(alloc => alloc.id !== direct.id)] });
      })
    );
  }

  @Action(UpdateDirect)
  updateDirect(ctx: StateContext<MeritBudgetAllocationStateModel>, { payload }: UpdateDirect): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const foundIndex = state.directs.findIndex(item => item.id === payload.id);

        state.directs[foundIndex] = { ...state.directs[foundIndex], ...payload };

        ctx.dispatch(new LoadMeritDirectBudgetOverview());
      })
    );
  }

  @Action(UpdateDirectCompensationComponents)
  updateDirectCompensationComponents(ctx: StateContext<MeritBudgetAllocationStateModel>, { payload }: UpdateDirectCompensationComponents): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const foundIndex = state.directs.findIndex(direct => direct.employeeId === payload.employeeId);

        if (foundIndex >= 0) {
          const direct = state.directs[foundIndex];
          direct.compensationComponents = payload.compensationComponents ?? direct?.compensationComponents;
          direct.isDone = payload.isDone ?? direct?.isDone;
          direct.promotion = payload.promotion ?? direct?.promotion;
        }
      })
    );
  }

  @Action(LoadMeritTaskByEmployeeOrgCode)
  loadMeritTaskByEmployeeOrgCode(ctx: StateContext<MeritBudgetAllocationStateModel>, { employeeId, orgCode }: LoadMeritTaskByEmployeeOrgCode): Observable<MeritTask> {
    const state = ctx.getState();
    return this.meritTasksService.getMeritTaskByEmployee(state.season.id, employeeId, orgCode).pipe(
      tap(result => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.tasks[orgCode] = result;
          })
        );
      })
    );
  }

  @Action(LoadIncentiveTaskByEmployeeOrgCode)
  loadIncentiveTaskByEmployeeOrgCode(
    ctx: StateContext<MeritBudgetAllocationStateModel>,
    { employeeId, orgCode }: LoadIncentiveTaskByEmployeeOrgCode
  ): Observable<IncentiveAllocationOverview> {
    return this.store.dispatch(new LoadMeritTaskByEmployeeOrgCode(employeeId, orgCode)).pipe(
      switchMap(() => this.incentiveAllocationService.getAllocationTotalOverview(ctx.getState().season.id, orgCode)),
      tap(result => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.incentiveAllocations[orgCode] = { ...result, dueDate: state.tasks[orgCode]?.dueDate };
          })
        );
      })
    );
  }

  @Action(ChangeMeritTaskStatus)
  changeMeritTaskStatus(ctx: StateContext<MeritBudgetAllocationStateModel>, { payload: { employee, seasonId } }: ChangeMeritTaskStatus): Observable<unknown> {
    return this.meritTasksService
      .setTaskStatus(employee.headId ?? employee.id, seasonId)
      .pipe(switchMap(() => this.store.dispatch(new UpdateNode({ ...employee, isDisabled: !employee.isDisabled }))));
  }

  @Action(ResetMeritBudgetAllocationState)
  resetMeritBudgetAllocationState(ctx: StateContext<MeritBudgetAllocationStateModel>): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.allocationTask = null;
        state.tasks = {};
        state.incentiveAllocations = {};
        state.seasonSettings = undefined;
        state.season = undefined;
        state.allocationSettings = undefined;
        state.directs = [];
        state.responsibilityType = MeritBudgetResponsibilityType.DirectResponsibility;
        state.budgetOverview = {
          totalBudget: null,
          directBudget: null
        };
        state.onlyOpenItemsVisible = false;
      })
    );
  }

  @Action(SetEmployeeSelector)
  public setEmployeeSelector(ctx: StateContext<MeritBudgetAllocationStateModel>, employeeSelector: SetEmployeeSelector): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.employeeSelector = employeeSelector.payload;
      })
    );
  }

  @Action(PromoteAllocation)
  promoteAllocation(ctx: StateContext<MeritBudgetAllocationStateModel>, { payload }: PromoteAllocation): Observable<void> {
    const seasonId = ctx.getState().season.id;

    return this.meritAllocationGeneralService.promoteEmployee(seasonId, payload.employeeId, payload.comment).pipe(
      tap(() => {
        ctx.setState(
          produce(ctx.getState(), state => {
            const index = state.directs.findIndex(direct => direct.id === payload.employeeId);
            state.directs[index] = { ...state.directs[index], promotion: MeritPromotionState.Requested };
          })
        );
      })
    );
  }
}
