import { TreeNode } from '../route-builder';

export const TranslationsModule = {
  segment: 'translations',
  title: '',
  children: {
    TranslationsComponent: {
      segment: '',
      title: 'translations.headline',
      navigationRoot: true,
      children: {
        TranslationsAdminComponent: {
          segment: 'admin',
          icon: 'admin_panel_settings',
          title: 'translations.apps.admin',
          params: ['key']
        },
        TranslationsCustomerComponent: {
          segment: 'customer',
          icon: 'apps',
          title: 'translations.apps.customer',
          params: ['key']
        },
        TranslationsCosmosComponent: {
          segment: 'cosmos',
          icon: 'public',
          title: 'translations.apps.cosmos'
        }
      }
    }
  }
} as const satisfies TreeNode;
