<div *ngIf="item" class="cms-chapter-container">
  <mat-form-field *ngIf="contentEdit" class="spacing-top">
    <mat-label>{{ 'cms.content-pages.chapter-icon' | translate }}</mat-label>
    <input matInput aria-label="chapter icon" [(ngModel)]="item.content.icon" [placeholder]="'cms.content-pages.chapter-icon' | translate" type="text" />
  </mat-form-field>
  <mat-form-field *ngIf="contentEdit" class="flex-one spacing-top">
    <mat-label>{{ 'cms.content-pages.chapter-text' | translate }}</mat-label>
    <input matInput aria-label="icon" [(ngModel)]="item.content.text" [placeholder]="'cms.content-pages.chapter-text' | translate" type="text" />
  </mat-form-field>
  <div class="cms-chapter-checks">
    <mat-checkbox *ngIf="contentEdit" [(ngModel)]="item.content['showPrintButton']" [checked]="item.content['showPrintButton']">
      {{ 'cms.content-pages.show-print-page-button' | translate }}</mat-checkbox
    >
    <mat-checkbox *ngIf="contentEdit" [(ngModel)]="item.content['showGermanyRowButton']" [checked]="item.content['showGermanyRowButton']">
      {{ 'cms.content-pages.show-germany-row-button' | translate }}</mat-checkbox
    >
    <mat-checkbox *ngIf="contentEdit" [(ngModel)]="item.requiresAuthentication" [checked]="item.requiresAuthentication">
      {{ 'cms.content-pages.requires-authentication' | translate }}
    </mat-checkbox>
  </div>
</div>

<div *ngIf="item" class="cms-chapter-main" [id]="item?.id ?? ''">
  <div>
    <div class="chapter-icon">
      <span class="material-icons-outlined">{{ item?.content.icon || 'view_in_ar' }}</span>
    </div>
    <p class="cms-chapter-text">{{ item?.content.text }}</p>
  </div>
  <div>
    <div class="cp-slide-toggle" *ngIf="item.content['showGermanyRowButton']">
      <p>{{ 'general.germany' | translate }}</p>
      <mat-slide-toggle [checked]="germanyRowSlideToggle" (change)="slideChange.emit($event)"></mat-slide-toggle>
      <p>ROW</p>
    </div>
    <coin-action-button *ngIf="item.content['showPrintButton']" (click)="printPage()" [height]="20" class="secondary cms-chapter-print" svgIcon="print" [noicon]="true">{{
      'general.print' | translate
    }}</coin-action-button>
  </div>
</div>
