import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import {
  GenericSimulationResponse,
  MassExportMetadata,
  PaginatedResult,
  RuleSetV2,
  SeasonPlan,
  SeasonPlanSettings,
  TransactionStatus,
  TransactionStatusMetadata
} from '@coin/shared/util-models';
import { environment } from '@coin/shared/util-environments';
import { catchError, finalize, map, retryWhen, tap } from 'rxjs/operators';
import { checkResult, pollingStrategy, RuleEngineHelper } from '@coin/shared/util-helpers';
import { IncentivePlanRecalculationStatus, SeasonPlanRepopulation } from '@coin/customer/merit-incentive/util';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';

@Injectable({
  providedIn: 'root'
})
export class SeasonPlanService {
  constructor(
    private httpClient: HttpClient,
    private toast: ToastrService,
    private loadingService: LoadingService,
    private httpHelper: HttpHelpersService
  ) {}

  public getSeasonPlans(seasonId: string): Observable<SeasonPlan[]> {
    const params = new HttpParams({ fromObject: { seasonId } });

    return this.httpClient.get<PaginatedResult<SeasonPlan>>(`${environment.api.baseUrl}/admin/api/v2/master/season-plans`, { params }).pipe(
      map(({ content }) => content),
      this.httpHelper.handleError('Cannot get season plans'),
      this.loadingService.withLoadingScreen
    );
  }

  public getPlanById(planId: string): Observable<SeasonPlan> {
    return this.httpClient
      .get<SeasonPlan>(`${environment.api.baseUrl}/admin/api/v2/master/season-plans/${planId}`)
      .pipe(map(checkResult()), retryWhen(pollingStrategy()), this.httpHelper.handleError('Cannot get plan'));
  }

  public createPlan(body: SeasonPlan): Observable<SeasonPlan> {
    this.loadingService.present();

    return this.httpClient.post<SeasonPlan>(`${environment.api.baseUrl}/admin/api/v2/master/season-plans`, body).pipe(
      tap(() => {
        this.toast.success(`"${body.title}" created.`);
      }),
      this.httpHelper.handleError('Cannot create season plan'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  // used for updating plan without setting isInMonitoring
  public updatePlan(plan: SeasonPlan): Observable<SeasonPlan> {
    this.loadingService.present();

    return this.httpClient
      .patch<SeasonPlan>(`${environment.api.baseUrl}/admin/api/v2/master/season-plans/${plan.id}`, {
        ...plan,
        isInMonitoring: undefined
      })
      .pipe(
        this.httpHelper.handleError('Cannot update'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public createPlanSettings(planId: string, attachedDocumentUri: string): Observable<SeasonPlanSettings> {
    return this.httpClient
      .post<SeasonPlanSettings>(`${environment.api.baseUrl}/admin/api/v2/master/season-plans/${planId}/settings`, {
        attachedDocumentUri
      })
      .pipe(this.httpHelper.handleError('Error creating plan settings'));
  }

  public deletePlanSettings(planId: string, settingsId: string): Observable<void> {
    return this.httpClient
      .delete<void>(`${environment.api.baseUrl}/admin/api/v2/master/season-plans/${planId}/settings/${settingsId}`)
      .pipe(this.httpHelper.handleError('Error deleting plan settings'));
  }

  public getSimulatedPopulation(body: RuleSetV2, take: number, skip: number, planId: string): Observable<GenericSimulationResponse> {
    const page = !skip ? 1 : Math.floor(skip / take) + 1;

    return this.httpClient
      .post<GenericSimulationResponse>(
        `${environment.api.baseUrl}/admin/api/v2/master/season-plans/${planId}/simulate-population?page=${page}&size=${take}`,
        RuleEngineHelper.ruleSetV2ToDto(body)
      )
      .pipe(this.httpHelper.handleError('Cannot get simulated population'));
  }

  public savePopulation(planId: string): Observable<TransactionStatus<TransactionStatusMetadata>> {
    this.loadingService.present();

    return this.httpClient
      .post<TransactionStatus<TransactionStatusMetadata>>(`${environment.api.baseUrl}/admin/api/v2/master/season-plans/${planId}/save-population`, {
        id: planId
      })
      .pipe(
        this.httpHelper.handleError('Cannot save population'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public deletePlan(planId: string): Observable<unknown> {
    return this.httpClient.delete(`${environment.api.baseUrl}/admin/api/v2/master/season-plans/${planId}`).pipe(
      tap(() => {
        this.toast.success(`Deleted.`);
      }),
      this.httpHelper.handleError('Cannot delete season plan'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  public getRecalculationStatus(): Observable<IncentivePlanRecalculationStatus[]> {
    return this.httpClient.get<IncentivePlanRecalculationStatus[]>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/population-status`).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(`Error getting status. ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }

  public recalculate(body: SeasonPlanRepopulation): Observable<unknown> {
    return this.httpClient.post(`${environment.api.baseUrl}/admin/api/v2/master/seasons/repopulation`, body).pipe(
      tap(() => this.toast.success('Recalculation started.')),
      this.httpHelper.handleError('Cannot start recalculation')
    );
  }

  public setMonitoringState(plan: SeasonPlan, isInMonitoring: boolean): Observable<SeasonPlan> {
    this.loadingService.present();

    return this.httpClient
      .patch<SeasonPlan>(`${environment.api.baseUrl}/admin/api/v2/master/season-plans/${plan.id}`, {
        ...plan,
        isInMonitoring
      })
      .pipe(
        tap(() => {
          this.toast.success(isInMonitoring ? 'Monitoring Set!' : 'Monitoring Reset!');
        }),
        this.httpHelper.handleError('Cannot set state'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public setDesignReady(plan: SeasonPlan, isDesignReady: boolean): Observable<SeasonPlan> {
    this.loadingService.present();

    return this.httpClient
      .patch<SeasonPlan>(`${environment.api.baseUrl}/admin/api/v2/master/season-plans/${plan.id}`, {
        ...plan,
        isDesignReady,
        isInMonitoring: undefined
      })
      .pipe(
        tap(() => (isDesignReady ? this.toast.success('Design ready.') : this.toast.info('Design not ready.'))),
        this.httpHelper.handleError('Cannot set design state'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public recalculatePlans(seasonId: string): Observable<TransactionStatus> {
    this.loadingService.present();
    return this.httpClient.post<TransactionStatus>(`${environment.api.baseUrl}/admin/api/v2/master/season-plans/${seasonId}/population-recalculation`, null).pipe(
      this.httpHelper.handleError('Cannot recalculate population'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  public startUnassignedValuesExport(seasonId: string): Observable<TransactionStatus<MassExportMetadata>> {
    this.loadingService.present();
    return this.httpClient
      .put<TransactionStatus<MassExportMetadata>>(`${environment.api.baseUrl}/admin/api/v2/master/season-plans/missing-individual-values?seasonId=${seasonId}`, {})
      .pipe(
        this.httpHelper.handleError('Cannot export missing individual values'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public updatePlanPriorities(seasonId: string, changes: { priority: number; id: string }[]): Observable<unknown> {
    return this.loadingService.withLoadingScreen(
      this.httpClient
        .patch(`${environment.api.baseUrl}/admin/api/v2/master/season-plans/${seasonId}/priorities`, { changes })
        .pipe(this.httpHelper.handleError('Cannot update priorities'))
    );
  }

  public changePlanForEmployee(seasonId: string, employeeId: string, body: { planId: string }): Observable<unknown> {
    return this.httpClient
      .patch(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/${seasonId}/allocations/${employeeId}/change-scheme `, body)
      .pipe(this.httpHelper.handleError('Cannot change employee plan'), this.loadingService.withLoadingScreen);
  }
}
