import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OpenFaqService {
  @Output() openClose = new EventEmitter<boolean>();

  public faqManage(type: boolean): void {
    this.openClose.emit(type);
  }
}
