import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true
})
export class FilterPipe implements PipeTransform {
  transform<T extends Record<string, unknown>, Key extends keyof T>(values: T[], objectKey: Key, objectValue: T[Key]): T[] {
    return values?.length && objectKey && objectValue ? values.filter(value => value[objectKey] === objectValue) : [];
  }
}
