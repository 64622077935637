<div class="headline">
  <span>{{ 'general.rule-engine-headline-prefix' | translate }}</span>
  <coin-rule-engine-condition-picker [disabled]="disabled" [currentCondition]="ruleSet.condition" (changed)="setCondition($event)"></coin-rule-engine-condition-picker>
  <span>{{ 'general.rule-engine-headline-postfix' | translate }}</span>
  <div class="spacer"></div>
  <mat-icon *ngIf="!disabled" (click)="delete.emit(ruleSet)" class="delete">delete</mat-icon>
</div>

<coin-lightweight-rule
  *ngFor="let rule of ruleSet.rules"
  [disabled]="disabled"
  (changes)="changes.emit()"
  [rule]="rule"
  [deletable]="ruleSet.rules.length > 2"
  [config]="config"
  [translateKeys]="translateKeys"
  (delete)="deleteRule(rule)">
</coin-lightweight-rule>
<coin-v2-button *ngIf="!disabled" (click)="addRule()" icon="add" type="secondary" [text]="'general.add-sub-rule' | translate"></coin-v2-button>
