<mat-form-field
  [matTooltip]="errorTooltip$ | async"
  [matTooltipDisabled]="ngControl?.untouched"
  matTooltipClass="error-tooltip"
  class="date-input-picker coin-form-field-outline coin-form-field-outline--{{ matFormFieldClass }} no-margin"
  appearance="outline"
  floatLabel="always">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    [style.font-family]="boldValue && value ? 'Siemens Sans Bold !important' : null"
    [placeholder]="placeholder"
    matInput
    [matDatepicker]="datePicker"
    [matDatepickerFilter]="dayFilter"
    [disabled]="disabled"
    [(ngModel)]="value"
    [min]="min"
    [max]="max"
    (focus)="datePicker.open()" />
  <mat-icon *ngIf="icon" matSuffix>{{ icon }}</mat-icon>
  <mat-datepicker (closed)="onTouch(value)" #datePicker (opened)="insertTodayButton()"> </mat-datepicker>
</mat-form-field>

<ng-template #todayButton>
  <coin-v2-button type="primary" [text]="'general.today' | translate" (click)="selectToday(datePicker)"> </coin-v2-button>
</ng-template>
