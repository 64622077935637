import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Fn } from '@coin/shared/util-models';
import { MatRadioChange } from '@angular/material/radio';

const DefaultColorPickerValues = {
  backgroundColor: '#FFFFFF',
  textColor: '#000000'
};

@Component({
  selector: 'coin-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true
    }
  ]
})
export class ColorPickerComponent implements ControlValueAccessor {
  @Input() isTextColorPicker = false;
  @Input() disabled = false;

  @Input() set extraColors(val: ColorCode[]) {
    this.colors.fg.push(...val);
    this.colors.bg.push(...val);
  }

  colors = {
    fg: [
      { displayName: 'Black', hexCode: DefaultColorPickerValues.textColor, class: 'shift' },
      {
        displayName: 'White',
        hexCode: '#FFFFFF'
      }
    ],
    bg: [
      { displayName: 'White', hexCode: DefaultColorPickerValues.backgroundColor, class: 'shift' },
      { displayName: 'Grey', hexCode: '#F0F0F0', class: 'shift' },
      { displayName: 'Dark Blue', hexCode: '#1B1534' }
    ]
  };

  private _value: string | null = null;

  public set value(val: string | null) {
    if (!val) {
      return;
    }
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  public get value(): string | null {
    return this._value;
  }

  public onChange: Fn = () => {};
  public onTouch: Fn = () => {};

  public onChangeSelection(event: MatRadioChange): void {
    this.writeValue(event.value);
  }

  public writeValue(value: string | null): void {
    if (!value) {
      return;
    }
    this._value = value;
  }

  public registerOnChange(fn: Fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Fn): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

export interface ColorCode {
  displayName: string;
  hexCode: string;
}
