import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CmsComponentsModule } from '@coin/admin/cms/feature-preview';
import { TranslateModule } from '@ngx-translate/core';
import { SimplebarAngularModule } from 'simplebar-angular';
import { EquityModule } from '../equity/equity.module';
import { CustomTranslationLoader } from '../shared/constants/translation-loader';
import { SharedModule } from '../shared/shared.module';
import { ContentPagesComponent } from './content-pages.component';

@NgModule({
  declarations: [ContentPagesComponent],
  imports: [CmsComponentsModule, TranslateModule.forChild(CustomTranslationLoader.config), SharedModule, CommonModule, FormsModule, SimplebarAngularModule, EquityModule],
  providers: []
})
export class ContentPagesModule {}
