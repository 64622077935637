import { Component, Input, OnInit, Renderer2, AfterViewInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { combineLatest, map } from 'rxjs';
import { FrontendType, PermissionResource } from '@coin/shared/util-enums';
import { AuthService } from '@coin/modules/auth/data-access';
import { UserState } from '@coin/modules/auth/data-management';
import { Admin } from '@coin/shared/util-helpers';

@Component({
  selector: 'coin-siemens-energy-coin-apps-overview',
  templateUrl: './coin-apps-overview.component.html',
  styleUrls: ['./coin-apps-overview.component.scss']
})
export class CoinAppsOverviewComponent implements OnInit, AfterViewInit {
  private _coinBaseUrl: string;
  @Input()
  public get coinBaseUrl(): string {
    return this._coinBaseUrl;
  }
  public set coinBaseUrl(value: string) {
    this._coinBaseUrl = this.removeTrailingSlash(value);
  }

  private _cosmosBaseUrl: string;
  @Input()
  public get cosmosBaseUrl(): string {
    return this._cosmosBaseUrl;
  }
  public set cosmosBaseUrl(value: string) {
    this._cosmosBaseUrl = this.removeTrailingSlash(value);
  }

  @Input()
  public isLineManager = false;

  public permissionResource = PermissionResource;
  public appType = FrontendType;

  public isMercerAccessAllowed$ = combineLatest([this.store.select(UserState.allPermissions), this.store.select(UserState.isDynamicMercerAccessAllowed)]).pipe(
    map(([permissions, isDynamicMercerAccessAllowed]) => isDynamicMercerAccessAllowed || permissions?.some(permission => permission.resource === PermissionResource.All))
  );

  public analyticsUrl = 'https://siemens-energy.pulse.cloud/project/2';
  public isDeputy = false;

  constructor(
    private router: Router,
    private store: Store,
    private authService: AuthService,
    private renderer: Renderer2,
    private element: ElementRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.isDeputy = (await this.authService.getEmulationDecoded())?.IsDeputy;
  }

  ngAfterViewInit(): void {
    const element = this.element.nativeElement.querySelector('.simplebar-content-wrapper');

    if (element) {
      this.renderer.setAttribute(element, 'tabindex', '-1');
    }
  }

  public openApp(link: string, applicationType: FrontendType = FrontendType.customer): void {
    if (applicationType === FrontendType.customer && this.coinBaseUrl) {
      this.openPage(`${this.coinBaseUrl}${link}`);
    } else if (applicationType === FrontendType.cosmos && this.cosmosBaseUrl) {
      this.openPage(`${this.cosmosBaseUrl}${link}`);
    } else {
      this.router.navigate([link]);
    }
  }

  public openPage(url: string): void {
    window.open(url, '_blank', 'noopener noreferrer');
  }

  public openAdminFrontend(): void {
    Admin.open('HomePageModule');
  }

  private removeTrailingSlash(value: string): string {
    return value.replace(/\/$/, '');
  }
}
