import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CmsItem } from '@coin/admin/cms/util';
import { adminShrinkExpandAnimation, flyInFromTopNew } from '@coin/shared/util-animations';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-equity-cms-chapter-overview',
  templateUrl: './equity-cms-chapter-overview.component.html',
  styleUrls: ['./equity-cms-chapter-overview.component.scss'],
  animations: [
    trigger('animateChapter', [
      transition(':enter', [style({ opacity: '0', 'max-height': '0px', overflow: 'hidden' }), animate('200ms', style({ opacity: '1', 'max-height': '60px', overflow: 'hidden' }))]),
      transition(':leave', [style({ opacity: '1', 'max-height': '60px', overflow: 'hidden' }), animate('500ms', style({ opacity: '0', 'max-height': '0px', overflow: 'hidden' }))])
    ]),
    adminShrinkExpandAnimation,
    flyInFromTopNew
  ]
})
export class EquityCmsChapterOverviewComponent implements OnInit, OnChanges {
  @Input() item: CmsItem[];
  @Input() contentEdit = false;
  @Input() update = true;
  @Input() collapsed = false;

  public usedChapters: CmsItem[] = [];

  /**
   * On any changes look for new chapters and filter for these
   * @param changes SimpleChanges Object trigger item
   */
  ngOnChanges(): void {
    if (this.item) {
      this.usedChapters = this.item.filter(itm => itm.type === 'chapter');
    }
  }

  ngOnInit(): void {
    this.usedChapters = this.item?.filter(itm => itm.type === 'chapter');
  }

  /**
   * On any chapter click, scroll to the respective item in view
   * @param chapter Object that has been clicked
   */
  public chapterClick(chapter: CmsItem): void {
    if (chapter.id) {
      const chapterElement = document.getElementById(chapter.id);
      if (chapterElement) {
        chapterElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
