import { Injectable } from '@angular/core';
import { EmployeeService } from '@coin/modules/auth/data-access';
import { Employee } from '@coin/shared/util-models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClearDirects, GetDirects, GetDirectsInCompanyManager, GetDirectsLineManager } from './directs.actions';

const STATE_DEFAULTS: DirectsStateModel = {
  inCompanyManagerDirects: [],
  lineManagerDirects: []
};
export interface DirectsStateModel {
  inCompanyManagerDirects: Employee[];
  lineManagerDirects: Employee[];
}
@State<DirectsStateModel>({
  name: 'DirectsState',
  defaults: STATE_DEFAULTS
})
@Injectable()
export class DirectsState {
  constructor(private employeeService: EmployeeService) {}

  @Selector()
  static hasDirects(state: DirectsStateModel): boolean {
    return !!(state.inCompanyManagerDirects.length || state.lineManagerDirects.length);
  }

  @Selector()
  static isLineManager(state: DirectsStateModel): boolean {
    return !!state.lineManagerDirects.length;
  }

  @Selector()
  static inCompanyManagerDirects(state: DirectsStateModel): Employee[] {
    return state.inCompanyManagerDirects;
  }

  @Selector()
  static lineManagerDirects(state: DirectsStateModel): Employee[] {
    return state.lineManagerDirects;
  }

  static sortEmployees(employees: Employee[]): Employee[] {
    return employees.sort((a, b) => a?.firstname?.localeCompare(b?.firstname));
  }

  @Action(GetDirectsInCompanyManager)
  getDirectsInCompanyManager(ctx: StateContext<DirectsStateModel>, { payload }: GetDirectsInCompanyManager): Observable<DirectsStateModel> {
    return this.employeeService.getInCompanyManagerDirectsForEmployee(payload.userId).pipe(
      map(inCompanyManagerDirects => DirectsState.sortEmployees(inCompanyManagerDirects)),
      map(inCompanyManagerDirects => ctx.patchState({ inCompanyManagerDirects }))
    );
  }

  @Action(GetDirectsLineManager)
  getDirectsLineManager(ctx: StateContext<DirectsStateModel>, { payload }: GetDirectsLineManager): Observable<DirectsStateModel> {
    return this.employeeService.getLineManagerDirectsForEmployee(payload.userId).pipe(
      map(lineManagerDirects => DirectsState.sortEmployees(lineManagerDirects)),
      map(lineManagerDirects => ctx.patchState({ lineManagerDirects }))
    );
  }

  @Action(GetDirects)
  getDirects(ctx: StateContext<DirectsStateModel>, { payload }: GetDirects): Observable<DirectsStateModel> {
    return forkJoin([this.employeeService.getInCompanyManagerDirectsForEmployee(payload.userId), this.employeeService.getLineManagerDirectsForEmployee(payload.userId)]).pipe(
      map(([inCompanyManagerDirects, lineManagerDirects]) => [DirectsState.sortEmployees(inCompanyManagerDirects), DirectsState.sortEmployees(lineManagerDirects)]),
      map(([inCompanyManagerDirects, lineManagerDirects]) => ctx.patchState({ inCompanyManagerDirects, lineManagerDirects }))
    );
  }

  @Action(ClearDirects)
  clearDirects(ctx: StateContext<DirectsStateModel>): Observable<DirectsStateModel> {
    return of(ctx.patchState({ inCompanyManagerDirects: [], lineManagerDirects: [] }));
  }
}
