export class EmployeeMercerData {
  gid = '';
  lastname = '';
  firstname = '';
  orgCode = '';
  country = '';
  are = '';
  mercerJobCode = '';
  mercerPositionClass?: number = null;
  mercerValidFrom = '';
  mercerFamilyName = '';
  mercerSubFamilyName = '';
  mercerSpecializationName = '';
  mercerCareerStream = '';
  mercerCareerLevelName = '';
  mercerInternalLevel? = '';
  mercerPcValidated?: boolean = null;

  approved? = false;
  flaggedNotOwner? = false;

  constructor(initial?: Partial<EmployeeMercerData>) {
    Object.assign(this, initial);
  }

  public getExcelExportValue() {
    return {
      gid: this.gid,
      lastname: this.lastname,
      firstname: this.firstname,
      orgCode: this.orgCode,
      country: this.country,
      are: this.are,
      mercerJobCode: this.mercerJobCode,
      mercerPositionClass: this.mercerPositionClass,
      mercerValidFrom: this.mercerValidFrom,
      mercerFamilyName: this.mercerFamilyName,
      mercerSubFamilyName: this.mercerSubFamilyName,
      mercerSpecializationName: this.mercerSpecializationName,
      mercerCareerStream: this.mercerCareerStream,
      mercerCareerLevelName: this.mercerCareerLevelName,
      mercerInternalLevel: this.mercerInternalLevel,
      mercerPcValidated: this.mercerPcValidated
    };
  }
}

export class EmployeeMercerDataSlim {
  id?: string;
  employeeId: string;
  positionClassValidFrom?: string | Date;
  positionClassValidTo?: string | Date;
  positionClass?: number;
  jobCode?: string;
  jobCodeValidFrom?: string | Date;
  jobCodeValidTo?: string | Date;
  internalLevel?: string;
  pcValidated?: boolean;
  jobCodeValidated?: boolean;
}

export class MercerData {
  id = '';
  jobCode = '';
  jobTitle = '';
  familyCode = '';
  family = '';
  familyDescription = '';
  subFamilyCode = '';
  subFamily = '';
  subFamilyDescription = '';
  specializationCode = '';
  specialization = '';
  specializationDescription = '';
  careerStream = '';
  careerStreamDescription = '';
  careerLevel = '';
  careerLevelShort = '';
  careerLevelDescription = '';
  positionClass?: number | string = null;
  jobCodeValidFrom = '';
  jobCodeValidTo = '';
  jobCodeValidated = false;
  validFrom = '';
  validTo = '';
  positionClassValidated = false;

  constructor(initial?: Partial<MercerData>) {
    if (initial) {
      for (const key of Object.keys(this)) {
        this[key] = initial[key];
      }
    }
  }
}

export interface MercerDataUpload {
  gid: string;
  validFrom: string;
  jobCode: string;
  positionClass: string;
}

export interface FieldValuesDescription {
  title: string;
  description: string;

  // only FE for sorting career stream
  rank?: number;
}

export function deleteCascadingFieldsIfNecessary(key: string, mercerData: MercerData): void {
  if (key.includes('family')) {
    mercerData.family = '';
    mercerData.familyCode = '';
    mercerData.familyDescription = '';
  } else if (key.includes('subFamily')) {
    mercerData.subFamily = '';
    mercerData.subFamilyCode = '';
    mercerData.subFamilyDescription = '';
  } else if (key.includes('specialization')) {
    mercerData.specialization = '';
    mercerData.specializationCode = '';
    mercerData.specializationDescription = '';
  } else if (key.includes('careerStream')) {
    mercerData.careerStream = '';
    mercerData.careerStreamDescription = '';
  } else if (key.includes('careerLevel')) {
    mercerData.careerLevel = '';
    mercerData.careerLevelShort = '';
    mercerData.careerLevelDescription = '';
  }
}
