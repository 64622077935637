import { Directive, ElementRef, HostListener } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[copyClick]',
  providers: [MatTooltip],
  standalone: true
})
export class CopyOnClickDirective {
  @HostListener('click')
  private onClick(): void {
    navigator.clipboard.writeText(this.host.nativeElement.textContent);

    setTimeout(() => {
      this.setTooltip(true);
      this.tooltip.show();
    }, 10);

    setTimeout(() => {
      this.setTooltip(false);
    }, 5000);
  }

  @HostListener('mouseover')
  private onMouseOver(): void {
    this.tooltip.show();
  }

  @HostListener('mouseleave')
  private onMouseLeave(): void {
    this.tooltip.hide();
  }

  constructor(
    private host: ElementRef<HTMLElement>,
    private tooltip: MatTooltip,
    private translateService: TranslateService
  ) {
    this.host.nativeElement.style.cursor = 'default';

    this.setTooltip(false);
  }

  private setTooltip(copied: boolean): void {
    this.tooltip.message = this.translateService.instant(copied ? 'employee-profile.copied-tooltip' : 'employee-profile.copy-tooltip');
  }
}
