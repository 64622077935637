<div *ngIf="item" class="full-image-main">
  <div class="full-image-container">
    <div class="full-image-box">
      <h4 *ngIf="!contentEdit">{{ item.content.headline }}</h4>
      <mat-form-field *ngIf="contentEdit" class="full-image-headline">
        <mat-label>{{ 'cms.content-pages.headline-text' | translate }}</mat-label>
        <input matInput aria-label="image headline" [(ngModel)]="item.content.headline" [placeholder]="'cms.content-pages.headline-text' | translate" type="text" />
      </mat-form-field>
      <div class="full-image-enlarge">
        <div *ngIf="enableEnlarge" (click)="enlargeImage()" class="enlarge-icon">
          <span class="material-icons">fit_screen</span>
        </div>
        <div><img aria-label="image" [src]="item.content.imageUrl" /></div>
      </div>
      <coin-action-button class="full-image-change-picture" (click)="editPicture(item.content)" class="secondary" svgIcon="edit" [noicon]="true" *ngIf="contentEdit"
        >Change Picture</coin-action-button
      >
      <p *ngIf="!contentEdit" class="light">{{ item.content.text }}</p>
      <mat-form-field *ngIf="contentEdit" class="full-image-headline">
        <mat-label>{{ 'cms.content-pages.headline-text' | translate }}</mat-label>
        <input matInput aria-label="image headline" [(ngModel)]="item.content.text" [placeholder]="'cms.content-pages.text' | translate" type="text" />
      </mat-form-field>
    </div>
  </div>
</div>
