import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CustomTranslationLoader } from '../../../shared/constants/translation-loader';
import { TopicActionBarComponent } from './topic-action-bar.component';

@NgModule({
  declarations: [TopicActionBarComponent],
  imports: [TranslateModule.forChild(CustomTranslationLoader.config), CommonModule, MatIconModule, FormsModule],
  exports: [TopicActionBarComponent]
})
export class TopicActionBarModule {}
