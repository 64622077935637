import { Component, Input } from '@angular/core';
import { TranslationFile } from '@coin/shared/util-models';
import { CmsItem } from '@coin/admin/cms/util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'gmm-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent {
  // TODO: check if quote has been removed
  @Input() item: CmsItem;
  @Input() contentEdit = false;
  @Input() translationFile: TranslationFile;

  constructor() {}
}
