import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { S3File } from '@coin/shared/util-models';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetDocumentsService {
  constructor(
    private http: HttpClient,
    private loadingService: LoadingService
  ) {}

  getAllMyFiles(): Observable<S3File<never>[]> {
    return this.http.get<S3File<never>[]>(`${environment.api.documentsUrl}/private-documents/list`);
  }

  getFileDownloadUrl(filename: string): Observable<string> {
    return this.http.post<string>(`${environment.api.documentsUrl}/private-documents`, { key: filename });
  }

  getFileForDirectDownload(filename: string, directGid: string): Observable<string> {
    return this.http.post<string>(`${environment.api.documentsUrl}/direct-documents`, { key: filename, userGid: directGid });
  }

  getFile(link: string): Observable<Blob> {
    this.loadingService.present();
    return this.http.get(link, { responseType: 'blob' }).pipe(finalize(() => this.loadingService.dismiss()));
  }

  getAllDirectLetters(gid: string): Observable<S3File<never>[]> {
    this.loadingService.present();
    const params = new HttpParams().append('userGid', gid);
    return this.http.get<S3File<never>[]>(`${environment.api.documentsUrl}/direct-documents/list`, { params }).pipe(
      catchError(() => of(null)),
      finalize(() => this.loadingService.dismiss())
    );
  }
}
