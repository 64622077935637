<div class="footer-expand" (click)="showFooter = !showFooter">
  <p>
    © Siemens Energy <span class="material-icons">{{ showFooter ? 'expand_more' : 'expand_less' }}</span>
  </p>
</div>
<div class="footer" [ngClass]="{ 'footer--show': showFooter }">
  <a class="footer-item" href="https://www.siemens-energy.com/global/en.html">© Siemens Energy, {{ currentYear }}</a>
  <a class="footer-item" href="https://www.siemens-energy.com/global/en/general/terms-of-use.html">Terms of Use</a>
  <a class="footer-item" href="https://www.siemens-energy.com/global/en/general/digital-id.html">Digital ID</a>
  <a class="footer-item" href="https://www.siemens-energy.com/global/en/general/corporate-information.html">Corporate Information</a>
  <a class="footer-item" href="https://www.siemens-energy.com/global/en/general/cookie-notice.html">Cookie Notice</a>
  <a class="footer-item" href="https://www.siemens-energy.com/global/en/general/privacy-notice.html">Privacy Notice</a>
  <ng-container *featureFlag="'customerGbrFooter'">
    <a class="footer-item" routerLink="/gbr">GBR</a>
  </ng-container>
  <a class="footer-item" routerLink="/service-provider">Service Provider</a>
</div>
