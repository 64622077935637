import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { StorageKey } from '@coin/shared/util-enums';
import { StorageValue } from '@coin/shared/util-models';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private readonly platform = inject(PLATFORM_ID);

  constructor() {
    this.init();
  }

  private async init(): Promise<void> {
    if (isPlatformBrowser(this.platform)) {
      this.copyData();
    }
  }

  // TODO: Temp migration of old keys
  private copyData(): void {
    const prefix = '_ionicstorage/_ionickv/';

    for (const key in localStorage) {
      if (!key.startsWith(prefix)) {
        continue;
      }

      const value = localStorage.getItem(key);
      localStorage.setItem(key.replace(prefix, ''), value);
    }

    for (const key in localStorage) {
      if (!key.startsWith(prefix)) {
        continue;
      }

      localStorage.removeItem(key);
    }
  }

  public async getAsync<Key extends StorageKey>(key: Key): Promise<StorageValue[Key]> {
    if (isPlatformBrowser(this.platform)) {
      try {
        const obj = JSON.parse(localStorage?.getItem(key)) as StorageValue[Key];
        return typeof obj === 'object' ? obj : (obj?.toString() as StorageValue[Key]);
      } catch {
        return localStorage?.getItem(key) as StorageValue[Key];
      }
    }
  }

  public get<Key extends StorageKey>(key: Key): StorageValue[Key] {
    if (isPlatformBrowser(this.platform)) {
      try {
        const obj = JSON.parse(localStorage?.getItem(key)) as StorageValue[Key];
        return typeof obj === 'object' ? obj : (obj?.toString() as StorageValue[Key]);
      } catch {
        return localStorage?.getItem(key) as StorageValue[Key];
      }
    }
  }

  public async setAsync<Key extends StorageKey>(key: Key, value: StorageValue[Key]): Promise<void> {
    if (isPlatformBrowser(this.platform)) {
      localStorage?.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
    }
  }

  public set<Key extends StorageKey>(key: Key, value: StorageValue[Key]): void {
    if (isPlatformBrowser(this.platform)) {
      localStorage?.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
    }
  }

  public async remove<Key extends StorageKey>(key: Key): Promise<void> {
    if (isPlatformBrowser(this.platform)) {
      localStorage?.removeItem(key);
    }
  }

  public async clear(): Promise<void> {
    if (isPlatformBrowser(this.platform)) {
      localStorage?.clear();
    }
  }
}
