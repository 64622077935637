import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GetImageWithGidService } from '@coin/modules/auth/data-access';
import { Employee, EmployeeSlim, PaginatedResult } from '@coin/shared/util-models';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'coin-siemens-energy-employee-preview',
  templateUrl: './employee-preview.component.html',
  styleUrls: ['./employee-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeePreviewComponent implements OnInit, OnChanges {
  @Input() employee: Employee;
  @Input() boldName = true;
  @Input() light = false;
  @Input() big = false;
  @Input() small = false;
  @Input() tiny = false;
  @Input() noWrap? = false;
  @Input() wrap? = false;
  @Input() singleLine = true;
  @Input() showGid = false;
  @Input() showOrgCode = true;
  @Input() showFlag = true;
  @Input() positionClass?: number;
  @Input() smallerPositionClass = false;
  @Input() useIntersectionObserver = false;
  @Input() subTitle?: string;
  @Input() actionButtonTitle?: string;
  @Input() showActionButton = false;
  @Input() actionIcon = 'person_add';
  @Input() showHintIfNoPositionClassSet = false;
  @Input() placeholderText: string;
  @Input() imageIcon: string;
  @Input() idKey = 'GID';
  @Input() isOrgCodeEditable = false;
  @Input() namePrefix: string;
  @Input() customTopInfo: string[];
  @Input() customSubInfo: string[];
  @Input() showExitDateWarning = false;
  @Input() lazyLoadOrgCodesFn: (page: number, search: string) => Observable<PaginatedResult<string>>;
  @Output() orgCodeChanged = new EventEmitter<string>();
  @Output() action = new EventEmitter<void>();

  public userImage: string;

  public country(employee: Employee): string {
    return employee?.countryCode ?? employee?.placeOfAction;
  }

  public get roleNames(): string[] {
    return (this.employee as EmployeeSlim)?.roleNames || [];
  }

  public get showPlaceholder(): boolean {
    return !this.employee && !!this.placeholderText;
  }

  public get formattedCompanyExitDate(): string {
    if (!this.employee?.companyExitDate) return '';

    return moment(this.employee.companyExitDate).format('MM/DD/YYYY');
  }

  constructor(
    private imageService: GetImageWithGidService,
    private cdRef: ChangeDetectorRef,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.setImageLoadingListener();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.employee && changes.employee.previousValue?.id !== changes.employee.currentValue?.id) {
      this.setImageLoadingListener();
    }
  }

  public onActionClick(): void {
    this.action.emit();
  }

  private setImageLoadingListener(): void {
    of('')
      .pipe(
        debounceTime(200),
        filter(() => this.imageService && !this.light),
        map(() => this.employee?.headGid || this.employee?.gid),
        switchMap(gid => (gid ? this.imageService.getImageWithStore(gid) : of(''))),
        tap(image => {
          this.userImage = image;
        }),
        tap(() => this.cdRef.detectChanges())
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
