<div class="item-container" (click)="onClickItem()" [class.is-selected]="isSelected" [matTooltip]="disableNavigation ? ('allocation.list-view.max-level' | translate) : null">
  <div class="tree-container">
    <div *ngIf="!isRoot" class="tree-wrapper">
      <div *ngIf="!lastParent" class="tree-marker-vertical" [class.last]="last && !isMaxLevel"></div>
      <div *ngIf="!isMaxLevel" class="tree-marker-horizontal"></div>
    </div>

    <div *ngIf="!isRoot && isMaxLevel" class="tree-wrapper shift">
      <div class="tree-marker-vertical" [class.last]="last"></div>
      <div class="tree-marker-horizontal"></div>
    </div>

    <div class="content-wrapper">
      <p class="bold" [class.isRoot]="isRoot">{{ head?.headName }}</p>
      <p [class.isRoot]="isRoot">{{ head?.orgCode }}</p>
    </div>
  </div>
  <div class="action-wrapper">
    <mat-icon class="arrow">arrow_forward_ios</mat-icon>
  </div>
</div>
<coin-c-org-tree-overview-items
  *ngIf="level < 2 && level !== 0"
  [rootEmployee]="head"
  [level]="2"
  [lastParent]="last"
  [selectedEmployeeId]="selectedEmployeeId"
  (itemSelect)="clickTreeItem.emit()"
  [customOpenEmployeeInViewAction]="customOpenEmployeeInViewAction"
  [service]="service"
  [seasonId]="seasonId"
>
</coin-c-org-tree-overview-items>
