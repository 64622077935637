import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@coin/shared/util-environments';
import { Observable } from 'rxjs';
import { OrganisationSnapshot } from '@coin/shared/util-models';
import { LoadingService } from './loading/loading.service';
import { HttpHelpersService } from './http-helpers';

@Injectable({
  providedIn: 'root'
})
export class OrganisationSnapshotService {
  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private httpHelper: HttpHelpersService
  ) {}

  public getOrganisationSnapshot(seasonId: string, organisationSnapshotId: string): Observable<OrganisationSnapshot> {
    return this.httpClient
      .get<OrganisationSnapshot>(`${environment.api.baseUrl}/admin/api/v3/master/seasons/${seasonId}/snapshots/organisations/${organisationSnapshotId}`)
      .pipe(this.loadingService.withLoadingScreen, this.httpHelper.handleError('Cannot get organisation snapshot'));
  }
}
