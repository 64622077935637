export interface BudgetAllocationOverviewDto {
  orgCode?: string;

  employeeTotal: number;
  employeeDone: number;

  merit: MeritBudgetOverviewDto;
  equity: EquityBudgetOverviewDto;
}

export interface MeritBudgetOverviewDto {
  totalBudget: number;
  budget: number;
  spent: number;
  restricted?: number;
}

export interface EquityBudgetOverviewDto {
  budget: number;
  spent: number;
}

export class BudgetAllocationOverview {
  orgCode?: string;
  employeeTotal: number;
  employeeDone: number;
  merit: MeritBudgetOverview;
  equity: EquityBudgetOverview;

  constructor(dto: BudgetAllocationOverviewDto) {
    this.orgCode = dto?.orgCode;
    this.employeeTotal = dto.employeeTotal;
    this.employeeDone = dto.employeeDone;
    this.merit = new MeritBudgetOverview(dto.merit);
    this.equity = new EquityBudgetOverview(dto.equity);
  }
}

export class EquityBudgetOverview {
  budget: number;
  spent: number;

  get spentPercentage(): number {
    if (this.budget === 0 && this.spent === 0) {
      return 1;
    }
    return this.spent / this.budget;
  }
  get spentTotal(): number {
    return this.spent - this.budget;
  }
  get spentDiffPercentage(): number {
    return this.spentPercentage - 1;
  }

  constructor(dto: EquityBudgetOverviewDto) {
    Object.assign(this, dto);
  }
}

export class MeritBudgetOverview extends EquityBudgetOverview {
  totalBudget: number;
  restricted?: number;

  get restrictedPercentage(): number {
    return this.budget === 0 ? 0 : this.restricted / this.budget;
  }
}
