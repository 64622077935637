<div style="padding: 20px 0px; margin-top: 20px">
  <h3 *ngIf="!contentEdit">{{ item?.headline }}</h3>
  <h3 *ngIf="contentEdit" [contentEditable]="contentEdit" [textContent]="item?.headline" (input)="item.headline = $event.target.textContent"></h3>

  <div class="mod-image-quote mod-image-quote--light mod-image-quote--left mod-image-quote--background">
    <div class="m-content" style="padding: 20px 0px 30px 0px">
      <div class="m-text-content">
        <p class="m-quote blockquote" *ngIf="!contentEdit" [innerHTML]="item?.text"></p>
        <app-html-editor *ngIf="contentEdit" [item]="item" [contentEdit]="true" [isNews]="true"></app-html-editor>
        <!-- <p class="m-quote blockquote" *ngIf="contentEdit" [contentEditable]="contentEdit" [textContent]="item.text" (input)="item.text = $event.target.textContent"></p> -->
      </div>
    </div>
  </div>
</div>
