<div>
  <div class="single-double-main">
    <div class="flex-one" *ngFor="let image of item?.content">
      <div
        class="single-double-image"
        [ngClass]="{ 'multiple-images': item?.content?.length > 1 }"
        [style.background-image]="'url(' + image.link + ')'"
        [style.height.px]="item?.height"
        [style.max-height.vh]="isResponsive ? 20 : ''"></div>
      <div class="single-double-button">
        <coin-action-button (click)="editPicture(image)" class="secondary single-double-button-item" svgIcon="edit" [noicon]="true" *ngIf="contentEdit">
          {{ 'cms.emails.change-image-button' | translate }}
        </coin-action-button>
        <coin-input *ngIf="contentEdit && editImageLink" [(ngModel)]="image.anchorLink" addClass="shadow" label="Image URL"></coin-input>
      </div>
    </div>
  </div>
</div>

<div style="display: flex; gap: 8px">
  <mat-form-field appearance="fill" *ngIf="contentEdit">
    <mat-label>{{ 'cms.amount-images' | translate }}</mat-label>
    <mat-select (selectionChange)="amountChange($event.value)" [(ngModel)]="amountImages">
      <mat-option *ngFor="let option of amountOptions" [value]="option">{{ option }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="contentEdit && item" class="single-double-height" style="margin: auto">
    <mat-label>{{ 'cms.content-pages.image-height' | translate }}</mat-label>
    <input matInput aria-label="image height" [(ngModel)]="item.height" [placeholder]="'150'" type="number" />
  </mat-form-field>
</div>
<ng-container *ngIf="contentEdit && showBackgroundPicker && item">
  <div class="background-color-picker">
    <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
  </div>
  <coin-a-email-padding-slider [(ngModel)]="item.paddingTop" label="Space-Top"></coin-a-email-padding-slider>
  <coin-a-email-padding-slider [(ngModel)]="item.paddingBottom" label="Space-Bottom"></coin-a-email-padding-slider>
</ng-container>
