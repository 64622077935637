import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { produce } from 'immer';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { ILetter, S3File } from '@coin/shared/util-models';
import { EmployeeHelper } from '@coin/shared/util-helpers';
import { LoadingService } from '@coin/shared/data-access';
import { AuthService } from '@coin/modules/auth/data-access';
import { UserState } from '@coin/modules/auth/data-management';
import * as actions from './documents.actions';
import { GetDocumentsService } from '../services/get-documents.service';
import { LetterCreationService } from '../../shared/services/letter-creation.service';

export interface DocumentsStateModel {
  documentItems: S3File<never>[];
  letters: ILetter[];
}

@State<DocumentsStateModel>({
  name: 'documentsState',
  defaults: {
    documentItems: [],
    letters: []
  }
})
@Injectable()
export class DocumentsState {
  constructor(
    private documentsService: GetDocumentsService,
    private letterCreationService: LetterCreationService,
    private store: Store,
    private authService: AuthService,
    private loadingService: LoadingService
  ) {}

  @Selector()
  static documentItems(state: DocumentsStateModel): S3File<never>[] {
    return state.documentItems ? state.documentItems : [];
  }

  @Selector()
  static letters(state: DocumentsStateModel): ILetter[] {
    return state.letters ? state.letters : [];
  }

  @Action(actions.LoadDocuments)
  loadDocuments(ctx: StateContext<DocumentsStateModel>): Observable<[S3File<never>[], ILetter[]]> {
    this.loadingService.present();
    return this.store.select(UserState?.user).pipe(
      take(1),
      switchMap(user => forkJoin([from(this.authService.getEmulation()), from(this.authService.getEmulationDecoded()), from(this.authService.getUser()), of(user)])),
      map(([emulation, emulationDecoded, authUser, user]) => EmployeeHelper.getEmployeeIdByGid(user?.gid ?? (emulation ? emulationDecoded.Gid : authUser.gid))),
      switchMap(employeeId => forkJoin([this.documentsService.getAllMyFiles(), this.letterCreationService.getLetterByEmployeeId(employeeId)])),
      tap(([docs, letters]) => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.documentItems = docs;
            state.letters = letters;
          })
        );
      }),
      finalize(() => this.loadingService.dismiss()),
      catchError(() => of(null))
    );
  }
}
