import { Pipe, PipeTransform } from '@angular/core';
import { LanguagesByCode } from '@coin/shared/util-models';

@Pipe({
  name: 'translateLanguage',
  standalone: true
})
export class TranslateLanguagePipe implements PipeTransform {
  static translate(code: string): string {
    const language = LanguagesByCode[code];
    return language?.name || code;
  }

  transform(code: string): string {
    return TranslateLanguagePipe.translate(code);
  }
}
