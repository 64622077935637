import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DefaultControlValueAccessor } from '@coin/shared/util-helpers';

@Component({
  selector: 'coin-v2-toggle',
  standalone: true,
  imports: [CommonModule, FormsModule, MatSlideToggleModule],
  templateUrl: './v2-toggle.component.html',
  styleUrls: ['./v2-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => V2ToggleComponent),
      multi: true
    }
  ]
})
export class V2ToggleComponent extends DefaultControlValueAccessor<boolean> {
  @Input({ required: true }) color: ToggleColor;
  @Input() label: string;
}

export const TOGGLE_COLORS = ['purple', 'petrol'] as const;
export type ToggleColor = (typeof TOGGLE_COLORS)[number];
