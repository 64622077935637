import { MeritBenchmarkVisibilityType } from '@coin/shared/util-enums';
import { CurrencyIsoCode } from '../constants/default-currencies.config';
import { EmployeeSnapshotWithCompensationAndBenchmarking } from './employee.model';

export class BenchmarkData {
  currencyExternal?: CurrencyIsoCode;
  lowValueExternal: number;
  midValueExternal: number;
  highValueExternal: number;
  benchmarkVisibility?: MeritBenchmarkVisibilityType;
  benchmarkSettingsType?: MeritBenchmarkVisibilityType;
  currencyInternal?: CurrencyIsoCode;
  lowValueInternal: number;
  midValueInternal: number;
  highValueInternal: number;
  spsEligibilityEmploymentPercentage?: number;

  constructor(employee: EmployeeSnapshotWithCompensationAndBenchmarking) {
    this.currencyExternal = employee?.currencyExternal;
    this.lowValueExternal = employee?.lowValueExternal;
    this.midValueExternal = employee?.midValueExternal;
    this.highValueExternal = employee?.highValueExternal;
    this.currencyInternal = employee?.currencyInternal;
    this.lowValueInternal = employee?.lowValueInternal;
    this.midValueInternal = employee?.midValueInternal;
    this.highValueInternal = employee?.highValueInternal;
    this.benchmarkVisibility = employee?.benchmarkVisibility || employee?.benchmarkSettingsType;
  }
}

// Only BE Model - needs to be mapped to Benchmark data
export interface BenchmarkDto {
  id: string;
  country: string;
  createdAt: string;
  createdById: string;
  type: MeritBenchmarkVisibilityType;
  mercerPositionClass: number;
  compensationElement: string;
  currency: string;
  highValue: number;
  midValue: number;
  lowValue: number;
}
