import { TreeNode } from '../route-builder';

const params = ['seasonId', 'employeeGid'] as const;

export const MeritSupportModule = {
  segment: 'merit-support',
  children: {
    MeritSupportComponent: {
      segment: '',
      children: { MeritSupportListComponent: { segment: 'adjustments', params } },
      params
    }
  }
} as const satisfies TreeNode;
