import { TreeNode } from '../route-builder';

const params = ['simulationId'] as const;

export const OrgPlanningSupportModule = {
  segment: 'org-planning-support',
  children: {
    OrgPlanningSupportComponent: {
      segment: '',
      params,
      children: {
        OrgPlanningSupportEmployeesComponent: { segment: 'employees', params },
        OrgPlanningSupportOrgStructureComponent: { segment: 'org-guidelines', params }
      }
    }
  }
} as const satisfies TreeNode;
