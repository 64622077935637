<h4>{{ 'general.info' | translate }}</h4>
<div class="wrapper">
  <p class="msg">{{ 'deputy.add-deputy-msg' | translate }}</p>
  <div class="timeframe">
    <mat-form-field class="admin-form-field-outline admin-form-field-outline--shadow" appearance="outline"
      floatLabel="always" (click)="pickerStart.open()">
      <input matInput [matDatepicker]="pickerStart" placeholder="Start (optional)" matInput [(ngModel)]="startsAt" />
      <mat-icon matSuffix>date_range</mat-icon>
      <mat-datepicker #pickerStart></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="admin-form-field-outline admin-form-field-outline--shadow" appearance="outline"
      floatLabel="always" (click)="pickerEnd.open()">
      <input matInput [matDatepicker]="pickerEnd" placeholder="End (optional)" matInput [(ngModel)]="endsAt" />
      <mat-icon matSuffix>date_range</mat-icon>
      <mat-datepicker #pickerEnd></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="buttons">
    <coin-action-button (click)="cancel()"> {{ 'general.btnCancel' | translate }}</coin-action-button>
    <coin-action-button class="primary" (click)="confirm()">
      {{ 'general.btnConfirm' | translate }}
    </coin-action-button>
  </div>
</div>