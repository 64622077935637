import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { map, startWith } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, shareReplay } from 'rxjs';
import { TemplateWithValueDirective } from '@coin/shared/util-directives';
import { V2DividerComponent } from '../divider/v2-divider.component';

type GroupedTemplates = { [group: string]: TemplateRef<unknown>[] };

@Component({
  selector: 'coin-v2-list-accordion',
  standalone: true,
  imports: [CommonModule, V2DividerComponent, MatIconModule],
  templateUrl: './v2-list-accordion.component.html',
  styleUrls: ['./v2-list-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2ListAccordionComponent implements AfterViewInit {
  @Input({ required: true }) groups: string[];
  @ContentChildren(TemplateWithValueDirective) templates: QueryList<TemplateWithValueDirective<string>>;

  /** Group that id displayed as "open" in the UI */
  public activeGroup$: Observable<string | null>;

  /** Last group that was clicked on by the user */
  public selectedGroup$ = new BehaviorSubject<string | null>(null);

  public templatesByGroup$: Observable<GroupedTemplates>;

  constructor(private cd: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.setUpView();
  }

  private setUpView(): void {
    this.templatesByGroup$ = this.templates.changes.pipe(
      startWith(null),
      map(() =>
        this.templates.reduce((result, currentTemplate) => {
          result[currentTemplate.value] ??= [];
          result[currentTemplate.value].push(currentTemplate.template);
          return result;
        }, {} as GroupedTemplates)
      )
    );
    const onlyGroupWithTemplates$ = this.templatesByGroup$.pipe(
      map(groupedTemplates => {
        const groups = Object.keys(groupedTemplates);
        if (groups.length !== 1) return null;
        return groups[0];
      })
    );
    this.activeGroup$ = combineLatest([this.selectedGroup$, onlyGroupWithTemplates$]).pipe(
      map(([selectedGroup, onlyGroupWithTemplates]) => onlyGroupWithTemplates || selectedGroup),
      shareReplay({ refCount: true, bufferSize: 0 })
    );
    this.cd.markForCheck();
  }

  public toggleSelectedGroup(group: string): void {
    this.selectedGroup$.next(this.selectedGroup$.value === group ? null : group);
  }
}
