import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@coin/shared/util-environments';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

interface EmulationToken {
  jwtToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmulationService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  public emulateEmployee(employeeId: string, authenticationType: 'Emulation' | 'Deputy'): Observable<EmulationToken> {
    return this.http
      .post<EmulationToken>(`${environment.api.baseUrl}/oauth/token`, {
        targetEmployeeId: employeeId,
        authenticationType
      })
      .pipe(
        tap(token => {
          this.authService.emulation = token.jwtToken;
        })
      );
  }
}
