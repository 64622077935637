// TODO: rename to more general name
export enum IncentiveRecordsWorkflowTemplateTypeEnum {
  EmployeeMail = 'EmployeeMail',
  ManagerMail = 'ManagerMail',
  EmployeeTask = 'EmployeeTask',
  ManagerTask = 'ManagerTask',
  BusinessPartnerMail = 'BusinessPartnerMail',
  ManagerPromotionMail = 'ManagerPromotionMail',
  ECLMail = 'ECLMail'
}
