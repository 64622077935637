import { animate, style, transition, trigger } from '@angular/animations';

export const flyInFromLeft = trigger('flyInFromLeft', [
  transition(':enter', [style({ transform: 'translateX(-100%)' }), animate('250ms ease-in', style({ transform: 'translateX(0%)' }))]),
  transition(':leave', [animate('150ms ease-out', style({ transform: 'translateX(-100%)' }))])
]);
export const flyInFromLeftWithHighlight = trigger('flyInFromLeft', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)', 'box-shadow': 'inset 0px 0px 20px -8px var(--purple700)' }),
    animate('250ms ease-in', style({ transform: 'translateX(0%)' })),
    animate('600ms cubic-bezier(1,0,1,0)', style({ 'box-shadow': 'none' }))
  ]),
  transition(':leave', [animate('150ms ease-out', style({ transform: 'translateX(-100%)' }))])
]);
