import { Directive, OnDestroy } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[intersectionObserverList]',
  standalone: true
})
export class IntersectionObserverListDirective implements OnDestroy {
  private mapping: Map<Element, (isIntersecting: boolean) => void>;
  private observer: IntersectionObserver;

  constructor() {
    // Maps the HTML Elements to their callbacks which get triggered when the intersection changes.
    this.mapping = new Map();

    this.observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        for (const entry of entries) {
          const callback = this.mapping.get(entry.target);

          callback && callback(entry.isIntersecting);
        }
      },
      {
        // Add some "margin" on top/bottom of the intersection,
        // in order to trigger before the element is actually visible to the user.
        rootMargin: '300px 0px 300px 0px'
      }
    );
  }

  // Adds the given Element for intersection observation. When the intersection status
  // changes, the given callback is invoked with the new status.
  public add(element: HTMLElement, callback: (isIntersecting: boolean) => void): void {
    this.mapping.set(element, callback);
    this.observer.observe(element);
  }

  public ngOnDestroy(): void {
    this.mapping.clear();
    this.observer.disconnect();
  }

  /// Removes the given Element from intersection observation.
  public remove(element: HTMLElement): void {
    this.mapping.delete(element);
    this.observer.unobserve(element);
  }
}
