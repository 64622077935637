import { HttpClient, HttpErrorResponse, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContentLanguage, FrontendType } from '@coin/shared/util-enums';
import { environment } from '@coin/shared/util-environments';
import { LanguageMapping, Translation, TranslationFile } from '@coin/shared/util-models';
import { merge } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';

const VERSION_ID_HEADER_KEY = 'x-amz-version-id';

@Injectable({
  providedIn: 'root'
})
export class CmsTranslationService {
  public activeTranslationFile$ = new BehaviorSubject<TranslationFile>(null);
  public allTranslationFiles$ = new BehaviorSubject<Translation[]>([]);
  public fileVersions: Record<string, string> = {};

  constructor(
    private toast: ToastrService,
    private http: HttpClient
  ) {}

  public invalidate(): Observable<unknown> {
    return this.http.post(`${environment.api.cmsServiceProd}invalidation`, null).pipe(
      retry(2),
      tap(() => this.toast.success('Invalidation started. Your changes should soon be visible.')),
      catchError(() => of(null))
    );
  }

  public getTranslationFile(fileName: 'languages', frontendType: FrontendType): Observable<LanguageMapping>;
  public getTranslationFile(fileName: ContentLanguage, frontendType: FrontendType): Observable<TranslationFile>;
  public getTranslationFile(fileName: 'languages' | ContentLanguage, frontendType: FrontendType): Observable<TranslationFile | LanguageMapping> {
    return this.http
      .get<{
        link: string;
      }>(`${environment.api.cmsServiceProd}translations/download-url/${frontendType}/${fileName}.json`)
      .pipe(
        switchMap(linkData => this.getTranslationFileWithLink(linkData.link)),
        tap(response => {
          const versionId = response.headers.get(VERSION_ID_HEADER_KEY);
          this.fileVersions[fileName] = versionId;
        }),
        map(response => response.body)
      );
  }

  // todo: needs clarification on return type
  public getTranslationFileWithLink(link: string): Observable<HttpResponse<TranslationFile>> {
    return this.http.get<TranslationFile>(link, { observe: 'response' });
  }

  public getTranslationConfigFile(frontendType: FrontendType.customer): Observable<LanguageMapping>;
  public getTranslationConfigFile(frontendType: FrontendType.cosmos): Observable<LanguageMapping>;
  public getTranslationConfigFile(frontendType: FrontendType.admin): Observable<ContentLanguage[]>;
  public getTranslationConfigFile(frontendType: FrontendType): Observable<unknown> {
    let url;

    switch (frontendType) {
      case FrontendType.admin:
        url = `${environment.cmsContentProdBucketCloudfront}translations/admin/`;
        break;
      case FrontendType.customer:
        url = `${environment.cmsContentProdBucketCloudfront}translations/customer/`;
        break;
      case FrontendType.cosmos:
        url = `${environment.cmsContentProdBucketCloudfront}translations/cosmos/`;
        break;
    }

    return this.http.get<LanguageMapping>(`${url}languages.json`);
  }

  public uploadTranslationFile(language: ContentLanguage | 'languages', frontendType: FrontendType, content: TranslationFile): Observable<unknown> {
    const versionId = this.fileVersions[language];
    return this.http
      .get<{
        link: string;
      }>(`${environment.api.cmsServiceProd}translations/upload-url/${frontendType}/${language}.json?versionId=${versionId}`, {
        observe: 'response'
      })
      .pipe(
        switchMap(response => this.uploadTranslationFileWithLink(response.body.link, content).pipe(tap(() => this.toast.success('Success updating translation file')))),
        // Fetch the translation file one more time to get the new file version
        switchMap(() => this.getTranslationFile(language as ContentLanguage, frontendType)),
        catchError((errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === HttpStatusCode.Conflict) {
            this.toast.info('Remote version is newer. Merging.');
            return this.mergeRemoteVersionThenUpload(language, frontendType, content);
          }
          throw errorResponse as Error;
        })
      );
  }

  private mergeRemoteVersionThenUpload(language: ContentLanguage | 'languages', frontendType: FrontendType, localFile: TranslationFile): Observable<unknown> {
    return this.getTranslationFile(language as ContentLanguage, frontendType).pipe(
      switchMap(remoteFile => {
        const mergedFile = merge(localFile, remoteFile);
        this.activeTranslationFile$.next(mergedFile);
        return this.uploadTranslationFile(language, frontendType, mergedFile);
      })
    );
  }

  public uploadTranslationFileWithLink(link: string, content: TranslationFile): Observable<unknown> {
    return this.http.put(link, JSON.stringify(content), { responseType: 'text' });
  }
}
