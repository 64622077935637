<div class="segment left">
  <mat-icon *ngIf="icon || svgIcon" class="icon" [svgIcon]="svgIcon">{{ icon }}</mat-icon>
  <ng-container *ngIf="contentLeft">
    <p *ngIf="!isTemplate(contentLeft); else template">{{ contentLeft }}</p>
    <ng-template #template>
      <ng-container *ngTemplateOutlet="contentLeft"></ng-container>
    </ng-template>
  </ng-container>
</div>
<div *ngIf="textRight || textRight === 0" class="segment right">
  <div class="text">
    <p *ngIf="!isTemplate(textRight); else templateRight" coinEllipsisTooltip matTooltip>{{ textRight }}</p>
    <ng-template #templateRight>
      <ng-container *ngTemplateOutlet="textRight"></ng-container>
    </ng-template>
  </div>
</div>
