<div class="faq-border faq-inbetween-main">
  <ng-container *ngFor="let item of items?.content">
    <button
      class="no-style faq-list-item arrow--right faq-padding"
      (click)="openFaqItem(item, 2, item?.moreWidth, false, item?.title)"
      *ngIf="authRequired(item) && !item.showContentInParent">
      <p [ngStyle]="{ 'padding-left': item.icon && item.addIcon ? '30px' : '0px' }" style="position: relative; transition: all 300ms ease-in-out">
        <span class="material-icons" *ngIf="item.icon && item.addIcon" style="position: absolute; top: 50%; left: 0px; transform: translate(0px, -50%)">{{ item.icon }}</span>
        <span
          class="p"
          style="font-family: Siemens Sans black; display: inline-block"
          [matBadge]="item?.isAnswer && item?.newFlagCount > 0 ? '!' : item?.newFlagCount"
          [matBadgeHidden]="item?.newFlagCount === 0"
          matBadgeOverlap="false"
          >{{ item?.title }}</span
        >
      </p>
      <mat-icon class="chevron">chevron_right</mat-icon>
      <div class="faq-topic-border"></div>
    </button>
    <coin-siemens-energy-faq-answer-level *ngIf="authRequired(item) && item.showContentInParent" [items]="item"></coin-siemens-energy-faq-answer-level>
  </ng-container>
</div>
