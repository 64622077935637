<ng-container *ngIf="templatesByGroup$ | async as templatesByGroup">
  <div *ngFor="let group of groups" class="group">
    <div class="title" [class.disabled]="!templatesByGroup[group]?.length" (click)="toggleSelectedGroup(group)">
      <span>{{ group }}</span>
      <coin-v2-divider orientation="horizontal"></coin-v2-divider>
      <mat-icon>{{ (activeGroup$ | async) === group ? 'expand_more' : 'chevron_right' }}</mat-icon>
    </div>
    <div *ngIf="(activeGroup$ | async) === group" class="items">
      <ng-container *ngFor="let templates of templatesByGroup[group]">
        <ng-container *ngTemplateOutlet="templates"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
