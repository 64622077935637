import { SeasonTypeEnum } from '@coin/shared/util-enums';
import { Topic } from '@coin/shared/util-models';
import { MeritBudgetResponsibilityType } from '../../merit-shared/enums/merit-budget-responsibility-type.enum';
import { EmployeeSelector } from '../../merit-shared/models/employee-selector.enum';
import { MeritAllocationSeason } from '../../merit-shared/models/merit-allocation-season.model';
import { EmployeeWithCompensationInfo } from '../../merit-shared/models/merit-budget-direct-with-compensation.model';

export class LoadDirects {
  static readonly type = '[Allocation] Load Directs';
}
export class LoadDirect {
  static readonly type = '[Allocation] Load Direct';
  constructor(public readonly employeeId: string) {}
}
export class LoadSeasons {
  static readonly type = '[Allocation] Load Seasons';
}

export class LoadAllocationSettings {
  static readonly type = '[Allocation] Load Allocation Settings';
  constructor(public readonly payload: string) {}
}
export class LoadSeasonSettings {
  static readonly type = '[Allocation] Load Season Settings';
  constructor(public readonly payload: { id: string; type: SeasonTypeEnum }) {}
}

export class SetAllocationTask {
  static readonly type = '[Allocation] Set Allocation Task';
  constructor(public readonly payload: Topic) {}
}

export class SetAdminRights {
  static readonly type = '[Allocation] Set Admin Rights';
}

export class SetResponsibilityType {
  static readonly type = '[Allocation] Set Responsibility Type';
  constructor(public readonly payload: MeritBudgetResponsibilityType) {}
}

export class SetSeason {
  static readonly type = '[Allocation] Set Season';
  constructor(public readonly payload: MeritAllocationSeason) {}
}

export class LoadMeritDirectBudgetOverview {
  static readonly type = '[Allocation] Load Merit Direct Budget Overview';
}

export class SetTotalBudgetOverview {
  static readonly type = '[Allocation] Set Total Budget Overview';
}

export class ResetSeason {
  static readonly type = '[Allocation] Reset Season ';
}

export class SetSearchedDirect {
  static readonly type = '[Allocation] Set searched direct ';
  constructor(public readonly payload: { employee: EmployeeWithCompensationInfo }) {}
}

export class ResetMeritBudgetAllocationState {
  static readonly type = '[Allocation] Reset Merit Budget Allocation State';
}

export class UpdateDirect {
  static readonly type = '[Allocation] Update Direct';
  constructor(public readonly payload: EmployeeWithCompensationInfo) {}
}

export class LoadMeritTaskByEmployeeOrgCode {
  static readonly type = '[Allocation] Load Merit Task by Employee';
  constructor(
    public readonly employeeId: string,
    public readonly orgCode: string
  ) {}
}

export class LoadIncentiveTaskByEmployeeOrgCode {
  static readonly type = '[Allocation] Load Incentive Task by Employee';
  constructor(
    public readonly employeeId: string,
    public readonly orgCode: string
  ) {}
}

export class ChangeMeritTaskStatus {
  static readonly type = '[Allocation] Change Merit Task Status';
  constructor(public readonly payload: { seasonId: string; employee: EmployeeWithCompensationInfo }) {}
}

export class UpdateDirectCompensationComponents {
  static readonly type = '[Allocation] Update Direct Compensation Components';
  constructor(public readonly payload: EmployeeWithCompensationInfo) {}
}

export class ToggleOnlyOpenItemsVisible {
  static readonly type = '[Allocation] Toggle Show Only Open Items';
}

export class ToggleMultiSelect {
  static readonly type = '[Allocation] Toggle Multi Select';
}

export class ToggleMultiSelectForEmployee {
  static readonly type = '[Allocation] Toggle Multi Select For Employee';
  constructor(public readonly payload: EmployeeWithCompensationInfo) {}
}

export class SetSelectedDirectsToBudget {
  static readonly type = '[Allocation] Set Selected Directs To Budget';
}

export class FinalizeSelectedDirects {
  static readonly type = '[Allocation] Finalize Selected Directs';
}

export class UndoSelectedDirects {
  static readonly type = '[Allocation] Undo Selected Directs';
}

export class SetEmployeeSelector {
  static readonly type = '[Allocation] Set Employee Selector';
  constructor(public readonly payload: EmployeeSelector) {}
}

export class PromoteAllocation {
  static readonly type = '[Allocation] Promote';
  constructor(public readonly payload: { employeeId: string; comment: string }) {}
}
