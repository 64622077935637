import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fv-left',
  templateUrl: './fv-left.component.html',
  styleUrls: ['./fv-left.component.scss']
})
export class FvLeftComponent {
  @Input() image: string;
  @Input() text: { headline: string; text: string };
  @Input() needsColumn = false;
  @Input() maxHeight = 'calc(100vh - 210px)';
  @Input() maxWidth = '1000px';
  @Input() imageMaxWidth = '350px';
}
