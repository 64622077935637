import { Pipe, PipeTransform } from '@angular/core';
import { TinyHelpers } from '@coin/shared/util-helpers';

@Pipe({
  name: 'enumToText',
  standalone: true
})
export class EnumToTextPipe implements PipeTransform {
  transform(value: string): string {
    return TinyHelpers.pascalcaseToText(value);
  }
}
