import { ListViewTagFilterParameter } from '@coin/shared/util-models';
import { ActivityStreamEntityType, ActivityStreamLoadConfig } from '@coin/modules/activity-stream/util';

export class SetActivityStreamEntityType {
  static readonly type = '[Activity Stream] Set Activity Stream Event Type';
  constructor(public readonly payload: ActivityStreamEntityType) {}
}

export class SetActivityStreamLoadConfig {
  static readonly type = '[Activity Stream] Set Activity Stream Load Config';
  constructor(public readonly payload: ActivityStreamLoadConfig) {}
}

export class LoadActivityStreamItems {
  static readonly type = '[Activity Stream] Load Activity Stream Items';
}

export class ResetActivityStreamState {
  static readonly type = '[Activity Stream] Reset Activity Stream State';
}

export class AddActivityStreamFilterParameter {
  static readonly type = '[Activity Stream] Add Filter Parameter';
  constructor(public readonly payload: ListViewTagFilterParameter) {}
}

export class RemoveActivityStreamFilterParameter {
  static readonly type = '[Mercer Tree] Remove Activity Stream Filter Parameter';
  constructor(public readonly payload: ListViewTagFilterParameter) {}
}

export class ClearActivityStreamFilterParameterKey {
  static readonly type = '[Activity Stream] Clear Filter Parameter Key';
  constructor(public readonly category: string) {}
}
