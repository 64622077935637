import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateCountryPipe } from '@coin/shared/util-pipes';

@Component({
  selector: 'coin-v2-country-flag',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, TranslateCountryPipe],
  templateUrl: './v2-country-flag.component.html',
  styleUrls: ['./v2-country-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2CountryFlagComponent {
  @Input({ required: true }) size: FlagSize;
  @Input({ required: true, transform: (s: string) => s.toLowerCase() }) country: string;
  @Input() showTooltip = false;
  @Input() showLabel = false;

  @HostBinding('class')
  private get classes(): string {
    return `size-${this.size}`;
  }

  protected get flagUrl(): string {
    return `/assets/svgs/flags/${this.country}.svg`;
  }
}

export const FLAG_SIZES = ['s', 'm', 'l'] as const;
export type FlagSize = (typeof FLAG_SIZES)[number];
