import { FilterOptions, FilterSetting, SortingSetting } from '@coin/shared/util-models';

export class ResetSortOption {
  static readonly type = '[FilterSort] Reset Sort Option';
}

export class ResetFilterOption {
  static readonly type = '[FilterSort] Reset Filter Option';
}

export class ResetSortAndFilterOption {
  static readonly type = '[FilterSort] Reset Sort And Filter Option';
}
export class RemoveFilterOption {
  static readonly type = '[FilterSort] Remove Filter Option';
  constructor(public readonly payload: { filterOption: FilterSetting }) {}
}

export class RemoveFilterOptionByKey {
  static readonly type = '[FilterSort] Remove Filter Option By Key';
  constructor(public readonly payload: { filterOptionKey: FilterOptions }) {}
}

export class SetFilterOptions {
  static readonly type = '[FilterSort] Set Filter Options';
  constructor(public readonly payload: { filterOptions: FilterSetting[] }) {}
}

export class SetFilterOptionsFromText {
  static readonly type = '[FilterSort] Set Filter Options From Text';
  constructor(public readonly payload: string) {}
}

export class SetSortOption {
  static readonly type = '[FilterSort] Set Sort Option';
  constructor(public readonly payload: { sortOption: SortingSetting }) {}
}

export class SetReloadNeeded {
  static readonly type = '[FilterSort] Set Reload Needed';
  constructor(public readonly payload: { isReloadNeeded: boolean }) {}
}

export class SetFilterValues {
  static readonly type = '[FilterSort] Set Filter Values';
  constructor(public readonly payload: { values: string[]; index: number; allItemsSelected?: boolean }) {}
}

/** Invalidates and reloads filter options
 *  @param keys if not defined, invalidates all options
 */
export class InvalidateFilterOptions {
  static readonly type = '[FilterSort] Invalidate Filter Options';
  constructor(public readonly keys?: string[]) {}
}
