import { Injectable, Type } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { LetterComment } from '@coin/shared/util-models';
import { environment } from '@coin/shared/util-environments';
import { HttpHelpersService } from './http-helpers';

@Injectable({
  providedIn: 'root'
})
export class MeritBudgetCommentService {
  constructor(
    private httpClient: HttpClient,
    private toast: ToastrService,
    private dialog: MatDialog,
    private httpHelper: HttpHelpersService
  ) {}

  getComments(seasonId: string, employeeId: string) {
    return this.httpClient
      .get<LetterComment[]>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/values/${employeeId}/comments`)
      .pipe(this.httpHelper.handleError('Error getting comments'));
  }

  // employeeId is really employeeId and not allocation member id
  postComment(seasonId: string, employeeId: string, message: string, reason?: string) {
    return this.httpClient
      .post(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/values/${employeeId}/comments`, {
        value: message,
        reason
      })
      .pipe(this.httpHelper.handleError('Error posting comment'));
  }

  askForPromotionComment(confirmationDialogComponent: Type<unknown>, msg = '') {
    return this.dialog
      .open(confirmationDialogComponent, {
        disableClose: true,
        data: {
          headline: 'merit-budget.promotion.comment-confirm',
          msg,
          provideInput: true,
          inputMandatory: true,
          cancelMsg: 'general.btnCancel',
          confirmMsg: 'general.btnConfirm',
          translate: true
        }
      })
      .afterClosed();
  }
}
