import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@coin/shared/feature-legacy-components';
import { DisplayedCurrency, RecordState, SpecialPaymentProposalState } from '@coin/shared/util-enums';
import { ConfirmationDialogData, ListViewTagFilterParameter, PaginatedResult } from '@coin/shared/util-models';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { produce } from 'immer';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { LoadingService } from '@coin/shared/data-access';
import { Season, SeasonSetting, SpecialPaymentSetting } from '@coin/admin/season-mgmt/util';
import { SeasonSettingsService } from '@coin/admin/season-mgmt/data-access';
import { SpecialPaymentProposal } from '../models/special-payment-proposal.model';
import { FilterSortState } from '../../shared/components/filter-sort-bar/store/filter-sort.state';
import { MeritSupportSortingParameter } from '../../merit/merit-support/enums/merit-support-sorting-parameter.enum';
import { SpecialPaymentApprovalType } from '../enums/special-payment-approval-type.enum';
import { SpecialPaymentApproval } from '../models/special-payment-approval.model';
import { SpecialPaymentDashboard } from '../models/special-payment-dashboard.model';
import { SpecialPaymentService } from '../services/special-payment.service';
import {
  AddSpecialPaymentFilterParameter,
  ApproveOrRejectSpecialPaymentProposal,
  ClearSpecialPaymentFilterParameterKey,
  CreateSpecialPaymentProposalAction,
  DeleteSpecialPaymentRecord,
  GetSpecialPaymentDashboard,
  LazyLoadAllSpecialPaymentProposals,
  LazyLoadInvolvedSpecialPaymentProposals,
  LoadAllSpecialPaymentProposals,
  LoadInvolvedSpecialPaymentProposals,
  LoadSpecialPaymentProposalForEmployee,
  LoadSpecialPaymentSeasons,
  LoadTaskSpecialPaymentProposals,
  ResetSpecialPaymentFilterParameterKey,
  ResetSpecialPaymentProposalState,
  SetSeasonForEmployeeInvalid,
  SetSelectedSeasonSettings,
  SetSpecialPaymentCurrency,
  SetSpecialPaymentProposalsBySearch,
  SetUpdatedSpecialPaymentApproval,
  UpdateSpecialPaymentProposalAction
} from './special-payment.actions';

const STATE_DEFAULTS: SpecialPaymentModel = {
  filterParameter: [],
  involvedListCurrentPage: 0,
  involvedListMaxPage: 0,
  allListCurrentPage: 0,
  allListMaxPage: 0,
  sortingParameter: MeritSupportSortingParameter.Default,
  taskProposals: [],
  myInvolvementProposals: [],
  allProposals: [],
  selectedCurrency: DisplayedCurrency.Local,
  dashboard: undefined,
  seasons: [],
  seasonsSettings: [],
  selectedSeasonSettings: undefined,
  isSeasonInvalidForEmployee: false
};

const DEFAULT_PAGE_SIZE = 15;

interface SpecialPaymentModel {
  filterParameter: ListViewTagFilterParameter[];
  involvedListCurrentPage: number;
  involvedListMaxPage: number;
  allListCurrentPage: number;
  allListMaxPage: number;
  sortingParameter: string;
  taskProposals: SpecialPaymentProposal[];
  myInvolvementProposals: SpecialPaymentProposal[];
  allProposals: SpecialPaymentProposal[];
  selectedCurrency: DisplayedCurrency;
  dashboard: SpecialPaymentDashboard;
  isSeasonInvalidForEmployee: boolean;
  seasons: Season[];
  seasonsSettings: SpecialPaymentSetting[];
  selectedSeasonSettings: SpecialPaymentSetting;
}

@State<SpecialPaymentModel>({
  name: 'SpecialPaymentState',
  defaults: STATE_DEFAULTS
})
@Injectable()
export class SpecialPaymentState {
  constructor(
    private store: Store,
    private specialPaymentService: SpecialPaymentService,
    private seasonSettingsService: SeasonSettingsService,
    private loading: LoadingService,
    private dialog: MatDialog
  ) {}

  @Selector()
  static selectedCurrency(state: SpecialPaymentModel): DisplayedCurrency {
    return state.selectedCurrency;
  }

  @Selector()
  static seasons(state: SpecialPaymentModel): Season[] {
    return state.seasons;
  }

  @Selector()
  static seasonsSettings(state: SpecialPaymentModel): SpecialPaymentSetting[] {
    return state.seasonsSettings;
  }

  @Selector()
  static selectedSeasonSettings(state: SpecialPaymentModel): SpecialPaymentSetting {
    return state.selectedSeasonSettings;
  }

  @Selector()
  static hasSelectedSeasonSettings(state: SpecialPaymentModel): boolean {
    return !!state.selectedSeasonSettings;
  }

  @Selector()
  static seasonsWithSettings(state: SpecialPaymentModel): { seasons: Season[]; seasonsSettings: SeasonSetting[] } {
    return { seasons: state.seasons, seasonsSettings: state.seasonsSettings };
  }

  @Selector()
  static allProposals(state: SpecialPaymentModel): SpecialPaymentProposal[] {
    return state.allProposals;
  }

  @Selector()
  static allProposalsCount(state: SpecialPaymentModel): number {
    return state.allProposals.length;
  }

  @Selector()
  static hasAllProposals(state: SpecialPaymentModel): boolean {
    return !!state.allProposals.length;
  }

  @Selector()
  static myInvolvementProposals(state: SpecialPaymentModel): SpecialPaymentProposal[] {
    return state.myInvolvementProposals;
  }

  @Selector()
  static hasInvolvementProposals(state: SpecialPaymentModel): boolean {
    return !!state.myInvolvementProposals.length;
  }

  @Selector()
  static taskProposals(state: SpecialPaymentModel): SpecialPaymentProposal[] {
    return state.taskProposals;
  }

  @Selector()
  static hasTaskProposals(state: SpecialPaymentModel): boolean {
    return !!state.taskProposals.length;
  }

  @Selector()
  static myInvolvementProposalCount(state: SpecialPaymentModel): number {
    return state.dashboard?.userIsInvolvedCount;
  }

  @Selector()
  static taskProposalCount(state: SpecialPaymentModel): number {
    return state.dashboard?.userActionNeededCount;
  }

  @Selector()
  static filterParameter(state: SpecialPaymentModel): ListViewTagFilterParameter[] {
    return state.filterParameter;
  }

  @Selector()
  static dashboard(state: SpecialPaymentModel): SpecialPaymentDashboard {
    return state.dashboard;
  }

  @Selector()
  static isSeasonInvalidForEmployee(state: SpecialPaymentModel): boolean {
    return state.isSeasonInvalidForEmployee;
  }

  static proposal(id: string): (state: SpecialPaymentModel) => SpecialPaymentProposal {
    return createSelector([SpecialPaymentState], (state: SpecialPaymentModel) => {
      return state.allProposals.find(proposal => proposal.id === id);
    });
  }

  @Action(SetSpecialPaymentCurrency)
  setSpecialPaymentCurrency(ctx: StateContext<SpecialPaymentModel>, { payload }: SetSpecialPaymentCurrency): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.selectedCurrency = payload;
      })
    );
  }

  private getSortingParameterText(ctx: StateContext<SpecialPaymentModel>): string {
    return ctx.getState().sortingParameter !== MeritSupportSortingParameter.Default ? `&Sorting.Property=${ctx.getState().sortingParameter}` : '';
  }

  private static getFilterParameterText(filterParameter: ListViewTagFilterParameter[], showQueryPrefix = true): string {
    let filterText = '';

    filterParameter.forEach(param => {
      filterText += `&${showQueryPrefix ? 'Query.' : ''}${encodeURIComponent(param.category)}=${encodeURIComponent(param.value)}`;
    });

    return filterText ?? '';
  }

  @Action(LoadInvolvedSpecialPaymentProposals)
  loadInvolvedSpecialPaymentProposals(ctx: StateContext<SpecialPaymentModel>): Observable<PaginatedResult<SpecialPaymentProposal>> {
    this.loading.present();

    let filterText = this.store.selectSnapshot(FilterSortState.getSortAndFilterText);
    filterText += SpecialPaymentState.getFilterParameterText(ctx.getState().filterParameter) + this.getSortingParameterText(ctx);

    return this.specialPaymentService.getSpecialPaymentTaskProposals(0, DEFAULT_PAGE_SIZE, undefined, SpecialPaymentProposalState.Pending, filterText).pipe(
      tap(myInvolvementProposals => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.myInvolvementProposals = myInvolvementProposals.content;
            state.involvedListCurrentPage = 0;
            state.involvedListMaxPage = myInvolvementProposals.pageCount - 1;
          })
        );
      }),
      finalize(() => this.loading.dismiss())
    );
  }

  @Action(LoadAllSpecialPaymentProposals)
  loadAllSpecialPaymentProposals(ctx: StateContext<SpecialPaymentModel>): Observable<PaginatedResult<SpecialPaymentProposal>> {
    this.loading.present();

    let filterText = this.store.selectSnapshot(FilterSortState.getSortAndFilterText);
    filterText += SpecialPaymentState.getFilterParameterText(ctx.getState().filterParameter) + this.getSortingParameterText(ctx);

    return this.specialPaymentService.getSpecialPaymentTaskProposals(0, DEFAULT_PAGE_SIZE, undefined, undefined, filterText, true).pipe(
      tap(allProposals => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.allProposals = allProposals.content;
            state.allListCurrentPage = 0;
            state.allListMaxPage = allProposals.pageCount - 1;
          })
        );
      }),
      finalize(() => this.loading.dismiss())
    );
  }

  @Action(LoadSpecialPaymentProposalForEmployee)
  loadSpecialPaymentProposalForEmployee(
    ctx: StateContext<SpecialPaymentModel>,
    { payload }: LoadSpecialPaymentProposalForEmployee
  ): Observable<PaginatedResult<SpecialPaymentProposal>> {
    this.loading.present();

    return this.specialPaymentService.getSpecialPaymentTaskProposals(0, DEFAULT_PAGE_SIZE, undefined, undefined, `&Query.EmployeeId=${payload.employeeId}`, true).pipe(
      tap(result => {
        ctx.setState(
          produce(ctx.getState(), state => {
            const proposals = [...state.allProposals, ...result.content];
            state.allProposals = proposals?.filter((proposal, index) => proposals.findIndex(e => e.id === proposal.id) === index); // delete all duplicates
          })
        );
      }),
      finalize(() => this.loading.dismiss())
    );
  }

  @Action(LazyLoadAllSpecialPaymentProposals)
  lazyLoadAllSpecialPaymentProposals(ctx: StateContext<SpecialPaymentModel>): Observable<PaginatedResult<SpecialPaymentProposal>> {
    const currentPage = ctx.getState().allListCurrentPage;
    const maxPage = ctx.getState().allListMaxPage;

    if (currentPage >= maxPage) {
      return;
    }

    let filterText = this.store.selectSnapshot(FilterSortState.getSortAndFilterText);
    filterText += SpecialPaymentState.getFilterParameterText(ctx.getState().filterParameter) + this.getSortingParameterText(ctx);

    return this.specialPaymentService.getSpecialPaymentTaskProposals(currentPage + 1, DEFAULT_PAGE_SIZE, undefined, undefined, filterText, true).pipe(
      tap(result => {
        ctx.setState(
          produce(ctx.getState(), state => {
            const proposals = [...state.allProposals, ...result.content];
            state.allProposals = proposals?.filter((proposal, index) => proposals.findIndex(e => e.id === proposal.id) === index); // delete all duplicates
            state.allListCurrentPage += 1;
          })
        );
      })
    );
  }

  @Action(LoadTaskSpecialPaymentProposals)
  loadTaskSpecialPaymentProposals(ctx: StateContext<SpecialPaymentModel>): Observable<PaginatedResult<SpecialPaymentProposal>> {
    this.loading.present();

    return this.specialPaymentService.getSpecialPaymentTaskProposals(0, 1_000, true, undefined, '&Query.isDraft=true').pipe(
      tap(taskProposals => ctx.patchState({ taskProposals: taskProposals.content })),
      finalize(() => this.loading.dismiss())
    );
  }

  @Action(LazyLoadInvolvedSpecialPaymentProposals)
  lazyLoadInvolvedSpecialPaymentProposals(ctx: StateContext<SpecialPaymentModel>): Observable<PaginatedResult<SpecialPaymentProposal>> {
    const currentPage = ctx.getState().involvedListCurrentPage;
    const maxPage = ctx.getState().involvedListMaxPage;

    if (currentPage >= maxPage) {
      return;
    }

    let filterText = this.store.selectSnapshot(FilterSortState.getSortAndFilterText);
    filterText += SpecialPaymentState.getFilterParameterText(ctx.getState().filterParameter) + this.getSortingParameterText(ctx);

    return this.specialPaymentService.getSpecialPaymentTaskProposals(currentPage + 1, DEFAULT_PAGE_SIZE, undefined, SpecialPaymentProposalState.Pending, filterText).pipe(
      tap(result => {
        ctx.setState(
          produce(ctx.getState(), state => {
            const proposals = [...state.myInvolvementProposals, ...result.content];
            state.myInvolvementProposals = proposals?.filter((proposal, index) => proposals.findIndex(e => e.id === proposal.id) === index); // delete all duplicates
            state.involvedListCurrentPage += 1;
          })
        );
      })
    );
  }

  @Action(ResetSpecialPaymentProposalState)
  resetProposalState(ctx: StateContext<SpecialPaymentModel>, { task }: ResetSpecialPaymentProposalState): Observable<void | SpecialPaymentProposal> {
    return this.specialPaymentService.resetProposalRequest(task.id, 1).pipe(
      tap(proposal => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.allProposals = this.replaceProposal(ctx.getState().allProposals || [], { ...task, ...proposal });
            state.myInvolvementProposals = this.replaceProposal(ctx.getState().myInvolvementProposals || [], { ...task, ...proposal });
            state.taskProposals = ctx.getState().taskProposals?.filter(proposal => proposal.id !== task.id);
          })
        );
      })
    );
  }

  @Action(ApproveOrRejectSpecialPaymentProposal)
  approveOrRejectProposal(ctx: StateContext<SpecialPaymentModel>, { task, proposalState }: ApproveOrRejectSpecialPaymentProposal): Observable<void | SpecialPaymentProposal> {
    return this.dialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData>(ConfirmationDialogComponent, {
        data: {
          translate: true,
          headline: 'special-payment.update-status-confirmation-headline',
          msg: 'special-payment.update-status-confirmation-msg',
          msgInterpolationParams: { proposalState },
          confirmMsg: 'general.confirm',
          cancelMsg: 'general.cancel'
        }
      })
      .afterClosed()
      .pipe(
        filter(result => !!result),
        switchMap(() => this.specialPaymentService.approveOrReject(task, proposalState)),
        tap(proposal => {
          ctx.setState(
            produce(ctx.getState(), state => {
              // remove my involvement proposal if HoH approves or rejects
              const removeMyInvolvementProposal =
                task.approvalChain
                  ?.filter(chain => [SpecialPaymentApprovalType.HRBusinessPartner, SpecialPaymentApprovalType.LineManager].includes(chain.type))
                  ?.every(chain => [SpecialPaymentProposalState.Approved, SpecialPaymentProposalState.AutoApproved].includes(chain.currentState)) &&
                task.approvalChain?.find(chain => chain.type === SpecialPaymentApprovalType.HeadOfHierarchy)?.currentState === SpecialPaymentProposalState.Pending;

              state.taskProposals = ctx.getState().taskProposals?.filter(proposal => proposal.id !== task.id);
              state.myInvolvementProposals = removeMyInvolvementProposal
                ? ctx.getState().myInvolvementProposals?.filter(proposal => proposal.id !== task.id)
                : this.replaceProposal(ctx.getState().myInvolvementProposals || [], { ...task, ...proposal });
              state.allProposals = this.replaceProposal(ctx.getState().allProposals || [], { ...task, ...proposal });
            })
          );
        })
      );
  }

  private replaceProposal(proposals: SpecialPaymentProposal[], replacement: SpecialPaymentProposal): SpecialPaymentProposal[] {
    const index = proposals.findIndex(proposal => proposal.id === replacement.id);

    if (index !== -1) {
      // Copy array before inserting, because the original array is readonly
      const proposalsCopy = [...proposals];
      proposalsCopy[index] = replacement;
      return proposalsCopy;
    }

    return proposals;
  }

  private updateApproval(updatedApproval: SpecialPaymentApproval, proposals: SpecialPaymentProposal[]): SpecialPaymentProposal[] {
    return proposals.map(proposal => ({
      ...proposal,
      approvalChain: proposal.approvalChain.map(approval => (approval.id === updatedApproval.id ? updatedApproval : approval))
    }));
  }

  @Action(CreateSpecialPaymentProposalAction)
  createProposal(ctx: StateContext<SpecialPaymentModel>, { proposal }: CreateSpecialPaymentProposalAction): Observable<SpecialPaymentProposal> {
    return this.specialPaymentService.createProposal(proposal).pipe(
      tap(createdProposal =>
        ctx.setState(
          produce(ctx.getState(), state => {
            if (proposal?.state === RecordState.Draft) {
              state.taskProposals = [...state.taskProposals, createdProposal];
            } else {
              state.allProposals = [...state.allProposals, createdProposal];
              state.myInvolvementProposals = [...state.myInvolvementProposals, createdProposal];
            }
          })
        )
      )
    );
  }

  @Action(UpdateSpecialPaymentProposalAction)
  updateProposal(ctx: StateContext<SpecialPaymentModel>, { proposal }: UpdateSpecialPaymentProposalAction): Observable<SpecialPaymentProposal> {
    return this.specialPaymentService.updateProposal(proposal).pipe(
      tap(updatedProposal => {
        ctx.patchState({
          allProposals: this.replaceProposal(ctx.getState().allProposals || [], updatedProposal),
          myInvolvementProposals: this.replaceProposal(ctx.getState().myInvolvementProposals || [], updatedProposal),
          taskProposals: this.replaceProposal(ctx.getState().taskProposals || [], updatedProposal)
        });
      })
    );
  }

  @Action(GetSpecialPaymentDashboard)
  getDashboard(ctx: StateContext<SpecialPaymentModel>): Observable<SpecialPaymentDashboard> {
    return this.specialPaymentService.getDashboard().pipe(
      tap(dashboard => {
        ctx.patchState({ dashboard });
      })
    );
  }

  @Action(SetSpecialPaymentProposalsBySearch)
  setProposalsBySearch(ctx: StateContext<SpecialPaymentModel>, { employeeId }: SetSpecialPaymentProposalsBySearch) {
    const getProposals = (allProposals: boolean) =>
      this.specialPaymentService
        .getSpecialPaymentTaskProposals(0, 1_000, undefined, undefined, `&Query.EmployeeId=${employeeId}`, allProposals)
        .pipe(map(result => result.content));

    return forkJoin([getProposals(false), getProposals(true)]).pipe(
      tap(([newInvolvedProposals, newAllProposals]) => {
        ctx.setState(
          produce(ctx.getState(), state => {
            const filteredInvolvedProposals = state.myInvolvementProposals.filter(proposal => !newInvolvedProposals.some(newProposal => newProposal.id === proposal.id));
            const allFilteredProposals = state.allProposals.filter(proposal => !newAllProposals.some(newProposal => newProposal.id === proposal.id));
            state.myInvolvementProposals = [...newInvolvedProposals, ...filteredInvolvedProposals];
            state.allProposals = [...newAllProposals, ...allFilteredProposals];
          })
        );
      })
    );
  }

  @Action(AddSpecialPaymentFilterParameter)
  addSpecialPaymentFilterParameter(ctx: StateContext<SpecialPaymentModel>, { payload }: AddSpecialPaymentFilterParameter): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.filterParameter.push(payload);
      })
    );
  }

  @Action(ClearSpecialPaymentFilterParameterKey)
  clearSpecialPaymentFilterParameterKey(ctx: StateContext<SpecialPaymentModel>, { category }: ClearSpecialPaymentFilterParameterKey): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.filterParameter = state.filterParameter.filter(param => param.category !== category);
      })
    );
  }

  @Action(ResetSpecialPaymentFilterParameterKey)
  resetSpecialPaymentFilterParameterKey(ctx: StateContext<SpecialPaymentModel>): void {
    ctx.patchState({ filterParameter: [] });
  }

  @Action(SetUpdatedSpecialPaymentApproval)
  setUpdatedSpecialPaymentApproval(ctx: StateContext<SpecialPaymentModel>, { payload }: SetUpdatedSpecialPaymentApproval): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.allProposals = this.updateApproval(payload, state.allProposals);
        state.taskProposals = this.updateApproval(payload, state.taskProposals);
        state.myInvolvementProposals = this.updateApproval(payload, state.myInvolvementProposals);
      })
    );
  }

  @Action(LoadSpecialPaymentSeasons)
  loadSpecialPaymentSeasons(ctx: StateContext<SpecialPaymentModel>, { payload }: LoadSpecialPaymentSeasons): Observable<unknown> {
    this.loading.present();
    return this.specialPaymentService.getSeasons(payload.compensationType, payload.employeeId).pipe(
      mergeMap(seasons => forkJoin([of(seasons), ...seasons.map(season => this.seasonSettingsService.getSeasonSettings(season.id, season.seasonType))])),
      map(([seasons, ...seasonsSettings]) => ctx.patchState({ seasons: seasons as Season[], seasonsSettings: seasonsSettings as SpecialPaymentSetting[] })),
      finalize(() => this.loading.dismiss())
    );
  }

  @Action(DeleteSpecialPaymentRecord)
  deleteSpecialPaymentRecord(ctx: StateContext<SpecialPaymentModel>, { payload }: DeleteSpecialPaymentRecord): Observable<unknown> {
    return this.specialPaymentService.deleteProposal(payload.proposalId).pipe(
      tap(() => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.allProposals = state.allProposals.filter(proposal => proposal.id !== payload.proposalId);
            state.taskProposals = state.taskProposals.filter(proposal => proposal.id !== payload.proposalId);
            state.myInvolvementProposals = state.myInvolvementProposals.filter(proposal => proposal.id !== payload.proposalId);
          })
        );
      })
    );
  }

  @Action(SetSeasonForEmployeeInvalid)
  setSeasonForEmployeeInvalid(ctx: StateContext<SpecialPaymentModel>, { invalid }: SetSeasonForEmployeeInvalid): void {
    ctx.patchState({ isSeasonInvalidForEmployee: invalid });
  }

  @Action(SetSelectedSeasonSettings)
  setSelectedSeasonSettings(ctx: StateContext<SpecialPaymentModel>, { payload }: SetSelectedSeasonSettings): void {
    ctx.patchState({ selectedSeasonSettings: payload.selectedSeasonSetting });
  }
}
