import { TranslateService } from '@ngx-translate/core';
import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { ContentLanguage, FrontendType, StorageKey } from '@coin/shared/util-enums';
import { filter, switchMap, tap } from 'rxjs/operators';

import { CmsTranslationService, StorageService } from '@coin/shared/data-access';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface LanguageOptions {
  [lang: string]: string;
}

@Component({
  selector: 'coin-simple-lang-switch',
  templateUrl: './simple-lang-switch.component.html',
  styleUrls: ['./simple-lang-switch.component.scss']
})
export class SimpleLangSwitchComponent implements OnInit {
  public languageSelected = ContentLanguage.ENGLISH;
  public selectableLangs: LanguageOptions;

  @Input() excludedLangs: string[] = [];
  @Input() includedLangs: string[] = [];

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService,
    private cmsTranslationService: CmsTranslationService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.getLangs();
  }

  private getLangs(): void {
    this.cmsTranslationService
      .getTranslationConfigFile(FrontendType.customer)
      .pipe(
        filter(selectableLangs => !!selectableLangs),
        tap(selectableLangs => {
          this.selectableLangs = this.filterLangOptions(selectableLangs);
        }),
        switchMap(() => this.storageService.getAsync(StorageKey.LANGUAGE)),
        tap(lang => {
          this.languageSelected = lang;
        })
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private filterLangOptions(langOptions: LanguageOptions): LanguageOptions {
    return Object.keys(langOptions)
      .filter((langKey: string) => this.filterLangKey(langKey))
      .reduce((res, key: string) => {
        res[key] = langOptions[key];
        return res;
      }, {});
  }

  private filterLangKey(langKey: string): boolean {
    return !this.excludedLangs.includes(langKey) && (!this.includedLangs.length || this.includedLangs.includes(langKey));
  }

  public changeLanguage(lang: ContentLanguage): void {
    this.translateService.use(lang);
    this.languageSelected = lang;
    this.storageService.setAsync(StorageKey.LANGUAGE, lang);
  }
}
