import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-equity-legal-disclaimer',
  templateUrl: './equity-legal-disclaimer.component.html',
  styleUrls: ['./equity-legal-disclaimer.component.scss']
})
export class EquityLegalDisclaimerComponent {
  getTranslation(translateString): string | SafeHtml {
    return this.translateService.instant(translateString);
  }

  constructor(
    private dialogRef: MatDialogRef<EquityLegalDisclaimerComponent>,
    private translateService: TranslateService
  ) {}

  confirm(): void {
    this.dialogRef.close(true);
  }
  close(): void {
    this.dialogRef.close();
  }
}
