<div class="container max-width" [class.max-width]="!data?.options?.fullWidth" [class.full-width]="data?.options?.fullWidth">
  <div class="img-container" [class.layer]="data?.content?.textLayer">
    <img *ngIf="data?.content?.imageUrl" [src]="getSanitizedUrl(data.content.imageUrl)" [alt]="data?.content?.imageAlt" />
    <div *ngIf="data?.content?.textLayer" class="text-layer">
      <div class="text-layer-content">
        <div class="section mat-elevation-z4">
          <div>
            <div class="left">
              <mat-icon>exit_to_app</mat-icon>
            </div>
            <div class="text">
              <h3>{{ 'equity.welcome-headline' | translate }}</h3>
              <!-- <p class="bold">{{data.content?.textLayer.text}}</p> -->
              <p>{{ 'equity.welcome-text' | translate }}</p>
            </div>
          </div>
          <!-- <coin-action-button *ngIf="data.content?.textLayer.button as button" [white]="button.white" (click)="login()">
            {{button.name}}</coin-action-button> -->
          <coin-action-button class="primary" (click)="login()">{{ 'general.login' | translate }}</coin-action-button>
        </div>
      </div>
    </div>
  </div>
  <iframe *ngIf="data?.content?.videoUrl" frameborder="0" width="100%" height="500px" [src]="getSanitizedUrl(data.content.videoUrl)" [title]="data.content?.videoTitle"> </iframe>
  <div class="collage" *ngIf="data?.content?.images?.length" [class.collage-l]="data?.options?.isCollageL">
    <div class="img-container" *ngFor="let item of data.content.images">
      <h4 *ngIf="item?.headline" [class.contrast]="item?.contrast">{{ item.headline }}</h4>
      <img [src]="getSanitizedUrl(item.imageUrl)" [alt]="item.imageAlt" />
    </div>
  </div>
</div>
