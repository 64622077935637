import { trigger, transition, style, animate } from '@angular/animations';

export const widthExpandAllAnimation = trigger('widthExpand', [
  transition(':enter', [
    style({ width: '0px', minWidth: '0px', maxWidth: '0px', opacity: 0, overflow: 'hidden', padding: 0, margin: 0 }),
    animate('400ms ease-in', style({ width: '*', minWidth: '300px', maxWidth: '300px', opacity: 1, overflow: 'hidden', padding: '*', margin: '*' }))
  ]),
  transition(':leave', [
    style({ width: '*', minWidth: '300px', maxWidth: '300px', opacity: 1, overflow: 'hidden', padding: '*', margin: '*' }),
    animate('300ms ease-out', style({ width: '0px', minWidth: '0px', maxWidth: '0px', opacity: 0, overflow: 'hidden', padding: 0, margin: 0 }))
  ])
]);
