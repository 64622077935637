export enum TransactionState {
  Started = 'Started',
  Running = 'Running',
  Finished = 'Finished',
  Cancelled = 'Cancelled',
  Failed = 'Failed',

  // FE only
  None = 'None'
}
