import { PermissionResource } from '@coin/shared/util-enums';
import { TreeNode } from '../route-builder';

export const EmploymentConditionsLibraryModule = {
  segment: 'employment-conditions-library',
  title: 'home.employment-conditions-library',
  navigationRoot: true,
  emptyPage: true,
  children: {
    SignatureAuthorizationsComponent: {
      segment: 'signature-authorizations',
      title: 'employment-conditions-library.signature-authorizations-headline',
      icon: 'settings_applications',
      permissions: [PermissionResource.EmploymentConditionLibraryAdmin, PermissionResource.EmploymentConditionLibraryRestricted, PermissionResource.SignatureAuthorizationAdmin]
    },
    EclEmployeesComponent: {
      segment: 'ecl-employees',
      title: 'employment-conditions-library.ecl-employees-headline',
      icon: 'people_alt',
      permissions: [
        PermissionResource.EmploymentConditionLibraryAdmin,
        PermissionResource.EmploymentConditionLibraryRestricted,
        PermissionResource.EmploymentConditionLibraryGenerator
      ],
      children: {
        EmployeeAttributesComponent: {
          segment: 'attributes',
          params: ['employeeId'],
          title: 'employment-conditions-library.ecl-employee-attributes-headline'
        }
      }
    },
    EclAttributesComponent: {
      segment: 'attributes',
      title: 'employment-conditions-library.ecl-attributes-headline',
      icon: 'attribution',
      permissions: [PermissionResource.EmploymentConditionLibraryAdmin],
      params: ['attributeType']
    },
    EclLegalEntitiesComponent: {
      segment: 'legal-entities',
      title: 'employment-conditions-library.legal-entities-headline',
      icon: 'co_present',
      permissions: [PermissionResource.EmploymentConditionLibraryAdmin],
      children: {
        LegalEntitiesAttributesComponent: {
          segment: 'attributes',
          params: ['entityId'],
          title: 'employment-conditions-library.legal-entities-attributes-headline'
        }
      }
    },
    EclCategoriesComponent: {
      segment: 'categories',
      title: 'employment-conditions-library.categories-headline',
      icon: 'category',
      permissions: [PermissionResource.EmploymentConditionLibraryAdmin],
      params: ['type', 'clusterId', 'subjectId']
    }
  }
} as const satisfies TreeNode;
