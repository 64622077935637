import { Component, Input } from '@angular/core';
import { CmsItem } from '@coin/admin/cms/util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-cms-button',
  templateUrl: './cms-button.component.html',
  styleUrls: ['./cms-button.component.scss']
})
export class CmsButtonComponent {
  @Input() item: CmsItem<'button'>;
  @Input() contentEdit = false;

  public buttonStyles = ['primary', 'secondary'] as const;

  public buttonIcons = ['add', 'close', 'arrow', 'save', 'reset', 'notification', 'search', 'simulate', 'star'] as const;

  public openPage(link: string): void {
    window.open(link, '_blank');
  }
}
