import { v4 as uuid } from 'uuid';
import { SignatureRoleEnum, SignatureTypeEnum } from '@coin/shared/util-enums';

export const PREVIEW_CMS_LETTER_SIGNATURE = {
  content: {
    signatures: [
      {
        id: uuid(),
        role: SignatureRoleEnum.Employee,
        type: SignatureTypeEnum.Signature,
        completed: true,
        autoCompleted: false,
        autoCompleteSignatureText: 'Completed with autofinalize',
        employee: {
          id: uuid(),
          firstname: 'Peter',
          lastname: 'Schwarz',
          gid: 'XXXX1168'
        },
        createdAt: new Date().toUTCString(),
        completedAt: new Date().toUTCString()
      },
      {
        id: uuid(),
        role: SignatureRoleEnum.Manager,
        type: SignatureTypeEnum.Signature,
        completed: true,
        autoCompleted: false,
        employee: {
          id: uuid(),
          firstname: 'Thomas',
          lastname: 'Meister',
          gid: 'XXXX1163'
        },
        createdAt: new Date().toUTCString(),
        completedAt: new Date().toUTCString()
      }
    ]
  }
};
