import { Workbook, Worksheet } from 'exceljs';
import * as FileSaver from 'file-saver';
import { ExcelJSHelper } from './excel-js-helper';

export type ExcelDataValidation = (worksheet: Worksheet, data: unknown, index: number) => void;

interface TableDataValidation {
  items: Record<string, string>[];
  sheetName: string;
  cellNames: string[];
}
export interface ExportConfig {
  percentageColumns?: number[];
  numberColumns?: number[];
  columnFormats?: {
    column: number;
    format: string;
  }[];
  validations?: ExcelDataValidation[]; // only suitable for small arrays
  validationsAsTableData?: TableDataValidation[];
  autoStretchColumns?: boolean;
}

export class ITSExcelExport {
  public static readonly excelMimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

  private static fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  private static fileExtension = '.xlsx';

  public static exportExcel(excelData: unknown[], header: string[], fileName: string, config: ExportConfig, sheetName = 'Targets'): void {
    const workbook = new Workbook();
    const wsTargets = ExcelJSHelper.setWorksheetData(workbook, sheetName, header, excelData);

    this.setStylesAndData(wsTargets, config);
    this.setHeaderWidths(wsTargets, header);
    if (config.autoStretchColumns) {
      this.autoStretchColumns(wsTargets);
    }
    for (const validation of config.validations || []) {
      this.addDataValidation(wsTargets, excelData, validation);
    }

    if (config.validationsAsTableData?.length) {
      for (const validation of config.validationsAsTableData) {
        this.setAdditionalSheetValidation(workbook, validation, wsTargets, excelData);
      }
    }

    // actual download
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.fileType });
      FileSaver.saveAs(blob, fileName + this.fileExtension);
    });
  }

  private static setAdditionalSheetValidation(workbook: Workbook, validation: TableDataValidation, wsTargets: Worksheet, excelData: unknown[]): void {
    ExcelJSHelper.setWorksheetData(workbook, validation.sheetName, ['Data'], validation.items, 10, 'hidden');

    for (let index = 2; index < excelData.length + 2; index++) {
      for (const cellName of validation.cellNames) {
        wsTargets.getCell(cellName + index).dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: [`='${validation.sheetName}'!$A$2:$A$9999`] // only allow these ids
        };
      }
    }
  }

  private static setStylesAndData(worksheet: Worksheet, config: ExportConfig): void {
    for (const format of config.columnFormats || []) {
      worksheet.getColumn(format.column).numFmt = format.format;
    }

    for (const index of config.percentageColumns || []) {
      worksheet.getColumn(index).numFmt = '0.00%';
    }

    for (const index of config.numberColumns || []) {
      worksheet.getColumn(index).numFmt = '0.00';
    }
  }

  private static setHeaderWidths(worksheet: Worksheet, headings: string[]): void {
    for (const index in headings) {
      if (headings.hasOwnProperty(index)) {
        const i: number = parseInt(index);
        worksheet.getColumn(i + 1).width = 15;
      }
    }
  }

  private static addDataValidation(worksheet: Worksheet, excelData: unknown[], validation: ExcelDataValidation): void {
    excelData.forEach((data, index) => validation(worksheet, data, index));
  }

  public static cellItemsValidation(items: string[], cellName: string): ExcelDataValidation {
    return (worksheet: Worksheet, data: unknown, index: number) => {
      worksheet.getCell(cellName + (+index + 2)).dataValidation = {
        type: 'list',
        formulae: [`"${items.join(',')}"`] // only allow these values
      };
    };
  }

  private static autoStretchColumns(worksheet: Worksheet): void {
    worksheet.columns.forEach(column => {
      let highestCellLength = column.width;
      column.eachCell({ includeEmpty: true }, cell => {
        const cellLength = cell.value?.toString()?.length;
        if (cellLength > highestCellLength) {
          highestCellLength = cellLength;
        }
      });

      column.width = highestCellLength + 1;
    });
  }
}
