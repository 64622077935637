import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, HostBinding, HostListener, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { DefaultControlValueAccessor } from '@coin/shared/util-helpers';
import { TypeofPipe } from '@coin/shared/util-pipes';
import { debounce, filter, of, Subject, timer } from 'rxjs';
import { InputSize, MatFormFieldClass } from '../../input.types';

@Component({
  selector: 'coin-v2-text-input',
  templateUrl: './v2-text-input.component.html',
  styleUrls: ['./v2-text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatInputModule, FormsModule, MatIconModule, MatAutocompleteModule, MatTooltip, TypeofPipe],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => V2TextInputComponent),
      multi: true
    }
  ]
})
export class V2TextInputComponent extends DefaultControlValueAccessor<string> {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() icon: string;
  @Input() matFormFieldClass: MatFormFieldClass = 'customer-grey';
  @Input() autoCompleteOptions: string[];
  @Input() loading: boolean;
  @Input() clearable = false;
  @Input() prefix: string | TemplateRef<unknown>;
  @Input() size: InputSize = 'medium';
  /** Time delay (in ms) between manually typing and updating the internal control value. 300 for local load indicators, 500 for global indicators */
  @Input() inputDebounce: InputDebounceTime;
  @Input() showErrorTooltip = true;
  @Input() direction: 'ltr' | 'rtl' = 'ltr';

  @Output() customClear = new EventEmitter<void>();
  @ViewChild('textInput') private textInput: ElementRef<HTMLInputElement>;

  protected setValueDebounced$ = new Subject<string>();

  @HostBinding('class')
  private get class(): string {
    return `size-${this.size}`;
  }

  constructor() {
    super();
    this.setValueDebounced$
      .pipe(
        debounce(() => (this.inputDebounce ? timer(this.inputDebounce) : of(null))),
        filter(value => this.value !== value),
        takeUntilDestroyed()
      )
      .subscribe(value => {
        this.value = value;
      });
  }

  public focus(): void {
    this.textInput?.nativeElement?.focus();
  }

  public blur(): void {
    this.textInput?.nativeElement?.blur();
  }

  protected onClear(): void {
    if (!this.clearable) return;

    if (this.customClear.observed) {
      this.customClear.emit();
    } else if (this.value) {
      this.value = '';
      this.setValueDebounced$.next(this.value);
    }
  }
}

export const INPUT_DEBOUNCE_TIMES = [0, 300, 500] as const;
export type InputDebounceTime = (typeof INPUT_DEBOUNCE_TIMES)[number];
