<div [class.invalid]="!!errorMessage" [matTooltip]="errorMessage" class="lib-input">
  <span class="material-icons pre-icon" *ngIf="preIcon">{{ preIcon }}</span>
  <span class="pre-symbol" *ngIf="preSymbol">{{ preSymbol }}</span>
  <mat-form-field
    class="lib-input coin-form-field-outline {{ addClass ? 'coin-form-field-outline--' + addClass : '' }} {{ noMargin ? 'no-margin' : '' }}"
    [ngClass]="inputIconClasses"
    appearance="outline"
    floatLabel="always">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
      *ngIf="!isNumber && !isInteger"
      (keyup.enter)="onEnter()"
      (focus)="focusEnterLeave('enter')"
      (input)="onInput($event.target.value)"
      [ngStyle]="{ 'text-align': textAlign }"
      (focusout)="focusEnterLeave('leave')"
      [placeholder]="placeholder"
      matInput
      [readonly]="readonly"
      [(ngModel)]="value"
      [disabled]="disabled"
      [required]="required"
      autocomplete="off"
      #input />
    <input
      *ngIf="isNumber || isInteger"
      (keyup.enter)="onEnter()"
      (focus)="focusEnterLeave('enter')"
      (input)="onInput($event.target.value)"
      [ngStyle]="{ 'text-align': textAlign }"
      (focusout)="focusEnterLeave('leave')"
      [placeholder]="placeholder"
      matInput
      [(ngModel)]="value"
      [readonly]="readonly"
      [disabled]="disabled"
      [required]="required"
      type="number"
      appTwoDecimalPlaces
      [class.hide-input-arrows]="hideInputArrows"
      [isDecimal]="!isInteger"
      [minValue]="minValue"
      [maxValue]="maxValue"
      [step]="isInteger ? 1 : 0.01"
      #input />
    <div *ngIf="svgIcon" #svgicon class="svg-icon"></div>
  </mat-form-field>
  <span class="material-icons after-icon" [ngClass]="{ presym: preSymbol }" *ngIf="afterIcon">{{ afterIcon }}</span>
  <span class="after-symbol" *ngIf="afterSymbol">{{ afterSymbol }}</span>
</div>
