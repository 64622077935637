import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-guest-access',
  templateUrl: './guest-access.component.html',
  styleUrls: ['./guest-access.component.scss']
})
export class GuestAccessComponent {
  constructor(private dialogRef: MatDialogRef<GuestAccessComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
