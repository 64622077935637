<div class="container">
  <div>
    <div class="image" [class.edit]="contentEdit" [style.background-image]="'url(' + item?.content?.img + ')'">
      <coin-action-button (click)="editPicture()" class="secondary" svgIcon="edit" [noicon]="true" *ngIf="contentEdit">
        {{ 'cms.emails.change-image-button' | translate }}
      </coin-action-button>
      <coin-input *ngIf="item?.content && contentEdit" [(ngModel)]="item.content.link" label="Image URL"></coin-input>
    </div>
    <mat-checkbox *ngIf="contentEdit" [(ngModel)]="item.inverted" [checked]="item.inverted" class="inverse-checkbox">
      {{ 'cms.emails.inverted-checkbox' | translate }}
    </mat-checkbox>
  </div>

  <app-html-editor [showAdditionalEditOptions]="showAdditionalEditOptions" [contentEdit]="contentEdit" [isContentText]="true" [item]="item"></app-html-editor>
</div>
