import { Directive, OnDestroy, OnInit } from '@angular/core';
import { LifecycleDependant } from '@coin/shared/util-models';

@Directive()
/**
 * Uses Angular lifecycle methods to construct and destroy non-angular objects along with the angular classes they are used with.
 */
export class LifecycleHelper implements OnInit, OnDestroy {
  private readonly lifecycleDependants: LifecycleDependant[] = [];

  protected addToLifecycle(lifecycleDependant: LifecycleDependant): void {
    this.lifecycleDependants.push(lifecycleDependant);
  }

  ngOnInit(): void {
    this.lifecycleDependants.forEach(dependant => dependant?.onInit?.());
  }

  ngOnDestroy(): void {
    this.lifecycleDependants.forEach(dependant => dependant?.onDestroy());
  }
}
