import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

@Injectable()
export class RequestTrackerInterceptorService implements HttpInterceptor {
  // TODO: good candidate for signal
  private _activeRequests$ = new BehaviorSubject(0);
  public hasActiveRequests$ = this._activeRequests$.pipe(
    map(count => !!count),
    distinctUntilChanged()
  );

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this._activeRequests$.next(this._activeRequests$.value + 1);

    return next.handle(request).pipe(
      finalize(() => {
        this._activeRequests$.next(this._activeRequests$.value - 1);
      })
    );
  }
}
