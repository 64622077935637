import { Component, OnInit } from '@angular/core';
import { AuthService } from '@coin/modules/auth/data-access';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'auth-gms-angular-client',
  template: '',
  styles: []
})
export class AuthGmsClientCallbackComponent implements OnInit {
  constructor(private auth: AuthService) {}

  ngOnInit() {
    this.auth.handleLoginCallback();
  }
}
