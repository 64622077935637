<ng-container [ngSwitch]="operator">
  <mat-icon *ngSwitchCase="'Equal'" class="operator-icon" svgIcon="equal"></mat-icon>
  <mat-icon *ngSwitchCase="'NotEqual'" class="operator-icon" svgIcon="notequal"></mat-icon>
  <mat-icon *ngSwitchCase="'GreaterOrEqual'" class="operator-icon" svgIcon="greaterorequal"></mat-icon>
  <mat-icon *ngSwitchCase="'Greater'" class="operator-icon" svgIcon="greater"></mat-icon>
  <mat-icon *ngSwitchCase="'Less'" class="operator-icon" svgIcon="less"></mat-icon>
  <mat-icon *ngSwitchCase="'LessOrEqual'" class="operator-icon" svgIcon="lessorequal"></mat-icon>
  <mat-icon *ngSwitchCase="'StartsWith'" class="operator-icon">start</mat-icon>
  <mat-icon *ngSwitchCase="'EndsWith'" class="operator-icon" svgIcon="endswith"></mat-icon>
  <mat-icon *ngSwitchCase="'Contains'" class="operator-icon" svgIcon="contains"></mat-icon>
  <mat-icon *ngSwitchCase="'NotContains'" class="operator-icon" svgIcon="notcontains"></mat-icon>
  <mat-icon *ngSwitchCase="'Empty'" class="operator-icon">do_disturb</mat-icon>
  <mat-icon *ngSwitchCase="'NotEmpty'" class="operator-icon">radio_button_checked</mat-icon>
  <mat-icon *ngSwitchCase="'NotStartsWith'" class="operator-icon" svgIcon="notstartswith"></mat-icon>
</ng-container>
