import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Toast, ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs';

export function notifyUpdates(): void {
  const updates = inject(SwUpdate);
  const toast = inject(ToastrService);

  updates.versionUpdates
    .pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      takeUntilDestroyed()
    )
    .subscribe(update => {
      const hasPreviousToastOpen = !!toast.toasts.find(t => t.portal.componentType === UpdateMessageComponent);
      if (!hasPreviousToastOpen && update.latestVersion.hash !== update.currentVersion.hash) {
        toast.info(undefined, undefined, { disableTimeOut: true, toastComponent: UpdateMessageComponent, toastClass: 'ngx-toastr update-notifier' });
      }
    });
}

@Component({
  template: `
    <div class="container">
      <div>A new version is available. Please refresh the page to update.</div>
      <button (click)="reloadPage()">
        <span class="icon">refresh</span>
        Refresh
      </button>
    </div>
  `,
  standalone: true,
  imports: [MatIcon],
  styles: `
    :host {
      display: block;

      .container {
        padding-left: 50px;
        display: flex;

        div {
          margin-top: 15px;
          margin-bottom: 15px;
        }

        button {
          width: 10rem;
          flex-direction: column;
          max-width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          overflow: hidden;
          -webkit-user-select: none;
          user-select: none;
          gap: 0.5rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          justify-content: center;
          background-color: white;
          font-weight: bold;

          .icon {
            font-size: 16px;
            font-family: 'Material Icons Outlined';
          }
        }
      }
    }
  `
})
class UpdateMessageComponent extends Toast {
  protected reloadPage() {
    location.reload();
  }
}
