// for reference. data is backend based
export enum AccessRole {
  HrBusinessPartner = 'HrBusinessPartner',
  LineManager = 'LineManager',
  CompanyManager = 'CompanyManager',
  Employee = 'Employee',
  Public = 'Public',
  CBAdmin = 'CBAdmin',
  SuperAdmin = 'SuperAdmin'
}
