import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHelpersService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { Deputy, EmployeeSlim, PaginatedResult } from '@coin/shared/util-models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeputyService {
  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelpersService
  ) {}

  getCurrentDeputies(page: number): Observable<Deputy[]> {
    return this.httpClient.get<PaginatedResult<Deputy>>(`${environment.api.baseUrl}/customer/api/v1/master/employees/deputies?Paging.Page=${page}&Paging.Size=50`).pipe(
      map(data => data.content),
      this.httpHelper.handleError('Cannot load your current deputies.')
    );
  }

  getPotentialDeputies(gid: string, firstName: string, lastName: string, page: number): Observable<EmployeeSlim[]> {
    let params = new HttpParams();
    if (gid) params = params.append('Query.Gid', gid);
    if (firstName) params = params.append('Query.Firstname', firstName);
    if (lastName) params = params.append('Query.Lastname', lastName);
    return this.httpClient
      .get<PaginatedResult<EmployeeSlim>>(`${environment.api.baseUrl}/customer/api/v1/master/employees/deputies?allPotentials=true&Paging.Page=${page}&Paging.Size=50`, { params })
      .pipe(
        map(data => data.content),
        this.httpHelper.handleError('Cannot load potential deputies.')
      );
  }

  getDeputiesForEmulation(): Observable<Deputy[]> {
    return this.httpClient
      .get<PaginatedResult<Deputy>>(`${environment.api.baseUrl}/customer/api/v1/master/employees/deputies?forEmulation=true&Paging.Page=1&Paging.Size=100`)
      .pipe(
        map(data => data.content),
        this.httpHelper.handleError('Cannot load potential deputies.')
      );
  }

  addDeputy({ id, startsAt, endsAt }: { id: string; startsAt: string; endsAt: string }): Observable<Deputy> {
    return this.httpClient
      .post<Deputy>(`${environment.api.baseUrl}/customer/api/v1/master/employees/deputies`, { deputyId: id, startsAt, endsAt })
      .pipe(this.httpHelper.handleError('Cannot set employee as deputy.'));
  }

  removeDeputy(deputyId: string): Observable<void> {
    return this.httpClient
      .delete<void>(`${environment.api.baseUrl}/customer/api/v1/master/employees/deputies/${deputyId}`)
      .pipe(this.httpHelper.handleError('Cannot remove employee as deputy.'));
  }
}
