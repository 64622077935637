import { Observable } from 'rxjs';
import { FormControl, ValidationErrors } from '@angular/forms';
import { Moment } from 'moment';
import { RuleEngineInput } from '@coin/shared/util-models';
import { isValuelessOperator } from './v2-lightweight-rule-engine.component';

export const LIGHTWEIGHT_OPERATORS = [
  'Equal',
  'NotEqual',
  'Contains',
  'NotContains',
  'StartsWith',
  'EndsWith',
  'Greater',
  'GreaterOrEqual',
  'Less',
  'LessOrEqual',
  'Empty',
  'NotEmpty',
  'NotStartsWith'
] as const;
export type LightWeightOperator = (typeof LIGHTWEIGHT_OPERATORS)[number];

export const LIGHTWEIGHT_CONDITIONS = ['Or', 'And'] as const;
export type LightWeightCondition = (typeof LIGHTWEIGHT_CONDITIONS)[number];

export type RuleValue = (string | boolean | Moment)[];

export const CONDITION_LABELS: Record<LightWeightCondition, string> = { And: 'all', Or: 'any' };

export interface LightweightRule {
  field: string;
  operator: LightWeightOperator;
  value: RuleValue;
}

export interface LightweightRuleSet {
  condition: LightWeightCondition;
  rules: LightweightRule[];
}

export type LightweightRuleEngine = { condition: LightWeightCondition; rules: Array<LightweightRule | LightweightRuleSet> };

export interface LightweightRuleEngineConfig {
  [field: string]: {
    operators: LightWeightOperator[];
    fieldValues$: Observable<string[]>;
    type: RuleEngineInput;
  };
}

export function RuleEngineValidator(control: FormControl<LightweightRuleEngine>): ValidationErrors | null {
  const validationError = { ruleEngine: true };

  if (!control.value?.rules?.length) return null;

  const ruleSets = control.value.rules.filter((item): item is LightweightRuleSet => 'rules' in item && !!item.rules.length);
  if (ruleSets.some(isRuleSetInvalid)) return validationError;

  const rules = [...control.value.rules.filter((item): item is LightweightRule => !('rules' in item) || !item.rules.length), ...ruleSets.map(set => set.rules).flat()];
  if (rules.some(isRuleInvalid)) return validationError;

  return null;
}

function isRuleSetInvalid(ruleSet: LightweightRuleSet): boolean {
  return ruleSet.rules.length < 2;
}

function isRuleInvalid(rule: LightweightRule): boolean {
  return !rule.operator || !rule.field || isInvalidRuleValue(rule);
}

function isInvalidRuleValue({ value, operator }: LightweightRule): boolean {
  const isNullValue = !value?.length || value[0] === '' || value[0] === null || value[0] === undefined;
  return isValuelessOperator(operator) ? !isNullValue : isNullValue;
}
