import { Injectable } from '@angular/core';
import { environment } from '@coin/shared/util-environments';

@Injectable({
  providedIn: 'root'
})
export class SetUserImageService {
  public setPersonalPicture(): void {
    const globalCurrentHost = environment.setProfilePictureEndpoint; // the stage the application is on currently var avatarVersion = new Date().getTime(); // parameter to refresh after closing of popup
    const profileWindow = window.open(`${globalCurrentHost}profile/photo`, 'profileWindow', 'height=700,width=800,resizable=1,scrollbars=yes');
    const profileWindowPoller = setInterval(() => {
      try {
        if (profileWindow.closed) {
          clearInterval(profileWindowPoller);
        }
      } catch (e) {
        clearInterval(profileWindowPoller);
      }
    }, 500);
  }
}
