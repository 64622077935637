import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EquityPageDataService } from '../../../../services/equity-page-data.service';

export interface CardModel {
  headline: string;
  backgroundUrl: string;
  text: string;
  icon: string;
  button: {
    name: string;
    url: string;
  };
}

@Component({
  selector: 'app-equity-card-item',
  templateUrl: './equity-card-item.component.html',
  styleUrls: ['./equity-card-item.component.scss']
})
export class EquityCardItemComponent {
  @Input() card: CardModel;
  @Input() small: boolean;

  get iconUrl(): string {
    return `assets/equity/icons/general/${this.card?.icon}.svg`;
  }

  constructor(
    private domSanitizer: DomSanitizer,
    public equityPageDataService: EquityPageDataService
  ) {}

  onButtonClick(button): void {
    if (button.url) {
      window.open(button.url, '_blank');
    } else if (button.program) {
      this.equityPageDataService.displayPage({ fileName: button.program });
    }
  }

  getSanitizedUrl(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
