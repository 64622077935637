import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EllipsisTooltipDirective } from '@coin/shared/util-directives';

@Component({
  selector: 'coin-v2-dual-pill',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule, EllipsisTooltipDirective],
  templateUrl: './v2-dual-pill.component.html',
  styleUrls: ['./v2-dual-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2DualPillComponent implements OnInit {
  @Input() icon?: string;
  @Input() svgIcon?: string;
  @Input() contentLeft: string | number | TemplateRef<unknown>;
  @Input() textRight?: string | number | TemplateRef<unknown>;
  @Input({ required: true }) themeColor: PillColor;
  @Input() size: PillSize = 'medium';
  @Output() clicked = new EventEmitter<void>();

  ngOnInit(): void {
    const bothDefined = this.icon && this.svgIcon;
    if (bothDefined) {
      throw new Error('V2DualPillComponent requires either "icon" or "svgIcon" to be defined or none of them.');
    }
  }

  @HostBinding('class')
  private get classes(): string {
    return `theme-${this.themeColor} size-${this.size}`;
  }

  @HostBinding('style.cursor')
  private get pointer(): string {
    return this.clicked.observed ? `pointer` : '';
  }

  @HostListener('click')
  private onClick(): void {
    this.clicked.next();
  }

  protected isTemplate(content: unknown): content is TemplateRef<unknown> {
    return content instanceof TemplateRef;
  }
}

export const THEME_COLORS = ['petrol', 'orange', 'grey', 'darkgrey', 'red', 'blue', 'purple', 'green'] as const;
export type PillColor = (typeof THEME_COLORS)[number];

export const PILL_SIZES = ['small', 'medium', 'large'] as const;
export type PillSize = (typeof PILL_SIZES)[number];
