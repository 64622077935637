import { Store } from '@ngxs/store';
import { PermissionResource } from '@coin/shared/util-enums';
import { UserState } from './user.state';

export class PermissionHelper {
  private static hasPositionEvaluationPermissions(store: Store): boolean {
    return store.selectSnapshot(UserState.allPermissions)?.some(permission => permission.resource === PermissionResource.PositionEvaluationHR);
  }

  public static isAdmin(store: Store): boolean {
    return store.selectSnapshot(UserState.allPermissions)?.some(permission => permission.resource === PermissionResource.All);
  }

  public static isCBAdmin(store: Store): boolean {
    return store.selectSnapshot(UserState.allPermissions)?.some(permission => permission.resource === PermissionResource.AdminApp) && this.hasPositionEvaluationPermissions(store);
  }

  public static isHrUser(store: Store): boolean {
    return store.selectSnapshot(UserState.allPermissions)?.every(permission => permission.resource !== PermissionResource.AdminApp) && this.hasPositionEvaluationPermissions(store);
  }

  public static isManager(store: Store): boolean {
    return !this.isAdmin(store) && !this.isCBAdmin(store) && !this.isHrUser(store);
  }

  public static isNonAdmin(store: Store): boolean {
    return !this.isAdmin(store) && !this.isCBAdmin(store);
  }

  public static hasPermission(store: Store, permissionResource: string[]): boolean {
    return store.selectSnapshot(UserState.allPermissions)?.some(permission => [PermissionResource.All, ...permissionResource].includes(permission.resource));
  }
}
