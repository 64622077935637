import { animate, style, transition, trigger } from '@angular/animations';

export const flyInFromTop = trigger('flyInFromTop', [
  transition(':enter', [
    style({ transform: 'translateY(-60%)', 'box-shadow': 'inset 0px 0px 20px -8px var(--purple700)' }),
    animate('400ms ease-in', style({ transform: 'translateY(0%)' })),
    animate('800ms cubic-bezier(1,0,1,0)', style({ 'box-shadow': 'none' }))
  ]),
  transition(':leave', [animate('300ms ease-out', style({ transform: 'translateY(-60%)' }))])
]);
