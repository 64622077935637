export enum TodoTaskType {
  LetterEmployee = 'LetterEmployee',
  LetterManager = 'LetterManager',
  Allocation = 'Allocation',
  ActiveConfirmation = 'ActiveConfirmation',
  PassiveConfirmation = 'PassiveConfirmation',
  General = 'General',
  FinalIncumbent = 'FinalIncumbent',
  HeadcountActions = 'HeadcountActions'
}
