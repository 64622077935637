import { Component, DestroyRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GlobalEventsService } from '@coin/shared/util-helpers';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss']
})
export class DividerComponent implements OnInit, OnDestroy {
  @Input() el1: HTMLElement;
  @Input() el2: HTMLElement;
  @Input() content: HTMLElement;
  @Input() minWidth = 30;
  @Input() maxWidth = 70;
  @Input() element1Width = 50;
  @Input() element2Width = 50;
  @Input() dragLineWidth = 20;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() resize = new EventEmitter<void>();

  public anim = false;
  private cleanListeners$ = new Subject<boolean>();

  @HostListener('mousedown', ['$event'])
  public onMouseDown(event: MouseEvent): void {
    event.preventDefault();
    this.events
      .listen('mouseup')
      .pipe(takeUntil(this.cleanListeners$), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.cleanListeners$.next(true));
    this.events.listen('mousemove').pipe(takeUntil(this.cleanListeners$), takeUntilDestroyed(this.destroyRef)).subscribe(this.onMouseMove);
  }

  constructor(
    private renderer: Renderer2,
    private events: GlobalEventsService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    if (this.el1 && this.el2 && this.content) {
      this.renderer.setStyle(this.el1, 'width', `calc(${this.element1Width}% - ${this.dragLineWidth / 2}px)`);
      this.renderer.setStyle(this.el2, 'width', `calc(${this.element2Width}% - ${this.dragLineWidth / 2}px)`);
    }
  }

  public ngOnDestroy(): void {
    this.cleanListeners$.next(true);
    this.cleanListeners$.complete();
  }

  private onMouseMove = (event: MouseEvent): void => {
    event.preventDefault();
    const contentRect = this.content.getBoundingClientRect();
    const newPosX = event.clientX - contentRect.left;
    const contentWidth = this.content.offsetWidth;
    const relativeEl1 = (newPosX / contentWidth) * 100;
    const relativeEl2 = ((contentWidth - newPosX) / contentWidth) * 100;

    const element1Width = relativeEl1 > this.minWidth ? (relativeEl1 < this.maxWidth ? relativeEl1 : this.maxWidth) : this.minWidth;
    const element2Width = relativeEl2 > this.minWidth ? (relativeEl2 < this.maxWidth ? relativeEl2 : this.maxWidth) : this.minWidth;
    this.renderer.setStyle(this.el1, 'width', `calc(${element1Width}%  - ${this.dragLineWidth / 2}px)`);
    this.renderer.setStyle(this.el2, 'width', `calc(${element2Width}%  - ${this.dragLineWidth / 2}px)`);

    this.resize.emit();
  };

  public mouseEnterLeave(): void {
    this.anim = !this.anim;
  }
}
