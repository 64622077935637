import { v4 as uuid } from 'uuid';

export const CMS_LETTER_TEMPLATE_REVIEW_PERIOD_DATA_HTML =
  `<table id="${uuid()}" class="cms-simple-table review-period-data">` +
  `<tbody>
  <tr>
    <td>DATE START</td>
    <td>DATE END</td>
    <td>CALENDAR DAYS</td>
    <td>PRORATION</td>
    <td>DEPARTMENT</td>
    <td>LOCATION</td>
    <td>MANAGER</td>
  </tr>
  <tr>
    <td>{rec_startDate}</td>
    <td>{rec_endDate}</td>
    <td>{rec_calenderDays}</td>
    <td>{rec_proration}</td>
    <td>{initial_orgCode}</td>
    <td>{initial_locationOffice}</td>
    <td>{initial_lineManager}</td>
  </tr>
</tbody>` +
  `</table>`;
