import { Employee } from '@coin/shared/util-models';
import { OrgTreeFilterType } from '../../../enums/org-tree-filter.enum';
import { OrgTreeSortType } from '../../../enums/org-tree-sorting.enum';

export class LoadNodes {
  static readonly type = '[OrgReview] Load Nodes';
  constructor(public readonly payload: { reload: boolean }) {}
}

export class LoadNodeChildren {
  static readonly type = '[OrgReview] Load Node Children';
  constructor(public readonly payload: { node: Employee; reload: boolean; resetSearchNode: boolean }) {}
}

export class LoadNodeChildrenSuccess {
  static readonly type = '[OrgReview] Load Node Children Success';
  constructor(public readonly payload: { children: Employee[]; reload: boolean; id: string }) {}
}

export class ResetChildrenVisibility {
  static readonly type = '[OrgReview] Reset Children Visibility';
}

export class UpdateBreadcrumbItems {
  static readonly type = '[OrgReview] Update Breadcrumb Items';
  constructor(public readonly payload?: { id: string }) {}
}

export class UpdateNode {
  static readonly type = '[OrgReview] Update Node';
  constructor(public readonly payload?: unknown) {}
}

export class UpdateSortingState {
  static readonly type = '[OrgReview] Update Sorting';
  constructor(public readonly payload: OrgTreeSortType) {}
}
export class UpdateFilterState {
  static readonly type = '[OrgReview] Update Filter';
  constructor(public readonly payload: OrgTreeFilterType) {}
}
export class UpdateSearchedNode {
  static readonly type = '[OrgReview] Update Searched node';
  constructor(public readonly payload: { node: Employee; isActive: boolean }) {}
}

export class OpenEmployeeInView {
  static readonly type = '[OrgReview] Open Employee In View';
  constructor(public readonly payload: { node: Employee; fullTree: boolean; isDeepLink: boolean; deepLinkOrgCode?: string }) {}
}

export class ResetState {
  static readonly type = '[OrgReview] Reset State';
}
