import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MeritSimulationMember } from '@coin/customer/merit-incentive/util';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { iif, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { BudgetAllocationOverview, BudgetAllocationOverviewDto } from '../../merit-shared/models/budget-allocation-overview.model';
import { MeritTask } from '../../merit-shared/models/merit-tasks.model';
import { MeritAllocationImpersonatedEmployeeService } from '../../merit-shared/services/merit-allocation-impersonated-employee.service';

@Injectable({
  providedIn: 'root'
})
export class MeritTasksService {
  constructor(
    private httpClient: HttpClient,
    private httpHelpersService: HttpHelpersService,
    private loadingService: LoadingService,
    private meritAllocationImpersonatedEmployeeService: MeritAllocationImpersonatedEmployeeService
  ) {}

  public getMeritTaskByEmployee(seasonId: string, employeeId: string, orgCode: string): Observable<MeritTask> {
    this.loadingService.present();
    return this.httpClient
      .get<BudgetAllocationOverviewDto>(
        `${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/tasks/overview/${employeeId}?orgCode=${encodeURIComponent(
          orgCode
        )}${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery()}`
      )
      .pipe(
        map(
          overview =>
            ({
              budgetOverview: !!overview && !!Object.keys(overview)?.length ? new BudgetAllocationOverview(overview) : null
            }) as MeritTask
        ),
        catchError(() => of(null)),
        switchMap(overview => iif(() => !!overview && !!Object.keys(overview)?.length, this.getDueDateTask(seasonId, employeeId, overview), of({ budgetOverview: null }))),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getDueDateTask(seasonId: string, employeeId: string, overview: MeritTask): Observable<MeritTask> {
    return this.httpClient.get<string>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/${employeeId}/due-date`).pipe(
      map(dueDate => {
        overview.dueDate = dueDate;
        return overview;
      }),
      catchError(() => of(overview))
    );
  }

  public setTaskStatus(employeeId: string, seasonId: string): Observable<unknown> {
    this.loadingService.present();
    return this.httpClient.patch<MeritSimulationMember>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/${employeeId}/disable`, {}).pipe(
      this.httpHelpersService.handleError('Cannot update simulation'),
      finalize(() => this.loadingService.dismiss())
    );
  }
}
