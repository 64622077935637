import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObservableCachingHelper } from '@coin/shared/util-helpers';
import { COUNTRY_CODES, COUNTRY_CODES_DICT, CountryCode } from '@coin/shared/util-models';

@Injectable({
  providedIn: 'root'
})
export class IsoToCountryService {
  private cache = new ObservableCachingHelper<CountryCode[]>();

  constructor(private http: HttpClient) {}

  /// Uses iso code alpha-2
  public getCountryByIso(isoCode: string): Observable<string | undefined> {
    return this.loadCountries().pipe(map(countries => countries.find(value => value.alpha2 === isoCode)?.name));
  }

  public getCountryByIsoCode(isoCode: string): CountryCode {
    return COUNTRY_CODES_DICT[isoCode];
  }

  public getIsoByCountryName(countryName: string): Observable<string | undefined> {
    return this.loadCountries().pipe(map(countries => countries.find(value => value.name.toLowerCase() === countryName.toLowerCase())?.alpha2));
  }

  public loadCountries(): Observable<CountryCode[]> {
    return this.cache.cacheSetOnMiss$(of(structuredClone(COUNTRY_CODES)), ['country-codes'], true);
  }
}
