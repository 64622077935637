<div id="confirmation-dialog" class="container" [style.width]="width">
  <h4 *ngIf="data.headline" class="top-header">{{ data.translate ? (data.headline | translate) : data.headline }} {{ getHeadlineData() }}</h4>
  <div class="content">
    <h4 *ngIf="!data.headline">{{ data.translate ? getTranslatedValue(data.msg) : data.msg }}</h4>
    <p class="msg" *ngIf="data.headline && data.msg" [innerHtml]="data.translate ? getTranslatedValue(data.msg) : data.msg"></p>
    <coin-dropdown
      *ngIf="data?.dropdownValues?.length"
      [label]="data?.dropdownLabel"
      [(ngModel)]="selectedDropdownValue"
      [items]="data.dropdownValues"
      [shadow]="true"
      [withSearch]="data.dropdownWithSearch"
      [displayFn]="data?.dropdownDisplayFn || dropdownDisplayFn">
    </coin-dropdown>
    <coin-textarea
      *ngIf="data.multilineInput"
      class="comment-text"
      title="text"
      name="text"
      id="text"
      [(ngModel)]="providedInput"
      placeholder="..."
      [disabled]="data?.inputReadOnly" />
    <div class="comment-text" *ngIf="data?.provideInput && !data.multilineInput && !data.dateInput">
      <input title="text" name="text" id="text" [(ngModel)]="providedInput" placeholder="..." [readOnly]="data?.inputReadOnly" />
    </div>
    <div class="comment-text" *ngIf="data?.provideInput && !data.multilineInput && data.dateInput">
      <coin-v2-date-input placeholder="..." [(ngModel)]="providedInput"> </coin-v2-date-input>
    </div>

    <div class="sub-text" *ngIf="data?.noteText">
      <mat-icon>warning</mat-icon>
      <p>{{ data.translate ? (data.noteText | translate) : data.noteText }}</p>
    </div>
    <coin-v2-dialog-footer color="white" padding="none">
      <coin-action-button *ngIf="data.cancelMsg" (click)="cancel()"> {{ data.translate ? (data.cancelMsg | translate) : data.cancelMsg }}</coin-action-button>
      <div class="v2-footer-spacer"></div>
      <coin-action-button
        *ngIf="data.thirdButtonMsg"
        (click)="onThirdButtonClick()"
        [disabled]="data?.inputMandatory ? !(data.dateInput ? providedInput : providedInput?.trim()) : false">
        {{ data.translate ? (data.thirdButtonMsg | translate) : data.thirdButtonMsg }}
      </coin-action-button>
      <coin-action-button
        class="primary"
        (click)="confirm()"
        [disabled]="data?.inputMandatory ? !(data.dateInput ? providedInput : providedInput?.trim()) : data.disableConfirmButton">
        {{ data.translate ? (data.confirmMsg | translate) : data.confirmMsg }}
      </coin-action-button>
    </coin-v2-dialog-footer>
  </div>
</div>