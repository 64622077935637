import { Component, Input } from '@angular/core';
import { CmsItem } from '@coin/admin/cms/util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-equity-expand-box',
  templateUrl: './equity-expand-box.component.html',
  styleUrls: ['./equity-expand-box.component.scss']
})
export class EquityExpandBoxComponent {
  @Input() item: CmsItem<'expand-box'>;
  @Input() contentEdit = false;

  public boxExpanded = false;
}
