import { Component, Input } from '@angular/core';

export interface TileModel {
  headline: string;
  icon?: string;
  text: string;
  backgroundUrl?: string;
}

@Component({
  selector: 'app-equity-tile-item',
  templateUrl: './equity-tile-item.component.html',
  styleUrls: ['./equity-tile-item.component.scss']
})
export class EquityTileItemComponent {
  @Input() tile: TileModel;
}
