import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Comment } from '@coin/shared/util-models';
import { environment } from '@coin/shared/util-environments';
import { finalize } from 'rxjs/operators';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';

@Injectable({
  providedIn: 'root'
})
export class SeasonRecordsCommentsService {
  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private httpHelpersService: HttpHelpersService
  ) {}

  public getComments(recordId: string): Observable<Comment[]> {
    this.loadingService.present();
    return this.httpClient.get<Comment[]>(`${environment.api.baseUrl}/admin/api/v2/master/season-records/${recordId}/comments`).pipe(
      this.httpHelpersService.handleError('Cannot get comments'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  public postComment(recordId: string, comment: Comment): Observable<Comment> {
    this.loadingService.present();
    return this.httpClient.post<Comment>(`${environment.api.baseUrl}/admin/api/v2/master/season-records/${recordId}/comments`, comment).pipe(
      this.httpHelpersService.handleError('Cannot post comment'),
      finalize(() => this.loadingService.dismiss())
    );
  }
}
