<mat-form-field
  [matTooltip]="errorTooltip$ | async"
  [matTooltipDisabled]="ngControl?.untouched"
  matTooltipClass="error-tooltip"
  class="lib-input coin-form-field-outline coin-form-field-outline--{{ matFormFieldClass }} no-margin"
  appearance="outline"
  floatLabel="always">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input #numberInput type="number" (blur)="onTouch(value)" [placeholder]="placeholder" matInput [(ngModel)]="value" [disabled]="disabled" autocomplete="off" />
</mat-form-field>
