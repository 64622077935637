import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent {
  @Input() maxHeight: string;

  get textContent(): string {
    return this.translateService.instant('guided-tour.intro-text');
  }
  constructor(private translateService: TranslateService) {}
}
