<mat-expansion-panel [expanded]="isExpanded" [hideToggle]="isLocked" (opened)="togglePanelExpansion()">
  <mat-expansion-panel-header [class.locked]="isLocked">
    <mat-panel-title>
      <div class="left">
        <h4 class="position">{{ index + 1 }}</h4>
        <h4>{{ question?.title | translate }}</h4>
        <div *ngIf="selectedAnswer && !isExpanded" class="selected-answer">
          <mat-icon>check</mat-icon>
          <p>{{ selectedAnswer.title | translate }}</p>
        </div>
      </div>
      <coin-action-button *ngIf="!isLocked && selectedAnswer && isExpanded" class="primary" (click)="openNextQuestion()">{{
        (isLastElement ? 'survey.general.complete-survey' : 'survey.general.next-question') | translate
      }}</coin-action-button>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <div class="answers">
      <coin-customer-survey-answer
        *ngFor="let answer of question?.answers; trackBy: trackByItemFn"
        [answer]="answer"
        [isEditable]="isEditable && !answer.isSelected"
        (toggle)="onAnswerToggle($event, answer)"></coin-customer-survey-answer>
    </div>
  </ng-template>
</mat-expansion-panel>
