<p *ngIf="contentEdit" style="margin-bottom: 20px">Just a line.</p>
<div class="line-style" [ngClass]="{ 'line-style--fullWidth': item?.fullWidth }"></div>

<ng-container *ngIf="contentEdit && item">
  <mat-checkbox class="spacing-elements" [(ngModel)]="item.fullWidth" [checked]="item.fullWidth">Full width</mat-checkbox>
  <ng-container *ngIf="showBackgroundPicker">
    <div class="spacing-elements">
      <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
    </div>
    <div class="spacing-elements">
      <coin-color-picker [(ngModel)]="item.textColor" [isTextColorPicker]="true"></coin-color-picker>
    </div>
  </ng-container>
</ng-container>
